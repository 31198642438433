import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import DragAndDropToUpload from "./DragAndDropToUpload";
import Webcam from "react-webcam";
import CustomModal from "components/base/Modal/Modal";
import { useState } from "react";
import { Button } from "@mui/material";

const videoConstraints = {
  width: 300,
  height: 300,
  facingMode: "user",
};
const ThirdFormLiveness = ({ formik, styles, image, setImage }) => {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);

  const btn = {
    width: "100%",
    height: 50,
    fontSize: 14,
    fontWeight: 500,
    color: "white.main",
    marginTop: "8px",
  };
  const btn2 = {
    width: "100%",
    height: 50,
    fontSize: 14,
    fontWeight: 500,
    marginTop: "8px",
    border: "1px solid #f03434",
  };
  return (
    <Box>
      <Stack direction="row" gap={{ sx: 0, sm: 2 }} mt={3}>
        {image ? (
          <div
            style={{
              width: "100%",
              display: "grid",
              placeItems: "center",
              borderRadius: "10px",
              border: "1px dashed #515151",
              paddingTop: "4px",
              cursor: "pointer",
            }}
            onClick={() => {
              setOpen("true");
              setStep(2);
            }}
          >
            <div
              style={{
                pointerEvents: "none",
              }}
            >
              <img
                src={image}
                style={{
                  width: "200px",
                  height: "200px",
                }}
                alt="liveness_image"
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
            }}
            onClick={() => {
              console.log("camera");
              setOpen("true");
            }}
          >
            <div
              style={{
                pointerEvents: "none",
              }}
            >
              <DragAndDropToUpload
                styles={styles}
                formik={formik}
                label="Take a photo"
                type="take"
              />
            </div>
          </div>
        )}
      </Stack>

      <CustomModal
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
      >
        {" "}
        {step === 1 && (
          <Webcam
            audio={false}
            height={300}
            screenshotFormat="image/jpeg"
            width={300}
            style={{
              width: "100%",
              borderRadius: "20px",
              margin: "auto",
            }}
            videoConstraints={videoConstraints}
          >
            {({ getScreenshot }) => (
              <Button
                variant="contained"
                type="button"
                color="green3"
                sx={btn}
                onClick={() => {
                  const imageSrc = getScreenshot();
                  console.log(imageSrc);
                  setImage(imageSrc);
                  setStep(2);
                }}
              >
                Capture photo
              </Button>
            )}
          </Webcam>
        )}
        {step === 2 && (
          <>
            <div
              style={{
                display: "grid",
                placeItems: "center",
              }}
            >
              <img
                src={image}
                style={{
                  width: "300px",
                  height: "300px",
                }}
                alt="liveness_image"
              />
            </div>
            <Button
              type="button"
              color="green3"
              sx={btn2}
              onClick={() => {
                setImage("");
                setStep(1);
              }}
            >
              Re take photo
            </Button>
            <Button
              variant="contained"
              type="button"
              color="green3"
              sx={btn}
              onClick={() => {
                setOpen(false);
              }}
            >
              Proceed
            </Button>
          </>
        )}
      </CustomModal>

      <Stack direction="row" gap={{ sx: 0, sm: 2 }} mt={3}>
        <DragAndDropToUpload
          styles={styles}
          formik={formik}
          formikKey="signature_image"
          label="signature"
        />
      </Stack>
    </Box>
  );
};

export default ThirdFormLiveness;
