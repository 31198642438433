export const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    width: { xs: "100%", sm: "90%", lg: "80%" },
    height: { xs: 150, md: 200 },
    background: "url(/img/dashboard4.webp) 50% 50% no-repeat",
    backgroundColor: "#C0C0C080",
    backgroundSize: "cover",
    borderRadius: "12px",
    px: { xs: 3, md: 6 },
    mx: "auto",
    boxSizing: "border-box",
  },
  profileName: {
    fontSize: { xs: 16, sm: 20 },
    fontWeight: 700,
    mb: { xs: 2, sm: 3, lg: 4 },
    ml: { xs: 3, md: 4 },
    mt: { xs: 1, md: 3 },
    textTransform: "capitalize",
  },
  userImg: {
    position: "relative",
    width: { xs: 60, md: 95 },
    height: { xs: 60, md: 95 },
    borderRadius: "50%",
    overflow: "hidden",
  },
  text: {
    fontSize: { xs: 20, sm: 25 },
    fontWeight: 700,
    mb: { xs: 2, sm: 3, lg: 4 },
  },
  camera: {
    position: "absolute",
    right: "-12px",
    backgroundColor: "#d22f3245",
    width: { xs: "28px", sm: "32px" },
    height: { xs: "28px", sm: "32px" },
    borderRadius: "50%",
    p: "4px",
    boxSizing: "border-box",
    cursor: "pointer",
  },
  file: {
    position: "absolute",
    left: "-1.35rem",
    width: { xs: "28px", sm: "32px" },
    height: { xs: "28px", sm: "32px" },
    borderRadius: "50%",
    px: 0,
    zIndex: 3,
    overflow: "hidden",
    opacity: "0",
    cursor: "pointer",
  },
};
