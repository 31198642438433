import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import AccountsCarousel from "../AccountsCarousel/AccountsCarousel";
import { styles } from "./AccountsAndAdsSlider.styles";
//import AdvertCarousel from "../AdvertCarousel/AdvertCarousel";
//import { userAccounts } from "assets/data";

import Box from "@mui/material/Box";
//import Typography from "@mui/material/Typography";
import ActivitiesChart from "../ActivitiesChart/ActivitiesChart";
import { Typography } from "@mui/material";
import ActivitiesTimeFrame from "../ActivitiesTimeFrame/ActivitiesTimeFrame";
//import UserAccountCard from "./UserAccountCard/UserAccountCard";
//import { userProfile } from "services/dashboard";
//import { useQuery } from "@tanstack/react-query";
//import Bar from "./Bar/Bar";
//import bg from "assets/svg/card-bg.svg";

// const AccountsAndAdsSlider = () => {
//   return (
//     <Grid>
//       <Box mt={2} sx={styles.cardContainer}>
//         <AccountsCarousel />
//       </Box>
//     </Grid>
//   );
// };

const AccountsAndAdsSlider = () => {
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      spacing={{ xs: 2, sm: 1, lg: 2 }}
      sx={styles.container}
    >
      <Grid item xs={12} sm={6} lg={5.5} height="100%">
        <Box mt={2} sx={styles.cardContainer}>
          <AccountsCarousel />
        </Box>
      </Grid>
      <Grid item xs={12} lg={6.5}>
        <Box sx={styles.activitiesChart}>
          <Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              pb={1}
              mb={3}
              sx={{
                borderBottom: "1px solid #E4E5E7",
              }}
            >
              <Typography sx={styles.header}>Activity</Typography>
              <ActivitiesTimeFrame />
            </Stack>
            <ActivitiesChart />
          </Box>
        </Box>
      </Grid>
    </Stack>
  );
};

export default AccountsAndAdsSlider;
