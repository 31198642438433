export const styles = {
  form: {
    width: { xs: "100%", sm: "70%", md: "70%", lg: 399, xl: "50%" },
    mx: { xs: 0, lg: 8 },
    mt: { xs: 7, lg: 8, xl: "30vh" },
  },
  openAccountTxt: {
    width: "max-content",
    fontSize: { xs: 12, md: 14 },
    color: "gray5.main",
    mb: { xs: "1.875rem", lg: 8 },
    mt: 1,
  },
  openAccount: {
    fontSize: { xs: 14, md: 16 },
    fontWeight: 700,
    color: "green7.main",
  },
  header: {
    fontSize: { xs: 20, sm: 25, md: 30 },
    fontWeight: 700,
    color: "black.main",
  },
  text: {
    fontSize: {
      xs: 12,
      sm: 14,
    },
    color: "gray4.main",
    "&:hover": {
      color: "green3.main",
    },
  },
  btn: {
    width: "100%",
    height: { xs: 50, md: 55, xl: 70 },
    fontSize: { xs: 16, sm: 18, md: 20 },
    fontWeight: 700,
    color: "white.main",
    "&:disabled": {
      backgroundColor: "white.main",
      color: "white.main",
      border: "1px solid #D22F31"
    },
  },
  bottomText: {
    fontSize: { xs: 12, sm: 14 },
    color: "gray5.main",
  },
  createAccount: {
    fontSize: { xs: 14, sm: 16 },
    fontWeight: 600,
    color: "green7.main",
    mt: 1,
  },
};
