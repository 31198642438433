import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CardEnquiryCard from "components/base/CardEnquiryCard/CardEnquiryCard";
import Select from "components/base/Select/Select";
import { useFormik } from "formik";
import { CardReplacementSchema } from "services/Yup/CardReplacementSchema";
import { userProfile } from "services/dashboard";
import { useQuery } from "@tanstack/react-query";
import { styles } from "components/CardHotList/CardHotList.styles";

const CardReplacement = () => {
  // get user profile
  const { data } = useQuery({
    queryKey: ["userProfile"],
    queryFn: userProfile,
  });

  const customerAccountNumber =
    data?.customer?.accounts?.map(({ account_no }) => account_no) || [];

  const formik = useFormik({
    initialValues: {
      accountToLink: "",
      accountToDebit: "",
      reason: "",
    },
    validationSchema: CardReplacementSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values));
    },
  });

  return (
    <Stack alignItems="center" sx={styles.background}>
      <Box sx={styles.container}>
        <Typography sx={styles.header}>Card Replacement</Typography>

        <CardEnquiryCard />

        <Box as="form" onSubmit={formik.handleSubmit} mt={{ xs: 5, sm: 7 }}>
          <Stack spacing={3}>
            <div>
              <Select
                id="accountToLink"
                name="accountToLink"
                options={customerAccountNumber}
                placeholder="Select Account to Link"
                value={formik.accountToLink}
                formik={formik}
              />
            </div>

            <div>
              <Select
                id="accountToDebit"
                name="accountToDebit"
                options={customerAccountNumber}
                placeholder="Select Account to debit for Charges"
                value={formik.accountToDebit}
                formik={formik}
              />
            </div>

            <div>
              <Select
                id="reason"
                name="reason"
                options={["Card is stolen or missing"]}
                placeholder="Reason"
                value={formik.reason}
                formik={formik}
              />
            </div>
          </Stack>

          <Button
            type="submit"
            variant="contained"
            color="green3"
            sx={styles.submitBtn}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};

export default CardReplacement;
