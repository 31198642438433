import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import CustomModal from "components/base/Modal/Modal";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import { changeTransactionPin } from "services/Auth";
import { useMutation } from "@tanstack/react-query";
import OTP from "components/base/OTP/OTP";
import { useFormik } from "formik";
import { styles } from "components/SavedBeneficiaries/AddNewCitmfbBeneficiaryModal/AddNewCitmfbBeneficiaryModal.styles";

const ChangePinModal = ({
  open,
  handleClose,
  step,
  error,
  handleError,
  handleOpen,
  nextStep,
}) => {
  const formik = useFormik({
    initialValues: {
      oldPin: "",
      newPin: "",
      confirmPin: "",
    },
  });

  const { mutate: ChangeTransactionPin, isLoading: changingPin } = useMutation({
    mutationFn: changeTransactionPin,
    onSuccess: () => {
      nextStep("changePin");
      formik.resetForm();
    },
    onError: (err) => {
      handleError("changePin", err);
      handleOpen("error");
    },
  });

  const handleChangePin = () => {
    const { oldPin, newPin, confirmPin } = formik.values;
    if (!oldPin || !newPin || !confirmPin) {
      handleError("changePin", "Fill all fields to proceed");
      return handleOpen("error");
    }

    if (newPin === confirmPin) {
      ChangeTransactionPin({
        old_pin: oldPin,
        new_pin: newPin,
        confirm_pin: confirmPin,
      });
    } else {
      handleError("changePin", "Old and new pin must be the same");
      handleOpen("error");
    }
  };

  return (
    <CustomModal
      open={open.changePin}
      handleClose={() => {
        handleClose("changePin");
        formik.resetForm();
      }}
    >
      <DirectionSnackbar
        open={open.error}
        handleClose={() => handleClose("error")}
        message={error}
        direction={{ vertical: "top", horizontal: "center" }}
      />

      <Box sx={styles.container} as="form">
        {step === 0 && (
          <>
            <Stack spacing={3} sx={styles.content} alignItems="center">
              <Typography sx={styles.header}>Change Pin</Typography>

              <InputWithLabel label="Old Pin">
                <OTP formik={formik} formikKey="oldPin" variant={2} />
              </InputWithLabel>

              <InputWithLabel label="New Pin">
                <OTP formik={formik} formikKey="newPin" variant={2} />
              </InputWithLabel>

              <InputWithLabel label="Confirm Pin">
                <OTP formik={formik} formikKey="confirmPin" variant={2} />
              </InputWithLabel>

              <Box sx={{ height: 10 }} />

              <Box mx="auto" width="fit-content" mt={{ xs: 3, sm: 5 }}>
                <Button
                  variant="contained"
                  color="green3"
                  sx={styles.btn}
                  disabled={changingPin}
                  onClick={handleChangePin}
                >
                  {changingPin ? "Please wait" : "Proceed"}
                </Button>
              </Box>
            </Stack>
          </>
        )}

        {step === 1 && (
          <>
            <Box
              as="img"
              src="/img/successful_gif.gif"
              alt="successful"
              sx={styles.gif}
            />
            <Stack spacing={3} sx={styles.content} alignItems="center">
              <Typography sx={styles.header}>Pin Change Successful</Typography>
              <Typography sx={styles.subText}>
                Your Transaction Pin has been succesfully changed.
              </Typography>
            </Stack>

            <Box mx="auto" width="fit-content" mt={{ xs: 3, sm: 5 }}>
              <Button
                variant="contained"
                color="green3"
                sx={styles.btn}
                onClick={() => {
                  handleClose("changePin");
                  formik.resetForm();
                }}
              >
                Done
              </Button>
            </Box>
          </>
        )}
      </Box>
    </CustomModal>
  );
};

export default ChangePinModal;
