import Stack from "@mui/material/Stack";
import { userProfile } from "services/dashboard";
import { useQuery } from "@tanstack/react-query";
import UpgradeTier from "components/BalanceEnquiry/UpgradeTier/UpgradeTier";
const UpgradeTierPage = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["profile"],
    queryFn: userProfile,
  });
  return (
    <Stack spacing={3}>
      <UpgradeTier data={data} isLoading={isLoading} />
    </Stack>
  );
};
export default UpgradeTierPage;
