export const styles = {
  container: {
    minHeight: 160,
    bgcolor: "white.main",
    border: "1px solid #EFF0F6",
    boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: {xs: "16px", sm: "20px"},
    px: { xs: 2, sm: 3, lg: 4 },
    py: { xs: 1, sm: 2, lg: 3 },
  },
  header: {
    color: "green3.main",
    fontSize: { xs: 23, sm: 25 },
    fontWeight: 600,
    lineHeight: "38px",
    mt: { xs: 2, sm: 0 },
  },
  subText: { color: "#919499", fontSize: { xs: 13, sm: 15 } },
  addNewBtn: { color: "#FCBA2D" },
  cardContainer: {
    display: "grid",
    gridTemplateColumns: {
      xs: "1fr",
      sm: "450px",
      md: "500px",
      lg: "repeat(2, 1fr)",
      gap: "18px",
    },
  },
};
