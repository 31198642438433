import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import { validateNumbers } from "utils/validateNumbers";

const FirstForm = ({ formik }) => {
  return (
    <>
      <InputWithLabel label="Account Number">
        <Input
          id="account_no"
          name="account_no"
          type="tel"
          placeholder="Enter Account Number"
          value={formik.values.account_no}
          onChange={(event) => {
            validateNumbers(event, 10, formik.handleChange);
          }}
          formik={formik}
        />
      </InputWithLabel>
      <InputWithLabel label="Email Address">
        <Input
          id="email"
          type="email"
          placeholder="Enter Your Email Address"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          formik={formik}
        />
      </InputWithLabel>

      <InputWithLabel label="Phone Number">
        <Input
          id="phone_number"
          name="phone_number"
          type="tel"
          placeholder="Enter Phone Number"
          value={formik.values.phone_number}
          onChange={(event) => {
            validateNumbers(event, 11, formik.handleChange);
          }}
          formik={formik}
        />
      </InputWithLabel>
    </>
  );
};

export default FirstForm;
