export const styles = {
    container: {
        width: { xs: '95%', md: '90%', lg: '80%' },
        mx: 'auto',
        mt: { xs: 2, sm: 3 },
    },
    loadingAnimation: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '40vh',
    },
    searchIcon: {
        width: 24,
        height: 24,
        mr: 1,
    },
    addBeneficiartBtn: {
        width: { xs: 'fit-content', sm: 288 },
        bgcolor: 'rgba(210, 47, 49, 0.2);',
        color: 'green3.main',
        fontSize: { xs: 14, lg: 16 },
        fontWeight: 600,
        boxShadow: 'none',
        alignSelf: { xs: 'flex-end', sm: 'auto' },
        '&:hover': { bgcolor: 'rgba(210, 47, 49, 0.2);' },
    },
};