export const styles = {
  quickServices: {
    px: 2,
    py: 2,
    mt: 4,
    border: '1px solid #EFF0F6',
    boxShadow: '0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)',
    borderRadius: {
      xs: '10px',
      md: '20px'
    },
    boxSizing: 'border-box',
  },
  activitiesChart: {
    height: '100%',
    border: '1px solid #EFF0F6',
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.05)',
    borderRadius: {
      xs: '10px',
      md: '20px'
    },
    px: {
      xs: 1,
      sm: 3
    },
    py: 2,
    boxSizing: 'border-box',
  },
  header: {
    fontSize: 14,
    fontWeight: 500,
  },
};