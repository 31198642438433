import React from "react";
import TransactionHistoryTable from "components/CorporateAwaitingApproval/TransactionHistoryTable/TransactionHistoryTable";
import { useQuery } from "@tanstack/react-query";
import { Box, Stack, Typography } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import { awaitingStatusTab } from "assets/data";
import {
  getBulkTransferReq,
  getTransferReq,
} from "services/corporateDashboard";
import { Axios } from "utils/Axios";

const TransactionHistory = () => {
  const [currentPage, setCurrentPage] = React.useState(1);

  //const customerAccountNumber = [];

  // const { data, isLoading } = useQuery({
  //   enabled: Boolean(customerAccountNumber),
  // });

  // const setPage = (page) => {
  //   setCurrentPage(page);
  // };

  const setPage = (page) => {
    setCurrentPage(page);
  };
  const { data, loading } = useQuery({
    queryKey: ["transfer_req", currentPage],
    queryFn: () => getTransferReq("?exclude=true"),
  });

  const { data: data2, loading: loading2 } = useQuery({
    queryKey: ["bulk_req", currentPage],
    queryFn: () => getBulkTransferReq("?exclude=true"),
  });

  const [param] = useSearchParams();
  const activeTab = param.get("tab");

  const [datum, setData] = React.useState();
  const [datum2, setData2] = React.useState();

  const [newLoading, setNewLoading] = React.useState(false);
  const [newLoading2, setNewLoading2] = React.useState(false);

  const sendReq = async (page) => {
    setNewLoading(true);
    Axios.get(page)
      .then((res) => {
        console.log(res);
        setNewLoading(false);
        setData(res?.data);
      })
      .catch((err) => {
        setNewLoading(false);
        console.log(err);
      });
  };
  const sendReq2 = async (page) => {
    setNewLoading2(true);
    Axios.get(page)
      .then((res) => {
        console.log(res);
        setNewLoading2(false);
        setData2(res?.data);
      })
      .catch((err) => {
        setNewLoading2(false);
        console.log(err);
      });
  };

  const styles = {
    tabContainer: {
      width: "100%",
      borderBottom: "1px solid #ABABAB",
      mb: 3,
      overflow: "hidden",
    },
    tab: {
      flexWrap: "nowrap",
      width: { lg: "85%" },
      px: { xs: 2, lg: 0 },
      mx: "auto",
      overflowX: "auto",
    },
    category: (active) => ({
      fontSize: { xs: 13, lg: 14 },
      fontWeight: 600,
      color: active ? "green3.main" : "#888686",
      borderBottom: active ? "1px solid #D22F31" : "1px solid transparent",
      px: { xs: 2, sm: 3, lg: 4 },
      pb: "5px",
      cursor: "pointer",
      minWidth: "max-content",
    }),
  };

  return (
    <Box
      sx={{
        bgcolor: "white.main",
        boxShadow: "0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)",
        borderRadius: "10.2375px",
        py: { xs: 3, sm: 5 },
      }}
    >
      <Box sx={styles.tabContainer}>
        <Stack direction="row" alignItems="center" sx={styles.tab}>
          {awaitingStatusTab.map(({ label, href }, index) => (
            <Link key={index} to={href}>
              <Typography
                sx={styles.category(
                  label
                    .toLowerCase()
                    .includes(activeTab?.toLowerCase().split("_")[0])
                )}
              >
                {label}
              </Typography>
            </Link>
          ))}
        </Stack>
      </Box>
      {activeTab === "single" && (
        <TransactionHistoryTable
          data={datum || data}
          loading={newLoading || loading}
          pagination={data?.pagination}
          setPage={setPage}
          action={sendReq}
          dataHead={[
            "NAME",
            "BANK NAME",
            "BANK ACCOUNT",
            "AMOUNT",
            "STATUS",
            "ACTIONS",
          ]}
        />
      )}{" "}
      {activeTab === "bulk" && (
        <TransactionHistoryTable
          data={datum2 || data2}
          loading={newLoading2 || loading2}
          pagination={data2?.pagination}
          setPage={setPage}
          action={sendReq2}
          dataHead={[
            "DESCRIPTION",
            "INSTITUTION",
            "ID",
            "AMOUNT",
            "STATUS",
            "ACTIONS",
          ]}
        />
      )}
    </Box>
  );
};

export default TransactionHistory;
