import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import CustomModal from "components/base/Modal/Modal";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import {
  setNewTransactionLimit,
  sendOtpReq,
} from "services/corporateDashboard";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { styles } from "components/SavedBeneficiaries/AddNewCitmfbBeneficiaryModal/AddNewCitmfbBeneficiaryModal.styles";
import Input from "components/base/Input/Input";
import { validateNumbers } from "utils/validateNumbers";
import OTP from "components/base/OTP/OTP";

const EditTransactionLimit = ({
  open,
  handleClose,
  step,
  error,
  handleError,
  handleOpen,
  nextStep,
}) => {
  const formik = useFormik({
    initialValues: {
      newLimit: "",
      confirmLimit: "",
      confirmPin: "",
    },
  });

  const { mutate: SetNewTransactionLimit, isLoading: changingPin } =
    useMutation({
      mutationFn: setNewTransactionLimit,
      onSuccess: () => {
        nextStep();
        formik.resetForm();
      },
      onError: (err) => {
        handleError(err);
        handleOpen("error");
      },
    });

  const {
    mutate: SendOtpReq,
    isLoading: reqOtp,
    data,
  } = useMutation({
    mutationFn: sendOtpReq,
    onSuccess: () => {
      nextStep();
      formik.resetForm();
    },
    onError: (err) => {
      handleError(err);
      handleOpen("error");
    },
  });
  //console.log(data);

  const handleChangePin = () => {
    const { confirmLimit, confirmPin, newLimit } = formik.values;
    if (!confirmLimit || !confirmPin || !newLimit) {
      handleError("Fill all fields to proceed");
      return handleOpen("error");
    }
    SetNewTransactionLimit({
      otp: confirmPin,
      daily_limit: newLimit,
      transfer_limit: confirmLimit,
    });
  };

  const handleSendOtpReq = () => {
    SendOtpReq();
  };

  return (
    <CustomModal
      open={open}
      handleClose={() => {
        handleClose();
        formik.resetForm();
      }}
    >
      <DirectionSnackbar
        open={error}
        handleClose={() => handleError("")}
        message={error}
        direction={{ vertical: "top", horizontal: "center" }}
      />

      <Box sx={styles.container} as="form">
        {step === 0 && (
          <Stack spacing={3} sx={styles.content} alignItems="center">
            <Typography sx={styles.header}>Request for OTP</Typography>
            <Button
              variant="contained"
              color="green3"
              sx={styles.btn}
              disabled={reqOtp}
              onClick={handleSendOtpReq}
            >
              {reqOtp ? "Please wait" : "Send Request"}
            </Button>
          </Stack>
        )}
        {step === 1 && (
          <>
            <Stack spacing={3} sx={styles.content} alignItems="center">
              <Typography sx={styles.header}>Set Transaction Limit</Typography>
              {data?.detail}
              <InputWithLabel label="OTP Pin">
                <OTP
                  formik={formik}
                  formikKey="confirmPin"
                  variant={2}
                  length={6}
                />
              </InputWithLabel>
              <Input
                name="newLimit"
                type="number"
                placeholder="Daily Limit"
                value={formik.values.newLimit}
                formik={formik}
                sx={styles.input}
                onChange={(event) => {
                  validateNumbers(event, 1000, formik.handleChange);
                }}
              />

              <Input
                name="confirmLimit"
                type="number"
                placeholder="Transfer Limit"
                value={formik.values.confirmLimit}
                formik={formik}
                onChange={(event) => {
                  validateNumbers(event, 1000, formik.handleChange);
                }}
                sx={styles.input}
              />

              <Box sx={{ height: 10 }} />

              <Box mx="auto" width="fit-content" mt={{ xs: 3, sm: 5 }}>
                <Button
                  variant="contained"
                  color="green3"
                  sx={styles.btn}
                  disabled={changingPin}
                  onClick={handleChangePin}
                >
                  {changingPin ? "Please wait" : "Set New Limit"}
                </Button>
              </Box>
            </Stack>
          </>
        )}

        {step === 2 && (
          <>
            <Box
              as="img"
              src="/img/successful_gif.gif"
              alt="successful"
              sx={styles.gif}
            />
            <Stack spacing={3} sx={styles.content} alignItems="center">
              <Typography sx={styles.header}>Limits Set Successful</Typography>
              <Typography sx={styles.subText}>
                Your Limits have been successfully changed.
              </Typography>
            </Stack>

            <Box mx="auto" width="fit-content" mt={{ xs: 3, sm: 5 }}>
              <Button
                variant="contained"
                color="green3"
                sx={styles.btn}
                onClick={() => {
                  handleClose();
                  formik.resetForm();
                }}
              >
                Done
              </Button>
            </Box>
          </>
        )}
      </Box>
    </CustomModal>
  );
};

export default EditTransactionLimit;
