export const validateNumbers = (event, inputLength, onChange) => {
  if (event.target.value.length > inputLength) return;

  const valid = event.target.value
    .split("")
    .every((item) =>
      ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(item)
    );

  if (valid) {
    onChange(event);
  }
};

export const validateLetters = (event, inputLength, onChange) => {
  if (event.target.value.length > inputLength) return;

  const valid = event.target.value
    .split("")

  if (valid) {
    onChange(event);
  }
};
