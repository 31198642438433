import * as Yup from 'yup';

export const TransferToOtherBanksSchema = Yup.object().shape({
  account: Yup.string().required('Select senders account number'),
  beneficiary_bank_name: Yup.string().required('Select beneficiary bank'),
  amount: Yup.number()
    .min(1, 'Enter a valid amount')
    .required('Enter valid amount'),
  beneficiary_account_no: Yup.string().required(
    'Enter beneficiary account number'
  ),
  beneficiary_account_name: Yup.string().required(
    'Enter valid account number to proceed'
  ),
  // narration: Yup.string().required('Enter brief transaction description'),
});
