import PinInput from "react-pin-input";
import Typography from "@mui/material/Typography";
import { styles } from "./OTP.styles";

const OTP = ({ formik, formikKey, length, error, variant }) => {
  return (
    <>
      <PinInput
        length={length}
        secret
        onChange={(value, index) => {
          formik.setFieldValue(formikKey, value);
        }}
        type="number"
        inputMode="numeric"
        inputStyle={
          variant === 1
            ? styles.inputStylesOne
            : variant === 3
            ? styles.inputStylesThree
            : styles.inputStylesTwo
        }
        inputFocusStyle={{ borderColor: "#D22F31" }}
        autoSelect={true}
        regexCriteria={/[0-9]/}
      />
      {error?.length > 0 && <Typography sx={styles.error}>{error}</Typography>}
    </>
  );
};

OTP.defaultProps = {
  length: 4,
  variant: 1,
};

export default OTP;
