import Banner from "components/CorporateAccountManager/Banner/Banner";
import ManagersInfo from "components/CorporateAccountManager/ManagersInfo/ManagersInfo";
import {
  getAllAccountOfficers,
  // userProfile,
} from "services/corporateDashboard";
import { useQuery } from "@tanstack/react-query";

const AccountManager = () => {
  /*  const { data: user } = useQuery({
    queryKey: ["profile"],
    queryFn: userProfile,
  }); */

  //const accountNo = user?.customer?.accounts[0]?.account_no;

  const { data, isLoading } = useQuery({
    queryKey: ["account_manager"],
    queryFn: () => getAllAccountOfficers(),
    //enabled: Boolean(user?.customer?.accounts[0]?.account_no),
  });

  return (
    <div>
      <Banner data={data?.data} isLoading={isLoading} />
      <ManagersInfo data={data?.data} isLoading={isLoading} />
    </div>
  );
};

export default AccountManager;
