import { useContext, useState } from "react";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as EditIcon } from "assets/svg/edit.svg";
import { ReactComponent as VisibilityOffIcon } from "assets/svg/visibility-off-green.svg";
import { ReactComponent as VisibilityOn } from "assets/svg/visibility-on.svg";
//import ResetPinModal from "./ResetPinModal";
import ChangePinModal from "components/CorporateDashboard/ChangePasswordModal";
//import ChangePasswordModal from "./ChangePasswordModal";
import { styles } from "./ProfileForm.styles";
import { formatCurrency } from "utils/formatCurrency";
import CorporateDashboardContext from "context/CorporateDashboardContext";
import EditTransactionLimit from "./EditTransactionLimit";
import { Button } from "@mui/material";

const ProfileForm = ({ isLoading }) => {
  const { data } = useContext(CorporateDashboardContext);

  const bvn = data?.bvn;
  const [showbvn, setShowBvn] = useState(false);

  const [step, setStep] = useState(0);

  const [open, setOpen] = useState(false);

  const [error, setError] = useState("");

  const nextStep = () => {
    setStep((current) => current + 1);
  };

  const handleClose = () => {
    setOpen(false);
    setStep(0);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleError = (err) => {
    setError(err?.response?.data?.detail || err?.message || err);
  };

  const toggleShowBvn = () => {
    setShowBvn((prevS) => !prevS);
  };

  const handleChange = () => {};

  //TRANSACTION LIMIT

  const [step2, setStep2] = useState(0);

  const [open2, setOpen2] = useState(false);

  const [error2, setError2] = useState("");

  const nextStep2 = () => {
    setStep2((current) => current + 1);
  };

  const handleClose2 = () => {
    setOpen2(false);
    setStep2(0);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleError2 = (err) => {
    setError2(err?.response?.data?.detail || err?.message || err);
  };

  const limits = JSON.parse(localStorage.getItem("limits"));

  const addBeneficiartBtn = {
    width: { xs: "fit-content", sm: 288 },
    bgcolor: "#d22f3245",
    color: "#D22F31",
    fontSize: { xs: 14, lg: 16 },
    fontWeight: 600,
    boxShadow: "none",
    alignSelf: { xs: "flex-end", sm: "auto" },
    "&:hover": { bgcolor: "rgba(202, 45, 45, 0.2)" },
    marginTop: "20px",
  };

  return (
    <Stack mt={4} spacing={3} sx={styles.container}>
      <ChangePinModal
        open={open}
        handleClose={handleClose}
        step={step}
        error={error}
        handleError={handleError}
        handleOpen={handleOpen}
        nextStep={nextStep}
      />

      <EditTransactionLimit
        open={open2}
        handleClose={handleClose2}
        step={step2}
        error={error2}
        handleError={handleError2}
        handleOpen={handleOpen2}
        nextStep={nextStep2}
      />

      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 3, sm: 4, lg: 5 }}
      >
        <InputWithLabel label="Change Password">
          {isLoading ? (
            <Skeleton sx={{ width: "100%", height: 50, transform: "none" }} />
          ) : (
            <Input
              value="********"
              onChange={handleChange}
              disabled
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <EditIcon />
                  </IconButton>
                </InputAdornment>
              }
              sx={styles.input}
              onClick={() => handleOpen("changePassword")}
            />
          )}
        </InputWithLabel>

        <InputWithLabel label="View BVN">
          {isLoading ? (
            <Skeleton sx={{ width: "100%", height: 50, transform: "none" }} />
          ) : (
            <Input
              value={showbvn ? bvn : "********"}
              onChange={handleChange}
              disabled
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowBvn}
                    edge="end"
                  >
                    {showbvn ? (
                      <VisibilityOn className="green-stroke " />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              sx={styles.input}
            />
          )}
        </InputWithLabel>
      </Stack>
      <hr></hr>
      <Button
        variant="contained"
        sx={addBeneficiartBtn}
        onClick={() => handleOpen2()}
      >
        Edit Limits
      </Button>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 3, sm: 4, lg: 5 }}
      >
        <InputWithLabel label="Transaction Limit">
          {isLoading ? (
            <Skeleton sx={{ width: "100%", height: 50, transform: "none" }} />
          ) : (
            <Input
              value={formatCurrency(limits?.total_transfer_limit)}
              disabled
              sx={styles.input}
            />
          )}
        </InputWithLabel>

        <InputWithLabel label="Daily Limit">
          {isLoading ? (
            <Skeleton sx={{ width: "100%", height: 50, transform: "none" }} />
          ) : (
            <Input
              value={formatCurrency(limits?.daily_transfer_limit)}
              disabled
              sx={styles.input}
            />
          )}
        </InputWithLabel>
      </Stack>
    </Stack>
  );
};

export default ProfileForm;
