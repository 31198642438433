import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { styles } from "./Banner.styles";

const Banner = ({ data, isLoading }) => {
  return (
    <Box sx={styles.container}>
      {isLoading ? (
        <Skeleton sx={{ ...styles.managerImg, transform: "none" }} />
      ) : (
        <Box
          as="img"
          src={data?.gender === "Male" ? "/img/male.webp" : "/img/female.webp"}
          alt="account manager"
          sx={styles.managerImg}
        />
      )}
      <Typography sx={styles.text}>Account Manager</Typography>
    </Box>
  );
};

export default Banner;
