import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TablePagination from "components/base/TablePagination/TablePagination";
import TransactionsTable from "components/UserDashboard/TransactionsTable/TransactionsTable";
import { styles } from "./TransactionHistoryTable.styles";

const TransactionHistoryTable = ({ data, pagination, isLoading, page, setPage }) => {
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.header}>TRANSACTIONS</Typography>

      <TransactionsTable data={data} loading={isLoading} />

      <TablePagination
        pagination={pagination}
        page={page}
        setPage={setPage}
      />
    </Box>
  );
};

export default TransactionHistoryTable;
