import AirtimeAndDataComponent from "components/AirtimeAndData/AirtimeAndData";

const AirtimeAndData = () => {
  return (
    <>
      <AirtimeAndDataComponent />
    </>
  );
};

export default AirtimeAndData;
