import Box from '@mui/material/Box';
import { styles } from './Dots.styles';

const Dots = ({ sx }) => {
  return (
    <Box sx={{ ...styles.dots, ...sx }}>
      {[...Array(70).keys()].map((item) => (
        <Box key={item} sx={styles.dot}></Box>
      ))}
    </Box>
  );
};

export default Dots;
