export const styles = {
    container: {
        backgroundColor: "white.main",
        boxShadow: "0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)",
        borderRadius: "10.2375px",
        py: { xs: 3, sm: 4 },
    },
    header: {
        width: { lg: "75%" },
        fontSize: { xs: 16, sm: 18, lg: 20 },
        fontWeight: 700,
        color: "#000000",
        px: { xs: 2, sm: 3, lg: 0 },
        mb: 3,
        mx: "auto",
    },
    tab: {
        width: { lg: "75%" },
        px: { xs: 2, sm: 3, lg: 0 },
        mx: "auto",
    },
    category: (active) => ({
        fontSize: { xs: 13, lg: 14 },
        fontWeight: 600,
        color: active ? "green3.main" : "#888686",
        borderBottom: active ? "1px solid #D22F31" : "1px solid transparent",
        px: { xs: 2, sm: 4 },
        pb: "5px",
        cursor: "pointer",
    }),
    input: {
        backgroundColor: "#FFFFFF",
        borderColor: "#EFF0F6",
        borderRadius: "6px",
    },
    provider: (selected) => ({
        width: { xs: 70, sm: 90 },
        height: { xs: 65, sm: 85 },
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "40px",
        border: selected ? "1px solid #D22F31" : "1px solid transparent",
        p: 2,
        cursor: "pointer",
        boxSizing: "border-box",
    }),
    providerImg: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
    },
    networkProviderErr: {
        fontSize: 12,
        color: "red.main",
        mt: 1
    },
    submitBtn: {
        width: { xs: "80%", sm: 300 },
        height: 55,
        alignSelf: "center",
        color: "white.main",
    },
};