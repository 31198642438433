import Banner from "components/AccountManager/Banner/Banner";
import ManagersInfo from "components/AccountManager/ManagersInfo/ManagersInfo";
import { getAccountManager, userProfile } from "services/dashboard";
import { useQuery } from "@tanstack/react-query";

const AccountManager = () => {
  const { data: user } = useQuery({
    queryKey: ["profile"],
    queryFn: userProfile,
  });

  const accountNo = user?.customer?.accounts[0]?.account_no;

  const { data, isLoading } = useQuery({
    queryKey: ["account_manager", accountNo],
    queryFn: () => getAccountManager(accountNo),
    enabled: Boolean(user?.customer?.accounts[0]?.account_no),
  });

  return (
    <div>
      <Banner data={data} isLoading={isLoading} />
      <ManagersInfo data={data} isLoading={isLoading} />
    </div>
  );
};

export default AccountManager;
