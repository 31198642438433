export const styles = {
  container: {
    bgcolor: 'white.main',
    border: '1px solid #EFF0F6',
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.05)',
    borderRadius: {xs: "16px", sm: '20px'},
    p: { xs: 2, sm: 3 },
  },
  header: { fontSize: { xs: 20, sm: 25 }, fontWeight: 700 },
  input: {
    border: 'none',
    height: 'auto',
    opacity: 0,
    zIndex: 1,
  },
  reactdatepickerwrapper:{
    border: 'none',
    height: 'auto',
    opacity: 0,
    zIndex: 1,
    


  },
  select: {
    bgcolor: '#FBFBFB',
    border: '0.5px solid #D22F31',
    borderRadius: '5px',
  },
  box: {
    position: 'relative',
    bgcolor: '#FBFBFB',
    height: { xs: 55, xl: 90 },
    border: '0.5px solid #D22F31',
    borderRadius: '5px',
  },
  placeholder: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.5)',
    px: { xs: 1, sm: 2 },
  },
  dateValue: {
    position: 'absolute',
    bottom: '5px',
    fontSize: 16,
    fontWeight: 500,
    px: { xs: 1, sm: 2 },
  },
  btn: {
    width: { xs: 'auto', sm: 220, md: 300 },
    height: 50,
    fontSize: 14,
    fontWeight: 500,
    color: 'white.main',
  },
};
