export const useLocalStorage = (key) => {
  const setLocalStorage = (data) =>
    localStorage.setItem(key, JSON.stringify(data));

  const getLocalStorage = JSON.parse(localStorage.getItem(key));

  const removeLocalStorage = () => localStorage.removeItem(key);

  return { setLocalStorage, getLocalStorage, removeLocalStorage };
};
