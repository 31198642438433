import SlideInModal from "components/base/SlideInModal/SlideInModal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import { styles } from "./ConfirmationModal.styles";

const Close = styled(CloseIcon)(() => {});

const ConfirmationModal = ({
  open,
  handleClose,
  mainText,
  subText,
  btnText,
  handleClick,
}) => {
  return (
    <SlideInModal open={open} handleClose={handleClose}>
      <Typography onClick={handleClose} sx={styles.closeX}>
        <Close sx={styles.closeIcon} />
      </Typography>

      <Box
        as="img"
        src="/img/successful_gif.gif"
        alt="Success gif"
        sx={styles.img}
      />

      <Typography sx={styles.header}>{mainText}</Typography>
      <Typography sx={styles.desc}>{subText}</Typography>

      <Button
        variant="contained"
        color="green3"
        sx={styles.closeBtn}
        onClick={handleClick}
      >
        {btnText}
      </Button>
    </SlideInModal>
  );
};

export default ConfirmationModal;
