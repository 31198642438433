export const styles = {
    container: {
      minHeight: 160,
      bgcolor: "white.main",
      border: "1px solid #EFF0F6",
      boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.05)",
      borderRadius: {xs: "16px", sm: "20px"},
      px: { xs: 2, sm: 3, lg: 4 },
      py: { xs: 1, sm: 2, lg: 3 },
    },
    header: {
      color: "#000",
      fontSize: { xs: 23, sm: 25 },
      fontWeight: 600,
      lineHeight: "38px",
      mt: { xs: 2, sm: 0 },
    },
    subText: { color: "#919499", fontSize: { xs: 13, sm: 15 } },
    addNewBtn: { color: "#FCBA2D" },
    cardContainer: {
      display: "grid",
      gridTemplateColumns: {
        xs: "1fr",
        sm: "450px",
        md: "repeat(2, 1fr)",
        lg: "repeat(2, 1fr)",
        gap: "48px",
      },
    },
    btn: {
      width: { xs: 'auto', sm: 220, md: 300 },
      height: 50,
      fontSize: 14,
      fontWeight: 500,
      color: 'white.main',
      margin: 'auto',
      marginTop: '50px',
    },
    card:{
     border: '1px solid #7777774D',
     borderRadius: '10px'
    },
    top:{
     padding: '14px 20px',
     borderBottom: '1px solid #7777774D',
     display:'flex',
     alignItems: 'center',
     fontWeight: '600'
     },
     body:{
      padding: '14px 20px'
     },
     bodycard:{
      display:'flex',
      alignItems: 'center',   
      justifyContent: 'space-between',
      marginTop: '14px',
      fontSize: '13px'
    },
    card2:{
      border: '1px solid #7777774D',
      borderRadius: '10px',
      overflow:'hidden'   
  },
     top2:{
      padding: '14px 20px',
      borderBottom: '1px solid #7777774D',
      display:'flex',
      alignItems: 'center',
      background: '#ca2d2d',
      color: 'white',
      fontWeight: '600'
      },
      body2:{
       padding: '14px 20px'
      },
      bodycard2:{
       display:'flex',
       alignItems: 'center',   
       justifyContent: 'space-between',
       marginTop: '14px',
       background: '#f6b4b433',
       fontSize: '13px'
     }
     
  };
  
  