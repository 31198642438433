export const styles = {
  container: (active) => ({
    position: "relative",
    p: 0,
    "&::before": {
      position: "absolute",
      content: '""',
      left: 0,
      top: 0,
      width: "4px",
      height: 45,
      bgcolor: active ? "green3.main" : "white.main",
    },
  }),
  navBtn: (active) => ({
    width: { xs: "80%", sm: "85%", md: "80%" },
    minHeight: 45,
    px: { xs: 2, sm: 1, md: 2 },
    py: { xs: "12px", sm: 1, md: 1 },
    mx: "auto",
    bgcolor: active ? "green3.main" : "transparent",
    borderRadius: "8px",
    "&:hover": {
      bgcolor: "green3.main",
      color: "white.main",
    },
  }),
  icon: (active) => ({
    width: { xs: 24, sm: 18 },
    height: { xs: 24, sm: 18 },
    mr: { xs: 2, sm: 1, md: 2 },
    path: { stroke: active ? "#ffffff" : "#868686" },
    "this.navBtn:hover": {
      path: { stroke: "white.main" },
    },
  }),
  text: (active) => ({
    fontSize: { xs: 16, sm: 12, md: 14, lg: 16 },
    fontWeight: 500,
    color: active ? "white.main" : "gray8.main",
  }),
  arrow: (active) => ({
    transform: active ? "rotate(90deg)" : "rotate(0deg)",
    path: { stroke: active ? "#ffffff" : "#868FA0" },
    transition: "all 0.3s ease-in-out",
    "this.navBtn:hover": {
      path: { stroke: "white.main"},
    },
  }),
  collapse: { width: "80%", mx: "auto" },
  subMenuLink: {
    fontSize: { xs: 14, sm: 12, lg: 14 },
    fontWeight: 500,
    py: 1,
  },
};
