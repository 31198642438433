import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styles } from "components/base/TransferModal/TransferModal.styles";
import TransactionsReceipt from "components/TransactionsReceipt";
//import ReactToPrint from "react-to-print";
import { useRef } from "react";
import jsPDF from "jspdf";

const TransactionSuccessful = ({
  handleClick,
  formik,
  title = "Transfer receipt has been generated to your Bank Statement Page",
}) => {
  const closeModalAndResetform = () => {
    handleClick();
    formik.resetForm();
  };

  let componentRef = useRef(null);
  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a3",
      unit: "px",
    });

    doc.html(componentRef.current, {
      async callback(doc) {
        await doc.save("document");
      },
    });
  };

  const data = JSON.parse(localStorage.getItem("transfer_data"))?.data;

  console.log(data , 'keeee');

  return (
    <Box>
      <Box
        as="img"
        src="/img/successful_gif.gif"
        alt="successful"
        sx={styles.gif}
      />
      <Typography sx={{ ...styles.header, mt: 0, textAlign: "center" }}>
        Transaction Successful
      </Typography>

      <Typography sx={styles.subText}>{title}</Typography>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap-reverse",
          justifyContent: "center",
          marginTop: "14px",
          gap: "8px",
        }}
      >
        <div
          onClick={() => {
            handleGeneratePdf();
          }}
        >
          <Button variant="contained" color="green3" sx={styles.btn}>
            Download Receipt
          </Button>
        </div>
        <Button
          variant="contained"
          color="green3"
          sx={styles.btn}
          onClick={closeModalAndResetform}
        >
          Continue
        </Button>
      </div>
      <div style={{ display: "none", position: "fixed", right: 0, top: 0 }}>
        <div ref={componentRef}>
          <TransactionsReceipt
            amount={data?.amount}
            name={data?.beneficiary_name}
            note={data?.narration}
            number={data?.beneficiary_acct}
            reference={data?.reference}
            status={data?.status}
            type={data?.transfer_type}
            bank={data?.bank_name}
            time={data?.updated_on}
            description={data?.description}
          />
        </div>
      </div>
    </Box>
  );
};

export default TransactionSuccessful;
