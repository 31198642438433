import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Input from "components/base/Input/Input";
import InputAdornment from "@mui/material/InputAdornment";
import CitmfbBeneficiariesTable from "../CitmfbBeneficiariesTable/CitmfbBeneficiariesTable";
import AddNewCitmfbBeneficiaryModal from "../AddNewCitmfbBeneficiaryModal/AddNewCitmfbBeneficiaryModal";
import DeleteCitmfbBeneficiaryModal from "../DeleteCitmfbBeneficiaryModal/DeleteCitmfbBeneficiaryModal";
import { styled } from "@mui/material/styles";
import { ReactComponent as SearchIcon } from "assets/svg/search.svg";
import { useFormik } from "formik";
import { createBeneficiary } from "services/dashboard";
import { useMutation } from "@tanstack/react-query";
import { AddNewCitmfbBeneficiarySchema } from "services/Yup/AddNewCitmfbBeneficiarySchema";
import { styles } from "./CitmfbTransfer.styles";
import LoadingAnimation from "components/base/LoadingAnimation/LoadingAnimation";

export const Search = styled(SearchIcon)({});

const CitmfbTransfer = ({
  searchInput,
  handleChange,
  tableData,
  isLoading,
  page,
  setPage,
  item_count,
}) => {
  const [open, setOpen] = React.useState({
    beneficiaries: false,
    delete: false,
    error: false,
  });

  const [error, setError] = React.useState("");

  const [step, setStep] = React.useState({
    beneficiaries: 0,
    delete: 0,
  });

  const handleClose = (objectKey) => {
    setOpen((prevS) => ({ ...prevS, [objectKey]: false }));
    setStep((prevS) => ({ ...prevS, [objectKey]: 0 }));
  };

  const handleOpen = (objectKey) => {
    setOpen((prevS) => ({ ...prevS, [objectKey]: true }));
  };

  const nextStep = (objectKey) => {
    if (step[objectKey] === 1) {
      return handleClose(objectKey);
    }
    setStep((prevS) => ({ ...prevS, [objectKey]: prevS[objectKey] + 1 }));
  };

  const deleteBeneficiary = () => {
    nextStep("delete");
  };

  const { mutate, isLoading: loading } = useMutation({
    mutationFn: createBeneficiary,
    onSuccess: () => {
      nextStep("beneficiaries");
      formik.resetForm();
    },
    onError: (err) => {
      setError(err?.response?.data?.detail || err.message);
      handleOpen("error");
    },
  });

  const formik = useFormik({
    initialValues: {
      BeneficiariesAccountName: "",
      BeneficiariesAccountNo: "",
    },
    validationSchema: AddNewCitmfbBeneficiarySchema,
    onSubmit: (values) => {
      mutate({
        beneficiary_type: "local_transfer",
        beneficiary_acct_no: values.BeneficiariesAccountNo,
        beneficiary_name: values.BeneficiariesAccountName,
      });
    },
  });

  return isLoading ? (
    <Box sx={styles.loadingAnimation}>
      <LoadingAnimation />
    </Box>
  ) : (
    <>
      <Box sx={styles.container}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Input
            value={searchInput}
            onChange={handleChange}
            placeholder="Search"
            startAdornment={
              <InputAdornment position="end">
                <Search sx={styles.searchIcon} className="arrow-down" />
              </InputAdornment>
            }
          />

          <Button
            variant="contained"
            sx={styles.addBeneficiartBtn}
            onClick={() => handleOpen("beneficiaries")}
          >
            + Add New Beneficiary
          </Button>
        </Stack>
      </Box>

      <Box mt={3}>
        <AddNewCitmfbBeneficiaryModal
          open={open}
          step={step.beneficiaries}
          handleClose={handleClose}
          error={error}
          isLoading={loading}
          formik={formik}
        />

        <DeleteCitmfbBeneficiaryModal
          open={open.delete}
          step={step.delete}
          handleClose={handleClose}
          deleteBeneficiary={deleteBeneficiary}
        />

        <CitmfbBeneficiariesTable
          tableData={tableData}
          handleOpen={handleOpen}
          page={page}
          setPage={setPage}
          item_count={item_count}
        />
      </Box>
    </>
  );
};

export default CitmfbTransfer;
