export const styles = {
  container: {
    maxWidth: { xs: '95%', sm: '80%', lg: '75%' },
    mx: 'auto',
    mt: { xs: 5, md: 6 },
  },
  input: {
    backgroundColor: 'rgba(180, 246, 215, 0.05)',
    borderColor: '#D22F31',
  },
  input2: {
    border: 'none',
    height: 'auto',
    opacity: 0,
    zIndex: 1,
  },
  select: {
    bgcolor: '#FBFBFB',
    border: '0.5px solid #D22F31',
    borderRadius: '5px',
  },
  box: {
    position: 'relative',
    bgcolor: '#FBFBFB',
    height: { xs: 55, xl: 90 },
    border: '0.5px solid #D22F31',
    borderRadius: '5px',
  },
  placeholder: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.5)',
    px: { xs: 1, sm: 2 },
  },
  dateValue: {
    position: 'absolute',
    bottom: '5px',
    fontSize: 16,
    fontWeight: 500,
    px: { xs: 1, sm: 2 },
  },
  submitBtn: {
    width: { xs: "80%", sm: 300 },
    height: 55,
    alignSelf: 'center',
    color: 'white.main',
  },
};
