import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useLocalStorage } from "hooks/useLocalStorage";
import { formatCurrency } from "utils/formatCurrency";
import HideAccountBalance from "components/base/HideAccountBalance/HideAccountBalance";
import { styles } from "./AccountCard.styles";

const FlexComponent = ({ children, sx }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ py: 1, ...sx }}
  >
    {children}
  </Stack>
);

const AccountCard = ({
  firstName,
  lastName,
  accountNo,
  accountType,
  bal,
  ledger_balance,
  active,
}) => {
  const { setLocalStorage, getLocalStorage } = useLocalStorage("__hideBalance");
  const [hideBal, setHideBal] = React.useState(getLocalStorage);

  const toggleAccountBalanceVisibility = () => {
    getLocalStorage ? setLocalStorage(false) : setLocalStorage(true);
    setHideBal(!hideBal);
  };

  return (
    <Stack sx={styles.container}>
      <FlexComponent sx={{ borderBottom: "1px solid #E4E5E7" }}>
        <Typography color="black.main" sx={styles.accountType}>
          {accountType.replaceAll("Or", "/")}
        </Typography>
        <Typography color="black.main" sx={styles.accountNumber}>
          {accountNo}
        </Typography>
      </FlexComponent>

      <FlexComponent>
        <Typography color="black.main" sx={styles.accountName}>
          {`${firstName} ${lastName}`}
        </Typography>
        <Box>
          <Typography color="black.main" sx={styles.status}>
            Status &gt;
          </Typography>
          <Typography color="black.main" sx={styles.accountStatus}>
            {active ? "Active" : "In Active"}
          </Typography>
        </Box>
      </FlexComponent>

      <FlexComponent>
        <Box sx={{ flex: "1" }}>
          <Typography color="black.main" sx={styles.availableBalance}>
            Available Balance
          </Typography>

          <Typography color="black.main" sx={styles.accountBalance}>
            {hideBal ? "******************" : formatCurrency(bal)}
          </Typography>
          <Typography color="black.main" sx={styles.availableBalance}>
            Ledger Balance: {formatCurrency(ledger_balance)}
          </Typography>
        </Box>
        <HideAccountBalance
          toggle={toggleAccountBalanceVisibility}
          state={getLocalStorage}
          storeKey="__hideBalance"
          showLabel={true}
        />
      </FlexComponent>
      <Typography color="black.main" sx={styles.viewAccounts}>
        <Link to="/balance-enquiry">View Account Details &gt;</Link>
      </Typography>
    </Stack>
  );
};

export default AccountCard;
