import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const style = {
  fontSize: { xs: 14, sm: 17 },
  fontWeight: 600,
  color: 'gray2.main',
  textTransform: 'capitalize',
};

const FlexDetails = ({ left, right }) => {
  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center'>
      <Typography
        sx={{
          fontSize: { xs: 13, sm: 16 },
          fontWeight: 600,
          color: 'gray8.main',
        }}
      >
        {left}
      </Typography>
      <Typography sx={style}>{right}</Typography>
    </Stack>
  );
};

export default FlexDetails;
