import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
//import Stack from "@mui/material/Stack";
//import Typography from "@mui/material/Typography";
//import ActivitiesChart from "../ActivitiesChart/ActivitiesChart";
//import ActivitiesTimeFrame from "../ActivitiesTimeFrame/ActivitiesTimeFrame";
import ServicesCard from "../ServicesCard/ServicesCard";
import { styles } from "./QuickServicesAndActivitiesStat.styles";

const QuickServicesAndActivitiesStat = () => {
  return (
    <Grid item>
      <Box sx={styles.quickServices}>
        <ServicesCard />
      </Box>
    </Grid>
  );
};

export default QuickServicesAndActivitiesStat;
