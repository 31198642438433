import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FlexDetails from "components/base/FlexDetails/FlexDetails";
import CustomModal from "components/base/Modal/Modal";
import Typography from "@mui/material/Typography";
import { formatCurrency } from "utils/formatCurrency";
import { styles } from "components/AirtimeAndData/AirtimeAndDataModal/AirtimeAndDataModal.styles";

const BillsPaymentModal = ({
  open,
  handleClose,
  step,
  nextStep,
  formik,
  Subscribe,
  error,
  isLoading,
}) => {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <Box sx={{ mx: "auto" }}>
        {step === 0 && (
          <>
            <Typography sx={styles.header}>Confirm Transaction</Typography>

            <Stack spacing={1}>
              {formik.values.account && (
                <FlexDetails left="Account" right={formik.values.account} />
              )}

              {formik.values.disco_name && (
                <FlexDetails left="Biller" right={formik.values.disco_name} />
              )}

              {formik.values.service_name && (
                <FlexDetails left="Biller" right={formik.values.service_name} />
              )}

              {formik.values.smart_card_no && (
                <FlexDetails
                  left="Smart card Number"
                  right={formik.values.smart_card_no}
                />
              )}

              {formik.values.product_name && (
                <FlexDetails
                  left="Plan Category"
                  right={formik.values.product_name}
                />
              )}

              {formik.values.amount && (
                <FlexDetails
                  left="Amount"
                  right={formatCurrency(formik.values.amount)}
                />
              )}

              {formik.values.meter_no && (
                <FlexDetails
                  left="Meter Number"
                  right={formik.values.meter_no}
                />
              )}

              {formik.values.phone_no && (
                <FlexDetails
                  left="Phone Number"
                  right={formik.values.phone_no}
                />
              )}

              {formik.values.duration && (
                <FlexDetails left="Duration" right={formik.values.duration} />
              )}
            </Stack>

            <Box mx="auto" width="fit-content" mt={{ xs: 3, sm: 5 }}>
              <Button
                variant="contained"
                color="green3"
                sx={styles.btn}
                disabled={isLoading}
                onClick={Subscribe}
              >
                {isLoading ? "Please wait..." : "Send Request"}
              </Button>
            </Box>
          </>
        )}

        {step === 1 && (
          <Box>
            <Box
              as="img"
              src="/img/successful_gif.gif"
              alt="successful"
              sx={styles.gif}
            />
            <Typography sx={{ ...styles.header, mb: 2, mt: "-20px" }}>
              Transaction Successful
            </Typography>

            <Typography sx={styles.subText}>
              Bill payment request has been sent
            </Typography>

            <Button
              variant="contained"
              color="green3"
              sx={styles.btn}
              onClick={handleClose}
            >
              Continue
            </Button>
          </Box>
        )}
      </Box>
    </CustomModal>
  );
};

export default BillsPaymentModal;
