import Stack from "@mui/material/Stack";
import * as React from "react";
import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as VisibilityOn } from "assets/svg/visibility-on.svg";
import { ReactComponent as VisibilityOff } from "assets/svg/visibility-off.svg";
import { validateNumbers } from "utils/validateNumbers";

const SetUpPasswordForm = ({ formik }) => {
  const [showPassword, setShowPassword] = React.useState({
    password: false,
    confirmPassword: false,
  });

  const toggleShowPassword = (type) => {
    setShowPassword((prevS) => ({ ...prevS, [type]: !prevS[type] }));
  };

  return (
    <Stack spacing={3} mb={2} mt={3}>
      <InputWithLabel label="Password">
        <Input
          id="password"
          type={showPassword.password ? "text" : "password"}
          placeholder="Enter your Password"
          name="password"
          value={formik.values.password}
          onChange={(event) => {
            validateNumbers(event, 6, formik.handleChange);
          }}
          formik={formik}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => toggleShowPassword("password")}
                edge="end"
              >
                {showPassword.password ? (
                  <VisibilityOn />
                ) : (
                  <VisibilityOff className="dark-bg-visibility-off" />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </InputWithLabel>

      <InputWithLabel label="Confirm Password">
        <Input
          id="confirmPassword"
          type={showPassword.confirmPassword ? "text" : "password"}
          placeholder="Confirm your Password"
          name="confirmPassword"
          value={formik.values.confirmPassword}
          onChange={(event) => {
            validateNumbers(event, 6, formik.handleChange);
          }}
          formik={formik}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle confirmPassword visibility"
                onClick={() => toggleShowPassword("confirmPassword")}
                edge="end"
              >
                {showPassword.confirmPassword ? (
                  <VisibilityOn />
                ) : (
                  <VisibilityOff className="dark-bg-visibility-off" />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </InputWithLabel>
    </Stack>
  );
};

export default SetUpPasswordForm;
