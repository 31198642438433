export const styles = {
  header: {
    fontSize: {
      xs: 20,
      sm: 22
    },
    fontWeight: 700,
    color: "green3.main",
    mb: {
      xs: 4,
      sm: 5
    },
    textAlign: "center",
  },
  btn: {
    width: '100%',
    height: 55,
    color: "white.main",
    fontSize: {
      xs: 16,
      sm: 18
    },
    fontWeight: 600,
    mt: 3,
  },
  subText: {
    width: {
      xs: "80%",
      sm: "70%"
    },
    fontSize: {
      xs: 14,
      sm: 16
    },
    fontWeight: 500,
    textAlign: "center",
    mx: "auto",
  },
  gif: {
    display: "block",
    width: 200,
    mx: "auto",
    objectFit: "contain",
  },
};