import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputWithLabel from 'components/base/InputWithLabel/InputWithLabel';
import Input from 'components/base/Input/Input';
import CustomModal from 'components/base/Modal/Modal';
import Select from 'components/base/Select/Select';
import DirectionSnackbar from 'components/base/SnackBar/SnackBar';
import { getAccountName } from 'services/dashboard';
import { useQuery } from '@tanstack/react-query';
import { dummyBanks } from 'assets/data';
import { styles } from 'components/SavedBeneficiaries/AddNewCitmfbBeneficiaryModal/AddNewCitmfbBeneficiaryModal.styles';

const AddNewOtherBanksBeneficiariesModal = ({
  open,
  step,
  handleClose,
  formik,
  error,
  isLoading,
}) => {
  const banks = dummyBanks.map(({ bank_name }) => bank_name);

  const getBankCode = (bankName) => {
    const bankObject = dummyBanks.find(
      ({ bank_name }) => bank_name === bankName
    );

    return bankObject?.bank_code || '';
  };

  // get beneficiary account name query
  const queryType = 'other_bank';
  const account_no = `${formik.values.BeneficiariesAccountNo}`;
  const bankCode = getBankCode(formik.values.BeneficiariesBankName);

  useQuery({
    queryKey: ['name_enquiry', queryType, account_no, bankCode],
    queryFn: () => getAccountName(queryType, account_no, bankCode),
    onSuccess: (data) => {
      formik.setFieldValue(
        'BeneficiariesAccountName',
        data.name.replace(',', '')
      );
    },
    onError: () => {
      formik.setFieldValue('BeneficiariesAccountName', '');
    },
    enabled: account_no.length === 10,
  });

  return (
    <CustomModal
      open={open.beneficiaries}
      handleClose={() => handleClose('beneficiaries')}
    >
      <DirectionSnackbar
        open={open.error}
        handleClose={() => handleClose('error')}
        message={error}
      />

      <Box sx={styles.container} as='form' onSubmit={formik.handleSubmit}>
        {step === 0 && (
          <Stack spacing={3} sx={styles.content} alignItems='center'>
            <Typography sx={styles.header}>Add New Beneficiary</Typography>

            <InputWithLabel label='Beneficiary Bank Name'>
              <Select
                options={banks}
                id='BeneficiariesBankName'
                name='BeneficiariesBankName'
                value={formik.values.BeneficiariesBankName}
                placeholder='Enter Beneficiary Bank Name'
                formik={formik}
              />
            </InputWithLabel>

            <InputWithLabel label='Beneficiary Account Number'>
              <Input
                id='BeneficiariesAccountNo'
                name='BeneficiariesAccountNo'
                type='number'
                value={formik.values.BeneficiariesAccountNo}
                onChange={formik.handleChange}
                placeholder='Enter Beneficiary Account Number'
                formik={formik}
              />
            </InputWithLabel>

            <InputWithLabel label='Beneficiary Account Name'>
              <Input
                id='BeneficiariesAccountName'
                name='BeneficiariesAccountName'
                value={formik.values.BeneficiariesAccountName}
                disabled={true}
                onChange={formik.handleChange}
                placeholder='Enter Beneficiary Account Name'
                formik={formik}
              />
            </InputWithLabel>

            <Box mx='auto' width='fit-content' mt={{ xs: 3, sm: 5 }}>
              <Button
                type='submit'
                variant='contained'
                color='green3'
                sx={styles.btn}
                disabled={isLoading}
              >
                Add Beneficiary
              </Button>
            </Box>
          </Stack>
        )}

        {step === 1 && (
          <Box sx={styles.content}>
            <Box
              as='img'
              src='/img/successful_gif.gif'
              alt='successful'
              sx={styles.gif}
            />
            <Stack spacing={3} alignItems='center'>
              <Typography sx={styles.header}>Done!</Typography>
              <Typography sx={styles.subText}>
                Beneficiary has been created successfully
              </Typography>
            </Stack>

            <Box mx='auto' width='fit-content' mt={{ xs: 3, sm: 5 }}>
              <Button
                type='button'
                variant='contained'
                color='green3'
                sx={styles.btn}
                onClick={() => handleClose('beneficiaries')}
              >
                Done
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </CustomModal>
  );
};

export default AddNewOtherBanksBeneficiariesModal;
