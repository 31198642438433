import { useState } from "react";
import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import { useMutation } from "@tanstack/react-query";
import { validateBvn } from "services/Auth";
import { validateNumbers } from "utils/validateNumbers";
import moment from "moment";

const FirstForm = ({ formik }) => {
  const [isValid, setIsValid] = useState(true);

  const { mutate } = useMutation({
    mutationFn: validateBvn,
    onSuccess: (data) => {
      const { DOB, FirstName, LastName, OtherNames, phoneNumber } = data.detail;
      formik.setFieldValue("phone", phoneNumber);
      formik.setFieldValue("dob", moment(DOB).format("YYYY-MM-DD"));
      formik.setFieldValue("first_name", FirstName.toLowerCase());
      formik.setFieldValue("middle_name", OtherNames.toLowerCase());
      formik.setFieldValue("last_name", LastName.toLowerCase());

      setIsValid(true);

      setTimeout(() => {
        formik.validateForm();
      }, 1000);
    },
    onError: (err) => {
      // TODO SHOW ERROR NOTIFICATION
      console.log(err, "err");
      setError(err?.response?.data?.detail);
      setIsValid(true);
    },
  });

  const [error, setError] = useState();

  const validate = (number, bvn) => {
    setIsValid(true);

    mutate({
      phone_number: number,
      bvn: bvn,
    });
  };

  return (
    <>
      <InputWithLabel label="BVN">
        <Input
          id="bvn"
          name="bvn"
          type="tel"
          placeholder="Enter your BVN"
          value={formik.values.bvn}
          onChange={(event) => {
            if (
              event.target.value.length > 10 &&
              formik.values.phone?.length > 10
            ) {
              validate(formik.values.phone, event.target.value);
            }

            validateNumbers(event, 11, formik.handleChange);
          }}
          formik={formik}
        />
      </InputWithLabel>

      <InputWithLabel label="Phone Number">
        <Input
          id="phone"
          name="phone"
          type="tel"
          placeholder="(e.g 07064378577)"
          value={formik.values.phone}
          onChange={(event) => {
            if (
              event.target.value.length > 10 &&
              formik.values.bvn.length > 10
            ) {
              validate(event.target.value, formik.values.bvn);
            }

            validateNumbers(event, 11, formik.handleChange);
          }}
          formik={formik}
        />
      </InputWithLabel>
      {/* bvn phone number */}
      <span style={{ color: "red" }}>{error}</span>

      <InputWithLabel label="First Name">
        <Input
          id="firstName"
          name="first_name"
          placeholder="Enter your First Name"
          value={formik.values.first_name}
          onChange={formik.handleChange}
          formik={formik}
          disabled={isValid}
        />
      </InputWithLabel>

      <InputWithLabel label="Middle Name">
        <Input
          id="middleName"
          name="middle_name"
          placeholder="Enter your Middle Name"
          value={formik.values.middle_name}
          onChange={formik.handleChange}
          formik={formik}
          disabled={isValid}
        />
      </InputWithLabel>

      <InputWithLabel label="Last Name">
        <Input
          id="lastName"
          name="last_name"
          placeholder="Enter your Last Name"
          value={formik.values.last_name}
          onChange={formik.handleChange}
          formik={formik}
          disabled={isValid}
        />
      </InputWithLabel>
    </>
  );
};

export default FirstForm;
