export const styles = {
  content: {
    width: "fit-content",
    mx: "auto",
  },
  header: {
    fontSize: { xs: 22, sm: 25, lg: 25 },
    fontWeight: 700,
    textAlign: "center",
  },
  subText: {
    fontSize: { xs: 14, sm: 16, lg: 18 },
    fontWeight: 500,
    textAlign: "center",
  },
  gif: {
    display: "block",
    width: 200,
    mx: "auto",
  },
  btn: {
    width: { xs: 190, sm: 245 },
    height: { xs: 45, sm: 55 },
    color: "white.main",
    fontSize: { xs: 16, sm: 18 },
    fontWeight: 600,
  },
};
