import { /* useState */ useEffect } from "react";
//import Box from "@mui/material/Box";
//import ApplyAndOpen from "components/LandingPage/ApplyAndOpen/ApplyAndOpen";
//import Banner from "components/LandingPage/Banner/Banner";
//import BestServices from "components/LandingPage/BestServices/BestServices";
//import DigitalBanking from "components/LandingPage/DigitalBanking/DigitalBanking";
import { Navigate } from "react-router-dom";
// import Footer from "components/LandingPage/Footer/Footer";
//import GetAccountNumber from "components/LandingPage/GetAccountNumber/GetAccountNumber";
//import HelpYouGrow from "components/LandingPage/HelpYouGrow/HelpYouGrow";
//import NavBar from "components/LandingPage/NavBar/NavBar";

const LandingPage = () => {
  //const [loaded, setLoaded] = useState(false);

  useEffect(() => {});

  return (
    <div>
      {/*       <Box as="article">
      <Box
        sx={{
          position: "relative",
          maxWidth: "100%",
          bgcolor: loaded ? "transparent" : "green1.main",
          overflow: "hidden",
          transition: "all 0.5s ease-in-out",
        }}
      >
        <Box
          as="img"
          src="/img/banner.svg"
          key={1}
          onLoad={() => {
            setTimeout(() => {
              setLoaded(true);
            }, 500);
          }}
          alt="banner-bg"
          sx={{
            opacity: loaded ? "1" : "0",
            position: "absolute",
            top: { xs: "-50px", sm: "-100px", md: 0, lg: "-10px" },
            right: 0,
            bottom: 0,
            left: 0,
            height: "100%",
            minWidth: { xs: "100%", lg: "auto" },
            minHeight: { xs: "100vh", lg: "auto" },
            transition: "all 0.5s ease-in-out",
            mx: "auto",
            zIndex: "-1",
          }}
        />
        <NavBar />
        <Banner />
      </Box>
      <ApplyAndOpen />
      <GetAccountNumber />
      <HelpYouGrow />
      <BestServices />
      <DigitalBanking />
      {/* <Footer /> */}
      {/*  <Box sx={{height: 100}}></Box>
    </Box>  */}
      <Navigate to="/auth/sign-in" />
    </div>
  );
};

export default LandingPage;
