import Stack from "@mui/material/Stack";
import * as React from "react";
import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as VisibilityOn } from "assets/svg/visibility-on.svg";
import { ReactComponent as VisibilityOff } from "assets/svg/visibility-off.svg";
import { validateLetters } from "utils/validateNumbers";

const ThirdForm = ({ formik }) => {
  const [showPassword, setShowPassword] = React.useState({
    new_password: false,
    confirm_password: false,
  });

  const toggleShowPassword = (type) => {
    setShowPassword((prevS) => ({ ...prevS, [type]: !prevS[type] }));
  };

  return (
    <Stack spacing={3} mb={2} mt={3}>
      <InputWithLabel label="Password">
        <Input
          id="new_password"
          type={showPassword.new_password ? "text" : "password"}
          placeholder="Enter your Password"
          name="new_password"
          value={formik.values.new_password}
          onChange={(event) => {
            validateLetters(event, 8, formik.handleChange);
          }}
          formik={formik}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => toggleShowPassword("new_password")}
                edge="end"
              >
                {showPassword.new_password ? (
                  <VisibilityOn />
                ) : (
                  <VisibilityOff className="dark-bg-visibility-off" />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </InputWithLabel>

      <InputWithLabel label="Confirm Password">
        <Input
          id="confirm_password"
          type={showPassword.confirm_password ? "text" : "password"}
          placeholder="Confirm your Password"
          name="confirm_password"
          value={formik.values.confirm_password}
          onChange={(event) => {
            validateLetters(event, 8, formik.handleChange);
          }}
          formik={formik}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle confirm_password visibility"
                onClick={() => toggleShowPassword("confirm_password")}
                edge="end"
              >
                {showPassword.confirm_password ? (
                  <VisibilityOn />
                ) : (
                  <VisibilityOff className="dark-bg-visibility-off" />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </InputWithLabel>
    </Stack>
  );
};

export default ThirdForm;
