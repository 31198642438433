// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
.fade-enter {
  opacity: 0;
  transform: scale(1.1);
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
} */

.fade-enter {
  opacity: 0;
  transform: translateX(100%);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 300ms, transform 300ms;
}`, "",{"version":3,"sources":["webpack://./src/components/base/PageTransition/styles.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;GAqBG;;AAEH;EACE,UAAU;EACV,2BAA2B;AAC7B;AACA;EACE,UAAU;EACV,yBAAyB;AAC3B;AACA;EACE,UAAU;EACV,yBAAyB;AAC3B;AACA;EACE,UAAU;EACV,4BAA4B;AAC9B;AACA;;EAEE,0CAA0C;AAC5C","sourcesContent":["/* \n.fade-enter {\n  opacity: 0;\n  transform: scale(1.1);\n}\n\n.fade-enter-active {\n  opacity: 1;\n  transform: scale(1);\n  transition: opacity 300ms, transform 300ms;\n}\n\n.fade-exit {\n  opacity: 1;\n  transform: scale(1);\n}\n\n.fade-exit-active {\n  opacity: 0;\n  transform: scale(0.9);\n  transition: opacity 300ms, transform 300ms;\n} */\n\n.fade-enter {\n  opacity: 0;\n  transform: translateX(100%);\n}\n.fade-enter-active {\n  opacity: 1;\n  transform: translateX(0%);\n}\n.fade-exit {\n  opacity: 1;\n  transform: translateX(0%);\n}\n.fade-exit-active {\n  opacity: 0;\n  transform: translateX(-100%);\n}\n.fade-enter-active,\n.fade-exit-active {\n  transition: opacity 300ms, transform 300ms;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
