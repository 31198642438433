import Box from "@mui/material/Box";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import OTP from "components/base/OTP/OTP";

const EnterOtpForm = ({ formik }) => {
  return (
    <Box mb={2} mt={3}>
      <InputWithLabel label="Enter OTP">
        <Box mt={2} />
        <OTP
          formik={formik}
          formikKey="otp"
          variant={3}
          length={6}
        />
      </InputWithLabel>
    </Box>
  );
};

export default EnterOtpForm;
