import * as React from "react";
import { useContext } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import Select from "components/base/Select/Select";
import Switch from "components/base/Switch/Switch";
import TransferModal from "components/base/TransferModalCorporate/TransferModal";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import {
  getAccountName,
  //userProfile,
  createBeneficiary,
  transferReq,
  getAllBanks,
} from "services/corporateDashboard";
import { useQuery, useMutation } from "@tanstack/react-query";
import { validateNumbers } from "utils/validateNumbers";
import { styles } from "./TransferToCitForm.styles";
import CorporateDashboardContext from "context/CorporateDashboardContext";
import { Box, Typography } from "@mui/material";
import { dummyBanks } from "assets/data";
import { useNavigate } from "react-router-dom";

const TransferToCitForm = ({ action, formik, open, setOpen }) => {
  const { accountNumbers } = useContext(CorporateDashboardContext);

  console.log(accountNumbers);
  // get user profile
  /*  const { data } = useQuery({
    queryKey: ["userProfile"],
    queryFn: userProfile,
  }); */

  // const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState({
    nameEnquiry: false,
    transaction: false,
  });
  const [error, setError] = React.useState({
    nameEnquiry: "",
    transaction: "",
  });
  const [step, setStep] = React.useState(0);
  const [otpError, setOtpError] = React.useState(0);

  // const handleOpenModal = () => setOpen(true);

  const handleCloseModal = () => {
    setStep(0);
    setOpen(false);
    formik.resetForm();
  };

  const handleCloseError = (key) => {
    setOpenError((prevS) => ({ ...prevS, [key]: false }));
  };

  const handleOpenError = (key) => {
    setOpenError((prevS) => ({ ...prevS, [key]: true }));
  };

  const setBackendError = (key, err) => {
    setError((prevS) => ({ ...prevS, [key]: err }));
  };

  React.useEffect(() => {
    if (formik.values.transaction_pin.length === 4) {
      setOtpError("");
    }
  }, [formik.values.transaction_pin.length]);

  const handleConfirmTransactionBtn = () => {
    setStep((prevS) => prevS + 1);
  };
  // get all banks query
  const { data: allBanks } = useQuery({
    queryKey: ["allBanks"],
    queryFn: getAllBanks,
    initialData: [],
  });
  const fullBanks = allBanks[0]?.nip_banks || dummyBanks;
  console.log(fullBanks);
  const getBankCode = (bankName) => {
    const bankObject = fullBanks?.find(
      ({ bank_name }) => bank_name === bankName
    );

    return bankObject?.bank_code || "";
  };

  const bankCode =
    getBankCode(formik.values.beneficiary_bank_name) ||
    formik.values.beneficiary_bank_name?.match(/\d/g)?.join("");
  console.log(bankCode);
  const handleTransfer = () => {
    if (formik.values.saveBeneficiary) {
      CreateBeneficiary({
        beneficiary_type: "local_transfer",
        beneficiary_name: formik.values.beneficiary_account_name,
        beneficiary_bank: "",
        beneficiary_acct_no: formik.values.beneficiary_account_no,
        account_type: "corporate",
      });
    }

    if (formik.values.frequencyType === "Monthly") {
      mutate({
        account_no: formik.values.account?.slice(0, 10),
        beneficiary_name: formik.values.beneficiary_account_name,
        beneficiary_bank_code:
          bankCode || getBankCode(formik.values.beneficiary_bank_name),
        beneficiary_bank_name: formik.values.beneficiary_bank_name
          ?.replace(/[^a-z]+/gi, " ")
          ?.split("")
          ?.join(""),
        beneficiary_acct_no: formik.values.beneficiary_account_no,
        amount: formik.values.amount,
        schedule_type: formik.values.frequencyType,
        day_of_the_month: formik.values.frequency,
        schedule: true,
        start_date: formik.values.fromDate,
        end_date: formik.values.toDate,
        narration: formik.values.narration,
        beneficiary_acct_type: "savings",
        transfer_type: "single",
      });
    } else {
      mutate({
        transfer_type: "single",
        account_no: formik.values.account?.slice(0, 10),
        beneficiary_name: formik.values.beneficiary_account_name,
        beneficiary_bank_code:
          bankCode || getBankCode(formik.values.beneficiary_bank_name),
        beneficiary_bank_name: formik.values.beneficiary_bank_name
          ?.replace(/[^a-z]+/gi, " ")
          ?.split("")
          ?.join(""),
        beneficiary_acct_no: formik.values.beneficiary_account_no,
        amount: formik.values.amount,
        schedule_type: formik.values.frequencyType,
        schedule: true,
        day_of_the_week: formik.values.frequency,
        start_date: formik.values.fromDate,
        end_date: formik.values.toDate,
        narration: formik.values.narration,
        beneficiary_acct_type: "savings",
      });
    }
  };

  // get beneficiary account name query
  const queryType = "other_bank";
  const account_no = `${formik.values.beneficiary_account_no}`;

  const { isError } = useQuery({
    queryKey: ["name_enquiry", queryType, account_no, bankCode],
    queryFn: () => getAccountName(queryType, account_no, bankCode),
    onSuccess: (data) => {
      handleCloseError("nameEnquiry");

      formik.setFieldValue(
        "beneficiary_account_name",
        data.name.replace(",", "")
      );
    },
    onError: (err) => {
      setBackendError(
        "nameEnquiry",
        err?.response?.data?.detail || err.message
      );
      handleOpenError("nameEnquiry");
      formik.setFieldValue("beneficiary_account_name", "");
    },
    enabled: account_no.length === 10,
  });
  const router = useNavigate();
  const { mutate, isLoading } = useMutation({
    mutationFn: transferReq,
    onSuccess: (data) => {
      //setStep((prevS) => prevS + 1);
      setStep(0);
      router("/corporate-scheduled-payments?tab=table");
      action();
      handleCloseModal();
      formik.resetForm();
    },
    onError: (err) => {
      setBackendError(
        "transaction",
        err?.response?.data?.detail || err.message
      );
      handleOpenError("transaction");
      console.log(err?.response?.data?.detail, err.message);
    },
  });

  // save beneficiary mutation
  const { mutate: CreateBeneficiary } = useMutation({
    mutationFn: createBeneficiary,
  });

  //const accounts =
  //data?.customer?.accounts?.map(({ account_no }) => account_no) || [];

  return (
    <Stack
      as="form"
      spacing={{ xs: 3, sm: 4 }}
      onSubmit={formik.handleSubmit}
      sx={styles.container}
    >
      <TransferModal
        open={open}
        handleClose={handleCloseModal}
        step={step}
        handleConfirmTransactionBtn={handleConfirmTransactionBtn}
        setStep={setStep}
        handleTransfer={handleTransfer}
        isLoading={isLoading}
        openError={openError.transaction}
        handleCloseError={() => handleCloseError("transaction")}
        errorMessage={error.transaction}
        formik={formik}
        error={otpError}
      />

      <DirectionSnackbar
        open={openError.nameEnquiry}
        handleClose={() => handleCloseError("nameEnquiry")}
        message={error.nameEnquiry}
      />

      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Account">
          <Select
            disableClearable={true}
            options={accountNumbers || []}
            placeholder="Select Account"
            name="account"
            value={formik.values.account}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>

        <InputWithLabel label="Amount">
          <Input
            name="amount"
            type="tel"
            placeholder="Enter Amount"
            value={formik.values.amount}
            onChange={(event) => {
              validateNumbers(event, 1000, formik.handleChange);
            }}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>
      </Stack>

      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Beneficiary Bank Name">
          <Select
            options={fullBanks?.map(({ bank_name }) => bank_name)}
            placeholder="Enter Beneficiary Bank Name"
            name="beneficiary_bank_name"
            value={formik.values.beneficiary_bank_name
              ?.replace(/[^a-z]+/gi, " ")
              ?.split("")
              ?.join("")}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>
        <InputWithLabel label="Beneficiary Account Number">
          <Input
            type="tel"
            name="beneficiary_account_no"
            placeholder="Enter Beneficiary Account Number"
            value={formik.values.beneficiary_account_no}
            onChange={(event) => {
              validateNumbers(event, 10, formik.handleChange);
              formik.setFieldValue("beneficiary_account_name", "");
            }}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>
      </Stack>
      <InputWithLabel label="Beneficiary Account Name">
        <Input
          name="beneficiary_account_name"
          placeholder="Enter Beneficiary Account Name"
          value={formik.values.beneficiary_account_name}
          disabled={true}
          onChange={formik.handleChange}
          formik={formik}
          sx={{
            ...styles.input,
            borderColor: isError ? "red.main" : "gray3.main",
          }}
        />
      </InputWithLabel>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Frequency Type">
          <Select
            options={["Monthly", "Weekly"]}
            placeholder="Select Frequency Type"
            name="frequencyType"
            value={formik.values.frequencyType}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>
        <InputWithLabel label="Frequency">
          <Select
            options={
              formik.values.frequencyType === "Monthly"
                ? [
                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                    18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                  ]
                : formik.values.frequencyType === "Weekly"
                ? [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ]
                : []
            }
            placeholder="Select Frequency"
            name="frequency"
            value={formik.values.frequency}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>
      </Stack>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Start Date">
          <Box sx={styles.box}>
            <Typography sx={styles.placeholder}>Start Date</Typography>
            <Input
              type="date"
              name="fromDate"
              value={formik.values.fromDate}
              onChange={formik.handleChange}
              formik={formik}
              sx={styles.input2}
            />
            <Typography sx={styles.dateValue}>
              {formik.values.fromDate
                ? new Date(formik.values.fromDate)
                    .toDateString()
                    .slice(4)
                    .replaceAll(" ", "-")
                : ""}
            </Typography>
          </Box>
        </InputWithLabel>
        <InputWithLabel label="End Date">
          <Box sx={styles.box}>
            <Typography sx={styles.placeholder}>End Date</Typography>
            <Input
              type="date"
              name="toDate"
              value={formik.values.toDate}
              onChange={formik.handleChange}
              formik={formik}
              sx={styles.input2}
            />
            <Typography sx={styles.dateValue}>
              {formik.values.toDate
                ? new Date(formik.values.toDate)
                    .toDateString()
                    .slice(4)
                    .replaceAll(" ", "-")
                : ""}
            </Typography>
          </Box>
        </InputWithLabel>
      </Stack>

      <InputWithLabel label="Narration">
        <Input
          name="narration"
          placeholder="Enter Description (Optional)"
          value={formik.values.narration}
          onChange={formik.handleChange}
          formik={formik}
          sx={styles.input}
        />
      </InputWithLabel>

      <Stack sx={{ transform: "translateY(-1rem)", pb: 5 }}>
        <Switch
          name="saveBeneficiary"
          label="Save as beneficiary"
          value={formik.values.saveBeneficiary}
          onChange={formik.handleChange}
        />
      </Stack>

      <Button
        type="submit"
        variant="contained"
        color="green3"
        sx={styles.submitBtn}
      >
        Proceed
      </Button>
    </Stack>
  );
};

export default TransferToCitForm;
