import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import HideAccountBalance from "components/base/HideAccountBalance/HideAccountBalance";
import { styled } from "@mui/material/styles";
import { ReactComponent as SimIcon } from "assets/svg/sim.svg";
import { formatCurrency } from "utils/formatCurrency";
import { useLocalStorage } from "hooks/useLocalStorage";
import { styles } from "./UserAccountCard.styles";

const Sim = styled(SimIcon)({});

const UserAccountCard = ({
  firstName,
  lastName,
  type,
  AcNumber,
  bal,
  status,
  color,
  bgImg,
}) => {
  const { setLocalStorage, getLocalStorage } = useLocalStorage(
    `__bal${AcNumber}`
  );
  const [hideBal, setHideBal] = React.useState(getLocalStorage);

  const toggleAccountBalanceVisibility = () => {
    getLocalStorage ? setLocalStorage(false) : setLocalStorage(true);
    setHideBal(!hideBal);
  };

  return (
    <Box sx={styles.accountCard(color, bgImg)}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          borderBottom: "1px solid rgba(255, 255, 255, 0.5)",
          pb: { xs: 1, sm: 2 },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Sim sx={styles.simIcon} />
          <Typography sx={styles.accountType}>
            {type.replaceAll("Or", "/")}
          </Typography>
        </Stack>
        <Typography sx={styles.accountNumber}>{AcNumber}</Typography>
      </Stack>

      <Typography
        sx={styles.accountName}
      >{`${firstName} ${lastName}`}</Typography>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={5}
      >
        <Box>
          <Typography sx={styles.availableBalText}>
            Available Balance
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography sx={styles.availBal}>
              {getLocalStorage ? "******************" : formatCurrency(bal)}{" "}
            </Typography>
            <HideAccountBalance
              toggle={toggleAccountBalanceVisibility}
              state={getLocalStorage}
            />
          </Stack>
        </Box>

        <Box>
          <Typography sx={styles.statusText}>Status &gt;</Typography>
          <Typography sx={styles.status}>
            {status ? "Active" : "In Active"}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default UserAccountCard;
