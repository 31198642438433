import * as Yup from 'yup';

export const AccountStatementSchema = Yup.object().shape({
  account: Yup.string().required('Select account'),
  dateFrom: Yup.date()
    .nullable()
    .min(new Date(1900, 0, 1))
    .max(new Date(), "Can't select future date")
    .required('Select start date'),
  dateTo: Yup.date()
    .nullable()
    .min(new Date(1900, 0, 1))
    .max(new Date(), "Can't select future date")
    .required('Select end date'),
});
