import { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CardEnquiryCard from "components/base/CardEnquiryCard/CardEnquiryCard";
import Select from "components/base/Select/Select";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import Input from "components/base/Input/Input";
import { useFormik } from "formik";
import { CardHotListSchema } from "services/Yup/CardHotListSchema";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import { userProfile, getCustomersCard, blockCard } from "services/dashboard";
import { useQuery, useMutation } from "@tanstack/react-query";
import { styles } from "./CardHotList.styles";
import LoadingAnimation from "components/base/LoadingAnimation/LoadingAnimation";

const CardHotList = () => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      account: "",
      reason: "",
      serialNumber: "",
    },
    validationSchema: CardHotListSchema,
    onSubmit: (values) => {
      BlockCard({
        account_no: values.values,
        reason: values.reason,
        serial_no: values.serialNumber,
        action: "block",
      });
    },
  });

  // get user profile
  const { data } = useQuery({
    queryKey: ["userProfile"],
    queryFn: userProfile,
    onSuccess: (data) => {
      formik.setFieldValue(
        "account",
        data.customer.accounts.map(({ account_no }) => account_no)[0]
      );
    },
    onError: (err) => {
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
    },
  });

  const accounts =
    data?.customer?.accounts?.map(({ account_no }) => account_no) || [];

  // get customer card info
  const account_no = formik.values.account || accounts[0];
  const { data: cardDetails, isLoading: fetchingCardInfo } = useQuery({
    queryKey: ["getCustomersCard", account_no],
    queryFn: () => getCustomersCard(account_no),
    onError: (err) => {
      setMessage(err?.response?.data?.detail || err.message);
      setOpen(true);
    },
    enabled: Boolean(account_no),
  });

  const { mutate: BlockCard, isLoading } = useMutation({
    mutationFn: blockCard,
    onSuccess: () => {
      setSeverity("success");
      setMessage("Card block successful");
      setOpen(true);
    },
    onError: (err) => {
      setSeverity("error");
      setMessage(err?.response?.data?.detail || err.message);
      setOpen(true);
    },
  });

  return (
    <Stack alignItems="center" sx={styles.background}>
      <DirectionSnackbar
        open={open}
        message={message}
        severity={severity}
        handleClose={() => {
          setOpen(false);
        }}
      />
      <Box sx={styles.container}>
        <Typography sx={styles.header}>Card Hotlist Request</Typography>

        {typeof cardDetails?.detail === "object" &&
        cardDetails?.detail.length > 0 ? (
          <CardEnquiryCard />
        ) : (
          <Stack sx={styles.noCardIssued}>
            {fetchingCardInfo ? (
              <LoadingAnimation />
            ) : (
              <Typography>NO CARD ISSUED FOR THIS ACCOUNT</Typography>
            )}
          </Stack>
        )}

        {cardDetails?.detail.length > 0 && (
          <Box as="form" onSubmit={formik.handleSubmit} mt={{ xs: 3, sm: 4 }}>
            <Stack spacing={2}>
              <InputWithLabel label="Select Account">
                <Select
                  options={accounts}
                  placeholder="Select Account"
                  name="account"
                  value={formik.values.account}
                  formik={formik}
                  sx={styles.input}
                />
              </InputWithLabel>

              <InputWithLabel label="Reason">
                <Select
                  id="reason"
                  name="reason"
                  options={["Card is stolen or missing"]}
                  placeholder="Reason"
                  value={formik.reason}
                  formik={formik}
                />
              </InputWithLabel>

              <InputWithLabel label="Serial Number">
                <Input
                  id="serialNumber"
                  name="serialNumber"
                  type="number"
                  placeholder="Enter card serial number"
                  value={formik.serialNumber}
                  onChange={formik.handleChange}
                  formik={formik}
                />
              </InputWithLabel>
            </Stack>

            <Button
              type="submit"
              variant="contained"
              color="green3"
              sx={styles.submitBtn}
              disabled={isLoading}
            >
              {isLoading ? "Please wait..." : "Submit"}
            </Button>
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default CardHotList;
