import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FlexDetails from "components/base/FlexDetails/FlexDetails";
import { formatCurrency } from "utils/formatCurrency";
import { styles } from "components/base/TransferModal/TransferModal.styles";

const ConfirmTransaction = ({ handleClick, isLoading, formik, error }) => {
  return (
    <Box>
      <Typography sx={styles.header}>Confirm Transaction</Typography>
      <Stack spacing={2}>
        <FlexDetails left="Payment Method" right="Bank Transfer" />

        {formik?.value?.beneficiary_account_no && (
          <FlexDetails
            left="Account No"
            right={formik.values.beneficiary_account_no}
          />
        )}

        {formik?.values?.amount && (
          <FlexDetails
            left="Amount"
            right={`${formatCurrency(formik.values.amount)}`}
          />
        )}

        {Boolean(formik.values.narration) && (
          <FlexDetails left="Narration" right={formik.values.narration} />
        )}
        {/* <FlexDetails left='Reference No.' right='2103428464717174731' /> */}
        <FlexDetails left="Sent on" right={new Date().toLocaleString()} />
      </Stack>
      <span style={{ color: "red" }}>{error || ""}</span>

      <Box mx="auto" width="fit-content" mt={{ xs: 3, sm: 5 }}>
        <Button
          variant="contained"
          color="green3"
          sx={styles.btn}
          onClick={handleClick}
          disabled={isLoading}
        >
          {isLoading ? "Please wait" : "Send Request"}
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmTransaction;
