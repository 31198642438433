import Stack from '@mui/material/Stack';
import AccountSummary from 'components/BalanceEnquiry/AccountSummary/AccountSummary';
import AccountSummaryTable from 'components/BalanceEnquiry/AccountSummaryTable/AccountSummaryTable';
import { userProfile } from 'services/dashboard';
import { useQuery } from '@tanstack/react-query';

const BalanceEnquiry = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['profile'],
    queryFn: userProfile,
  });

  return (
    <Stack spacing={3}>
      <AccountSummary data={data} isLoading={isLoading} />
      <AccountSummaryTable data={data} isLoading={isLoading} />
    </Stack>
  );
};

export default BalanceEnquiry;
