import FastCashIcon from "assets/svg/fast-cash.svg";
import FlexibleCreditIcon from "assets/svg/flexible-credit.svg";
import NoCollateralIcon from "assets/svg/no-collateral.svg";
import CompetitveInterestIcon from "assets/svg/competitive-interest.svg";

export const plan = [{
        icon: FastCashIcon,
        text: "Fast Business Cash",
    },
    {
        icon: FlexibleCreditIcon,
        text: "Flexible Credit Line",
    },
    {
        icon: NoCollateralIcon,
        text: "No Collateral or Guarantor",
    },
    {
        icon: CompetitveInterestIcon,
        text: "Competitive Interest Rates",
    },
];

export const landingPageNavLinks = [{
        nav: "Loan Products",
        color: "green1.main",
        subItems: ["Item One", "Item Two", "Item Three"],
    },
    {
        nav: "Savings Products",
        color: "green1.main",
        subItems: ["Item One", "Item Two", "Item Three"],
    },
    {
        nav: "Investment Products",
        color: "white.main",
    },
    {
        nav: "Our Bank",
        color: "white.main"
    },
    {
        nav: "Media",
        color: "white.main"
    },
];

export const bestServicesRow = [
    "img/cit-5.webp",
    "img/cit-6.webp",
    "img/cit-7.webp",
];

export const digitalBanking = [
    "Check Account Balance and Account Statement",
    "Inter-bank Transfers and Card Request",
    "Pay Bills, e.g. DSTv, GoTv, PHCN, etc.",
    "Buy Airtime from all Networks",
];

export const footerLinks = [{
        header: "About KC MFBank",
        links: [{
                label: "Our Company",
                link: "/",
            },
            {
                label: "Our Leadership",
                link: "/",
            },
            {
                label: "Our Management",
                link: "/",
            },
            {
                label: "Career Opportunities",
                link: "/",
            },
            {
                label: "KC Media Blog",
                link: "/",
            },
            {
                label: "Our Events Gallery",
                link: "/",
            },
            {
                label: "Frequently Asked Qtns",
                link: "/",
            },
            {
                label: "Customer Support",
                link: "/",
            },
        ],
    },
    {
        header: "Personal & SME Loans",
        links: [{
                label: "Instant Cash",
                link: "/",
            },
            {
                label: "Salary Advance",
                link: "/",
            },
            {
                label: "KC LAGS Loan",
                link: "/",
            },
            {
                label: "Asset Finance",
                link: "/",
            },
            {
                label: "SME Business Loan",
                link: "/",
            },
            {
                label: "Commercial Loan",
                link: "/",
            },
            {
                label: "Temporary Overdraft",
                link: "/",
            },
            {
                label: "Overdraft Loan",
                link: "/",
            },
        ],
    },
    {
        header: "Savings & Investments",
        links: [{
                label: "Super Savings Account",
                link: "/",
            },
            {
                label: "Target Savings Account",
                link: "/",
            },
            {
                label: "Kids Win Savings Acct",
                link: "/",
            },
            {
                label: "Investment Notes",
                link: "/",
            },
            {
                label: "Fixed Deposit Account",
                link: "/",
            },
        ],
    },
];

export const gender = ["Male", "Female"];
export const sideBarLiveness = [{
    categoryName: "Dashboard",
    paths: ["/dashboard"],
    subMenu: [],
},

{
    categoryName: "My Account",
    paths: [
        "/balance-enquiry",
        /* "/bank-flex",
        "/fixed-deposit", */
        "/transaction-history",
        "/transaction-history",
        "/account-statement",
        "/account-manager",
        "/account-tier",
    ],
    subMenu: [{
            label: "Balance Enquiry",
            href: "/balance-enquiry",
        },
        /* {
          label: "Bank Flex",
          href: "/bank-flex",
        }, {
          label: "Fixed Deposit",
          href: "/fixed-deposit",
        }, */
        {
            label: "Transaction History",
            href: "/transaction-history",
        },
        {
            label: "Account Statement",
            href: "/account-statement",
        },
        {
            label: "Account Manager",
            href: "/account-manager",
        },
        {
            label: "Account Tier",
            href: "/account-tier",
        },
    ],
},
{
    categoryName: "Transfers",
    paths: [
        "/transfer-internal",
        "/transfer-other-banks",
        "/self-account-transfer",
        "/saved-beneficiaries",
        "/standing-order-KCMFB",
    ],
    subMenu: [{
            label: "Transfer to KCMFB A/c",
            href: "/transfer-internal",
        },
        {
            label: "Transfer to other Banks",
            href: "/transfer-other-banks",
        },
        {
            label: "Self-Account Transfer",
            href: "/self-account-transfer",
        },
        {
            label: "Saved Beneficiary",
            href: "/saved-beneficiaries?tab=local_transfer",
        },
        // {
        //   label: 'Standing Order to KCMFB A/C',
        //   href: '/standing-order-KCMFB',
        // },
    ],
},

{
    categoryName: "Cards",
    paths: ["/card-enquiry", "/card-hotlist", "/card-replacement"],
    subMenu: [{
            label: "Card Enquiry",
            href: "/card-enquiry",
        },
        {
            label: "Card Hotlist",
            href: "/card-hotlist",
        },
        // {
        //   label: "Card Replacement",
        //   href: "/card-replacement",
        // },
    ],
},

{
    categoryName: "Airtime & Data",
    paths: ["/airtime-data/"],
    subMenu: [{
            label: "Airtime Purchase",
            href: "/airtime-data/?q=airtime",
            searchQuery: "?q=airtime",
        },
        {
            label: "Data Purchase",
            href: "/airtime-data/?q=data",
            searchQuery: "?q=data",
        },
    ],
},

{
    categoryName: "Bills Payment",
    paths: ["/bills-payment/"],
    subMenu: [{
            label: "Electricity Payment",
            href: "/bills-payment/?tab=electricity-payment",
            searchQuery: "?tab=electricity-payment",
        },
        {
            label: "Cable TV",
            href: "/bills-payment/?tab=cable-tv",
            searchQuery: "?tab=cable-tv",
        },
        {
            label: "Betting",
            href: "/bills-payment/?tab=betting"
        }
    
        // {
        //   label: "Airline & Travels",
        //   href: "bills-payment/?tab=airline-&-travels",
        //   searchQuery: "?tab=airline-&-travels",
        // },
        // {
        //   label: "Church Payment",
        //   href: "bills-payment/?tab=church-payment",
        //   searchQuery: "?tab=church-payment",
        // },
        // {
        //   label: "Remita Payment",
        //   href: "bills-payment/?tab=remita-payment",
        //   searchQuery: "?tab=remita-payment",
        // },
    ],
},

// {
//   categoryName: "Cheques",
// paths: ["/request-new-cheques-book", "/stop-cheques", "/confirm-cheque"],
//   subMenu: [
//     {
//       label: "Request New Cheques Book",
//       href: "/request-new-cheques-book",
//     },
//     {
//       label: "Stop Cheques",
//       href: "/stop-cheques",
//     },
//     {
//       label: "Confirm Cheque",
//       href: "/confirm-cheque",
//     },
//   ],
// },

{
    categoryName: "Profile",
    paths: ["/auth/profile"],
    subMenu: [
        // {
        //   label: "User",
        //   href: "/auth/profile",
        // },
    ],
},
];

export const sideBar = [{
        categoryName: "Dashboard",
        paths: ["/dashboard"],
        subMenu: [],
    },

    {
        categoryName: "My Account",
        paths: [
            "/balance-enquiry",
            /* "/bank-flex",
            "/fixed-deposit", */
            "/transaction-history",
            "/transaction-history",
            "/account-statement",
            "/account-manager",
        ],
        subMenu: [{
                label: "Balance Enquiry",
                href: "/balance-enquiry",
            },
            /* {
              label: "Bank Flex",
              href: "/bank-flex",
            }, {
              label: "Fixed Deposit",
              href: "/fixed-deposit",
            }, */
            {
                label: "Transaction History",
                href: "/transaction-history",
            },
            {
                label: "Account Statement",
                href: "/account-statement",
            },
            {
                label: "Account Manager",
                href: "/account-manager",
            },
        ],
    },

    {
        categoryName: "Transfers",
        paths: [
            "/transfer-internal",
            "/transfer-other-banks",
            "/self-account-transfer",
            "/saved-beneficiaries",
            "/standing-order-KCMFB",
        ],
        subMenu: [{
                label: "Transfer to KCMFB A/c",
                href: "/transfer-internal",
            },
            {
                label: "Transfer to other Banks",
                href: "/transfer-other-banks",
            },
            {
                label: "Self-Account Transfer",
                href: "/self-account-transfer",
            },
            {
                label: "Saved Beneficiary",
                href: "/saved-beneficiaries?tab=local_transfer",
            },
            // {
            //   label: 'Standing Order to KCMFB A/C',
            //   href: '/standing-order-KCMFB',
            // },
        ],
    },

    {
        categoryName: "Cards",
        paths: ["/card-enquiry", "/card-hotlist", "/card-replacement"],
        subMenu: [{
                label: "Card Enquiry",
                href: "/card-enquiry",
            },
            {
                label: "Card Hotlist",
                href: "/card-hotlist",
            },
            // {
            //   label: "Card Replacement",
            //   href: "/card-replacement",
            // },
        ],
    },

    {
        categoryName: "Airtime & Data",
        paths: ["/airtime-data/"],
        subMenu: [{
                label: "Airtime Purchase",
                href: "/airtime-data/?q=airtime",
                searchQuery: "?q=airtime",
            },
            {
                label: "Data Purchase",
                href: "/airtime-data/?q=data",
                searchQuery: "?q=data",
            },
        ],
    },

    {
        categoryName: "Bills Payment",
        paths: ["/bills-payment/"],
        subMenu: [{
                label: "Electricity Payment",
                href: "/bills-payment/?tab=electricity-payment",
                searchQuery: "?tab=electricity-payment",
            },
            {
                label: "Cable TV",
                href: "/bills-payment/?tab=cable-tv",
                searchQuery: "?tab=cable-tv",
            },
            
            // {
            //   label: "Airline & Travels",
            //   href: "bills-payment/?tab=airline-&-travels",
            //   searchQuery: "?tab=airline-&-travels",
            // },
            // {
            //   label: "Church Payment",
            //   href: "bills-payment/?tab=church-payment",
            //   searchQuery: "?tab=church-payment",
            // },
            // {
            //   label: "Remita Payment",
            //   href: "bills-payment/?tab=remita-payment",
            //   searchQuery: "?tab=remita-payment",
            // },
        ],
    },

    // {
    //   categoryName: "Cheques",
    // paths: ["/request-new-cheques-book", "/stop-cheques", "/confirm-cheque"],
    //   subMenu: [
    //     {
    //       label: "Request New Cheques Book",
    //       href: "/request-new-cheques-book",
    //     },
    //     {
    //       label: "Stop Cheques",
    //       href: "/stop-cheques",
    //     },
    //     {
    //       label: "Confirm Cheque",
    //       href: "/confirm-cheque",
    //     },
    //   ],
    // },

    {
        categoryName: "Profile",
        paths: ["/auth/profile"],
        subMenu: [
            // {
            //   label: "User",
            //   href: "/auth/profile",
            // },
        ],
    },
];

export const corporateSideBar = [{
        categoryName: "Dashboard",
        paths: ["/corporate-dashboard"],
        subMenu: [],
    },

    {
        categoryName: "My Account",
        paths: [
            "/corporate-account-statement",
            "/corporate-account-manager",
        ],
        subMenu: [{
                label: "Account Statement",
                href: "/corporate-account-statement",
            },
            {
                label: "Account Manager",
                href: "/corporate-account-manager",
            },
        ],
    },

    {
        categoryName: "Transfers",
        paths: [
            "/corporate-self-account-transfer",
            "/corporate-transfer-internal",
            "/corporate-transfer-other-banks",
            "/corporate-scheduled-payments",
            "/corporate-saved-beneficiaries",
        ],
        subMenu: [{
                label: "Self-Account Transfer",
                href: "/corporate-self-account-transfer",
            },
            {
                label: "Transfer to KCMFB A/c",
                href: "/corporate-transfer-internal?tab=single",
            },
            {
                label: "Transfer to other Banks",
                href: "/corporate-transfer-other-banks?tab=single",
            },
            {
                label: 'Scheduled Payments',
                href: '/corporate-scheduled-payments?tab=table',
            },

            {
                label: "Beneficiary",
                href: "/corporate-saved-beneficiaries?tab=local_transfer",
            },
        ],
    },

    {
        categoryName: "Transactions",
        paths: ["/corporate-transactions"],
        subMenu: [

        ],
    },

    {
        categoryName: "Bills Payment",
        paths: ["/corporate-bills-payment"],
        subMenu: [],
    },
    {
        categoryName: "Pending Approvals",
        paths: ["/corporate-bills", "/corporate-awaiting", "/corporate-transaction-limits"],
        subMenu: [{
                label: "Bills Payments",
                href: "/corporate-bills?tab=electricity",
                searchQuery: "?tab=electricity",
            },
            {
                label: "Transfers",
                href: "/corporate-awaiting?tab=single",
                subMenu: [{
                        label: "Single",
                        href: "/corporate-awaiting?tab=single",
                        searchQuery: "?tab=single",
                    },
                    {
                        label: "Bulk",
                        href: "/corporate-awaiting?tab=bulk",
                        searchQuery: "?tab=bulk",
                    },



                ],
            },
            {
                label: "Transaction Limits",
                href: "/corporate-transaction-limits",
            },



        ],
    },
    {
        categoryName: "Approval Status",
        paths: ["/corporate-approval-status"],
        subMenu: [{
                label: "Single",
                href: "/corporate-approval-status?tab=single",
                searchQuery: "?tab=single",
            },
            {
                label: "Bulk",
                href: "/corporate-approval-status?tab=bulk",
                searchQuery: "?tab=bulk",
            },
            {
                label: "Electricity",
                href: "/corporate-approval-status?tab=electricity",
                searchQuery: "?tab=electricity",
            },
            {
                label: "Cable",
                href: "/corporate-approval-status?tab=cable_tv",
                searchQuery: "?tab=cable_tv",
            },
            {
                label: "Airtime",
                href: "/corporate-approval-status?tab=airtime",
                searchQuery: "?tab=airtime",
            },
            {
                label: "Data",
                href: "/corporate-approval-status?tab=data",
                searchQuery: "?tab=data",
            },

        ],
    },

    {
        categoryName: "Profile",
        paths: ["/auth/corporate-profile"],
        subMenu: [
            // {
            //   label: "User",
            //   href: "/auth/profile",
            // },
        ],
    },
    {
        categoryName: "Signatories",
        paths: ["/corporate-signatories"],
        subMenu: [
            // {
            //   label: "User",
            //   href: "/auth/profile",
            // },
        ],
    },
];

export const corporateTransferTab = [{
        label: "Single Transfer",
        searchParam: "single"
    },
    {
        label: "Bulk Transfer",
        searchParam: "bulk"
    }
];



export const userAccounts = [{
        color: "#05958C",
        bgImg: "img/card1.webp",
    },
    {
        color: "#00A3E0",
        bgImg: "img/card2.webp",
    },
    {
        color: "#05958C",
        bgImg: "img/card1.webp",
    },
    {
        color: "#00A3E0",
        bgImg: "img/card2.webp",
    },
];

export const beneficiariesTab = [{
        label: "KCMFB Transfer",
        searchParam: "local_transfer"
    },
    {
        label: "Other Banks",
        searchParam: "external_transfer"
    },
    {
        label: "Airtime & Data",
        searchParam: "airtime"
    },
    {
        label: "Bills Payment",
        searchParam: "utility"
    },
];

export const corporateBeneficiariesTab = [{
        label: "KCMFB Transfer",
        searchParam: "local_transfer"
    },
    {
        label: "Other Banks",
        searchParam: "external_transfer"
    },
];

export const dummyBanks = [{
        bank_name: "Polaris Bank",
        bank_code: "076",
    },
    {
        bank_name: "Wema Bank",
        bank_code: "035",
    },
    {
        bank_name: "First Bank",
        bank_code: "011",
    },
    {
        bank_name: "Access Bank",
        bank_code: "044",
    },
];

export const buyAirtimeAndDataTab = [{
        label: "Buy Airtime",
        href: "/airtime-data/?q=airtime"
    },
    {
        label: "Buy Data",
        href: "/airtime-data/?q=data"
    },
];
export const buyAirtimeAndDataTab2 = [{
        label: "Buy Airtime",
        href: "/corporate-bills-payment?tab=airtime"
    },
    {
        label: "Buy Data",
        href: "/corporate-bills-payment?tab=airtime"
    },
];
export const networkProviders = [{
        label: "mtn",
        src: "/img/mtn.webp"
    },
    {
        label: "glo",
        src: "/img/glo.webp"
    },
    {
        label: "airtel",
        src: "/img/airtel.webp"
    },
    {
        label: "9mobile",
        src: "/img/etisalat.webp"
    },
];

export const billsPaymentTab = [{
        label: "Electricity Payment",
        href: "/bills-payment/?tab=electricity-payment",
    },
    // { label: "Airline & Travels", href: "/bills-payment/?tab=airline-&-travels" },
    {
        label: "Cable Tv",
        href: "/bills-payment/?tab=cable-tv"
    },
    
    {
        label: "Betting",
        href: "/bills-payment/?tab=betting"
    }

    // { label: "Church Payment", href: "/bills-payment/?tab=church-payment" },
    // { label: "Remita Payment", href: "/bills-payment/?tab=remita-payment" },
];
export const billPaymentsTab = [{
        label: "Electricity Payment",
        href: "/corporate-bills-payment?tab=electricity"
    },
    {
        label: "Cable Tv",
        href: "/corporate-bills-payment?tab=cable_tv"

    },
    {
        label: "Betting",
        href: "/corporate-bills-payment?tab=betting"

    },


    {
        label: "Airtime Payment",
        href: "/corporate-bills-payment?tab=airtime&tab2=single"

    },
    {
        label: "Data Payment",
        href: "/corporate-bills-payment?tab=data"

    },

];

export const approvalStatusTab = [{
        label: "Single",
        href: "/corporate-approval-status?tab=single"
    },
    {
        label: "Bulk",
        href: "/corporate-approval-status?tab=bulk"

    },
    {
        label: "Electricity",
        href: "/corporate-approval-status?tab=electricity",
    },
    {
        label: "Cable",
        href: "/corporate-approval-status?tab=cable_tv",
    },
    {
        label: "Airtime",
        href: "/corporate-approval-status?tab=airtime",
    },
    {
        label: "Data",
        href: "/corporate-approval-status?tab=data",
    },

];

export const awaitingStatusTab = [{
        label: "Single",
        href: "/corporate-awaiting?tab=single"
    },
    {
        label: "Bulk",
        href: "/corporate-awaiting?tab=bulk"

    }

];

export const pendingApprovalTab = [{
        label: "Electricity Payment",
        searchParam: "electricity"
    },
    {
        label: "Cable Tv",
        searchParam: "cable_tv"
    },
    {
        label: "Airtime Payment",
        searchParam: "airtime"
    },
    {
        label: "Data Payment",
        searchParam: "data"
    },

];


export const rowColors = [
    "#FF7000",
    "#540375",
    "#FFBF00",
    "#10A19D",
];