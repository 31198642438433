export const styles = {
  container: { width: { xs: "auto", xl: 1536 }, mx: "auto" },
  left: {
    position: { xs: "fixed", sm: "relative" },
    top: { xs: 0, sm: 0 },
    width: "100%",
    height: "100vh",
    zIndex: { xs: 3, sm: 0 },
  },
  right: {
    height: { xs: "auto", sm: "100vh" },
    overflowY: "auto",
  },
  content: {
    px: { xs: 1, sm: 2, lg: 3 },
    pl: {xs: "6px", sm: "8px", lg: "20px"},
    transform: {
      xs: "translateY(-65px)",
      sm: "translateY(-75px)",
      lg: "translateY(-117.5px)",
    },
  },
};
