import { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Hamburger from "components/Hamburger/Hamburger";
import { Link } from "react-router-dom";
import { corporateSideBar } from "assets/data";
import ChangePasswordModal from "components/CorporateDashboard/ChangePasswordModal";
import { styles } from "./Sidebar.styles";
import SidebarNavItem from "../SidebarNavItem2/SidebarNavItem";

const Sidebar = ({ open, toggleHamburger }) => {
  const [activeTab, setActiveTab] = useState("Dashboard");
  const passwordHas = JSON.parse(sessionStorage.getItem("password_changed"));
  const [step, setStep] = useState(0);
  const [open2, setOpen2] = useState(true);
  const [error, setError] = useState("");
  const nextStep = () => {
    setStep((prevS) => prevS + 1);
  };
  const handleClose = () => {
    setOpen2(true);
    //setStep(0);
  };
  const handleOpen = (key) => {
    setOpen2(true);
  };

  const handleError = (err) => {
    setError(err?.response?.data?.detail || err?.message || err);
  };

  console.log(passwordHas);

  const updateActiveTab = (tab) => {
    if (tab === activeTab) {
      setActiveTab("");
    } else setActiveTab(tab);
  };

  return (
    <Box as="aside" sx={styles.container}>
      <Stack
        direction="row"
        justifyContent={{ xs: "flex-start", sm: "center" }}
        sx={{
          position: { xs: "sticky", sm: "relative" },
          top: 0,
          bgcolor: "white.main",
          px: 2,
          py: 3,
          zIndex: 2,
        }}
      >
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <Hamburger open={open} toggleHamburger={toggleHamburger} />
        </Box>

        <Stack justifyContent="center" sx={{ flex: "1" }}>
          <Link to="/corporate-dashboard">
            <Stack
              as="img"
              src="/img/brand-logo.png"
              alt="brand"
              sx={styles.brandLogo}
            />
          </Link>
        </Stack>
      </Stack>
      {passwordHas && (
        <ChangePasswordModal
          open={open2}
          handleClose={handleClose}
          handleOpen={handleOpen}
          step={step}
          error={error}
          handleError={handleError}
          nextStep={nextStep}
        />
      )}

      <Stack spacing={3} mt={{ xs: 2, sm: 8 }}>
        {corporateSideBar.map(({ categoryName, subMenu, paths }) => (
          <SidebarNavItem
            key={categoryName}
            toggleHamburger={toggleHamburger}
            categoryName={categoryName}
            subMenu={subMenu}
            paths={paths}
            activeTab={activeTab}
            updateActiveTab={updateActiveTab}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default Sidebar;
