import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import { styles } from "./ManagersInfo.styles";

const ManagersInfo = ({ data, isLoading }) => {
  const { name, email, branch, phone_number } = data?.detail || {};

  // to clear out react console error of controlled input change
  const handleChange = () => {};

  return (
    <Stack mt={4} spacing={3} sx={styles.container}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 3, sm: 4, lg: 5 }}
      >
        <InputWithLabel label="First Name">
          {isLoading ? (
            <Skeleton sx={{ width: "100%", height: 50, transform: "none" }} />
          ) : (
            <Input
              value={name?.split(" ")[1]}
              onChange={handleChange}
              disabled
              sx={styles.input}
            />
          )}
        </InputWithLabel>

        <InputWithLabel label="Last Name">
          {isLoading ? (
            <Skeleton sx={{ width: "100%", height: 50, transform: "none" }} />
          ) : (
            <Input
              value={name?.split(",")[0]}
              onChange={handleChange}
              disabled
              sx={styles.input}
            />
          )}
        </InputWithLabel>
      </Stack>

      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 3, sm: 4, lg: 5 }}
      >
        <InputWithLabel label="Email">
          {isLoading ? (
            <Skeleton sx={{ width: "100%", height: 50, transform: "none" }} />
          ) : (
            <Input
              value={email}
              onChange={handleChange}
              disabled
              sx={styles.input}
            />
          )}
        </InputWithLabel>

        <InputWithLabel label="Phone">
          {isLoading ? (
            <Skeleton sx={{ width: "100%", height: 50, transform: "none" }} />
          ) : (
            <Input
              value={phone_number}
              onChange={handleChange}
              disabled
              sx={styles.input}
            />
          )}
        </InputWithLabel>
      </Stack>

      <InputWithLabel label="Branch">
        {isLoading ? (
          <Skeleton sx={{ width: "100%", height: 50, transform: "none" }} />
        ) : (
          <Input
            value={branch}
            onChange={handleChange}
            disabled
            sx={styles.input}
          />
        )}
      </InputWithLabel>

      {/* <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 4, sm: 4, lg: 5 }}
        pt={1}
      >
        <Input
          value='City'
          disabled
          sx={{ ...styles.input, flex: { xs: 'auto', sm: '0.5' } }}
        />

        <Input
          value='State / Province'
          disabled
          sx={{ ...styles.input, flex: { xs: 'auto', sm: '0.3' } }}
        />

        <Input
          value='Zip Code'
          disabled
          sx={{ ...styles.input, flex: { xs: 'auto', sm: '0.2' } }}
        />
      </Stack> */}
    </Stack>
  );
};

export default ManagersInfo;
