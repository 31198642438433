import React from "react";

import logo from "../assets/png/logo.png";
import line from "../assets/png/line.png";
import back from "../assets/png/backdrop.png";
import moment from "moment";

const TransactionsReceipt = ({
  name,
  number,
  amount,
  type,
  note,
  reference,
  status,
  bank,
  time,
  description,

}) => {
  return (
    <div
      style={{
        padding: "30px",
        background: "white",
        width: "570px",
        margin: "0 auto",
        fomtSize: "17px",
        position: "relative",
      }}
    >
      <img
        style={{ width: "100%", position: "absolute", top: "40%", left: "0" }}
        src={back}
        alt=""
      />

      <img style={{ width: "80px" }} src={logo} alt="" />
      <div>
        <div
          style={{
            textAlign: "center",
            margin: "20px 0",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "24px",
            }}
          >
            Transaction Receipt
          </div>
          <div style={{ marginTop: "10px" }}>
            {" "}
            {moment(time).format("DD MMM. YYYY : h :mm a")}
          </div>
        </div>
        <img style={{ margin: "10px 0", width: "100%" }} src={line} alt="" />
        <br />
        <div
          style={{
            padding: "0 20px",
            fontFamily: "initial",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Reference no.:</span>
              <span>{reference}</span>
            </div>
          </div>
          <br />

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Beneficiary Name:</span>
              <span>{name}</span>
            </div>
          </div>
          <br />

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                Beneficiary Account Number:
              </span>
              <span>{number}</span>
            </div>
          </div>
          <br />

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Bank:</span>
              <span>{bank}</span>
            </div>
          </div>
          <br />

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Amount:</span>
              <span>{amount}</span>
            </div>
          </div>
          <br />

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Transaction Type:</span>
              <span>{type}</span>
            </div>
          </div>
          <br />
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Transaction Status:</span>
              <span>{status}</span>
            </div>
          </div>
          <br />
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Narration:</span>
              <span>{description}</span>
            </div>
          </div>
        </div>

        <img style={{ margin: "10px 0", width: "100%" }} src={line} alt="" />

        <div style={{ marginTop: "20px", fontFamily: "initial" }}>
          <span style={{ fontWeight: "bold" }}>DISCLAIMER :</span>{" "}
          <span style={{ fontSize: "10px", lineHeight: "8px" }}>
            Your transaction has been successfully processed. Note, however the
            completion of other transfers may be affected by other factors
            including but not limited to transmission errors, incomplete
            information, flunctuation on the network/internet, interruption,
            glitch, delayed information or other matters beyond the bank’s
            control which may impact on the transaction and for which the bank
            will not be liable. All transfers are subject to confirmation and
            fraud proof verification.
          </span>
        </div>
      </div>
    </div>
  );
};

export default TransactionsReceipt;
