import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TablePagination from "components/base/TablePagination2/TablePagination";
import TransactionsTable from "../TransactionsTable/TransactionsTable";
import { styles } from "./TransactionHistoryTable.styles";

const TransactionHistoryTable = ({
  loading,
  data,
  setPage,
  dataHead,
  action,
}) => {
  //

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.header}>Status</Typography>

      <TransactionsTable
        data={data?.results}
        dataHead={dataHead}
        loading={loading}
      />

      <TablePagination transactions={data} action={action} />
    </Box>
  );
};

export default TransactionHistoryTable;
