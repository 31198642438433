import usePagination from "@mui/material/usePagination";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "./TablePagination.styles";
import { styled } from "@mui/material/styles";

const Button = styled("button")({});

const TablePagination = ({ pagination, page, setPage }) => {
  const { items } = usePagination({
    count: pagination?.page_count,
    boundaryCount: 1,
    hidePrevButton: true,
  });

  const handleChange = (page, item) => {
    item.onClick();
    setPage(page);
  };

  const startCount = (page * pagination?.size) - pagination?.size + 1
  const endCount = page * pagination?.size > pagination?.item_count ? pagination?.item_count : page * pagination?.size

  return pagination?.item_count > 1 ? (
    <Stack
      direction={{ xs: "column", md: "row" }}
      justifyContent="space-between"
      alignItems={{ xs: "flex-start", md: "center" }}
      px={2}
      mt={4}
      spacing={{ xs: 3, sm: 1 }}
    >
      <Typography sx={styles.pageInfo}>
        Showing {startCount} to{" "}
        {endCount} of {pagination?.item_count} Entries
      </Typography>
      <Stack
        direction="row"
        alignItems="flex-end"
        spacing={1}
        sx={{ alignSelf: "flex-end" }}
      >
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <Button
                type="button"
                sx={styles.navItem(selected)}
                onClick={() => handleChange(page, item)}
              >
                {page}
              </Button>
            );
          } else {
            children = (
              <Button
                type="button"
                sx={styles.nextBtn(page > pagination?.page_count)}
                disabled={page > pagination?.page_count}
                onClick={() => handleChange(page, item)}
              >
                {type}
              </Button>
            );
          }

          return <Box key={index}>{children}</Box>;
        })}
      </Stack>
    </Stack>
  ) : (
    <div></div>
  );
};

export default TablePagination;
