import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import OTP from "components/base/OTP/OTP";
import { Box } from "@mui/material";

const SecondForm = ({ formik }) => {
  return (
    <>
    <InputWithLabel label="Enter OTP">
        <Box mt={2} />
        <OTP
          formik={formik}
          formikKey="token"
          variant={3}
          length={6}
          n
        />
      </InputWithLabel>
      

        </>
  );
};

export default SecondForm;
