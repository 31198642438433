export const styles = {
  accountCard: (color, img) => ({
    background: `linear-gradient(45deg, ${color}e7, ${color}e7), url(${img}) 50% 50% no-repeat`,
    borderRadius: { xs: '15px', sm: '20px' },
    px: { xs: 2, md: 2 },
    py: { xs: 2, md: 2 },
  }),
  simIcon: {
    width: { xs: 20, sm: 27 },
    height: { xs: 17, sm: 23 },
  },
  accountType: {
    fontSize: { xs: 14, sm: 16, lg: 18 },
    fontWeight: 500,
    color: 'white.main',
  },
  accountNumber: {
    fontSize: { xs: 16, sm: 18, lg: 20 },
    fontWeight: 800,
    color: 'white.main',
  },
  accountName: {
    fontSize: { xs: 20, lg: 22 },
    fontWeight: 600,
    color: 'white.main',
    mt: '0.6rem',
    textTransform: 'capitalize',
  },
  availableBalText: {
    fontSize: { xs: 14, lg: 14 },
    fontWeight: 500,
    color: 'white.main',
    mt: '0.6rem',
  },
  availBal: {
    fontSize: { xs: 22, lg: 28 },
    fontWeight: 700,
    color: 'white.main',
    letterSpacing: '1px',
  },
  viewDetails: {
    fontSize: { xs: 13, sm: 14, lg: 15 },
    fontWeight: 500,
    color: 'white.main',
    mt: '0.6rem',
    '&:hover': {
      color: 'green3.main',
    },
  },
  statusText: {
    fontSize: { xs: 10, sm: 12, lg: 12 },
    fontWeight: 500,
    color: 'white.main',
    textAlign: 'center',
  },
  status: {
    fontSize: { xs: 15, sm: 18, lg: 22 },
    fontWeight: 700,
    color: 'white.main',
  },
};
