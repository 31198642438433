export const styles = {
  container: { borderBottom: '1px solid #ABABAB' },
  header: {
    width: { xs: '95%', md: '90%', lg: '80%' },
    fontSize: { xs: 22, sm: 25, md: 28 },
    fontWeight: 700,
    mx: 'auto',
  },
  tab: {
    width: { xs: '95%', md: '90%', lg: '80%' },
    justifyContent: 'space-between',
    flexWrap: 'no-wrap',
    mx: 'auto',
    mt: { xs: 2, sm: 3 },
    overflowX: { xs: 'auto', sm: 'hidden' },
    boxSizing: 'border-box',
  },
  tabItem: (color) => ({
    position: 'relative',
    fontSize: { xs: 14, sm: 16 },
    fontWeight: 700,
    color: color ? 'green3.main' : '#888686',
    minWidth: 'max-content',
    p: 1,
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:hover': { color: 'green3.main' },
    '&:before': {
      position: 'absolute',
      content: "''",
      bottom: 0,
      left: 1,
      width: '100%',
      height: 2,
      bgcolor: color ? 'green3.main' : 'transparent',
      boxSizing: 'border-box',
    },
  }),
};
