import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SlideInModal from "components/base/SlideInModal/SlideInModal";
import { styled } from "@mui/material/styles";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import { styles } from "./DownloadStatement.styles";
import Input from "components/base/Input/Input";

const CloseBtn = styled(CloseIcon)({});

const DownLoadFormat = ({ state, handleState, downloadStatementAsPdf }) => {
  return (
    <>
      <Typography sx={styles.downloadFormatHeader}>Download Format</Typography>
      <Box sx={styles.selectionBox}>
        {["As PDF", "Send to email"].map((item) => (
          <Box
            key={item}
            sx={styles.selectionOption}
            onClick={() => handleState("downloadAs", item)}
          >
            <Box sx={styles.option(state.downloadAs === item)} />
            <Typography sx={styles.optionText(state.downloadAs === item)}>
              {item}
            </Typography>
          </Box>
        ))}
      </Box>

      <Button
        variant="contained"
        color="green3"
        sx={styles.downloadFormatBtn}
        onClick={downloadStatementAsPdf}
        // onClick={() => handleState('activeModal', 1)}
      >
        Download Statement
      </Button>
      <Typography
        sx={styles.sendToEmail}
        onClick={() => handleState("activeModal", 2)}
      >
        or Send to your Email!
      </Typography>
    </>
  );
};

const Downloading = ({ handleClose }) => {
  return (
    <>
      <Box
        as="img"
        src="/img/successful_gif.gif"
        alt="successful"
        sx={styles.img}
      />

      <Typography sx={styles.downloadingHeader}>Downloading</Typography>

      <Typography sx={styles.subText}>
        Your Bank Statement will start downloading shortly!
      </Typography>

      <Button
        variant="contained"
        color="green3"
        sx={styles.downloadFormatBtn}
        onClick={() => handleClose("downloadStatement")}
      >
        Continue
      </Button>
    </>
  );
};

const SendStatementToEmail = ({ formik }) => {
  return (
    <Box as="form" onSubmit={formik.handleSubmit}>
      <Typography sx={styles.STEHeader}>Enter Your Email</Typography>
      <Typography sx={styles.STESubtext}>
        Enter the Email address the Statement will be sent to
      </Typography>
      <Input
        name="email"
        id="name"
        type="email"
        placeholder="Enter Email Address"
        value={formik.values.email}
        onChange={formik.handleChange}
        sx={{ mb: 5 }}
      />

      <Button
        type="submit"
        variant="contained"
        color="green3"
        sx={styles.downloadFormatBtn}
      >
        Get Statement
      </Button>
    </Box>
  );
};

const StatementSentToEmail = ({ handleClose, email }) => {
  return (
    <>
      <Box
        as="img"
        src="/img/successful_gif.gif"
        alt="successful"
        sx={styles.img}
      />

      <Typography sx={styles.downloadingHeader}>Sent</Typography>
      <Typography sx={styles.subText}>
        Your Bank Statement has been sent to {email}
      </Typography>

      <Button
        variant="contained"
        color="green3"
        sx={styles.downloadFormatBtn}
        onClick={handleClose}
      >
        Continue
      </Button>
    </>
  );
};

const DownloadStatement = ({
  open,
  handleClose,
  state,
  handleState,
  formik,
  email,
  downloadStatementAsPdf,
}) => {
  return (
    <SlideInModal open={open} handleClose={handleClose}>
      <Box sx={{ textAlign: "center" }}>
        <CloseBtn sx={styles.closeBtn} onClick={() => handleClose()} />
        {state.activeModal === 0 && (
          <DownLoadFormat
            state={state}
            handleState={handleState}
            downloadStatementAsPdf={downloadStatementAsPdf}
          />
        )}

        {state.activeModal === 1 && <Downloading handleClose={handleClose} />}

        {state.activeModal === 2 && <SendStatementToEmail formik={formik} />}

        {state.activeModal === 3 && (
          <StatementSentToEmail handleClose={handleClose} email={email} />
        )}
      </Box>
    </SlideInModal>
  );
};

export default DownloadStatement;
