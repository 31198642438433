import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import Button from "@mui/material/Button";
import Select from "components/base/Select/Select";
import AirtimeAndDataModal from "./AirtimeAndDataModal/AirtimeAndDataModal";
import Switch from "components/base/Switch/Switch";
import { useFormik } from "formik";
import { AirtimeSchema } from "services/Yup/AirtimeAndDataSchema";
import { corporateTransferTab } from "assets/data";
import { useSearchParams } from "react-router-dom";
import { networkProviders } from "assets/data";
import {
  billPayments,
  //userProfile,
  createBeneficiary,
  bulkAirtime,
} from "services/corporateDashboard";
import { validateNumbers } from "utils/validateNumbers";
import { useMutation } from "@tanstack/react-query";
import { styles, styles2 } from "./AirtimeAndData.styles";
import CorporateDashboardContext from "context/CorporateDashboardContext";
import FileUpload from "assets/svg/file.svg";
import LoadingAnimation from "components/base/LoadingAnimation/LoadingAnimation";

const AirtimeAndDataComponent = () => {
  const [param, setParam] = useSearchParams();
  //const currentTab = param.get("q");
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [error, setError] = useState("");

  const [file, setFile] = useState();

  //const [query, setQuery] = useState("");

  const activeTab = param.get("tab");
  const activeTab2 = param.get("tab2");

  const formik = useFormik({
    initialValues: {
      account_source: "",
      networkProvider: "",
      phone: "",
      amount: "",
      beneficiaryName: "",
      saveBeneficiary: false,
    },
    validationSchema: AirtimeSchema,
    onSubmit: () => {
      setOpenModal(true);
    },
  });

  const { accountNumbers } = useContext(CorporateDashboardContext);

  // Reset error
  useEffect(() => {
    if (formik.values.transaction_pin.length === 4) {
      setError("");
    }
  }, [formik.values.transaction_pin.length]);

  // get user profile
  // const { data } = useQuery({
  //   queryKey: ["userProfile"],
  //   queryFn: userProfile,
  // });

  const accounts = accountNumbers || [];

  // Buy airtime mutation
  const { mutate: BuyAirtimeAndData, isLoading } = useMutation({
    mutationFn: billPayments,
    onSuccess: () => {
      setSeverity("success");
      setMessage("Transaction successful");
      setOpen(true);
      setStep(1);
      setFile();
    },
    onError: (err) => {
      setSeverity("error");
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
      setFile();
    },
  });

  // Bulk Airtime Mutations

  const { mutate: BulkAirtime, isLoading: isLoading2 } = useMutation({
    mutationFn: bulkAirtime,
    onSuccess: () => {
      setSeverity("success");
      setMessage("Transaction successful");
      setOpen(true);
      setOpenModal(true);
      setStep(1);
      setFile();
    },
    onError: (err) => {
      setSeverity("error");
      setErrMessage(err.response.data.detail || err.message);
      setOpen(true);
      setFile();
    },
  });

  // save beneficiary mutation
  const { mutate: CreateBeneficiary } = useMutation({
    mutationFn: createBeneficiary,
  });

  const setNetworkProvider = (value) => {
    formik.setFieldValue("networkProvider", value);
  };

  // reset form fields on mount
  useEffect(() => {
    if (activeTab === "data" || activeTab === "airtime") {
      formik.resetForm();
    }
    // eslint-disable-next-line
  }, [activeTab]);

  const handleClose = () => {
    setOpenModal(false);
    setStep(0);
    formik.resetForm();
  };

  const nextStep = () => {
    setStep((prevS) => prevS + 1);
  };
  //const [typeTf, setTypeTf] = useState(true);

  const Subscribe = () => {
    if (activeTab === "airtime") {
      if (formik.values.saveBeneficiary) {
        CreateBeneficiary({
          beneficiary_type: "airtime",
          beneficiary_name: formik.values.beneficiaryName,
          beneficiary_number: formik.values.phone,
          biller_name: formik.values.networkProvider.toUpperCase(),
        });
      }

      return BuyAirtimeAndData({
        account_no: formik.values.account_source?.slice(0, 10),
        phone_number: formik.values.phone,
        network: formik.values.networkProvider,
        amount: formik.values.amount,
        payment_type: "airtime",
      });
    }

    if (activeTab === "data") {
      if (formik.values.saveBeneficiary) {
        CreateBeneficiary({
          beneficiary_type: "data",
          beneficiary_name: formik.values.beneficiaryName,
          beneficiary_number: formik.values.phone,
          biller_name: formik.values.networkProvider.toUpperCase(),
        });
      }

      return BuyAirtimeAndData({
        account_no: formik.values.account_source?.slice(0, 10),
        phone_number: formik.values.phone,
        network: formik.values.networkProvider,
        amount: formik.values.amount,
        plan_id: formik.values.plan_id,
        payment_type: "data",
      });
    }
  };
  console.log(activeTab);
  return (
    <Box sx={styles.container}>
      <AirtimeAndDataModal
        open={openModal}
        handleClose={handleClose}
        step={step}
        nextStep={nextStep}
        formik={formik}
        Subscribe={Subscribe}
        error={error}
        isLoading={isLoading}
        openError={open}
        severity={severity}
        message={message}
        closeError={() => {
          setOpen(false);
        }}
      />

      <Box sx={styles2.container}>
        <Stack direction="row" spacing={{ xs: 2, sm: 0 }} sx={styles2.tab}>
          {corporateTransferTab.map(({ label, searchParam }) => (
            <Typography
              key={label}
              sx={styles2.tabItem(activeTab2 === searchParam)}
              onClick={() => {
                console.log(activeTab);
                setParam({ tab: activeTab, tab2: searchParam });
              }}
            >
              {label}
            </Typography>
          ))}
        </Stack>
      </Box>

      {activeTab2 === "single" && (
        <Stack
          spacing={{ xs: 3, sm: 4 }}
          as="form"
          sx={styles.tab}
          onSubmit={formik.handleSubmit}
        >
          <InputWithLabel label="Account">
            <Select
              options={accounts}
              placeholder="Select Account"
              name="account_source"
              value={formik.values.account_source}
              formik={formik}
              sx={styles.input}
            />
          </InputWithLabel>

          <InputWithLabel label="Select Network Provider">
            <Stack direction="row" spacing={{ xs: 3, sm: 4 }}>
              {networkProviders.map(({ label, src }) => (
                <Box
                  key={src}
                  sx={styles.provider(formik.values.networkProvider === label)}
                  onClick={() => setNetworkProvider(label)}
                >
                  <Box as="img" src={src} alt={label} sx={styles.providerImg} />
                </Box>
              ))}
            </Stack>
            {formik.touched.networkProvider &&
              formik.errors.networkProvider && (
                <Typography sx={styles.networkProviderErr}>
                  {formik.errors.networkProvider}
                </Typography>
              )}
          </InputWithLabel>

          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 3, sm: 4 }}
          >
            <InputWithLabel label="Phone Number">
              <Input
                type="tel"
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                placeholder="Enter Phone number (e.g 07054635423)"
                formik={formik}
                sx={styles.input}
              />
            </InputWithLabel>

            <InputWithLabel label="Amount">
              <Input
                type="tel"
                id="amount"
                name="amount"
                value={formik.values.amount}
                onChange={(event) => {
                  validateNumbers(event, 1000, formik.handleChange);
                }}
                placeholder="Enter Amount"
                formik={formik}
                disabled={activeTab === "data"}
                sx={styles.input}
              />
            </InputWithLabel>
          </Stack>

          {formik.values.saveBeneficiary && (
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 3, sm: 4 }}
            >
              <InputWithLabel label="Beneficiary name">
                <Input
                  id="beneficiaryName"
                  name="beneficiaryName"
                  value={formik.values.beneficiaryName}
                  onChange={formik.handleChange}
                  placeholder="Enter beneficiary name"
                  formik={formik}
                  sx={styles.input}
                />
              </InputWithLabel>
            </Stack>
          )}

          <Stack
            sx={{
              transform: "translateY(-1rem)",
              pb: 5,
            }}
          >
            <Switch
              name="saveBeneficiary"
              label="Save as beneficiary"
              value={formik.values.saveBeneficiary}
              onChange={formik.handleChange}
            />
          </Stack>

          <Button
            type="submit"
            variant="contained"
            color="green3"
            sx={styles.submitBtn}
          >
            {isLoading ? "Please wait..." : "Proceed"}
          </Button>
        </Stack>
      )}

      {activeTab2 === "bulk" && (
        <Stack
          as="form"
          spacing={{ xs: 3, sm: 4 }}
          onSubmit={formik.handleSubmit}
          sx={styles.tab}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 3, sm: 4 }}
          >
            <InputWithLabel label="Narration">
              <Input
                id="description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                placeholder="Enter Description"
                formik={formik}
                sx={styles.input}
              />
              <span style={{ color: "red" }}>{errMessage}</span>
            </InputWithLabel>
          </Stack>

          {formik.values.description ? (
            file ? (
              <div style={{ margin: "20px 0" }}>
                <div>Click Send Bulk Request</div>
                <Button
                  variant="contained"
                  sx={{
                    width: { xs: "fit-content" },
                    bgcolor: "rgba(202, 45, 45, 0.2)",
                    color: "rgb(202, 45, 45)",
                    fontSize: "16px",
                    fontWeight: 600,
                    boxShadow: "none",
                    margin: "20px 0",
                    alignSelf: { xs: "flex-end", sm: "auto" },
                    "&:hover": { bgcolor: "rgba(202, 45, 45, 0.2)" },
                  }}
                  onClick={() => {
                    setFile();
                  }}
                >
                  Delete File
                </Button>
              </div>
            ) : (
              <label
                onClick={() => setErrMessage("")}
                style={{ display: "block" }}
                name="file"
                id="file"
              >
                <Box sx={{ width: "100%" }}>
                  <img style={{ width: "100%" }} src={FileUpload} alt="" />
                </Box>
                <input
                  style={{ display: "none" }}
                  name="file"
                  id="file"
                  type="file"
                  accept=".csv"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                />
              </label>
            )
          ) : (
            <img
              onClick={() => setErrMessage("Description has to be filled")}
              style={{ width: "100%" }}
              src={FileUpload}
              alt=""
            />
          )}
          <div>
            Click{" "}
            <a
              href={`${process.env.REACT_APP_API}/media/bulk-airtime-upload-sample.csv`}
              
              target="_blank"
              rel="noreferrer"
              download
              style={{ color: "#D22F31", textDecoration: "none" }}
            >
              Here
            </a>{" "}
            to download template
          </div>
          {file && (
            <Button
              type="submit"
              variant="contained"
              color="green3"
              sx={styles.submitBtn}
              onClick={() => {
                const formdata = new FormData();
                formdata.append("file", file);
                formdata.append("narration", formik.values.description || "");
                if (file) {
                  BulkAirtime(formdata);
                }
              }}
            >
              Send Bulk Request
            </Button>
          )}
          {isLoading2 && <LoadingAnimation />}
        </Stack>
      )}
    </Box>
  );
};

export default AirtimeAndDataComponent;
