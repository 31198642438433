export const styles = {
  header: {
    fontSize: { xs: 22, sm: 25 },
    fontWeight: 700,
    color: 'green3.main',
    mb: { xs: 4, sm: 5 },
  },
  subText: {
    width: '70%',
    fontSize: { xs: 14, sm: 16 },
    fontWeight: 500,
    textAlign: 'center',
    mt: '20px',
    mx: 'auto',
  },
  gif: {
    display: 'block',
    width: 200,
    mx: 'auto',
    objectFit: 'contain',
  },
  btn: {
    width: { xs: 180, sm: 245 },
    height: 55,
    color: 'white.main',
    fontSize: { xs: 16, sm: 18 },
    fontWeight: 600,
    mt: 3
  },
};
