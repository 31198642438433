export const styles = {
  container: {
    display: 'flex',
    gridTemplateColumns: 'repeat(auto-fit, minmax(5px, auto))',
    margin: '0 auto',
  },
  range: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 2,
  },
  bar: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  index: {
    fontSize: 9.75,
    fontWeight: 600,
    color: 'black',
  },
  months: {
    fontSize: 8,
    fontWeight: 500,
    color: 'black',
    textTransform: 'uppercase',
    mt: '5px',
  },
  progressBar: {
    height: { xs: 200, lg: 165 },
    width: 12,
  },
  progressTrack: {
    position: 'relative',
    width: 12,
    height: '100%',
    background: '#f2f7ff',
    borderRadius: '100px',
  },
  progressFill: (height) => ({
    position: 'absolute',
    bottom: 0,
    background:
      'linear-gradient(356.64deg,rgba(52, 125, 1, 0.8) 48.49%,rgba(52, 112, 11, 0.8) 282.14%)',
    height: height,
    width: 12,
    color: 'white.main',
    overflow: 'hidden',
    borderRadius: '100px',
  }),
};
