export const styles = {
  closeX: {
    position: 'absolute',
    top: { xs: '5%', sm: '8%' },
    right: { xs: '5%', sm: '8%' },
    cursor: 'pointer',
  },
  closeIcon: {
    width: { xs: 18, sm: 32 },
    height: { xs: 18, sm: 32 },
    '&:hover': { transform: 'scale(1.05)' },
  },
  header: {
    fontSize: { xs: 30, md: 40 },
    fontWeight: 700,
    color: 'black.main',
    textAlign: 'center',
    mt: "-50px"
  },
  desc: {
    fontSize: { xs: 14, md: 16, lg: 20 },
    fontWeight: 500,
    color: 'black.main',
    textAlign: 'center',
  },
  closeBtn: {
    width: 'fit-content',
    fontSize: { xs: 16, md: 20, lg: 22 },
    fontWeight: 600,
    color: 'white.main',
    px: 3,
  },
  img: {
    display: 'block',
    width: 200,
    mx: 'auto',
  },
};
