import { Box, Skeleton } from "@mui/material";
import Stack from "@mui/material/Stack";
import AccountsAndAdsSlider from "components/CorporateDashboard/AccountsAndAdsSlider/AccountsAndAdsSlider";
import QuickServicesAndActivitiesStat from "components/CorporateDashboard/QuickServicesAndActivitiesStat/QuickServicesAndActivitiesStat";
import RecentTransaction from "components/CorporateDashboard/RecentTransaction/RecentTransaction";
import CorporateDashboardContext from "context/CorporateDashboardContext";
import { useContext } from "react";

const CorporateDashboard = () => {
  const { data, isLoading } = useContext(CorporateDashboardContext);

  return (
    <Box
      spacing={3}
      bgcolor="#FFF"
      padding="30px"
      paddingTop="60px"
      borderRadius="40px"
    >
      {isLoading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <>
          <Stack fontSize="26px" color="green3.main" fontWeight="bold">
            {data?.institution_name}
          </Stack>

          <Box
            sx={{ marginY: 1 }}
            display="flex"
            flexDirection="row"
            margin="none"
          >
            <Stack marginRight="2px">Welcome,</Stack>
            <Stack fontWeight="700" color="#595959">
              {data?.first_name}
            </Stack>
          </Box>
          <Stack
            fontWeight="700"
            display="flex"
            flexDirection="row"
            alignItems="end"
            color="#595959"
            marginBottom="20px"
          >
            <Stack>{data?.bvn}</Stack> <Stack fontSize="13px">(BVN)</Stack>
          </Stack>
        </>
      )}
      <AccountsAndAdsSlider />
      <QuickServicesAndActivitiesStat />

      <RecentTransaction />
    </Box>
  );
};

export default CorporateDashboard;
