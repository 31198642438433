import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UserDashboardContext from "context/UserDashboardContext";
import Skeleton from "@mui/material/Skeleton";
import { styles } from "./ActivitiesChart.styles";

const ActivitiesChart = () => {
  const { data, isLoading } = React.useContext(UserDashboardContext) || {};
  const keys = Object.keys(data || {});

  const categoryArray = [
    data?.transfer,
    data?.airtime,
    data?.data,
    data?.cableTv,
    data?.electricity,
  ];

  let maxValue = 0;
  let priceRange = [];

  categoryArray?.forEach((item) => {
    if (item?.amount > maxValue) {
      maxValue = item?.amount;
    }
  });

  categoryArray?.forEach((item, index) => {
    priceRange.push(Math.round(((maxValue || 50000) / 4) * index));
  });

  const chart = (categoryArray || [0, 1, 2, 3, 4])?.map((item, index) => ({
    service: keys[index],
    value: `${(item?.amount / maxValue) * 100}%`,
  }));

  return (
    <Box sx={styles.container}>
      <Box sx={styles.range}>
        {priceRange
          .sort((a, b) => b - a)
          .map((item, index) => (
            <Typography key={`${item} ${index}`} sx={styles.index}>
              {item}
            </Typography>
          ))}
      </Box>

      {chart.map(({ service, value }, index) => (
        <Box key={`${service} ${index}`} sx={styles.bar}>
          <Box sx={styles.progressBar}>
            <Box sx={styles.progressTrack}>
              <Box sx={styles.progressFill(value)}></Box>
            </Box>
          </Box>
          {isLoading ? (
            <Skeleton variant="text" sx={{ fontSize: 8, width: 40 }} />
          ) : (
            <Typography sx={styles.months}>{service}</Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default ActivitiesChart;

// isLoading ? (
//   <Box sx={styles.animationContainer}>
//     <LoadingAnimation />
//   </Box>
// ) :
