import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import TransactionsTable from "../TransactionsTable/TransactionsTable";
import UserDashboardContext from "context/UserDashboardContext";
import { styles } from "./RecentTransaction.styles";

const RecentTransaction = () => {
  const { data, isLoading } = React.useContext(UserDashboardContext);

  return (
    <Box sx={styles.container}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={{ xs: 2, lg: "26px" }}
      >
        <Typography sx={styles.header}>Recent Transactions</Typography>
        <Typography sx={styles.viewMore}>
          <Link to="/transaction-history">View More... &gt;</Link>
        </Typography>
      </Stack>

      <TransactionsTable data={data?.recent_transactions} loading={isLoading} />
    </Box>
  );
};

export default RecentTransaction;
