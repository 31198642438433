import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import { styles } from './SlideInModal.styles';

const SlideInModal = ({ open, handleClose, children, sx }) => {
  const containerRef = React.useRef(null);

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Slide in={open} container={containerRef.current}>
          <Box sx={{ ...styles, ...sx }}>{children}</Box>
        </Slide>
      </Modal>
    </div>
  );
};

export default SlideInModal;
