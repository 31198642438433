import BillsPaymentComponent from "components/CorporateBillsPayment/BillsPayment";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const BillsPayment = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/corporate-bills-payment?tab=electricity");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BillsPaymentComponent />
    </>
  );
};

export default BillsPayment;
