import { useContext, useState } from "react";
import TransactionHistoryTable from "components/CorporateTransactionHistory/TransactionHistoryTable/TransactionHistoryTable";
import { useQuery } from "@tanstack/react-query";
import CorporateDashboardContext from "context/CorporateDashboardContext";
import { transaction_history } from "services/corporateDashboard";

const TransactionHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { accountNumbers } = useContext(CorporateDashboardContext);

  const customerAccountNumber = accountNumbers || [];

  const { data, isLoading } = useQuery({
    queryKey: [
      "transaction_history",
      customerAccountNumber[0]?.slice(0, 10),
      currentPage,
    ],
    queryFn: () =>
      transaction_history(customerAccountNumber[0]?.slice(0, 10), currentPage),
    enabled: Boolean(customerAccountNumber),
  });

  const setPage = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <TransactionHistoryTable
        data={data?.detail}
        isLoading={isLoading}
        pagination={data?.pagination}
        setPage={setPage}
        currentPage={currentPage}
      />
    </>
  );
};

export default TransactionHistory;
