import { useState } from "react";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as EditIcon } from "assets/svg/edit.svg";
import { ReactComponent as VisibilityOffIcon } from "assets/svg/visibility-off-green.svg";
import { ReactComponent as VisibilityOn } from "assets/svg/visibility-on.svg";
import ResetPinModal from "./ResetPinModal";
import ChangePinModal from "./ChangePinModal";
import ChangePasswordModal from "./ChangePasswordModal";
import { styles } from "./ProfileForm.styles";

const ProfileForm = ({ data, isLoading }) => {
  const [step, setStep] = useState({
    pinReset: 0,
    changePin: 0,
    changePassword: 0,
  });

  const bvn = data?.customer?.bvn_number;
  const [showbvn, setShowBvn] = useState(false);

  const [open, setOpen] = useState({
    pinReset: false,
    changePin: false,
    changePassword: false,
    error: false,
  });

  const [error, setError] = useState({
    pinReset: "",
    changePin: "",
    changePassword: "",
  });

  const nextStep = (key) => {
    setStep((prevS) => ({ ...prevS, [key]: prevS[key] + 1 }));
  };

  const toggleShowBvn = () => {
    setShowBvn((prevS) => !prevS);
  };

  const handleClose = (key) => {
    setOpen((prevS) => ({ ...prevS, [key]: false, error: false }));
    if (key !== "error") {
      setStep({
        pinReset: 0,
        changePin: 0,
        changePassword: 0,
      });
    }
  };

  const handleOpen = (key) => {
    setOpen((prevS) => ({ ...prevS, [key]: true }));
  };

  const handleError = (key, err) => {
    setError((prevS) => ({
      ...prevS,
      [key]: err?.response?.data?.detail || err?.message || err,
    }));
  };

  const handleChange = () => {};

  return (
    <Stack mt={4} spacing={3} sx={styles.container}>
      <ResetPinModal
        open={open}
        handleClose={handleClose}
        step={step.pinReset}
        error={error.pinReset}
        nextStep={nextStep}
        handleError={handleError}
        handleOpen={handleOpen}
      />

      <ChangePinModal
        open={open}
        handleClose={handleClose}
        step={step.changePin}
        error={error.changePin}
        handleError={handleError}
        handleOpen={handleOpen}
        nextStep={nextStep}
      />

      <ChangePasswordModal
        open={open}
        handleClose={handleClose}
        step={step.changePassword}
        error={error.changePassword}
        handleError={handleError}
        handleOpen={handleOpen}
        nextStep={nextStep}
      />

      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 3, sm: 4, lg: 5 }}
      >
        <InputWithLabel label="Reset Transaction Pin">
          {isLoading ? (
            <Skeleton sx={{ width: "100%", height: 50, transform: "none" }} />
          ) : (
            <Input
              value="****"
              onChange={handleChange}
              disabled
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <EditIcon />
                  </IconButton>
                </InputAdornment>
              }
              sx={styles.input}
              onClick={() => handleOpen("pinReset")}
            />
          )}
        </InputWithLabel>

        <InputWithLabel label="Change Transaction Pin">
          {isLoading ? (
            <Skeleton sx={{ width: "100%", height: 50, transform: "none" }} />
          ) : (
            <Input
              value="****"
              onChange={handleChange}
              disabled
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <EditIcon color="#D22F31" />
                  </IconButton>
                </InputAdornment>
              }
              sx={styles.input}
              onClick={() => handleOpen("changePin")}
            />
          )}
        </InputWithLabel>
      </Stack>

      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 3, sm: 4, lg: 5 }}
      >
        <InputWithLabel label="Change Password">
          {isLoading ? (
            <Skeleton sx={{ width: "100%", height: 50, transform: "none" }} />
          ) : (
            <Input
              value="********"
              onChange={handleChange}
              disabled
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <EditIcon sx={{ fill: "#D22F31" }} />
                  </IconButton>
                </InputAdornment>
              }
              sx={styles.input}
              onClick={() => handleOpen("changePassword")}
            />
          )}
        </InputWithLabel>

        <InputWithLabel label="View BVN">
          {isLoading ? (
            <Skeleton sx={{ width: "100%", height: 50, transform: "none" }} />
          ) : (
            <Input
              value={showbvn ? bvn : "********"}
              onChange={handleChange}
              disabled
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowBvn}
                    edge="end"
                  >
                    {showbvn ? (
                      <VisibilityOn className="green-stroke " />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              sx={styles.input}
            />
          )}
        </InputWithLabel>
      </Stack>
    </Stack>
  );
};

export default ProfileForm;
