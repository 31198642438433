/** @format */

import { useMutation, useQuery } from "@tanstack/react-query";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import Switch from "components/base/Switch/Switch";
import React, { useEffect, useState } from "react";
import { getProviders, vendBetting } from "services/dashboard";
import { validateNumbers } from "utils/validateNumbers";
import Select from "components/base/Select/Select";
import Input from "components/base/Input/Input";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { AcroFormListBox } from "jspdf";
import Button from "@mui/material/Button";
import BillPaymentTestModal from "./BillsPaymentModal/BillPaymentTest";

const SportBettingForm = ({
  formik,
  styles,
  validateSmartCard,
  cardInfo,
  accounts,
  action,
  open,
  setOpen,

}) => {
  const [message, setMessage] = useState("");
  const [step, setStep] = useState(0);
  const [otpError, setOtpError] = useState(0);
  const [open2, setOpen2] = useState(false);
  console.log(cardInfo)

  // console.log(formik.values);

  const [openError, setOpenError] = React.useState({
    nameEnquiry: false,
    transaction: false,
  });
  const [error, setError] = React.useState({
    nameEnquiry: "",
    transaction: "",
  });

  // const handleOpenModal = () => setOpen(true);

  const handleCloseModal = () => {
    setStep(0);
    setOpen(false);
    formik.setFieldValue("transaction_pin", "");
  };

  const handleCloseError = (key) => {
    setOpenError((prevS) => ({ ...prevS, [key]: false }));
  };

  const handleOpenError = (key) => {
    setOpenError((prevS) => ({ ...prevS, [key]: true }));
  };

  const setBackendError = (key, err) => {
    setError((prevS) => ({ ...prevS, [key]: err }));
  };

  React.useEffect(() => {
    if (formik.values.transaction_pin.length === 4) {
      setOtpError("");
    }
  }, [formik.values.transaction_pin.length]);

  const handleConfirmTransactionBtn = () => {
    setStep((prevS) => prevS + 1);
  };
  const handleTransfer = () => {
    if (formik.values.transaction_pin.length === 4) {
      mutate({
        service_name: formik.values.service_name.label,
        customer_id: formik.values.customer_id,
        name: cardInfo.detail.result.name,
        account_no: formik.values.account,
        amount: formik.values.amount,
        service_id: formik.values.service_name.service_id,
        transaction_pin:formik.values.transaction_pin
      });
    }
else{
  setOtpError("Enter valid OTP");

}


  };
  const router = useNavigate();


  const { mutate, isLoading } = useMutation({
    mutationFn: vendBetting,
    onSuccess: (data) => {
      setOpen(true);
      setStep(1);
      setOpen2(false);

      router("/corporate-scheduled-payments?tab=table");
      action();
      handleCloseModal();
      formik.resetForm();

      console.log(data);
    },
    onError: (err) => {
      setBackendError(
        "transaction",
        err?.response?.data?.detail || err.message
      );
      handleOpenError("transaction");
      console.log(err?.response?.data?.detail, err.message);
    },
  });

  // get Biller
  const { data: providers } = useQuery({
    queryKey: ["getProviders"],
    queryFn: getProviders,
    onError: (err) => {
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
    },
  });

  // Extracting provider names from the response

  let bettingBiller = [];
  if (providers && providers.detail) {
    bettingBiller = Object.entries(providers.detail.result).map(
      ([key, value]) => ({
        service_id: key,
        label: value,
      })
    );
  }

  const handleBillerOnchange = (e) => {
    // formik.setFieldValue("service_name", "");
    console.log(formik.value)
    formik.setFieldValue("amount", "");
    formik.setFieldValue("customer_id", "");
  };

  // reset form fields on mount
  useEffect(() => {
    formik.resetForm();
  }, []);

  return (
    <Stack
      as="form"
      spacing={{ xs: 3, sm: 4 }}
      onSubmit={formik.handleSubmit}
      sx={styles.container}
    >
      <BillPaymentTestModal
        open={open}
        handleClose={handleCloseModal}
        step={step}
        handleConfirmTransactionBtn={handleConfirmTransactionBtn}
        setStep={setStep}
        handleTransfer={handleTransfer}
        isLoading={isLoading}
        openError={openError.transaction}
        handleCloseError={() => handleCloseError("transaction")}
        errorMessage={error.transaction}
        formik={formik}
        error={otpError}
        cardInfo={cardInfo}
      />
      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <DirectionSnackbar
          open={message}
          message={message}
          handleClose={() => {
            setMessage(false);
          }}
        />

        <InputWithLabel label="Select Account">
          <Select
            options={accounts}
            placeholder="Select Account"
            name="account"
            value={formik.values.account}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>
        <InputWithLabel label="Select Biller">
          <Select
            options={bettingBiller}
            placeholder="Select Biller "
            name="service_name"
            value={formik.values.service_name.label}
            formik={formik}
            sx={styles.input}
            onChange={handleBillerOnchange}
          />
        </InputWithLabel>
      </Stack>

      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Amount">
          <Input
            id="amount"
            name="amount"
            type="tel"
            value={formik.values.amount}
            onChange={(event) => {
              validateNumbers(event, 1000, formik.handleChange);
            }}
            placeholder="Enter amount"
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>

        <InputWithLabel label="Customer ID">
          <Input
            type="tel"
            id="customer_id"
            name="customer_id"
            value={formik.values.customer_id}
            onChange={(event) => {
              validateNumbers(event, 50, formik.handleChange);
            }}
            placeholder="Enter Customer ID"
            formik={formik}
            sx={styles.input}
            onBlur={validateSmartCard}
          />
          <Typography sx={{ textTransform: "capitalize", fontSize: 12 }}>
            {cardInfo?.detail?.name?.toLowerCase()}
          </Typography>
        </InputWithLabel>
      </Stack>

      <Button
        type="submit"
        variant="contained"
        color="green3"
        sx={styles.submitBtn}
      >
        Proceed
      </Button>
    </Stack>
  );
};

export default SportBettingForm;
