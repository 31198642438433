import { useState } from 'react';
import TransactionHistoryTable from 'components/TransactionHistory/TransactionHistoryTable/TransactionHistoryTable';
import { transaction_history } from 'services/dashboard';
import { userProfile } from "services/dashboard";
import { useQuery } from '@tanstack/react-query';

const TransactionHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);

  // get user profile
  const { data: user} = useQuery({
    queryKey: ["userProfile"],
    queryFn: userProfile,
  });

  const customerAccountNumber =
  user?.customer?.accounts?.map(({ account_no }) => account_no)[0] || "";

  const { data, isLoading } = useQuery({
    queryKey: ['transaction_history', customerAccountNumber, currentPage],
    queryFn: () => transaction_history(customerAccountNumber, currentPage),
    enabled: Boolean(customerAccountNumber)
  });

  const setPage = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <TransactionHistoryTable
        data={data?.detail}
        isLoading={isLoading}
        pagination={data?.pagination}
        page={currentPage}
        setPage={setPage}
      />
    </>
  );
};

export default TransactionHistory;
