export const styles = {
  container: {
    width: { xs: "100%", sm: "60%", md: "70%", lg: 399, xl: "50%" },
    mx: { xs: 0, lg: 8 },
    mt: { xs: 4, sm: 6 },
    pb: 6,
  },
  header: {
    fontSize: { xs: 20, sm: 25, lg: 30 },
    fontWeight: 500,
    color: "black.main",
  },
  subText: {
    fotnSize: { xs: 14, sm: 14 },
    color: "gray5.main",
    mt: 2,
  },
  progressIndicator: (active, number) => ({
    width: 41,
    height: 4,
    bgcolor: active === number ? "green7.main" : "gray6.main",
    borderRadius: "10px",
    cursor: "pointer",
  }),
  thirdForm: {
    width: "100%",
    height: { xs: 179 },
    bgcolor: "white.main",
    border: "1px dashed",
    borderColor: "gray7.main",
    borderRadius: "4px",
    mt: "4px",
  },
  thirdFormHeader: {
    fontSize: {xs: 12, sm: 13},
    color: "black.main",
  },
  text: {
    fontSize: 14,
  },
  selectFileBtn: {
    position: "relative",
    fontSize: 12,
    boxShadow: "none",
  },
  file: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0,
    cursor: "pointer",
  },
  submitBtn: {
    width: "100%",
    height: { xs: 55, lg: 70 },
    fontSize: { xs: 16, sm: 20 },
    color: "white.main",
    boxShadow: "0px 4px 30px rgba(38, 58, 67, 0.15)",
  },
};



export const stylesTier = {
  container: {
    width: { xs: "100%", sm: "60%", md: "70%", lg: 399, xl: "50%" },
    mx: { xs: 0, lg: 8 },
    mt: { xs: 4, sm: 6 },
    pb: 6,
  },
  header: {
    fontSize: { xs: 20, sm: 25, lg: 30 },
    fontWeight: 500,
    color: "black.main",
  },
  subText: {
    fotnSize: { xs: 14, sm: 14 },
    color: "gray5.main",
    mt: 2,
  },
  progressIndicator: (active, number) => ({
    width: 41,
    height: 4,
    bgcolor: active === number ? "green7.main" : "gray6.main",
    borderRadius: "10px",
    cursor: "pointer",
  }),
  thirdForm: {
    width: "100%",
    height: { xs: 179 },
    bgcolor: "white.main",
    border: "1px dashed",
    borderColor: "gray7.main",
    borderRadius: "4px",
    mt: "4px",
  },
  thirdFormHeader: {
    fontSize: {xs: 12, sm: 13},
    color: "black.main",
  },
  text: {
    fontSize: 14,
  },
  selectFileBtn: {
    position: "relative",
    fontSize: 12,
    boxShadow: "none",
  },
  file: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0,
    cursor: "pointer",
  },
  submitBtn: {
    width: "100%",
    height: { xs: 55, lg: 70 },
    fontSize: { xs: 16, sm: 20 },
    color: "white.main",
    boxShadow: "0px 4px 30px rgba(38, 58, 67, 0.15)",
  },
};
