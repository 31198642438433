import InputBase from "@mui/material/InputBase";
import FormHelperText from "@mui/material/FormHelperText";
import { style } from "./Input.styles";

const Input = (props) => {
  return (
    <>
      <InputBase
        autoComplete={props.type === "password" ? "new-password" : "off"}
        {...props}
        sx={{
          ...style,
          ...props.sx,
        }}
        className="input-placeholder"
      />
      {props.formik?.touched[props.name] && (
        <FormHelperText
          variant="filled"
          sx={{ color: "red.main", fontSize: 12, ml: 0, mt: "3px" }}
        >
          {props.formik?.errors[props.name]}
        </FormHelperText>
      )}
    </>
  );
};

export default Input;
