import * as Yup from 'yup';

export const TransferToCitSchema = Yup.object().shape({
  account: Yup.string().required('Select senders account number'),
  amount: Yup.number().min(1, 'Enter a valid amount').required('Enter valid amount'),
  beneficiary_account_no: Yup.string().required(
    'Enter beneficiary account number'
  ),
  beneficiary_account_name: Yup.string().required(
    'Enter valid account number to proceed'
  ),
  // narration: Yup.string().required('Enter brief transaction description'),
});

export const TransferToCitScheduleSchema = Yup.object().shape({
  account: Yup.string().required('Select senders account number'),
  amount: Yup.number().min(1, 'Enter a valid amount').required('Enter valid amount'),
  beneficiary_account_no: Yup.string().required(
    'Enter beneficiary account number'
  ),
  beneficiary_account_name: Yup.string().required(
    'Enter valid account number to proceed'
  ),
  frequency: Yup.string().required('Select a frequency'),
  frequencyType: Yup.string().required('Select a frequency type'),
  fromDate: Yup.string().required('Enter a valid date'),
  toDate: Yup.string().required('Enter a valid date'),

  // narration: Yup.string().required('Enter brief transaction description'),
});

export const TransferToCitScheduleBulkSchema = Yup.object().shape({
  frequency: Yup.string().required('Select a frequency'),
  frequencyType: Yup.string().required('Select a frequency type'),
  fromDate: Yup.string().required('Enter a valid date'),
  toDate: Yup.string().required('Enter a valid date'),
  narration: Yup.string().required('Enter brief transaction description'),
});