export const styles = {
  card: {
    background: 'url(/img/dashboard7.webp) 50% 50% no-repeat',
    backgroundSize: 'cover',
    borderRadius: '8px',
    mt: { xs: 3, sm: 4 },
    p: { xs: '14px', sm: 2 },
    overflow: 'hidden',
  },
  brandLogo: {
    width: { xs: 60, sm: 75 },
    objectFit: 'contain',
  },
  bankName: {
    flex: '1',
    fontSize: { xs: 16, sm: 20, lg: 22 },
    fontWeight: 700,
    color: 'green1.main',
  },
  signal: { height: { xs: 25, sm: 35 } },
  sim: {
    width: { xs: 39, sm: 43 },
    height: { xs: 29, sm: 33 },
    mt: { xs: 1, sm: 2 },
  },
  cardNumber: {
    fontSize: { xs: 18, lg: 20 },
    fontWeight: 600,
    letterSpacing: '2px',
    mt: { xs: 1, sm: 2 },
  },
  validText: {
    fontSize: { xs: 10, lg: 11 },
    lineHeight: { xs: '11px', lg: '12px' },
  },
  validDate: {
    fontSize: { xs: 14, lg: 15 },
    fontWeight: 600,
  },
  cardName: {
    fontSize: { xs: 17, lg: 18 },
    fontWeight: 600,
  },
  mastercard: {
    position: 'relative',
    width: 48,

    '&:before': {
      position: 'absolute',
      content: "''",
      left: 0,
      width: 29,
      height: 29,
      bgcolor: '#EB001B',
      borderRadius: '50%',
    },
    '&:after': {
      position: 'absolute',
      content: "''",
      right: 0,
      width: 29,
      height: 29,
      bgcolor: '#F79E1BBB',
      borderRadius: '50%',
      zIndex: '1',
    },
  },
};
