export const styles = {
  container: {
    alignItems: 'center',
    height: { xs: '100%', lg: '100%' },
    borderRadius: '10px',
    overflow: 'hidden',
  },
  img: {
    display: 'block',
    width: '100%',
    height: '100%',
  },
};
