export const styles = {
  container: {
    backgroundColor: "white.main",
    boxShadow: "0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)",
    borderRadius: "10.2375px",
    padding: '0 120px',
  },
  header: {
    width: { lg: "100%" },
    fontSize: { xs: 16, sm: 18, lg: 20 },
    fontWeight: 700,
    color: "#000000",
    px: { xs: 2, sm: 3, lg: 0 },
    mb: 3,
    mx: "auto",
  },
  tab: {
    width: { lg: "100%" },
    px: { xs: 2, sm: 3, lg: 0 },
    mx: "auto",
  },
  category: (active) => ({
    fontSize: { xs: 13, lg: 14 },
    fontWeight: 600,
    color: active ? "green3.main" : "#888686",
    borderBottom: active ? "1px solid #D22F31" : "1px solid transparent",
    px: { xs: 2, sm: 4 },
    pb: "5px",
    cursor: "pointer",
  }),
  input: {
    backgroundColor: "rgba(180, 246, 215, 0.05)",
    borderColor: "#D22F31",
    borderRadius: "6px",
  },
  provider: (selected) => ({
    width: { xs: 70, sm: 90 },
    height: { xs: 65, sm: 85 },
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "40px",
    border: selected ? "1px solid #0E814A" : "1px solid transparent",
    p: 2,
    cursor: "pointer",
    boxSizing: "border-box",
  }),
  providerImg: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  networkProviderErr: {
    fontSize: 12,
    color: "red.main",
    mt: 1
  },
  submitBtn: {
    width: { xs: "80%", sm: 300 },
    height: 55,
    alignSelf: "center",
    color: "white.main",
  },
};


export const styles2 = {
  container: { borderBottom: "1px solid #ABABAB", marginBottom: "30px" },
  header: {
    width: { xs: "95%", md: "90%", lg: "80%" },
    fontSize: { xs: 22, sm: 25, md: 28 },
    fontWeight: 700,
    mx: "auto",
  },
  tab: {
    width: { xs: "95%", md: "90%", lg: "80%" },
    justifyContent: "space-between",
    flexWrap: "no-wrap",
    mx: "auto",
    mt: { xs: 2, sm: 3 },
    overflowX: { xs: "auto", sm: "hidden" },
    boxSizing: "border-box",
  },
  tabItem: (color) => ({
    position: "relative",
    fontSize: { xs: 14, sm: 16 },
    fontWeight: 700,
    color: color ? "green3.main" : "#888686",
    minWidth: "max-content",
    p: 1,
    boxSizing: "border-box",
    cursor: "pointer",
    "&:hover": { color: "green3.main" },
    "&:before": {
      position: "absolute",
      content: "''",
      bottom: 0,
      left: 1,
      width: "100%",
      height: 2,
      bgcolor: color ? "green3.main" : "transparent",
      boxSizing: "border-box",
    },
  }),
};

export const styles3 = {
  closeX: {
    position: 'absolute',
    top: { xs: '5%', sm: '8%' },
    right: { xs: '5%', sm: '8%' },
    cursor: 'pointer',
  },
  closeIcon: {
    width: { xs: 18, sm: 32 },
    height: { xs: 18, sm: 32 },
    '&:hover': { transform: 'scale(1.05)' },
  },
  header: {
    fontSize: { xs: 30, md: 40 },
    fontWeight: 700,
    color: 'black.main',
    textAlign: 'center',
    mt: "-50px"
  },
  desc: {
    fontSize: { xs: 14, md: 16, lg: 18 },
    fontWeight: 500,
    color: 'black.main',
    textAlign: '',
    mt: '10px',
    mb: '10px'
  },
  closeBtn: {
    borderRadius: '10px',
    width: '100%',
    fontSize: { xs: 16, md: 20, lg: 22 },
    fontWeight: 600,
    px: 3,
    "&:first-child": {
      mr: '10px'
    },
    "&:first-child:hover":{
      background: 'none',
      border: '1px solid'
    },
    "&:last-child:hover":{
      backgroundColor: 'green3.main'
    }
  },
  
  img: {
    display: 'block',
    width: 200,
    mx: 'auto',
  },
};