import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";
import { styles } from "./Modal.styles";

const Close = styled(CloseIcon)(() => ({}));

const CustomModal = ({ open, handleClose, children }) => {
  return (
    <Modal
      open={open}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.body}>
        <Close sx={styles.closeBtn} onClick={handleClose} />

        {children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
