export const styles = {
  content: { width: '80%', mx: 'auto' },
  header: {
    fontSize: { xs: 22, sm: 25, lg: 28 },
    fontWeight: 700,
    textAlign: 'center',
    mt: 3,
  },
  subText: {
    fontSize: { xs: 16, sm: 18, lg: 22 },
    fontWeight: 500,
    textAlign: 'center',
  },
  actionBtn: {
    width: '100%',
    height: { xs: 50, sm: 55 },
    color: 'white.main',
    fontSize: { xs: 16, sm: 18 },
    fontWeight: 600,
  },
  btn: {
    width: { xs: 'auto', sm: 245 },
    height: { xs: 45, sm: 55 },
    color: 'white.main',
    fontSize: { xs: 16, sm: 18 },
    fontWeight: 600,
  },
};
