export const styles = {
  container: {
    position: "relative",
    height: { xs: 150, sm: 180, lg: 235 },
    bgcolor: "green8.main",
    pt: { xs: 3, sm: 5 },
    px: { xs: 2, sm: 4 },
    overflow: "hidden",
    boxSizing: "border-box",

    "&::before": {
      position: "absolute",
      content: "''",
      top: {xs: "-15%", sm: "0%"},
      left: "0%",
      width: 180,
      height: 180,
      bgcolor: { xs: "white.main", sm: "green8.main" },
      transform: "translate(-50%, -50%)",
      border: {
        xs: "50px solid rgba(255, 255, 255, 0.2)",
        sm: "40px solid rgba(255, 255, 255, 0.2)",
      },
      borderRadius: "50%",
    },

    "&::after": {
      position: "absolute",
      content: "''",
      top: "0%",
      right: "0%",
      width: 180,
      height: 180,
      bgcolor: "green8.main",
      transform: "translate(50%, 20%)",
      border: "40px solid rgba(255, 255, 255, 0.2)",
      borderRadius: "50%",
    },
  },
  hamburger: {
    display: { xs: "block", sm: "none" },
    height: "fit-content",
    mr: 1,
    // zIndex: 20,
  },
  brandLogo: {
    display: { xs: "block", sm: "none" },
    height: "fit-content",
    zIndex: 1,
  },
  brand: {
    display: "block",
    width: 65,
    objectFit: "contain",
  },
  navItems: {
    height: "fit-content",
    zIndex: 1,
  },
  iconContainer: (unRead) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",

    "&::before": {
      display: unRead ? "block" : "none",
      position: "absolute",
      content: "''",
      top: 0,
      right: 0,
      width: { xs: 5, sm: 9 },
      height: { xs: 5, sm: 9 },
      bgcolor: "pink.main",
      borderRadius: "50%",
      border: "2px solid #ffffff",
    },
  }),
  notificationIcon: {
    width: { xs: 22, sm: 29 },
    height: { xs: 21, sm: 28 },
  },
  user: {
    width: { xs: 38, sm: 38 },
    height: { xs: 38, sm: 38 },
    borderRadius: "50%",
    objectFit: "cover",
  },
  logoutIcon: {
    width: { xs: 15, sm: 20 },
    height: { xs: 13, sm: 18 },
  },
};
