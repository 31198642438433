import * as Yup from "yup";

export const AirtimeSchema = Yup.object().shape({
  account_source: Yup.string().required("Select billing account"),
  networkProvider: Yup.mixed()
    .required(" Select network provider")
    .oneOf(
      ["mtn", "glo", "airtel", "9mobile"],
      "Must mtn, glo, airtel or 9mobile"
    ),
  phone: Yup.string()
    .min(11, "Minimum of 11 digits")
    .required("Enter valid phone number"),
  amount: Yup.number().min(1, "Can't be les than one").required("Enter amount"),
  saveBeneficiary: Yup.boolean(),
  beneficiaryName: Yup.string().when("saveBeneficiary", {
    is: true,
    then: (schema) => schema.required("Enter beneficiary name"),
    otherwise: (schema) => schema,
  }),
});

export const DataSchema = Yup.object().shape({
  account_source: Yup.string().required("Select billing account"),
  networkProvider: Yup.mixed()
    .required("Select network provider")
    .oneOf(
      ["mtn", "glo", "airtel", "9mobile"],
      "Must mtn, glo, airtel or 9mobile"
    ),
  dataPlan: Yup.string().required("Select data plan"),
  phone: Yup.string()
    .min(11, "Minimum of 11 digits")
    .required("Enter valid phone number"),
  amount: Yup.number().min(1, "Can't be les than one").required("Enter amount"),
  saveBeneficiary: Yup.boolean(),
  beneficiaryName: Yup.string().when("saveBeneficiary", {
    is: true,
    then: (schema) => schema.required("Enter beneficiary name"),
    otherwise: (schema) => schema,
  }),
});
