import * as React from "react";
import Box from "@mui/material/Box";
import BeneficiariesTab from "components/SavedBeneficiaries/BeneficiariesTab/BeneficiariesTab";
import CitmfbTransfer from "components/SavedBeneficiaries/CitmfbTransfer/CitmfbTransfer";
import OtherBanksBeneficiaries from "components/SavedBeneficiaries/OtherBanksBeneficiaries/OtherBanksBeneficiaries";
import AirtimeAndDataBeneficiaries from "components/SavedBeneficiaries/AirtimeAndDataBeneficiaries/AirtimeAndDataBeneficiaries";
import BillsPaymentBeneficiaries from "components/SavedBeneficiaries/BillsPaymentBeneficiaries/BillsPaymentBeneficiaries";
import { useQuery } from "@tanstack/react-query";
import { getBeneficiaries } from "services/dashboard";
import { useSearchParams } from "react-router-dom";

const SavedBeneficiaries = () => {
  const [searchInput, setSearchInput] = React.useState("");
  const [page, setPage] = React.useState(1);

  const [param] = useSearchParams();

  const currentTab = param.get("tab");

  const { data, isLoading } = useQuery({
    queryKey: ["beneficiaries", currentTab, searchInput, page],
    queryFn: () => getBeneficiaries(currentTab, searchInput, page),
  });

  const tabOnChange = () => {
    setSearchInput("");
  };

  const handleChange = (event) => {
    setSearchInput(event.target.value);
  };

  return (
    <Box
      sx={{
        bgcolor: "white.main",
        boxShadow: "0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)",
        borderRadius: "10.2375px",
        py: { xs: 3, sm: 5 },
      }}
    >
      <BeneficiariesTab tabOnChange={tabOnChange} />
      {currentTab === "local_transfer" && (
        <CitmfbTransfer
          searchInput={searchInput}
          handleChange={handleChange}
          tableData={data?.detail?.results || []}
          isLoading={isLoading}
          page={page}
          setPage={setPage}
          item_count={data?.detail?.count || 0}
        />
      )}

      {currentTab === "external_transfer" && (
        <OtherBanksBeneficiaries
          searchInput={searchInput}
          handleChange={handleChange}
          tableData={data?.detail?.results || []}
          isLoading={isLoading}
          page={page}
          setPage={setPage}
          item_count={data?.detail?.count || 0}
        />
      )}

      {currentTab === "airtime" && (
        <AirtimeAndDataBeneficiaries
          searchInput={searchInput}
          handleChange={handleChange}
          tableData={data?.detail?.results || []}
          isLoading={isLoading}
          page={page}
          setPage={setPage}
          item_count={data?.detail?.count || 0}
        />
      )}

      {currentTab === "utility" && (
        <BillsPaymentBeneficiaries
          searchInput={searchInput}
          handleChange={handleChange}
          tableData={data?.detail?.results || []}
          isLoading={isLoading}
          page={page}
          setPage={setPage}
          item_count={data?.detail?.count || 0}
        />
      )}
    </Box>
  );
};

export default SavedBeneficiaries;
