import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import FirstForm from "components/CorporateForgotPassword/FirstForm";
import SecondForm from "components/CorporateForgotPassword/SecondForm";
import ThirdForm from "components/CorporateForgotPassword/ThirdFormLiveness";

import AuthLayout from "layout/Auth/Auth.layout";
import ConfirmationModal from "components/base/ConfirmationModal/ConfirmationModal";
import {
  FirstFormSchema,
  SecondFormSchema,
  ThirdFormSchema,
} from "services/Yup/CorporateForgotPasswordSchema";
import {
  CorporateRequestOtp,
  CorporateValidateOtp,
  CorporateResetPassword,
} from "services/Auth";
import { useMutation } from "@tanstack/react-query";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import { useNavigate } from "react-router-dom";
import { styles } from "./CorporateForgotPassword.styles";

const CorporateForgotPassword = () => {
  const navigate = useNavigate();
  const [active, setActive] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  const handleError = (err) => {
    setError(err?.response?.data?.error || err.message);
    setOpenError(true);
  };

  const closeError = () => {
    setOpenError(false);
  };

  const navigateActiveForm = (form) => {
    if (form < active) {
      setActive(form);
    }
  };

  const { mutate: mutate1, isLoading: isLoading1 } = useMutation({
    mutationFn: CorporateRequestOtp,
    onSuccess: (res) => {
      handleOpen("otp");
      setActive((prevS) => prevS + 1);
      console.log(res);
    },
    onError: (err) => {
      handleError(err);
    },
  });

  const { mutate: mutate2, isLoading: isLoading2 } = useMutation({
    mutationFn: CorporateValidateOtp,
    onSuccess: (res) => {
      setActive((prevS) => prevS + 1);
      localStorage.setItem("session", res.reset_key);
    },
    onError: (err) => {
      handleError(err);
    },
  });

  const { mutate: mutate3, isLoading: isLoading3 } = useMutation({
    mutationFn: CorporateResetPassword,
    onSuccess: () => {
      navigate("/auth/corporate-sign-in");
    },
    onError: (err) => {
      handleError(err);
    },
  });

  const activeValidationSchema =
    active === 1
      ? FirstFormSchema
      : active === 2
      ? SecondFormSchema
      : active === 3
      ? ThirdFormSchema
      : "";

  const initialValues =
    active === 1
      ? {
          email: "",
          account_no: "",
          phone_number: "",
        }
      : active === 2
      ? {
          token: "",
        }
      : active === 3
      ? {
          new_password: "",
          confirm_password: "",
        }
      : "";

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: activeValidationSchema,
    onSubmit: (values) => {
      if (active === 1) {
        mutate1(values);
      }
      if (active === 2) {
        mutate2(values);
      }
      if (active === 3) {
        mutate3({ ...values, reset_key: localStorage.getItem("session") });
      }
    },
  });

  return (
    <AuthLayout image="/img/auth-2.webp">
      <DirectionSnackbar
        open={openError}
        handleClose={closeError}
        message={error}
      />

      <ConfirmationModal
        open={open.otp}
        handleClose={() => handleClose("otp")}
        mainText="OTP Sent"
        subText="An OTP has been sent to your _number Number."
        btnText="Continue"
        handleClick={() => handleClose("otp")}
      />

      <Box sx={styles.container}>
        <Typography sx={styles.header}>
          {active === 1
            ? "Forgot your Password?"
            : active === 2
            ? "Enter OTP"
            : "Enter New Password"}
        </Typography>
        <Typography sx={styles.subText}>
          {active === 1
            ? "Please enter your required information, an OTP will be sent to you."
            : active === 2
            ? "Please enter the OTP sent to your phone number annd Email Address"
            : "Please create a secure password including the following critiria below."}
        </Typography>

        <Stack direction="row" spacing={1} mt={2} mb={5}>
          {[1, 2, 3].map((tab) => (
            <Box
              key={tab}
              sx={styles.progressIndicator(active, tab)}
              onClick={() => navigateActiveForm(tab)}
            />
          ))}
        </Stack>
        <Stack spacing={3} as="form" onSubmit={formik.handleSubmit}>
          {active === 1 && <FirstForm formik={formik} />}
          {active === 2 && <SecondForm formik={formik} />}
          {active === 3 && <ThirdForm formik={formik} />}

          <Box pb={2} />

          <Button
            variant="contained"
            color="green3"
            type="submit"
            sx={styles.submitBtn}
          >
            {isLoading1 || isLoading2 || isLoading3
              ? "Submitting..."
              : active === 3
              ? "Submit"
              : "Proceed"}
          </Button>
        </Stack>
      </Box>
    </AuthLayout>
  );
};

export default CorporateForgotPassword;
