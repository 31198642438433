import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styles } from "components/base/TransferModal/TransferModal.styles";
import OTP from "components/base/OTP/OTP";

const EnterElectricityTransactionPin = ({ handleClick, isLoading, formik, error }) => {
  return (
    <Box sx={{ mx: "auto", width: "fit-content" }}>
      <Typography sx={{...styles.header, mb: 6}}>Enter Transaction Pin</Typography>

      <OTP formik={formik} formikKey="transaction_pin" error={error} />

      <Box mx="auto" width="fit-content" mt={{ xs: 4, sm: 7 }}>
        <Button
          variant="contained"
          color="green3"
          sx={styles.btn}
          onClick={handleClick}
          disabled={isLoading}
        >
          {isLoading ? "Please wait" : "Pay"}
        </Button>
      </Box>
    </Box>
  );
};

export default EnterElectricityTransactionPin;
