export const styles = {
  header: {
    fontSize: { xs: 16, lg: 18 },
    fontWeight: 700,
    color: 'green3.main',
  },
  services: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(3, 1fr)',
      sm: 'repeat(4, 1fr)',
    },
    gap: '11px',
    mt: { xs: 3, lg: 4 },
  },
  serviceItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: 'white.main',
    border: '1px solid #EFF0F6',
    borderRadius: '11.7px',
    minHeight: 99,
  },
  icon: (color) => ({
    width: 47,
    height: 47,
    bgcolor: color,
    borderRadius: '50%',
    '&:hover': { bgcolor: color },
  }),
  serviceName: {
    fontSize: 10,
    fontWeight: 700,
    color: 'gray8.main',
    mt: '0.875rem',
  },
};
