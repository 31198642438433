import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import Select from "components/base/Select/Select";
import { getServices, getPackages, vendBetting, cableTvSubscription } from "services/dashboard";
import { useMutation, useQuery } from "@tanstack/react-query";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import { formatCurrency } from "utils/formatCurrency";
import Switch from "components/base/Switch/Switch";
import { validateNumbers } from "utils/validateNumbers";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import CableTVPaymentModal from "./BillsPaymentModal/CableTvPaymentModal";

const CableTvForm = ({
  formik,
  styles,
  validateSmartCard,
  cardInfo,
  accounts,
  action,
  open,
  setOpen,


}) => {
  // const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [durations, setDurations] = useState([]);
  const [otpError, setOtpError] = useState(0);
  const [open2, setOpen2] = useState(false);
  const [step, setStep] = useState(0);

  console.log(formik.values);




  //   Get cable tv services
  const { data: getBiller } = useQuery({
    queryKey: ["getServices"],
    queryFn: getServices,
    onError: (err) => {
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
    },
  });

  const biller = getBiller?.detail || [];
  //   Get package by name
  const service_type = formik.values.service_name;
  const { data: packages } = useQuery({
    queryKey: ["getPackages", service_type],
    queryFn: () => getPackages(service_type),
    onError: (err) => {
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
    },
    enabled: Boolean(formik.values.service_name),
  });

  const packageNames = packages?.detail?.map(({ name }) => name) || [];

  const handleBillerOnchange = () => {
    formik.setFieldValue("product_name", "");
    formik.setFieldValue("duration", "");
    formik.setFieldValue("amount", "");
    formik.setFieldValue("smart_card_no", "");

  };

  const [newPackage, setNewPackage] = useState();

  const handlePackagesOnchange = (value) => {
    const selectedPackage = packages?.detail?.find((c) => c?.name === value);
    setNewPackage(selectedPackage);

    console.log(selectedPackage, "mee", value);

    const durations = selectedPackage?.availablePricingOptions?.map(
      ({ monthsPaidFor, price }) =>
        `${monthsPaidFor} month${
          monthsPaidFor > 1 ? "s" : ""
        } - ${formatCurrency(price)}`
    );

    setDurations(durations);

    formik.setFieldValue("product_codes", selectedPackage?.code);
  };

  const handleDurationOnchange = (value) => {
    /* const findSelectedPackage = packages?.detail?.find(
      ({ name }) => (name = formik.values.product_name)
    ); */

    const findSelectedDuration = newPackage?.availablePricingOptions?.find(
      ({ monthsPaidFor, price }) =>
        `${monthsPaidFor} month${
          monthsPaidFor > 1 ? "s" : ""
        } - ${formatCurrency(price)}` === value
    );

    formik.setFieldValue("amount", findSelectedDuration?.price);
    console.log(findSelectedDuration);
  };

  const [error, setError] = React.useState({
    nameEnquiry: "",
    transaction: "",
  });

  const [openError, setOpenError] = React.useState({
    nameEnquiry: false,
    transaction: false,
  });
  const router = useNavigate();

  const handleSubscription = () => {
    if (formik.values.transaction_pin.length === 4) {
      mutate({
        service_name: formik.values.service_name,
        duration: formik.values.duration,
        customer_name: cardInfo.detail.lastName,
        // phone_no : formik.values.phone_no,
        phone_number:cardInfo.detail.customerNumber,
        smart_card_no: formik.values.smart_card_no,
        account_no: formik.values.account,
        amount: formik.values.amount,
        product_codes: formik.values.product_codes,
        transaction_pin:formik.values.transaction_pin
      });
    }
else{
  setOtpError("Enter valid OTP");

}


  };
  const handleCloseModal = () => {
    setStep(0);
    setOpen(false);
    formik.setFieldValue("transaction_pin", "");
  };

  const handleCloseError = (key) => {
    setOpenError((prevS) => ({ ...prevS, [key]: false }));
  };

  const handleOpenError = (key) => {
    setOpenError((prevS) => ({ ...prevS, [key]: true }));
  };

  const setBackendError = (key, err) => {
    setError((prevS) => ({ ...prevS, [key]: err }));
  };

  React.useEffect(() => {
    if (formik.values.transaction_pin.length === 4) {
      setOtpError("");
    }
  }, [formik.values.transaction_pin.length]);

  const handleConfirmTransactionBtn = () => {
    setStep((prevS) => prevS + 1);
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: cableTvSubscription,
    onSuccess: (data) => {
      setOpen(true);
      setStep(1);
      setOpen2(false);

      router("/corporate-scheduled-payments?tab=table");
      action();
      handleCloseModal();
      formik.resetForm();

      console.log(data);
    },
    onError: (err) => {
      setBackendError(
        "transaction",
        err?.response?.data?.detail || err.message
      );
      handleOpenError("transaction");
      console.log(err?.response?.data?.detail, err.message);
    },
  });


  // reset form fields on mount
  useEffect(() => {
    formik.resetForm();
    // eslint-disable-next-line
  }, []);

  return (
    <>
        <Stack
      as="form"
      spacing={{ xs: 3, sm: 4 }}
      onSubmit={formik.handleSubmit}
      sx={styles.container}
    >
              <CableTVPaymentModal
          open={open}
          handleClose={handleCloseModal}
          step={step}
          handleConfirmTransactionBtn={handleConfirmTransactionBtn}
          setStep={setStep}
          handleTransfer={ handleSubscription }
          isLoading={isLoading}
          openError={openError.transaction}
          handleCloseError={() => handleCloseError("transaction")}
          errorMessage={error.transaction}
          formik={formik}
          error={otpError}
        />


      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <DirectionSnackbar
          open={open}
          message={message}
          handleClose={() => {
            setMessage(false);
          }}
        />

        <InputWithLabel label="Select Account">
          <Select
            options={accounts}
            placeholder="Select Account"
            name="account"
            value={formik.values.account}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>

        <InputWithLabel label="Select Biller">
          <Select
            options={biller}
            placeholder="(e.g DSTV, GOTV)"
            name="service_name"
            value={formik.values.service_name}
            formik={formik}
            sx={styles.input}
            onChange={handleBillerOnchange}
          />
        </InputWithLabel>
      </Stack>

      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Select Package">
          <Select
            options={packageNames}
            placeholder="Select package"
            name="product_name"
            value={formik.values.product_name}
            formik={formik}
            sx={styles.input}
            onChange={handlePackagesOnchange}
          />
        </InputWithLabel>

        <InputWithLabel label="Select Period">
          <Select
            options={durations}
            placeholder="Select package"
            name="duration"
            value={formik.values.duration}
            formik={formik}
            sx={styles.input}
            onChange={handleDurationOnchange}
          />
        </InputWithLabel>
      </Stack>

      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Amount">
          <Input
            type="number"
            id="amount"
            name="amount"
            value={formik.values.amount}
            placeholder="Enter amount"
            formik={formik}
            disabled={true}
            sx={styles.input}
          />
        </InputWithLabel>

        <InputWithLabel label="Smart Card Number">
          <Input
            type="tel"
            id="smart_card_no"
            name="smart_card_no"
            value={formik.values.smart_card_no}
            onChange={(event) => {
              validateNumbers(event, 50, formik.handleChange);
            }}
            placeholder="Enter smart card number"
            formik={formik}
            sx={styles.input}
            onBlur={validateSmartCard}
          />
          <Typography sx={{ textTransform: "capitalize", fontSize: 12 }}>
            {cardInfo?.detail?.firstName?.toLowerCase()}{" "}
            {cardInfo?.detail?.lastName?.toLowerCase()}
          </Typography>
        </InputWithLabel>
      </Stack>
      {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Phone Number">
          <Input
            type="number"
            id="phone_no"
            name="phone_no"
            value={formik.values.phone_no}
            placeholder="Enter Phone number"
            formik={formik}
            disabled={true}
            sx={styles.input}
          />
        </InputWithLabel>
</Stack> */}

      <Stack
        sx={{
          transform: "translateY(-1rem)",
          pb: 5,
        }}
      >
        <Switch
          name="saveBeneficiary"
          label="Save as beneficiary"
          value={formik.values.saveBeneficiary}
          onChange={formik.handleChange}
        />
      </Stack>
      <Button
        type="submit"
        variant="contained"
        color="green3"
        sx={styles.submitBtn}
      >
        Proceed
      </Button>
    </Stack>
    </>
  );
};

export default CableTvForm;
