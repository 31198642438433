import { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "assets/svg/arrow-right.svg";
import { rowColors } from "assets/data";
import { getBeneficiaries } from "services/dashboard";
import { useQuery } from "@tanstack/react-query";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import { styles } from "./OtherBanksBeneficiaries.styles";

const OtherBanksBeneficiaries = ({ formik }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const type = "external_transfer";
  const searchParam = "";
  const { data: beneficiaries, isLoading } = useQuery({
    queryKey: ["getBeneficiaries", type, searchParam],
    queryFn: () => getBeneficiaries(type, searchParam, 1),
    onError: (err) => {
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
    },
  });

  const handleSetbeneficiaryAccountNumber = (value) => {
    formik.setFieldValue("beneficiary_account_no", value?.no);
    formik.setFieldValue("beneficiary_bank_name", value?.bank);
    console.log(value);
  };
  return (
    <Box sx={styles.container}>
      <DirectionSnackbar
        open={open}
        message={message}
        handleClose={() => {
          setOpen(false);
        }}
      />

      <Typography sx={styles.header}>Transfer to Other Bank A/c</Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        mt={{ xs: 2, md: 3 }}
      >
        <Typography sx={styles.text}>Select Beneficiaries</Typography>
        {beneficiaries?.detail?.results?.length > 0 && (
          <Typography sx={styles.viewAll}>
            <Link to="/saved-beneficiaries?tab=external_transfer">
              View all Beneficiaries <ArrowIcon />
            </Link>
          </Typography>
        )}
      </Stack>

      {isLoading ? (
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ height: "auto" }}
        >
          {[1, 2, 3, 4, 5, 6, 7].map((skel) => (
            <Box key={skel}>
              <Skeleton
                variant="circular"
                sx={{
                  width: { xs: 45, lg: 60 },
                  height: { xs: 45, lg: 60 },
                  transform: "none",
                  mt: 1,
                }}
              />
              <Skeleton
                variant="text"
                sx={{
                  width: "100%",
                  fontSize: { xs: 12, lg: 14 },
                  transform: "none",
                  mt: 1,
                }}
              />
            </Box>
          ))}
        </Stack>
      ) : beneficiaries?.detail?.results?.length > 0 ? (
        <Box sx={{ overflow: "auto" }} mt={{ xs: 2, md: 3 }}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 3, sm: 4, lg: 0 }}
            sx={styles.beneficiaryContainer}
          >
            {(beneficiaries?.detail?.results || [])?.map(
              (
                { beneficiary_name, beneficiary_acct_no, beneficiary_bank },
                index
              ) => (
                <Box
                  key={beneficiary_name + index}
                  sx={styles.beneficiary}
                  onClick={() =>
                    handleSetbeneficiaryAccountNumber({
                      no: beneficiary_acct_no,
                      bank: beneficiary_bank,
                    })
                  }
                >
                  <Stack
                    sx={styles.beneficiaryInitials(rowColors[(index + 1) % 4])}
                  >
                    {beneficiary_name.split(" ")[0]?.charAt().toUpperCase()}{" "}
                    {beneficiary_name.split(" ")[1]?.charAt().toUpperCase()}
                  </Stack>
                  <Typography sx={styles.beneficiaryName}>
                    {beneficiary_name}
                  </Typography>
                </Box>
              )
            )}
          </Stack>
        </Box>
      ) : (
        <Typography sx={{ fontSize: 13 }}>No Beneficiary Found</Typography>
      )}
    </Box>
  );
};

export default OtherBanksBeneficiaries;
