import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import Input from "components/base/Input/Input";
import CustomModal from "components/base/Modal/Modal";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import OTP from "components/base/OTP/OTP";
import { styles } from "components/SavedBeneficiaries/AddNewCitmfbBeneficiaryModal/AddNewCitmfbBeneficiaryModal.styles";
import { resetOtp, resetTransactionPin } from "services/Auth";
import { useMutation } from "@tanstack/react-query";
import { ProfileSchema } from "services/Yup/ProfileSchema";
import { useFormik } from "formik";
import ResetOTP from "components/base/OTP/ResetOTP";

const ResetPinModal = ({
  open,
  handleClose,
  step,
  error,
  nextStep,
  handleError,
  handleOpen,
}) => {
  const { mutate: ResetOtp, isLoading: fetchingOtp } = useMutation({
    mutationFn: resetOtp,
    onSuccess: () => {
      nextStep("pinReset");
    },
    onError: (err) => {
      handleError("pinReset", err);
      handleOpen("error");
    },
  });

  const { mutate: ResetTransactionPin, isLoading: resettingPin } = useMutation({
    mutationFn: resetTransactionPin,
    onSuccess: () => {
      nextStep("pinReset");
      otpform.resetForm();
    },
    onError: (err) => {
      handleError("pinReset", err);
      handleOpen("error");
    },
  });

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: ProfileSchema,
    onSubmit: (values) => {
      ResetOtp({
        phone_number: values.phone,
        reset_type: "password",
      });
    },
  });

  const otpform = useFormik({
    initialValues: {
      oldPin: "",
      newPin: "",
      confirmPin: "",
    },
  });

  const handleResetPin = () => {
    const { oldPin, newPin, confirmPin } = otpform.values;
    if (!oldPin || !newPin || !confirmPin) {
      handleError("changePin", "Provide pins to proceed");
      return handleOpen("error");
    }

    if (newPin === confirmPin) {
      ResetTransactionPin({
        otp: oldPin,
        new_pin: newPin,
        confirm_new_pin: confirmPin,
      });
    } else {
      handleError("pinReset", "Old and new pin must be the same");
      handleOpen("error");
    }
  };

  return (
    <CustomModal
      open={open.pinReset}
      handleClose={() => {
        handleClose("pinReset");
        formik.resetForm();
      }}
    >
      <DirectionSnackbar
        open={open.error}
        handleClose={() => handleClose("error")}
        message={error}
        direction={{ vertical: "top", horizontal: "center" }}
      />

      <Box sx={styles.container} as="form" onSubmit={formik.handleSubmit}>
        {step === 0 && (
          <Stack spacing={3} sx={styles.content} alignItems="center">
            <Typography sx={styles.header}>Reset Pin</Typography>

            <InputWithLabel label="Phone number">
              <Input
                id="phone"
                type="tel"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                placeholder="Enter Phone number (e.g 07054363546)"
                formik={formik}
                sx={{
                  width: { xs: 300, sm: 350 },
                  backgroundColor: "#FFFFFF",
                  borderColor: "#EFF0F6",
                  borderRadius: "6px",
                }}
              />
            </InputWithLabel>

            <Box sx={{ height: 10 }} />

            <Box mx="auto" width="fit-content" mt={{ xs: 3, sm: 5 }}>
              <Button
                type="submit"
                variant="contained"
                color="green3"
                sx={{
                  ...styles.btn,
                  cursor: fetchingOtp ? "wait !important" : "pointer",
                }}
                disabled={fetchingOtp}
              >
                {fetchingOtp ? "Please wait" : "Proceed"}
              </Button>
            </Box>
          </Stack>
        )}

        {step === 1 && (
          <>
            <Box
              as="img"
              src="/img/successful_gif.gif"
              alt="successful"
              sx={styles.gif}
            />
            <Stack spacing={3} sx={styles.content} alignItems="center">
              <Typography sx={styles.header}>OTP Sent</Typography>
              <Typography sx={styles.subText}>
                A pin reset OTP has been sent to your phone number
              </Typography>
            </Stack>

            <Box mx="auto" width="fit-content" mt={{ xs: 3, sm: 5 }}>
              <Button
                variant="contained"
                color="green3"
                sx={styles.btn}
                onClick={() => nextStep("pinReset")}
              >
                Proceed
              </Button>
            </Box>
          </>
        )}

        {step === 2 && (
          <>
            <Stack spacing={3} sx={styles.content} alignItems="center">
              <Typography sx={styles.header}>Reset Pin</Typography>

              <InputWithLabel label="OTP">
                <ResetOTP formik={otpform} formikKey="oldPin" variant={2} />
              </InputWithLabel>

              <InputWithLabel label="New Pin">
                <OTP formik={otpform} formikKey="newPin" variant={2} />
              </InputWithLabel>

              <InputWithLabel label="Confirm Pin">
                <OTP formik={otpform} formikKey="confirmPin" variant={2} />
              </InputWithLabel>

              <Box mx="auto" width="fit-content" mt={{ xs: 3, sm: 5 }}>
                <Button
                  variant="contained"
                  color="green3"
                  sx={styles.btn}
                  disabled={resettingPin}
                  onClick={handleResetPin}
                >
                  {resettingPin ? "Please wait" : "Proceed"}
                </Button>
              </Box>
            </Stack>
          </>
        )}

        {step === 3 && (
          <>
            <Box
              as="img"
              src="/img/successful_gif.gif"
              alt="successful"
              sx={styles.gif}
            />
            <Stack spacing={3} sx={styles.content} alignItems="center">
              <Typography sx={styles.header}>Reset Pin Successful</Typography>
              <Typography sx={styles.subText}>
                Your Transaction Pin has been succesfully set.
              </Typography>
            </Stack>

            <Box mx="auto" width="fit-content" mt={{ xs: 3, sm: 5 }}>
              <Button
                variant="contained"
                color="green3"
                sx={styles.btn}
                onClick={() => {
                  handleClose("pinReset");
                  formik.resetForm();
                }}
              >
                Done
              </Button>
            </Box>
          </>
        )}
      </Box>
    </CustomModal>
  );
};

export default ResetPinModal;
