import { Axios } from "utils/Axios";

export const servicesAndTransactions = async(param) => {
    const { data } = await Axios.get(
        `${process.env.REACT_APP_API}account/dashboard/${process.env.REACT_APP_BANK_ID}?${param}`
    );
    return data;
};

export const userProfile = async() => {
    const { data } = await Axios.get(
        `${process.env.REACT_APP_API}account/profile/`
    );
    return data;
};

export const accountTiers = async () => {
  const { data } = await Axios.get(
    `${process.env.REACT_APP_API}account/tier/`
  );
  return data;
};

export const upgradeTier = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}account/tier-upgrade/`,
    body
  );
  return data;
};


export const transaction_history = async(account_no, page) => {
    const { data } = await Axios.get(
        `${process.env.REACT_APP_API}account/history/${process.env.REACT_APP_BANK_ID}?account_no=${account_no}&page_no=${page}`
    );
    return data;
};

export const getStatement = async(body) => {
    const { data } = await Axios.post(
        `${process.env.REACT_APP_API}account/statement/${process.env.REACT_APP_BANK_ID}`,
        body
    );
    return data;
};

export const getBeneficiaries = async(type, searchParam, page) => {
        const { data } = await Axios.get(
                `${process.env.REACT_APP_API}account/beneficiary/?beneficiary_type=${type}${
      searchParam && `&search=${searchParam}`
    }&page=${page}`
  );
  return data;
};

export const getAccountManager = async (account_no) => {
  const { data } = await Axios.get(
    `${process.env.REACT_APP_API}account/manager?account_no=${account_no}`
  );
  return data;
};

export const getAccountName = async (query_type, account_no, bank_code) => {
  const { data } = await Axios.get(
    `${process.env.REACT_APP_API}account/name-enquiry/${
      process.env.REACT_APP_BANK_ID
    }/?query_type=${query_type}&account_no=${account_no}${
      bank_code ? `&bank_code=${bank_code}` : ""
    }`
  );
  return data;
};

export const getAllBanks = async () => {
  const { data } = await Axios.get(
    `${process.env.REACT_APP_API}account/banks/`
  );
  return data;
};

export const getBankFlexBalance = async (phone_no) => {
  const { data } = await Axios.get(
    `${process.env.REACT_APP_API}account/bank-flex/${process.env.REACT_APP_BANK_ID}?phone_no=${phone_no}`
  );
  return data;
};

export const bankTransfer = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}account/transfer/${process.env.REACT_APP_BANK_ID}/`,
    body
  );
  console.log(data, 'kkklll')
  localStorage.setItem('transfer_data', JSON.stringify(data))
  return data;
};

export const createBeneficiary = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}account/beneficiary/`,
    body
  );
  return data;
};

export const getCustomersCard = async (account_no) => {
  const { data } = await Axios.get(
    `${process.env.REACT_APP_API}account/card/?account_no=${account_no}`
  );
  return data;
};

export const blockCard = async (body) => {
  const { data } = await Axios.put(
    `${process.env.REACT_APP_API}account/card/`,
    body
  );
  return data;
};

export const buyAirtimeAndData = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}bills/recharge/`,
    body
  );
  return data;
};

export const getdataPlans = async (provider) => {
  const { data } = await Axios.get(
    `${process.env.REACT_APP_API}bills/network/?network=${provider}`
  );
  return data;
};

export const getDiscos = async () => {
  const { data } = await Axios.get(
    `${process.env.REACT_APP_API}bills/electricity/`
  );
  return data;
};

export const buyElectricity = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}bills/electricity/`,
    body
  );
  return data;
};

export const getServices = async () => {
  const { data } = await Axios.get(
    `${process.env.REACT_APP_API}bills/cable/?service_type=cable`
  );
  return data;
};

export const validateUniqueNumber = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}bills/validate/`,
    body
  );
  return data;
};

export const getPackages = async (service_type) => {
  const { data } = await Axios.get(
    `${process.env.REACT_APP_API}bills/cable/${service_type.toLowerCase()}/`
  );
  return data;
};

export const cableTvSubscription = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}bills/cable/`,
    body
  );
  return data;
};

export const getFixedDeposit = async (phoneNumber) => {
  const { data } = await Axios.get(
    `${process.env.REACT_APP_API}account/fixed-deposit/1?phone_no=${phoneNumber}`
  );
  return data;
};

export const getProviders = async () => {
  const { data } = await Axios.get(
    `${process.env.REACT_APP_API}bills/betting/`
  );
  return data;
};

export const sportsBetting = async (body) => {
  const { data } = await Axios.post(
    `${process.env.REACT_APP_API}bills/betting/`,
    body
  );
  return data;
};
export const vendBetting = async (body) => {
  const {
    data
  } = await Axios.post(
    `${process.env.REACT_APP_API}bills/betting/`,
    body
  );
  return data;
};