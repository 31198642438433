import SlideInModal from 'components/base/SlideInModal/SlideInModal';
import LoadingAnimation from 'components/base/LoadingAnimation/LoadingAnimation';

const StatementLoading = ({ open, handleClose }) => {
  return (
    <SlideInModal
      open={open}
      handleClose={handleClose}
      sx={{ bgcolor: 'transparent', boxShadow: 'none' }}
    >
      <LoadingAnimation />
    </SlideInModal>
  );
};

export default StatementLoading;
