import Stack from '@mui/material/Stack';
import AccountsAndAdsSlider from 'components/UserDashboard/AccountsAndAdsSlider/AccountsAndAdsSlider';
import QuickServicesAndActivitiesStat from 'components/UserDashboard/QuickServicesAndActivitiesStat/QuickServicesAndActivitiesStat';
import RecentTransaction from 'components/UserDashboard/RecentTransaction/RecentTransaction';

const UserDashboard = () => {
  return (
    <Stack spacing={3}>
      <AccountsAndAdsSlider />
      <QuickServicesAndActivitiesStat />
      <RecentTransaction />
    </Stack>
  );
};

export default UserDashboard;
