import { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Switch from "components/base/Switch/Switch";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import Select from "components/base/Select/Select";
import { getCustomersCard, userProfile } from "services/dashboard";
import { useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import CardEnquiryCard from "components/base/CardEnquiryCard/CardEnquiryCard";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import LoadingAnimation from "components/base/LoadingAnimation/LoadingAnimation";
import { styles } from "./CardEnquiry.styles";

const CardEnquiry = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      account: "",
    },
  });

// get user profile
  const { data } = useQuery({
    queryKey: ["userProfile"],
    queryFn: userProfile,
    onSuccess: (data) => {
      formik.setFieldValue(
        "account",
        data.customer.accounts.map(({ account_no }) => account_no)[0]
      );
    },
    onError: (err) => {
      setMessage(err?.response?.data?.detail || err.message);
      setOpen(true);
    },
  });

  const accounts =
    data?.customer?.accounts?.map(({ account_no }) => account_no) || [];

    // get customer card info
  const account_no = formik.values.account || accounts[0];
  const { data: cardDetails, isLoading } = useQuery({
    queryKey: ["getCustomersCard", account_no],
    queryFn: () => getCustomersCard(account_no),
    onError: (err) => {
      setMessage(err?.response?.data?.detail || err.message);
      setOpen(true);
    },
    enabled: Boolean(account_no),
  });

  return (
    <Stack alignItems="center" sx={styles.background}>
      <DirectionSnackbar
        open={open}
        message={message}
        handleClose={() => {
          setOpen(false);
        }}
      />

      <Box sx={styles.container}>
        <Typography sx={styles.header}>Card Enquiry</Typography>

        <InputWithLabel label="Select Account">
          <Select
            options={accounts}
            placeholder="Select Account"
            name="account"
            value={formik.values.account}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>

        {typeof cardDetails?.detail === "object" &&
        cardDetails?.detail.length > 0 ? (
          <>
            <CardEnquiryCard />

            <Typography sx={styles.count}> 1 of 1</Typography>

            <Box sx={styles.actionBox}>
              <Typography sx={styles.cardName}>
                AKINNIYI SEGUN PETERS
              </Typography>

              <Stack direction="row" justifyContent="space-between" mt={3}>
                <Typography sx={styles.hashed}>4154 **** **** **85</Typography>
                <Switch
                  name="toggle"
                  label={true ? "ENABLED" : "DISABLED"}
                  disabled={true}
                  value={true}
                />
              </Stack>
            </Box>
          </>
        ) : (
          <Stack sx={styles.noCardIssued}>
            {isLoading ? (
              <LoadingAnimation />
            ) : (
              <Typography>NO CARD ISSUED FOR THIS ACCOUNT</Typography>
            )}
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default CardEnquiry;
