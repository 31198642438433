import CardEnquiryComponent from "components/CardEnquiry/CardEnquiry";

const CardEnquiry = () => {
  return (
    <>
      <CardEnquiryComponent />
    </>
  );
};

export default CardEnquiry;
