export const styles = {
  container: {
    borderRadius: "10px",
    // ml: { xs: "-4px", sm: "-9px" },
  },
  activitiesChart: {
    border: '1px solid #EFF0F6',
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.05)',
    borderRadius: {
      xs: '10px',
      md: '20px'
    },
    px: {
      xs: 1,
      sm: 3
    },
    py: 2,
    boxSizing: 'border-box',
  },
};