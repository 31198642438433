/** @format */

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import Select from "components/base/Select/Select";
import { ReactComponent as DownloadIcon } from "assets/svg/download-white.svg";
import { ReactComponent as ViewIcon } from "assets/svg/eye-white.svg";
//import { useSessionStorage } from "hooks/useSessionStorage";
import { styles } from "./StatementRequestForm.styles";
import { useContext } from "react";
import CorporateDashboardContext from "context/CorporateDashboardContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";


const StatementRequestForm = ({ formik, handleOpen }) => {
  //const { getSessionStorage } = useSessionStorage("__appUser");

  const { accountNumbers } = useContext(CorporateDashboardContext);

  return (
    <Box as="form" onSubmit={formik.handleSubmit} sx={styles.container}>
      <Typography sx={styles.header}>Statement Request</Typography>

      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 3, sm: 1, md: 2 }}
        mt={2}
      >
        <InputWithLabel>
          <Select
            options={accountNumbers}
            name="account"
            placeholder="Select Account"
            formik={formik}
            sx={styles.select}
          />
        </InputWithLabel>

        <InputWithLabel>
          <Box sx={styles.box}>
            <Typography sx={styles.placeholder}>From Date</Typography>
            <DatePicker
              selected={
                formik.values.dateFrom ? new Date(formik.values.dateFrom) : null
              }
              onChange={(date) =>
                formik.setFieldValue(
                  "dateFrom",
                  date ? format(date, "yyyy-MM-dd") : ""
                )
              }
              dateFormat="dd/MM/yyyy"
              calendarClassName="datePicker"
              className="datePicker"
            />
          </Box>
          {formik.errors.dateFrom && formik.touched.dateFrom && (
            <Typography sx={{ color: "red.main", fontSize: 12 }}>
              {formik.errors.dateFrom}
            </Typography>
          )}
        </InputWithLabel>

        <InputWithLabel>
          <Box sx={styles.box}>
            <Typography sx={styles.placeholder}>To Date</Typography>
            <DatePicker
              selected={
                formik.values.dateTo ? new Date(formik.values.dateTo) : null
              }
              onChange={(date) =>
                formik.setFieldValue(
                  "dateTo",
                  date ? format(date, "yyyy-MM-dd") : ""
                )
              }
              dateFormat="dd/MM/yyyy"
              calendarClassName="react-datepicker-calendar"
              className="datePicker"
            />
          </Box>
          {formik.errors.dateTo && formik.touched.dateTo && (
            <Typography sx={{ color: "red.main", fontSize: 12 }}>
              {formik.errors.dateTo}
            </Typography>
          )}
        </InputWithLabel>
      </Stack>

      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="center"
        spacing={{ xs: 3, md: 5 }}
        mt={4}
      >
        <Button
          variant="contained"
          type="button"
          color="green3"
          startIcon={<DownloadIcon />}
          sx={styles.btn}
          onClick={handleOpen}
        >
          Download Statement
        </Button>
        {/* <Button
          variant="contained"
          type="submit"
          color="green9"
          startIcon={<ViewIcon />}
          sx={styles.btn}
        >
          View Statement
        </Button> */}
      </Stack>
    </Box>
  );
};

export default StatementRequestForm;
