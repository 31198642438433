import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TablePagination from "components/base/TablePagination/TablePagination";
import TransactionsTable from "components/CorporateDashboard/TransactionsTable/TransactionsTable";
import { styles } from "./TransactionHistoryTable.styles";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import { Stack } from "@mui/material";
import CorporateDashboardContext from "context/CorporateDashboardContext";
import { useContext, useState } from "react";
import { transaction_history } from "services/corporateDashboard";
const TransactionHistoryTable = ({
  data,
  isLoading,
  setPage,
  page,
  currentPage,
  pagination,
}) => {
  const { accountNumbers } = useContext(CorporateDashboardContext);
  const customerAccountNumber = accountNumbers || [];

  const [newData, setNewData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getTransactions = (value) => {
    setLoading(true);
    transaction_history(value, currentPage)
      .then((res) => {
        console.log(res);
        setNewData(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const styles2 = {
    container: {
      width: { xs: "95%", sm: "90%", lg: "70%" },
      py: { xs: 4, sm: 4, lg: 6 },
      mx: "auto",
    },
    header: {
      fontSize: { xs: 18, sm: 20, md: 22 },
      fontWeight: 700,
    },
    input: {
      bgcolor: "#FBFBFB",
      border: "0.5px solid #D22F31",
      borderRadius: "5px",
      padding: "10px",
    },
    submitBtn: {
      width: { xs: "80%", sm: 300 },
      height: 55,
      alignSelf: "center",
      color: "white.main",
    },
  };
  console.log(customerAccountNumber);

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.header}>TRANSACTIONS</Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 3, sm: 4 }}
        sx={{ margin: "14px" }}
      >
        <InputWithLabel label="Account Source">
          <select
            onChange={(e) => {
              getTransactions(e.target.value.slice(0, 10), currentPage);
            }}
            name=""
            id=""
            style={styles2.input}
          >
            <option disabled selected>
              select account
            </option>
            {customerAccountNumber.map((item) => {
              return <option value={item}>{item}</option>;
            })}
          </select>
        </InputWithLabel>
      </Stack>

      <TransactionsTable
        data={newData.detail || data}
        loading={loading || isLoading}
      />

      <TablePagination
        pagination={newData.pagination || pagination}
        page={page}
        setPage={setPage}
      />
    </Box>
  );
};

export default TransactionHistoryTable;
