import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { corporateBeneficiariesTab } from "assets/data";
import { useSearchParams } from "react-router-dom";
import { styles } from "./BeneficiariesTab.styles";

const BeneficiariesTab = ({ tabOnChange }) => {
  const [param, setParam] = useSearchParams();
  const activeTab = param.get("tab");

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.header}>Beneficiaries</Typography>
      <Stack direction="row" spacing={{ xs: 2, sm: 0 }} sx={styles.tab}>
        {corporateBeneficiariesTab.map(({ label, searchParam }) => (
          <Typography
            key={label}
            sx={styles.tabItem(activeTab === searchParam)}
            onClick={() => {
              tabOnChange();
              setParam({ tab: searchParam });
            }}
          >
            {label}
          </Typography>
        ))}
      </Stack>
    </Box>
  );
};

export default BeneficiariesTab;
