import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import { validateNumbers } from "utils/validateNumbers";

const SetUpPinForm = ({ formik, getOtp, requestingOtp }) => {
  return (
    <>
      <InputWithLabel label="Account Number">
        <Input
          id="accountNumber"
          type="tel"
          name="accountNumber"
          placeholder="Enter your KC Account Number"
          value={formik.values.accountNumber}
          onChange={(event) => validateNumbers(event, 10, formik.handleChange)}
          formik={formik}
        />
      </InputWithLabel>

      <InputWithLabel label="Username">
        <Input
          id="username"
          name="username"
          placeholder="Set your username"
          value={formik.values.username}
          onChange={formik.handleChange}
          formik={formik}
        />
      </InputWithLabel>

      <InputWithLabel label="Transaction Pin">
        <Input
          id="transactionPin"
          type="password"
          name="transactionPin"
          placeholder="(e.g 1234)"
          value={formik.values.transactionPin}
          onChange={(event) => validateNumbers(event, 4, formik.handleChange)}
          formik={formik}
          sx={{ letterSpacing: "2px" }}
        />
      </InputWithLabel>

      <InputWithLabel label="Confirm Transaction Pin">
        <Input
          id="confirmTransactionPin"
          type="password"
          name="confirmTransactionPin"
          placeholder="(e.g 1234)"
          value={formik.values.confirmTransactionPin}
          onChange={(event) => validateNumbers(event, 4, formik.handleChange)}
          formik={formik}
          sx={{ letterSpacing: "2px" }}
        />
      </InputWithLabel>

      <InputWithLabel label="OTP">
        <Input
          id="otp"
          type="password"
          name="otp"
          placeholder="Enter OTP"
          value={formik.values.otp}
          onChange={(event) => validateNumbers(event, 6, formik.handleChange)}
          formik={formik}
          sx={{ letterSpacing: "2px" }}
          endAdornment={
            <InputAdornment position="end">
              <Button
                variant="outlined"
                color="green3"
                aria-label="toggle password visibility"
                onClick={getOtp}
                edge="end"
              >
                {requestingOtp ? "Sending OTP" : "Get OTP"}
              </Button>
            </InputAdornment>
          }
        />
      </InputWithLabel>
    </>
  );
};

export default SetUpPinForm;
