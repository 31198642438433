import * as React from "react";
import Box from "@mui/material/Box";
import OtherBanksBeneficiaries from "components/TransferToOtherBanks/OtherBanksBeneficiary/OtherBanksBeneficiaries";
import TransferToOtherBanksForm from "components/TransferToOtherBanks/TransferToOtherBanksForm/TransferToOtherBanksForm";
import { useFormik } from "formik";
import { TransferToOtherBanksSchema } from "services/Yup/TransferToOtherBanksSchema";

const TransferToOtherBanks = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpenModal = () => setOpen(true);

  const formik = useFormik({
    initialValues: {
      account: "",
      beneficiary_bank_name: "",
      beneficiary_account_no: "",
      beneficiary_account_name: "",
      amount: "",
      narration: "",
      saveBeneficiary: false,
      transaction_pin: "",
      nip_session_id: "",
    },
    validationSchema: TransferToOtherBanksSchema,
    onSubmit: () => {
      handleOpenModal();
    },
  });

  return (
    <Box
      sx={{
        bgcolor: "white.main",
        boxShadow: "0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)",
        borderRadius: "10.2375px",
        py: { xs: 3, sm: 5 },
      }}
    >
      <OtherBanksBeneficiaries formik={formik} />

      <TransferToOtherBanksForm formik={formik} open={open} setOpen={setOpen} />
    </Box>
  );
};

export default TransferToOtherBanks;
