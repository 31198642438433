import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ReactComponent as SendMoneyIcon } from "assets/svg/send-money.svg";
import { ReactComponent as AirtimeIcon } from "assets/svg/airtime&data.svg";
import { ReactComponent as ElectricityIcon } from "assets/svg/electricity.svg";
import { ReactComponent as CableTvIcon } from "assets/svg/cable-tv.svg";
import { ReactComponent as MyCardsIcon } from "assets/svg/my-cards.svg";
// import { ReactComponent as ChequesIcon } from "assets/svg/cheques.svg";
import { ReactComponent as BillPaymentIcon } from "assets/svg/bill-payment.svg";
import { ReactComponent as MoreIcon } from "assets/svg/more.svg";
import { Link } from "react-router-dom";
import { styles } from "./ServicesCard.styles";

const services = [
  {
    name: "Send Money",
    icon: <SendMoneyIcon />,
    color: "#FB7E36",
    href: "/transfer-other-banks",
  },
  {
    name: "Airtime & Data",
    icon: <AirtimeIcon />,
    color: "#5BCDF8",
    href: "/airtime-data/?q=airtime",
  },
  {
    name: "Electricity",
    icon: <ElectricityIcon />,
    color: "#FF5372",
    href: "/bills-payment/?tab=electricity-payment",
  },
  {
    name: "Cable TV",
    icon: <CableTvIcon />,
    color: "#05958C",
    href: "/bills-payment/?tab=cable-tv",
  },
  {
    name: "My Cards",
    icon: <MyCardsIcon />,
    color: "#6359E7",
    href: "/card-enquiry",
  },
  // {
  //   name: "Cheques",
  //   icon: <ChequesIcon />,
  //   color: "#8AC9FE",
  //   href: "/request-new-cheques-book",
  // },
  {
    name: "Bill Payments",
    icon: <BillPaymentIcon />,
    color: "#EBFFCD",
    href: "/bills-payment/?tab=electricity-payment",
  },
  {
    name: "More",
    icon: <MoreIcon />,
    color: "#E1FCEF",
    href: "/auth/profile",
  },
];

const ServicesCard = () => {
  return (
    <>
      <Typography sx={styles.header}>Quick Services</Typography>
      <Box sx={styles.services}>
        {services.map(({ name, icon, color, href }) => (
          <Link key={name} to={href}>
            <Box sx={styles.serviceItem}>
              <IconButton sx={styles.icon(color)}>{icon}</IconButton>
              <Typography sx={styles.serviceName}>{name}</Typography>
            </Box>
          </Link>
        ))}
      </Box>
    </>
  );
};

export default ServicesCard;
