import * as React from "react";
import Box from "@mui/material/Box";
import BeneficiariesTab from "components/CorporatePendingApprovals/BeneficiariesTab/BeneficiariesTab";
import TransactionHistoryTable from "components/CorporatePendingApprovals/TransactionHistoryTable/TransactionHistoryTable";
//import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { billPaymentsList, getBulkAirtime } from "services/corporateDashboard";
import { Axios } from "utils/Axios";
import { Button } from "@mui/material";
//import { sassTrue } from "sass";

const SavedBeneficiaries = () => {
  //eslint-disable-next-line
  const [searchInput, setSearchInput] = React.useState("");

  const [param] = useSearchParams();

  const currentTab = param.get("tab");

  /*   const { data } = useQuery({
    queryKey: ["beneficiaries", currentTab, searchInput],
    queryFn: () => getBeneficiaries(currentTab, searchInput),
  }); */

  //const [currentPage, setCurrentPage] = React.useState();

  const [type, setType] = React.useState(true);

  //eslint-disable-next-line
  /*   const { data: datum, loading } = useQuery({
    queryKey: ["bill_payment", currentPage],
    queryFn: () => billPaymentsList(currentTab),
  }); */
  const tabOnChange = () => {
    setSearchInput("");
  };

  /*  const handleChange = (event) => {
    setSearchInput(event.target.value);
  }; */

  const [airtimeData, setAirtimeData] = React.useState("");
  const [airtimeDataBukk, setAirtimeDataBulk] = React.useState("");
  const [dataData, setDataData] = React.useState("");
  const [cableData, setCableData] = React.useState("");
  const [electricityData, setElectricityData] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [loading3, setLoading3] = React.useState(false);
  const [loading4, setLoading4] = React.useState(false);
  const [loading5, setLoading5] = React.useState(false);

  const cable = async () => {
    setLoading(true);
    try {
      const data = await billPaymentsList("cable_tv&exclude=true");
      setCableData(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const electricity = async () => {
    setLoading2(true);
    try {
      setLoading2(false);
      const data = await billPaymentsList("electricity&exclude=true");
      setElectricityData(data);
    } catch (err) {
      setLoading2(false);
      console.log(err);
    }
  };
  const airtime = async () => {
    setLoading3(true);
    try {
      const data = await billPaymentsList("airtime&exclude=true");
      setAirtimeData(data);
      setLoading3(false);
    } catch (err) {
      setLoading3(false);
      console.log(err);
    }
  };
  const airtimeBulk = async () => {
    setLoading4(true);

    try {
      const data = await getBulkAirtime("?payment_type=airtime&exclude=true");
      setAirtimeDataBulk(data);
      setLoading4(false);
    } catch (err) {
      console.log(err);
      setLoading4(false);
    }
  };
  const nData = async () => {
    setLoading5(true);

    try {
      const data = await billPaymentsList("data&exclude=true");
      setDataData(data);
      setLoading5(false);
    } catch (err) {
      console.log(err);
      setLoading5(false);
    }
  };

  const sendReq1 = async (page) => {
    setLoading(true);

    Axios.get(page)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setCableData(res?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const sendReq2 = async (page) => {
    setLoading2(true);

    Axios.get(page)
      .then((res) => {
        console.log(res);
        setLoading2(false);
        setElectricityData(res?.data);
      })
      .catch((err) => {
        setLoading2(false);
        console.log(err);
      });
  };

  const sendReq3 = async (page) => {
    setLoading3(true);

    Axios.get(page)
      .then((res) => {
        console.log(res);
        setLoading2(false);
        setAirtimeData(res?.data);
      })
      .catch((err) => {
        setLoading2(false);
        console.log(err);
      });
  };

  const sendReq4 = async (page) => {
    setLoading4(true);
    Axios.get(page)
      .then((res) => {
        console.log(res);
        setLoading4(false);
        setDataData(res?.data);
      })
      .catch((err) => {
        setLoading4(false);
        console.log(err);
      });
  };

  const sendReq5 = async (page) => {
    setLoading5(true);

    Axios.get(page)
      .then((res) => {
        console.log(res);
        setLoading5(false);
        setAirtimeDataBulk(res?.data);
      })
      .catch((err) => {
        setLoading5(false);
        console.log(err);
      });
  };
  const container = {
    bgcolor: "white.main",
    borderRadius: "10px",
    Shadow: "0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)",
    overflow: "hidden",
    p: 2,
  };

  const addBeneficiartBtn = {
    width: { xs: "fit-content" },
    bgcolor: type ? "#d22f3245" : "none",
    marginRight: "10px",
    color: "#D22F31",
    fontSize: { xs: 14, lg: 16 },
    fontWeight: 600,
    boxShadow: "none",
    alignSelf: { xs: "flex-end", sm: "auto" },
    "&:hover": { bgcolor: "#d22f3245" },
    marginTop: "20px",
  };

  const addBeneficiartBtn2 = {
    width: { xs: "fit-content" },
    bgcolor: !type ? "#d22f3245" : "none",
    marginRight: "10px",
    color: "#D22F31",
    border: "1px solid green3.main",
    fontSize: { xs: 14, lg: 16 },
    fontWeight: 600,
    boxShadow: "none",
    alignSelf: { xs: "flex-end", sm: "auto" },
    "&:hover": { bgcolor: "#d22f3245" },
    marginTop: "20px",
  };
  React.useEffect(() => {
    billPaymentsList(currentTab);
    cable();
    electricity();
    airtime();
    airtimeBulk();
    nData();
  }, [currentTab]);

  return (
    <Box
      sx={{
        bgcolor: "white.main",
        boxShadow: "0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)",
        borderRadius: "10.2375px",
        py: { xs: 3, sm: 5 },
      }}
    >
      <BeneficiariesTab tabOnChange={tabOnChange} />
      {currentTab === "electricity" && (
        <TransactionHistoryTable
          dataLoading={loading2}
          dataHead={["NAME", "METER NO", "VENDER", "AMOUNT", "ACTIONS", ""]}
          dataData={electricityData}
          action={sendReq2}
          d
          //setPage={setPage}
        />
      )}

      {currentTab === "cable_tv" && (
        <TransactionHistoryTable
          dataLoading={loading}
          dataHead={["PHONE", "IUC NUMBER", "VENDOR", "AMOUNT", "ACTIONS", ""]}
          dataData={cableData}
          action={sendReq1}
          //setPage={setPage}
        />
      )}
      {currentTab === "airtime" && (
        <>
          <Box sx={container}>
            <Button
              onClick={() => {
                setType(true);
              }}
              sx={addBeneficiartBtn}
            >
              Single
            </Button>
            <Button
              onClick={() => {
                setType(false);
              }}
              sx={addBeneficiartBtn2}
            >
              Bulk
            </Button>
          </Box>
          {type ? (
            <TransactionHistoryTable
              dataLoading={loading3}
              type={type}
              dataHead={[
                "BENEFICIARY PHONE NO.",
                "NETWORK",
                "ACCOUNT FROM",
                "AMOUNT",
                "ACTIONS",
                "",
              ]}
              //setPage={setPage}
              dataData={airtimeData}
              action={sendReq3}
            />
          ) : (
            <TransactionHistoryTable
              dataLoading={loading4}
              type={type}
              dataHead={[
                "DESCRIPTION",
                "DATE",
                "Status",
                "AMOUNT",
                "ACTIONS",
                "",
              ]}
              //setPage={setPage}
              dataData={airtimeDataBukk}
              action={sendReq5}
            />
          )}
        </>
      )}
      {currentTab === "data" && (
        <TransactionHistoryTable
          dataLoading={loading5}
          dataHead={[
            "BENEFICIARY PHONE NO.",
            "NETWORK",
            "ACCOUNT FROM",
            "AMOUNT",
            "ACTIONS",
            "",
          ]}
          //setPage={setPage}
          dataData={dataData}
          action={sendReq4}
        />
      )}
      {/*   {currentTab === "church" && (
        <OtherBanksBeneficiaries
          searchInput={searchInput}
          handleChange={handleChange}
          tableData={data?.detail?.results || []}
          isLoading={isLoading}
        />
      )}
      {currentTab === "remita" && (
        <OtherBanksBeneficiaries
          searchInput={searchInput}
          handleChange={handleChange}
          tableData={data?.detail?.results || []}
          isLoading={isLoading}
        />
      )} */}
    </Box>
  );
};

export default SavedBeneficiaries;
