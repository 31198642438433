export const styles = {
  leftGrid: {
    height: '100vh',
    px: { xs: 2, sm: 3, lg: 4 },
    pt: 3,
  },
  brandLogo: { width: { xs: 125, sm: 125, md: 135 } },
  fixedBackground: {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: '100vh',
    bgcolor: 'green1.main',
  },
  rightGrid: {
    position: 'relative',
    top: 0,
    display: { xs: 'none', sm: 'block' },
    height: '100vh',
  },
  rightGridImgContainer: {
    width: { sm: 340, md: '40%' },
    position: 'absolute',
    top: '51%',
    transform: 'translate(-30%, -50%)',
  },
  dotsPosition: {
    position: 'absolute',
    content: "''",
    top: '20%',
    right: { xs: '-20%', md: '-25%', lg: '-12%' },
  },
  rightGridImg: {
    width: '100%',
  },
  bubble1: {
    position: 'absolute',
    left: '35%',
    bottom: '3%',
    width: 47,
    height: 47,
    borderRadius: '50%',
    border: '1px solid #ffffff80',
  },
  bubble2: {
    position: 'absolute',
    left: '36%',
    top: '3%',
    width: 27,
    height: 27,
    borderRadius: '50%',
    border: '1px solid #ffffff80',
  },
  bubble3: {
    position: 'absolute',
    left: '30%',
    top: '58%',
    width: 63,
    height: 63,
    borderRadius: '50%',
    border: '1px solid #ffffff80',
  },
  bubble4: {
    position: 'absolute',
    left: '7%',
    top: '3.5%',
    width: 39,
    height: 39,
    borderRadius: '50%',
    border: '1px solid #ffffff80',
  },
  bubble5: {
    position: 'absolute',
    left: '20%',
    bottom: '3%',
    width: 27,
    height: 27,
    borderRadius: '50%',
    border: '1px solid #ffffff80',
  },
  bubble6: {
    position: 'absolute',
    left: '10%',
    bottom: '8%',
    width: 15,
    height: 15,
    borderRadius: '50%',
    border: '1px solid #ffffff80',
  },
};
