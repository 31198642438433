import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import Select from "components/base/Select/Select";
import { gender } from "assets/data";
import { validateNumbers } from "utils/validateNumbers";

const SecondForm = ({ formik }) => {
  return (
    <>
      <InputWithLabel label="Gender">
        <Select
          options={gender}
          placeholder="Choose your Gender"
          name="gender"
          value={formik.values.gender}
          formik={formik}
        />
      </InputWithLabel>

      <InputWithLabel label="Date of Birth">
        <Input
          id="dob"
          name="dob"
          type="text"
          placeholder="Enter your Date of Birth"
          value={formik.values.dob}
          onChange={formik.handleChange}
          formik={formik}
          disabled
        />
      </InputWithLabel>

      <InputWithLabel label="NIN">
        <Input
          id="nin"
          name="nin"
          type="tel"
          placeholder="Enter your NIN"
          value={formik.values.nin}
          onChange={(event) => {
            validateNumbers(event, 11, formik.handleChange);
          }}
          formik={formik}
        />
      </InputWithLabel>

      <InputWithLabel label="Email Address">
        <Input
          id="email"
          name="email"
          type="email"
          placeholder="Enter your Email Address"
          value={formik.values.email}
          onChange={formik.handleChange}
          formik={formik}
        />
      </InputWithLabel>

      <InputWithLabel label="Residential Address">
        <Input
          id="residentialAddress"
          name="residentialAddress"
          placeholder="Enter your Residential Address"
          value={formik.values.residentialAddress}
          onChange={formik.handleChange}
          formik={formik}
        />
      </InputWithLabel>
    </>
  );
};

export default SecondForm;
