import Box from "@mui/material/Box";
import { styles } from "./Hamburger.styles";

const Hamburger = ({ open, toggleHamburger }) => {
  return (
    <Box
      sx={{
        position: "relative",
        cursor: "pointer",
        width: 25,
        height: 15,
      }}
      onClick={toggleHamburger}
    >
      <Box
        as="span"
        sx={{ ...styles.barStyle, top: 0, ...(open && styles.top) }}
      ></Box>

      <Box
        as="span"
        sx={{
          ...styles.barStyle,
          top: "calc(50% - 1px)",
          ...(open && styles.middle),
        }}
      ></Box>

      <Box
        as="span"
        sx={{ ...styles.barStyle, bottom: 0, ...(open && styles.bottom) }}
      ></Box>
    </Box>
  );
};

export default Hamburger;
