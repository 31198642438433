import Box from '@mui/material/Box';
import SelfAccountTransferForm from 'components/SelfAccountTransfer/SelfAccountTransferForm';

const SelfAccountTransfer = () => {
  return (
    <Box
      sx={{
        bgcolor: 'white.main',
        borderRadius: '10.2375px',
        boxShadow: '0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)',
      }}
    >
      <SelfAccountTransferForm />
    </Box>
  );
};

export default SelfAccountTransfer;
