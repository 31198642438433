import * as React from "react";
import AuthLayout from "layout/Auth/Auth.layout";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import EnterPhoneNumberForm from "components/ForgotPassword/EnterPhoneNumberForm";
import EnterOtpForm from "components/ForgotPassword/EnterOtpForm";
import SetUpPasswordForm from "components/Register/SetUpPasswordForm";
import ConfirmationModal from "components/base/ConfirmationModal/ConfirmationModal";
import {
  ValidatePhoneNumberSchema,
  ValidateOtpSchema,
  ValidatePasswordSchema,
} from "services/Yup/ForgotPasswordSchema";
import { resetOtp, forgotPassword } from "services/Auth";
import { useMutation } from "@tanstack/react-query";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import { useNavigate } from "react-router-dom";
import { styles } from "./ForgotPassword.styles";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [active, setActive] = React.useState(1);
  const [open, setOpen] = React.useState({
    otp: false,
    successfullyRegistered: false,
  });

  const [openError, setOpenError] = React.useState(false);
  const [otpError, setOtpError] = React.useState("");

  const closeError = () => {
    setOpenError(false);
  };

  const handleError = (err) => {
    const error =
      err?.response?.status === 500
        ? "Service unavailable. Try later"
        : err?.response?.data?.detail || err.message;
    setOtpError(error);
    setOpenError(true);
  };

  const handleOpen = (type) => setOpen((prevS) => ({ ...prevS, [type]: true }));
  const handleClose = (type) => {
    setOpen((prevS) => ({ ...prevS, [type]: false }));

    // route to login page on successful
    if (type === "successfullyRegistered") {
      navigate("/auth/sign-in");
    }
  };

  const navigateActiveForm = (tab) => {
    if (tab > active) {
      return;
    }

    setActive(tab);
  };

  const activeValidationSchema =
    active === 1
      ? ValidatePhoneNumberSchema
      : active === 2
      ? ValidateOtpSchema
      : ValidatePasswordSchema;

  // reset OTP mutation
  const { mutate: resetOTP, isLoading: fetchingOtp } = useMutation({
    mutationFn: resetOtp,
    onSuccess: () => {
      handleOpen("otp");
      setActive((prevS) => prevS + 1);
    },
    onError: (err) => {
      handleError(err);
    },
  });

  // forgot password reset mutation
  const { mutate: passwordReset, isLoading } = useMutation({
    mutationFn: forgotPassword,
    onSuccess: () => {
      handleOpen("successfullyRegistered");
    },
    onError: (err) => {
      handleError(err);
    },
  });

  const formik = useFormik({
    initialValues: {
      phone_number: "",
      username: "",
      otp: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: activeValidationSchema,
    onSubmit: (values) => {
      if (active <= 2) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });

        if (active === 1) {
          return resetOTP({
            username: values.username,
            phone_number: values.phone_number,
            reset_type: "password",
          });
        } else {
          return setActive((prevS) => prevS + 1);
        }
      } else {
        return passwordReset({
          username: values.username,
          otp: values.otp,
          phone_number: values.phone_number,
          new_password: values.password,
          confirm_password: values.confirmPassword,
        });
      }
    },
  });

  return (
    <AuthLayout image="/img/auth-2.webp">
      <DirectionSnackbar
        open={openError}
        handleClose={closeError}
        message={otpError}
      />

      <ConfirmationModal
        open={open.otp}
        handleClose={() => handleClose("otp")}
        mainText="OTP Sent"
        subText="An OTP has been sent to your Phone Number."
        btnText="Continue"
        handleClick={() => handleClose("otp")}
      />

      <ConfirmationModal
        open={open.successfullyRegistered}
        handleClose={() => handleClose("successfullyRegistered")}
        mainText="Success"
        subText="Password reset successful. Proceed to login"
        btnText="Continue"
        handleClick={() => handleClose("successfullyRegistered")}
      />

      <Box sx={styles.container}>
        <Typography sx={styles.header}>
          {active === 1
            ? "Forgot your Password?"
            : active === 2
            ? "Enter OTP"
            : "Enter New Password"}
        </Typography>
        <Typography sx={styles.subText}>
          {active === 1
            ? "Please enter your phone number below to recieve your password reset Link."
            : active === 2
            ? "Please enter the OTP sent to your phone number"
            : "Please create a secure password including the following critiria below."}
        </Typography>

        <Stack direction="row" spacing={1} mt={2} mb={5}>
          {[1, 2, 3].map((tab) => (
            <Box
              key={tab}
              sx={styles.progressIndicator(active, tab)}
              onClick={() => navigateActiveForm(tab)}
            />
          ))}
        </Stack>

        <Stack spacing={3} as="form" onSubmit={formik.handleSubmit}>
          {active === 1 && <EnterPhoneNumberForm formik={formik} />}
          {active === 2 && <EnterOtpForm formik={formik} />}
          {active === 3 && <SetUpPasswordForm formik={formik} />}

          <Button
            variant="contained"
            type="submit"
            color="green3"
            sx={styles.submitBtn}
          >
            {fetchingOtp || isLoading
              ? "Please wait..."
              : active === 3
              ? "Submit"
              : "Proceed"}
          </Button>
        </Stack>
      </Box>
    </AuthLayout>
  );
};

export default ForgotPassword;
