import * as React from "react";
import Box from "@mui/material/Box";
import CustomModal from "components/base/Modal/Modal";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import { useSearchParams } from "react-router-dom";
import ConfirmTransactionBill from "../ConfimTransactionBillPayment/ConfirmTransactionBill";
import EnterBillTransactionPin from "../EnterBillTransactionPin/EnterBillTransactionBill";
import TransactionBillSuccessful from "../TransactionBillSuccessful/TransactionBillSuccessful";

const BillPaymentTestModal = ({
  open,
  handleClose,
  step,
  handleConfirmTransactionBtn,
  handleTransfer,
  isLoading,
  openError,
  handleCloseError,
  errorMessage,
  formik,
  error,
  cardInfo,
}) => {
  const [param] = useSearchParams();

  const currentTab = param.get("tab");

  return (
    <CustomModal open={open} handleClose={handleClose}>
      <DirectionSnackbar
        open={openError}
        handleClose={handleCloseError}
        message={errorMessage}
      />

      <Box>
        {step === 0 && (
          <ConfirmTransactionBill
            formik={formik}
            handleClick={handleConfirmTransactionBtn}
            cardInfo={cardInfo}

          />
        )}

        {step === 1 && (
          <EnterBillTransactionPin
            handleConfirmTransactionBtn={handleConfirmTransactionBtn}
            handleClick={handleTransfer}
            isLoading={isLoading}
            formik={formik}
            error={error}
          />
        )}

        {step === 2 && <TransactionBillSuccessful handleClick={handleClose} />}
      </Box>
    </CustomModal>
  );
};

export default BillPaymentTestModal;
