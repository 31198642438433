import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styles } from "./UpgradeTier.styles";
import { Button } from "@mui/material";
import { upgradeTier, userProfile } from "services/dashboard";
import { useMutation, useQuery } from "@tanstack/react-query";
import DragAndDropToUpload from "components/CreateAccount/DragAndDropToUpload";
import { useFormik } from "formik";
import { UpgradeTierSchema } from "services/Yup/CreateAccountSchema";
import { upgradeTierBody } from "utils/createAccountBody";
import { useNavigate, useSearchParams } from "react-router-dom/dist";
import { stylesTier } from "pages/Auth/CreateAccount/CreateAccount.styles";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import { useState } from "react";
import ConfirmationModal from "components/base/ConfirmationModal/ConfirmationModal";

const UpgradeTier = ({ data }) => {
  /*   const getAccountBalance = (account_no) => {
    return data?.account_balances?.find(
      (item) => item.account_no === account_no
    );
  }; */

  const { data: profile } = useQuery({
    queryKey: ["userProfile"],
    queryFn: userProfile,
  });
  const navigate = useNavigate();

  const limits = profile?.customer?.limits;
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleError = (err) => {
    setError(err?.response?.data?.detail || err.message);
    setOpenError(true);
  };

  const closeError = () => {
    setOpenError(false);
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/account-tier");
  };
  console.log(limits);

  const { mutate, isLoading } = useMutation({
    mutationFn: upgradeTier,
    onSuccess: () => {
      handleOpen();
    },
    onError: (err) => {
      handleError(err);
    },
  });

  const [params] = useSearchParams();

  const formik = useFormik({
    initialValues: {
      tier_id: params.get("tierId"),
      utility_image: "",
      valid_id_image: "",
    },
    validationSchema: UpgradeTierSchema,
    onSubmit: (values) => {
      const body = upgradeTierBody({
        ...values,
      });

      mutate(body);
    },
  });

  return (
    <Box sx={styles.container}>
      <DirectionSnackbar
        open={openError}
        handleClose={closeError}
        message={error}
      />

      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        mainText="Success"
        subText="
        Your account upgrade is being processed."
        btnText="Continue"
        handleClick={handleClose}
      />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography sx={styles.header}>Tier Levels</Typography>
        </Box>

        {/* <Button sx={styles.addNewBtn}>+ Add new</Button> */}
      </Stack>

      <Box mt={2} sx={styles.cardContainer}>
        <DragAndDropToUpload
          styles={stylesTier}
          formik={formik}
          formikKey="utility_image"
          label="Utility Image"
        />
        <DragAndDropToUpload
          styles={stylesTier}
          formik={formik}
          formikKey="valid_id_image"
          label="ID Image"
        />
      </Box>

      <div
        style={{
          display: "grid",
          placeItems: "center",
        }}
      >
        <Button
          variant="contained"
          type="button"
          color="green3"
          sx={styles.btn}
          disabled={isLoading}
          onClick={formik.handleSubmit}
        >
          Upload
        </Button>
      </div>
    </Box>
  );
};

export default UpgradeTier;
