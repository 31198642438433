export const styles = {
  container: {
    bgcolor: 'white.main',
    borderRadius: '10px',
    Shadow: '0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)',
    overflow: 'hidden',
    pb: 3,
  },
  header: {
    fontSize: { xs: 16, sm: 18 },
    fontWeight: 700,
    mt: {xs: 3, sm: 4},
    ml: 2,
  },
};
