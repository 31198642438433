export const styles = {
  container: {
    bgcolor: "gray11.main",
    height: "100vh",
    overflowY: "auto",
    pb: {xs: 8, sm:5},
    boxSizing: "border-box",
  },
  brandLogo: {
    display: { xs: "block", sm: "block" },
    width: {xs: 65, lg: 130},
    mx: "auto",
  },
};
