import Box from "@mui/material/Box";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import { styles } from "./AdvertCarousel.styles";

const AdvertCarousel = () => {
  return (
    <Box sx={styles.container}>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <Box as="img" src="/img/banner.webp" alt="advert" sx={styles.img} />
        </SwiperSlide>
        <SwiperSlide>
          <Box as="img" src="/img/banner2.webp" alt="advert" sx={styles.img} />
        </SwiperSlide>
        <SwiperSlide>
          <Box as="img" src="/img/banner3.webp" alt="advert" sx={styles.img} />
        </SwiperSlide>
        <SwiperSlide>
          <Box as="img" src="/img/banner4.webp" alt="advert" sx={styles.img} />
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};

export default AdvertCarousel;
