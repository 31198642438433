import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ActivitiesChart from '../ActivitiesChart/ActivitiesChart';
import ActivitiesTimeFrame from '../ActivitiesTimeFrame/ActivitiesTimeFrame';
import ServicesCard from '../ServicesCard/ServicesCard';
import { styles } from './QuickServicesAndActivitiesStat.styles';

const QuickServicesAndActivitiesStat = () => {
  return (
    <Stack direction={{ xs: 'column', lg: 'row' }} spacing={2}>
      <Grid item xs={12} lg={5.5} pl={0}>
        <Box sx={styles.quickServices}>
          <ServicesCard />
        </Box>
      </Grid>
      <Grid item xs={12} lg={6.5}>
        <Box sx={styles.activitiesChart}>
          <Box>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              pb={1}
              mb={3}
              sx={{
                borderBottom: '1px solid #E4E5E7',
              }}
            >
              <Typography sx={styles.header}>Activity</Typography>
              <ActivitiesTimeFrame />
            </Stack>
            <ActivitiesChart />
          </Box>
        </Box>
      </Grid>
    </Stack>
  );
};

export default QuickServicesAndActivitiesStat;
