import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import { getDiscos } from "services/corporateDashboard";
import { useQuery } from "@tanstack/react-query";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import Select from "components/base/Select/Select";
import Switch from "components/base/Switch/Switch";
import { validateNumbers } from "utils/validateNumbers";

const ElectricityPaymentForm = ({
  formik,
  styles,
  validateMeterNumber,
  cardInfo,
  accounts,
}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  // get Biller
  const { data: discos } = useQuery({
    queryKey: ["getDiscos"],
    queryFn: getDiscos,
    onError: (err) => {
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
    },
  });

  const handleDiscoType = (value) => {
    const findSelectedDisco = discos?.detail?.find(
      ({ disco }) => disco === value
    );

    formik.setFieldValue("disco_type", findSelectedDisco.code);
  };

  const electricyBiller = discos?.detail?.map(({ disco }) => disco) || [];

  // reset form fields on mount
  useEffect(() => {
    formik.resetForm();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <DirectionSnackbar
        open={open}
        message={message}
        handleClose={() => {
          setOpen(false);
        }}
      />

      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Select Account">
          <Select
            options={accounts}
            placeholder="Select Account"
            name="account"
            value={formik.values.account}
            disableClearable={true}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>

        <InputWithLabel label="Select Biller">
          <Select
            options={electricyBiller}
            placeholder="Select Disco (e.g IKEDC)"
            name="disco_name"
            value={formik.values.disco_name}
            formik={formik}
            sx={styles.input}
            onChange={handleDiscoType}
          />
        </InputWithLabel>
      </Stack>

      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Amount">
          <Input
            id="amount"
            name="amount"
            type="tel"
            value={formik.values.amount}
            onChange={(event) => {
              validateNumbers(event, 1000, formik.handleChange);
            }}
            placeholder="Enter amount"
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>

        <InputWithLabel label="Meter Number">
          <Input
            type="tel"
            id="meter_no"
            name="meter_no"
            value={formik.values.meter_no}
            onChange={(event) => {
              validateNumbers(event, 50, formik.handleChange);
            }}
            placeholder="Enter meter number"
            formik={formik}
            sx={styles.input}
            onBlur={validateMeterNumber}
          />
          <Typography sx={{ textTransform: "capitalize", fontSize: 12 }}>
            {cardInfo?.detail?.name?.toLowerCase()}
          </Typography>
        </InputWithLabel>
      </Stack>
      <InputWithLabel label="Phone Number">
        <Input
          type="text"
          id="phone_number"
          name="phone_number"
          value={formik.values.phone_number}
          placeholder="Enter Phone Number"
          formik={formik}
          sx={styles.input}
          onChange={formik.handleChange}
        />
      </InputWithLabel>

      <Stack
        sx={{
          transform: "translateY(-1rem)",
          pb: 5,
        }}
      >
        <Switch
          name="saveBeneficiary"
          label="Save as beneficiary"
          value={formik.values.saveBeneficiary}
          onChange={formik.handleChange}
        />
      </Stack>
    </>
  );
};

export default ElectricityPaymentForm;
