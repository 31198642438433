import { useState } from "react";
import { useFormik } from "formik";
import StatementRequestForm from "components/CorporateAccountStatement/StatementRequestForm/StatementRequestForm";
import StatementLoading from "components/CorporateAccountStatement/StatementLoading/StatementLoading";
import DownloadStatement from "components/CorporateAccountStatement/DownloadStatement/DownloadStatement";
import StatementSummary from "components/CorporateAccountStatement/StatementSummary/StatementSummary";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import { AccountStatementSchema } from "services/Yup/AccountStatementSchema";
import { getStatement } from "services/corporateDashboard";
import { useMutation } from "@tanstack/react-query";

// const download = (file) => {
//   fetch(file).then((t) => {
//     return t.blob().then((b) => {
//       var a = document.createElement("a");
//       a.href = URL.createObjectURL(b);
//       a.setAttribute(
//         "download",
//         `Account_statement_${new Date().toLocaleDateString()}.pdf`
//       );
//       a.click();
//     });
//   });
// };

function download(fileUrl) {
  // Create a link element
  const link = document.createElement('a');
  link.href = fileUrl;
  
  // Set the download attribute with a filename
  const filename = fileUrl.split('/').pop() || 'Account statement.pdf';
  link.download = filename;
  
  // Append to the document, click, and remove
  document.body.appendChild(link);
  link.click();
link.target= '_blank'
  document.body.removeChild(link);
}

const AccountStatement = () => {
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleOpenError = () => {
    setOpenError(true);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const [state, setState] = useState({
    downloadAs: "As PDF",
    activeModal: 0,
  });

  const { mutate, data, isLoading } = useMutation({
    mutationFn: getStatement,
    onSuccess: (data) => {
      console.log(data);
    },
    onError: (err) => {
      setErrorMessage(err?.response?.data?.detail || err.message);
      handleOpenError();
    },
  });

  const { mutate: sendToEmail } = useMutation({
    mutationFn: getStatement,
    onSuccess: () => {
      handleState("activeModal", 3);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const { mutate: downloadAsPdf } = useMutation({
    mutationFn: getStatement,
    onSuccess: (data) => {
      download(data.detail);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleState = (key, value) => {
    if (value === "Send to email") {
      return setState((prevS) => ({
        ...prevS,
        activeModal: 2,
      }));
    }
    setState((prevS) => ({ ...prevS, [key]: value }));
  };

  const statementIsDownloading = () => {
    handleState("activeModal", 1);
    handleOpen();
  };

  const handleClose = () => {
    setOpen(false);
    setState((prevS) => ({
      ...prevS,
      downloadAs: "As PDF",
      activeModal: 0,
    }));
  };

  const handleOpen = () => {
    formik.validateForm().then((data) => {
      if (!Object.keys(data).length) {
        setOpen(true);
      } else {
        formik.setTouched({
          account: true,
          dateFrom: true,
          dateTo: true,
        });
      }
    });
  };

  const downloadStatementAsPdf = () => {
    downloadAsPdf({
      date_from: formik.values.dateFrom,
      date_to: formik.values.dateTo,
      account_no: formik.values.account?.slice(0, 10),
      download: true,
      account_type: "corporate",
    });
    statementIsDownloading();
  };

  const formik = useFormik({
    initialValues: {
      account: "",
      dateFrom: "",
      dateTo: "",
    },
    validationSchema: AccountStatementSchema,
    onSubmit: (values) => {
      mutate({
        date_from: values.dateFrom,
        date_to: values.dateTo,
        account_no: values.account?.slice(0, 10),
        download: false,
        account_type: "corporate",
      });
    },
  });

  const sendToEmailForm = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      // handleState('activeModal', 3);
      sendToEmail({
        date_from: formik.values.dateFrom,
        date_to: formik.values.dateTo,
        account_no: formik.values.account?.slice(0, 10),
        email: values.email,
        account_type: "corporate",
      });
    },
  });

  return (
    <div>
      <DirectionSnackbar
        open={openError}
        handleClose={handleCloseError}
        message={errorMessage}
      />

      <StatementRequestForm formik={formik} handleOpen={handleOpen} />

      <StatementLoading open={isLoading} handleClose={() => {}} />

      <DownloadStatement
        open={open}
        handleClose={handleClose}
        state={state}
        handleState={handleState}
        formik={sendToEmailForm}
        email={sendToEmailForm.values.email}
        downloadStatementAsPdf={downloadStatementAsPdf}
      />

      {data && (
        <StatementSummary
          showModal={statementIsDownloading}
          downloadStatementAsPdf={downloadStatementAsPdf}
          data={data}
        />
      )}
    </div>
  );
};

export default AccountStatement;
