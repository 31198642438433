import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputWithLabel from 'components/base/InputWithLabel/InputWithLabel';
import Input from 'components/base/Input/Input';
import CustomModal from 'components/base/Modal/Modal';
import Select from 'components/base/Select/Select';
import DirectionSnackbar from 'components/base/SnackBar/SnackBar';
import { styles } from 'components/SavedBeneficiaries/AddNewCitmfbBeneficiaryModal/AddNewCitmfbBeneficiaryModal.styles';

const AddNewBillsPaymentsBeneficiariesModal = ({
  open,
  handleClose,
  step,
  isLoading,
  error,
  formik,
}) => {
  return (
    <CustomModal
      open={open.beneficiaries}
      handleClose={() => handleClose('beneficiaries')}
    >
      <DirectionSnackbar
        open={open.error}
        handleClose={() => handleClose('error')}
        message={error}
      />

      <Box sx={styles.container} as='form' onSubmit={formik.handleSubmit}>
        {step === 0 && (
          <Stack spacing={3} sx={styles.content} alignItems='center'>
            <Typography sx={styles.header}>Add New Beneficiary</Typography>

            <InputWithLabel label='Biller'>
              <Select
                options={[
                  'EKEDC',
                  'Ikeja Electric',
                  'DSTV',
                  'GOTV',
                  'Startimes',
                ]}
                id='biller'
                name='biller'
                value={formik.values.biller}
                placeholder='Select Biller'
                formik={formik}
              />
            </InputWithLabel>

            <InputWithLabel label='Customer ID'>
              <Input
                id='customerID'
                name='customerID'
                value={formik.values.customerID}
                onChange={formik.handleChange}
                placeholder='Enter customer ID'
                formik={formik}
              />
            </InputWithLabel>

            <Box mx='auto' width='fit-content' mt={{ xs: 3, sm: 5 }}>
              <Button
                type='submit'
                variant='contained'
                color='green3'
                sx={styles.btn}
                disabled={isLoading}
              >
                {isLoading ? 'Please wait' : 'Add Beneficiary'}
              </Button>
            </Box>
          </Stack>
        )}

        {step === 1 && (
          <Box>
            <Box
              as='img'
              src='/img/successful_gif.gif'
              alt='successful'
              sx={styles.gif}
            />
            <Stack spacing={3} sx={styles.content} alignItems='center'>
              <Typography sx={styles.header}>Done!</Typography>
              <Typography sx={styles.subText}>
                Beneficiary has been created successfully
              </Typography>
            </Stack>

            <Box mx='auto' width='fit-content' mt={{ xs: 3, sm: 5 }}>
              <Button
                variant='contained'
                color='green3'
                sx={styles.btn}
                onClick={() => handleClose('beneficiaries')}
              >
                Done
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </CustomModal>
  );
};

export default AddNewBillsPaymentsBeneficiariesModal;
