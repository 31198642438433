import Box from "@mui/material/Box";
//import Typography from "@mui/material/Typography";
import TablePagination from "components/base/TablePagination2/TablePagination";
import TransactionsTableElectricity from "../TransactionsTableElectricity/TransactionsTable";
import TransactionsTableCable from "../TransactionsTableCable/TransactionsTable";
import TransactionsTableAirtime from "../TransactionsTableAirtime/TransactionsTable";
import TransactionsTableData from "../TransactionsTableData/TransactionsTable";
import { styles } from "./TransactionHistoryTable.styles";
import { useSearchParams } from "react-router-dom";
//import { useQuery } from "@tanstack/react-query";
//import { getTransferReq } from "services/corporateDashboard";
//import { useState } from "react";

const TransactionHistoryTable = ({
  dataHead,
  dataData,
  dataLoading,
  action,
  type,
}) => {
  //

  const [param] = useSearchParams();
  const activeTab = param.get("tab");
  console.log(dataData?.results);
  return (
    <Box sx={styles.container}>
      {activeTab === "electricity" && (
        <TransactionsTableElectricity
          data={dataData?.results}
          dataHead={dataHead}
          loading={dataLoading}
        />
      )}

      {activeTab === "cable_tv" && (
        <TransactionsTableCable
          data={dataData?.results}
          dataHead={dataHead}
          loading={dataLoading}
        />
      )}

      {activeTab === "airtime" && (
        <TransactionsTableAirtime
          type={type}
          data={dataData?.results}
          dataHead={dataHead}
          loading={dataLoading}
        />
      )}

      {activeTab === "data" && (
        <TransactionsTableData
          data={dataData?.results}
          dataHead={dataHead}
          loading={dataLoading}
        />
      )}

      <TablePagination transactions={dataData} action={action} />
    </Box>
  );
};

export default TransactionHistoryTable;
