import * as React from "react";
import Box from "@mui/material/Box";
import BulkTransfer from "components/CorporateTransferToCit/BulkTransfer/TransferToCitForm";
import SingleTransfer from "components/CorporateTransferToCit/SingleTransfer/TransferToCitForm";
import { useFormik } from "formik";
import { TransferToCitSchema } from "services/Yup/TransferToCitSchema";
//import { Stack, Typography } from "@mui/material";
import MiniTab from "../components/MiniTab/MiniTab";
import { corporateTransferTab } from "assets/data";
import { useSearchParams } from "react-router-dom";

const TransferToCit = () => {
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [param] = useSearchParams();
  const activeTab = param.get("tab");

  const handleOpenModal = () => setOpen(true);

  const formik = useFormik({
    initialValues: {
      account: "",
      amount: "",
      beneficiary_account_no: "",
      beneficiary_account_name: "",
      narration: "",
      saveBeneficiary: false,
      transaction_pin: "",
    },
    validationSchema: TransferToCitSchema,
    onSubmit: () => {
      handleOpenModal();
    },
  });

  React.useEffect(() => {
    console.log(query);
  }, [query]);

  return (
    <Box
      sx={{
        bgcolor: "white.main",
        boxShadow: "0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)",
        borderRadius: "10.2375px",
        py: { xs: 3, sm: 5 },
      }}
    >
      <MiniTab
        title="Transfer to KCMFB A/C"
        tabOnChange={() => {
          setQuery("");
        }}
        data={corporateTransferTab}
      />

      {activeTab === "single" ? (
        <SingleTransfer formik={formik} open={open} setOpen={setOpen} />
      ) : (
        <BulkTransfer formik={formik} />
      )}
    </Box>
  );
};

export default TransferToCit;
