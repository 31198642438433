export const styles = {
  container: {
    bgcolor: 'white.main',
    boxShadow: '0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)',
    borderRadius: '10.2375px',
    px: { xs: 2, sm: 3 },
    py: { xs: 1, sm: 2 },
  },
  header: {
    fontSize: { xs: 16, sm: 18 },
    fontWeight: 700,
    color: 'green3.main',
    mb: 3,
  },
  loadingAnimation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30vh',
  },
  tableHeader: { bgcolor: 'rgba(247, 249, 252, 0.8)' },
  headerText: { fontSize: 12, fontWeight: 600 },
  contentText: {
    fontSize: { xs: 12, sm: 12 },
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  status: (color, bg) => ({
    width: 'fit-content',
    fontSize: 12,
    bgcolor: bg,
    color: color,
    py: '2px',
    px: '4px',
    borderRadius: '4px',
    listStyle: 'none',
    paddingLeft: '1.3rem',
    '&::before': {
      content: "''",
      display: 'inline-block',
      ml: '-1.3em',
      mr: 2,
      width: 6,
      height: 6,
      borderRadius: '50%',
      bgcolor: color,
    },
  }),
  textColor: (color) => ({ color: color }),
};
