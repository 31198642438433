import Box from "@mui/material/Box";
import ProfileBanner from "components/Profile/ProfileBanner/ProfileBanner";
import ProfileForm from "components/Profile/ProfileForm/ProfileForm";
import { userProfile } from "services/dashboard";
import { useQuery } from "@tanstack/react-query";
import { styles } from "./Profile.styles";

const Profile = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["profile"],
    queryFn: userProfile,
  });
  
  return (
    <Box sx={styles.container}>
      <ProfileBanner data={data} isLoading={isLoading} />
      <ProfileForm data={data} isLoading={isLoading} />
    </Box>
  );
};

export default Profile;
