import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as ArrowIcon } from "assets/svg/arrow-right.svg";
import { ReactComponent as DashboardIcon } from "assets/svg/dashboard.svg";
import { styled } from "@mui/material";
import { getBankFlexBalance, userProfile } from "services/dashboard";
import { useQuery } from "@tanstack/react-query";
import { NavLink, useLocation } from "react-router-dom";
import { styles } from "./SidebarNavItem.styles";

const Dashboard = styled(DashboardIcon)(() => ({}));
const Arrow = styled(ArrowIcon)(() => ({}));
const Link = styled(NavLink)(() => ({}));

const SidebarNavItem = ({
  categoryName,
  subMenu,
  paths,
  toggleHamburger,
  activeTab,
  updateActiveTab,
}) => {
  const match = useMediaQuery("(max-width:600px)");

  const { pathname, search } = useLocation();
  const isActiveTab = paths.includes(pathname);

  const closeSideBarOnMobile = () => {
    if (match) {
      toggleHamburger();
    }
  };

  const toggleSubMenu = (tab) => {
    if (!subMenu.length) {
      toggleHamburger();
    }

    updateActiveTab(tab);
  };

  // get user profile
  const { data } = useQuery({
    queryKey: ["userProfile"],
    queryFn: userProfile,
  });

  const phone_no = data?.customer?.phone_number;
  const { data: bankFlexBal } = useQuery({
    queryKey: ["bankFlexBal", phone_no],
    queryFn: () => getBankFlexBalance(phone_no),
    enabled: Boolean(data?.customer?.phone_number),
  });

  return (
    <List
      component="div"
      aria-labelledby="nested-list"
      sx={styles.container(isActiveTab)}
    >
      <Link
        to={subMenu.length ? subMenu[0]?.href : paths[0]}
        sx={styles.text(isActiveTab)}
      >
        <ListItemButton
          sx={styles.navBtn(isActiveTab)}
          onClick={() => toggleSubMenu(categoryName)}
        >
          <Dashboard sx={styles.icon(isActiveTab)} />
          <ListItemText>
            <Typography
              sx={{
                fontSize: { xs: 15, fontWeight: 500 },
              }}
            >
              {categoryName}
            </Typography>
          </ListItemText>
          {subMenu.length > 0 && <Arrow sx={styles.arrow(isActiveTab)} />}
        </ListItemButton>
      </Link>
      {subMenu.length > 0 && (
        <Collapse
          in={isActiveTab && activeTab === categoryName}
          timeout="auto"
          unmountOnExit
          sx={styles.collapse}
        >
          <List component="div" disablePadding>
            <Typography as="ul" mt={3} onClick={closeSideBarOnMobile}>
              {subMenu.map(({ label, href, searchQuery, hasBankflex }) => (
                <NavLink
                  key={label}
                  to={href}
                  style={({ isActive }) => ({
                    color: searchQuery
                      ? searchQuery === search
                        ? "#D22F31"
                        : "#2b1616"
                      : isActive
                      ? "#D22F31"
                      : "#2b1616",
                    display: hasBankflex && !bankFlexBal && "none",
                  })}
                >
                  <Typography as="li" sx={styles.subMenuLink}>
                    {label}
                  </Typography>
                </NavLink>
              ))}
            </Typography>
          </List>
        </Collapse>
      )}
    </List>
  );
};

export default SidebarNavItem;
