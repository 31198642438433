import React, { useState } from "react";
import TransactionHistoryTable from "components/CorporateApprovalStatus/TransactionHistoryTable/TransactionHistoryTable";
import { useQuery } from "@tanstack/react-query";
import { Link, useSearchParams } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";
//import TransactionHistoryTablee from "components/CorporatePendingApprovals/TransactionHistoryTable/TransactionHistoryTable";
import { approvalStatusTab } from "assets/data";
import {
  getBulkTransferReq,
  getTransferReq,
  billPaymentsList,
  getBulkAirtime,
} from "services/corporateDashboard";
import { Axios } from "utils/Axios";
const TransactionHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [type, setType] = useState(true);

  const [param] = useSearchParams();
  const activeTab = param.get("tab");
  // get user profile

  //const customerAccountNumber = [];

  /*   const { data, isLoading } = useQuery({
    enabled: Boolean(customerAccountNumber),
  }); */

  const [datum, setData] = useState();

  const sendReq = async (page) => {
    Axios.get(page)
      .then((res) => {
        console.log(res);
        setData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [datum2, setData2] = useState();

  const sendReq2 = async (page) => {
    Axios.get(page)
      .then((res) => {
        console.log(res);
        setData2(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { data, loading } = useQuery({
    queryKey: ["transfer_req", currentPage],
    queryFn: () => getTransferReq(""),
  });

  const { data: data2, loading: loading2 } = useQuery({
    queryKey: ["bulk_req", currentPage],
    queryFn: () => getBulkTransferReq(""),
  });
  console.log(data?.results);
  const setPage = (page) => {
    setCurrentPage(page);
  };

  const styles = {
    tabContainer: {
      width: "100%",
      borderBottom: "1px solid #ABABAB",
      overflow: "hidden",
    },
    tab: {
      flexWrap: "nowrap",
      width: { lg: "85%" },
      px: { xs: 2, lg: 0 },
      mx: "auto",
      overflowX: "auto",
    },
    category: (active) => ({
      fontSize: { xs: 13, lg: 14 },
      fontWeight: 600,
      color: active ? "green3.main" : "#888686",
      borderBottom: active ? "1px solid #D22F31" : "1px solid transparent",
      px: { xs: 2, sm: 3, lg: 4 },
      pb: "5px",
      cursor: "pointer",
      minWidth: "max-content",
    }),
  };

  const [airtimeDataBulk, setAirtimeDataBulk] = React.useState("");
  const [airtimeData, setAirtimeData] = React.useState("");
  const [dataData, setDataData] = React.useState("");
  const [cableData, setCableData] = React.useState("");
  const [electricityData, setElectricityData] = React.useState("");
  const cable = async () => {
    try {
      const data = await billPaymentsList("cable_tv");
      setCableData(data);
    } catch (err) {
      console.log(err);
    }
  };
  const electricity = async () => {
    try {
      const data = await billPaymentsList("electricity");
      setElectricityData(data);
    } catch (err) {
      console.log(err);
    }
  };
  const airtime = async () => {
    try {
      const data = await billPaymentsList("airtime");
      setAirtimeData(data);
    } catch (err) {
      console.log(err);
    }
  };
  const airtimeBulk = async () => {
    try {
      const data = await getBulkAirtime("?payment_type=airtime");
      setAirtimeDataBulk(data);
    } catch (err) {
      console.log(err);
    }
  };
  const nData = async () => {
    try {
      const data = await billPaymentsList("data");
      setDataData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const sendReq1 = async (page) => {
    Axios.get(page)
      .then((res) => {
        console.log(res);
        setCableData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendReqele = async (page) => {
    Axios.get(page)
      .then((res) => {
        console.log(res);
        setElectricityData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendReq3 = async (page) => {
    Axios.get(page)
      .then((res) => {
        console.log(res);
        setAirtimeData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendReq4 = async (page) => {
    Axios.get(page)
      .then((res) => {
        console.log(res);
        setDataData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendReq5 = async (page) => {
    Axios.get(page)
      .then((res) => {
        console.log(res);
        setAirtimeDataBulk(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const container = {
    bgcolor: "white.main",
    borderRadius: "10px",
    Shadow: "0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)",
    overflow: "hidden",
    p: 2,
  };

  const addBeneficiartBtn = {
    width: { xs: "fit-content" },
    bgcolor: type ? "#d22f3245" : "none",
    marginRight: "10px",
    color: "#D22F31",
    fontSize: { xs: 14, lg: 16 },
    fontWeight: 600,
    boxShadow: "none",
    alignSelf: { xs: "flex-end", sm: "auto" },
    "&:hover": { bgcolor: "#d22f3245" },
    marginTop: "20px",
  };

  const addBeneficiartBtn2 = {
    width: { xs: "fit-content" },
    bgcolor: !type ? "#d22f3245" : "none",
    marginRight: "10px",
    color: "#D22F31",
    border: "1px solid green3.main",
    fontSize: { xs: 14, lg: 16 },
    fontWeight: 600,
    boxShadow: "none",
    alignSelf: { xs: "flex-end", sm: "auto" },
    "&:hover": { bgcolor: "#d22f3245" },
    marginTop: "20px",
  };
  React.useEffect(() => {
    //billPaymentsList(activeTab);
    cable();
    electricity();
    airtime();
    airtimeBulk();
    nData();
    //eslint-disable-next-line
  }, []);
  return (
    <Box
      sx={{
        bgcolor: "white.main",
        boxShadow: "0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)",
        borderRadius: "10.2375px",
        py: { xs: 3, sm: 5 },
      }}
    >
      <Box sx={styles.tabContainer}>
        <Stack direction="row" alignItems="center" sx={styles.tab}>
          {approvalStatusTab.map(({ label, href }, index) => (
            <Link key={index} to={href}>
              <Typography
                sx={styles.category(
                  label
                    .toLowerCase()
                    .includes(activeTab?.toLowerCase().split("_")[0])
                )}
              >
                {label}
              </Typography>
            </Link>
          ))}
        </Stack>
      </Box>
      {activeTab === "single" && (
        <TransactionHistoryTable
          data={datum || data}
          isLoading={loading}
          pagination={data?.pagination}
          setPage={setPage}
          action={sendReq}
          dataHead={[
            "NAME",
            "BANK NAME",
            "BANK ACCOUNT",
            "AMOUNT",
            "STATUS",
            "ACTIONS",
          ]}
        />
      )}{" "}
      {activeTab === "bulk" && (
        <TransactionHistoryTable
          data={datum2 || data2}
          isLoading={loading2}
          pagination={data2?.pagination}
          setPage={setPage}
          action={sendReq2}
          dataHead={[
            "DESCRIPTION",
            "INSTITUTION",
            "ID",
            "AMOUNT",
            "STATUS",
            "ACTIONS",
          ]}
        />
      )}
      {activeTab === "electricity" && (
        <TransactionHistoryTable
          setCurrentPage={setCurrentPage}
          dataLoading={loading}
          dataHead={["NAME", "METER NO", "VENDER", "AMOUNT", "ACTIONS", ""]}
          data={electricityData}
          action={sendReqele}
          //setPage={setPage}
        />
      )}
      {activeTab === "cable_tv" && (
        <TransactionHistoryTable
          dataLoading={loading}
          dataHead={[
            "PHONE",
            "SMART CARD NUMBER",
            "VENDOR",
            "AMOUNT",
            "ACTIONS",
            "",
          ]}
          data={cableData}
          action={sendReq1}
          //setPage={setPage}
        />
      )}
      {activeTab === "airtime" && (
        <>
          <Box sx={container}>
            <Button
              onClick={() => {
                setType(true);
              }}
              sx={addBeneficiartBtn}
            >
              Single
            </Button>
            <Button
              onClick={() => {
                setType(false);
              }}
              sx={addBeneficiartBtn2}
            >
              Bulk
            </Button>
          </Box>
          {type ? (
            <TransactionHistoryTable
              dataLoading={loading}
              dataHead={[
                "BENEFICIARY PHONE NO.",
                "NETWORK",
                "ACCOUNT FROM",
                "AMOUNT",
                "ACTIONS",
                "",
              ]}
              //setPage={setPage}
              data={airtimeData}
              action={sendReq3}
            />
          ) : (
            <TransactionHistoryTable
              dataLoading={loading}
              dataHead={[
                "DESCRIPTION",
                "DATE",
                "Status",
                "AMOUNT",
                "ACTIONS",
                "",
              ]}
              //setPage={setPage}
              data={airtimeDataBulk}
              action={sendReq5}
            />
          )}
        </>
      )}
      {activeTab === "data" && (
        <TransactionHistoryTable
          dataLoading={loading}
          dataHead={[
            "BENEFICIARY PHONE NO.",
            "NETWORK",
            "ACCOUNT FROM",
            "AMOUNT",
            "ACTIONS",
            "",
          ]}
          //setPage={setPage}
          data={dataData}
          action={sendReq4}
        />
      )}
    </Box>
  );
};

export default TransactionHistory;
