import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ReactComponent as SimIcon } from 'assets/svg/sim.svg';
import { ReactComponent as SignalIcon } from 'assets/svg/signal.svg';
import { styles } from './CardEnquiryCard.styles';

const Sim = styled(SimIcon)({});
const Signal = styled(SignalIcon)({});

const CardEnquiryCard = () => {
  return (
    <Box sx={styles.card}>
      <Stack direction='row' alignItems='center' spacing={1}>
        <Box
          as='img'
          src='img/brand-logo.webp'
          alt='bank logo'
          sx={styles.brandLogo}
        />
        <Typography sx={styles.bankName}>KC MICROFINANCE BANK</Typography>
        <Signal sx={styles.signal} />
      </Stack>

      <Sim sx={styles.sim} className='green-stroke' />

      <Typography sx={styles.cardNumber}>4521 8547 5325 7842</Typography>

      <Stack direction='row' alignItems='center' spacing={1} mt={{ xs: 1, sm: 2 }}>
        <Box>
          <Typography sx={styles.validText}>Valid</Typography>
          <Typography sx={styles.validText}>Thru</Typography>
        </Box>
        <Typography sx={styles.validDate}>08/24</Typography>
      </Stack>

      <Stack direction='row' justifyContent='space-between' mt={{ xs: 1, sm: 2 }}>
        <Typography sx={styles.cardName}>Akinniyi Segun Peters</Typography>
        <Box sx={styles.mastercard}></Box>
      </Stack>
    </Box>
  );
};

export default CardEnquiryCard;
