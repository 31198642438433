export const styles = {
  container: {
    maxWidth: { xs: '95%', sm: '80%', lg: '75%' },
    mx: 'auto',
    mt: { xs: 5, md: 6 },
  },
  input: {
    backgroundColor: 'rgba(180, 246, 215, 0.05)',
    borderColor: '#D22F31',
  },
  submitBtn: {
    width: { xs: "80%", sm: 300 },
    height: 55,
    alignSelf: 'center',
    color: 'white.main',
  },
};
