import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Input from 'components/base/Input/Input';
import InputAdornment from '@mui/material/InputAdornment';
import DeleteCitmfbBeneficiaryModal from 'components/SavedBeneficiaries/DeleteCitmfbBeneficiaryModal/DeleteCitmfbBeneficiaryModal';
import AirtimeAndDataBeneficiariesTable from '../AirtimeAndDataBeneficiariesTable/AirtimeAndDataBeneficiariesTable';
import AddNewAirtimeAndDataBeneficiaryModal from '../AddNewAirtimeAndDataBeneficiaryModal/AddNewAirtimeAndDataBeneficiaryModal';
import { AddNewAirtimeAndDataBeneficiarySchema } from 'services/Yup/AddNewAirtimeAndDataBeneficiarySchema';
import { useFormik } from 'formik';
import { Search } from '../CitmfbTransfer/CitmfbTransfer';
import { createBeneficiary } from 'services/dashboard';
import { useMutation } from '@tanstack/react-query';
import LoadingAnimation from 'components/base/LoadingAnimation/LoadingAnimation';
import { styles } from 'components/SavedBeneficiaries/CitmfbTransfer/CitmfbTransfer.styles';

const AirtimeAndDataBeneficiaries = ({
  searchInput,
  handleChange,
  tableData,
  isLoading,
  page,
  setPage,
  item_count,
}) => {
  const [open, setOpen] = React.useState({
    beneficiaries: false,
    delete: false,
    error: false,
  });

  const [error, setError] = React.useState('');

  const [step, setStep] = React.useState({
    beneficiaries: 0,
    delete: 0,
  });

  const handleClose = (objectKey) => {
    setOpen((prevS) => ({ ...prevS, [objectKey]: false }));
    setStep((prevS) => ({ ...prevS, [objectKey]: 0 }));
  };

  const handleOpen = (objectKey) => {
    setOpen((prevS) => ({ ...prevS, [objectKey]: true }));
  };

  const nextStep = (objectKey) => {
    if (step[objectKey] === 1) {
      return handleClose(objectKey);
    }
    setStep((prevS) => ({ ...prevS, [objectKey]: prevS[objectKey] + 1 }));
  };

  const deleteBeneficiary = () => {
    nextStep('delete');
  };

  const { mutate, isLoading: loading } = useMutation({
    mutationFn: createBeneficiary,
    onSuccess: () => {
      nextStep('beneficiaries');
      formik.resetForm();
    },
    onError: (err) => {
      setError(err?.response?.data?.detail || err.message);
      handleOpen('error');
    },
  });

  const formik = useFormik({
    initialValues: {
      network: '',
      phoneNumber: '',
      preferredName: '',
    },
    validationSchema: AddNewAirtimeAndDataBeneficiarySchema,
    onSubmit: (values) => {
      mutate({
        beneficiary_type: 'airtime',
        beneficiary_name: values.preferredName,
        beneficiary_number: values.phoneNumber,
        biller_name: values.network,
      });
    },
  });

  return isLoading ? (
    <Box sx={styles.loadingAnimation}>
      <LoadingAnimation />
    </Box>
  ) : (
    <>
      <Box sx={styles.container}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Input
            value={searchInput}
            onChange={handleChange}
            placeholder='Search'
            startAdornment={
              <InputAdornment position='end'>
                <Search sx={styles.searchIcon} className='arrow-down' />
              </InputAdornment>
            }
          />

          <Button
            variant='contained'
            sx={styles.addBeneficiartBtn}
            onClick={() => handleOpen('beneficiaries')}
          >
            + Add New Beneficiary
          </Button>
        </Stack>
      </Box>

      <Box mt={3}>
        <AddNewAirtimeAndDataBeneficiaryModal
          open={open}
          step={step.beneficiaries}
          handleClose={handleClose}
          isLoading={loading}
          formik={formik}
          error={error}
        />

        <DeleteCitmfbBeneficiaryModal
          open={open.delete}
          step={step.delete}
          handleClose={handleClose}
          deleteBeneficiary={deleteBeneficiary}
        />

        <AirtimeAndDataBeneficiariesTable
          tableData={tableData}
          handleOpen={handleOpen}
          page={page}
          setPage={setPage}
          item_count={item_count}
        />
      </Box>
    </>
  );
};

export default AirtimeAndDataBeneficiaries;
