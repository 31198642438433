import * as Yup from 'yup';

export const FirstFormSchema = Yup.object().shape({
  bvn: Yup.string()
    .min(11, 'Enter correct 11 digits')
    .max(11, 'Enter correct 11 digits')
    .required('BVN is required'),
  phone: Yup.string()
    .min(11, 'Invalid phone number')
    .max(15, 'Invalid phone number')
    .required('Phone number is required'),
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
});

export const SecondFormSchema = Yup.object().shape({
  gender: Yup.mixed()
    .required('Select Gender')
    .oneOf(['Male', 'Female'], 'Must Male or Female'),
  dob: Yup.date().required('Date of birth is required'),
  nin: Yup.string()
    .min(11, 'Enter valid NIN')
    .max(11, 'Enter valid NIN')
    .required('NIN is required'),
  email: Yup.string().email().required('Email is required'),
  residentialAddress: Yup.string().required('Residential address is required'),
});

export const ThirdFormSchema = Yup.object().shape({
  image: Yup.string().required('Please upload an image'),
  signature_image: Yup.string().required('Please upload your signature'),
  utility: Yup.string().required('Please upload your utility bill'),
  valid_id: Yup.string().required('Please upload your id card'),
});

export const ThirdFormLivenessSchema = Yup.object().shape({
  signature_image: Yup.string().required('Please upload your signature'),
});

export const UpgradeTierSchema = Yup.object().shape({
  utility_image: Yup.string().required('Please upload your utility image'),
  valid_id_image: Yup.string().required('Please upload your ID image'),
});
