import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import SlideInModal from "components/base/SlideInModal2/SlideInModal";
import Typography from "@mui/material/Typography";
//import { styled } from "@mui/material/";
import { ReactComponent as Close } from "assets/svg/close.svg";
import { styles2 } from "./TransactionsTable.styles";
//import { ReactComponent as InfoIcon } from "assets/svg/info.svg";
//import { ReactComponent as SortIcon } from "assets/svg/sort.svg";
import { Button /* styled */ /* TextField */ } from "@mui/material";
import { formatCurrency } from "utils/formatCurrency";
import { styles } from "./TransactionsTable.styles";
import { useState } from "react";
import { updateSchedule, deletedSchedules } from "services/corporateDashboard";
//import { updateTransReq } from "services/corporateDashboard";
//import { useQuery } from "@tanstack/react-query";
//import DirectionSnackbar from "components/base/SnackBar/SnackBar";
//eslint-disable-next-line
import TransactionSuccessful from "components/base/TransactionSuccessful/TransactionSuccessful";
import moment from "moment";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";

const TransactionsTable = ({ data, dataHead, loading, action }) => {
  const [loading2, setLoading2] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const [selected, setSelected] = useState();
  const [state, setState] = useState();

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const closeActionModal = () => {
    setActionModal(false);
  };
  const closeViewModal = () => {
    setViewModal(false);
  };
  const rowData = data;
  //eslint-disable-next-line
  const [trans, setTrans] = useState("");

  let transs = trans;
  const [open4, setOpen4] = useState("");
  const [error, setError] = useState("");

  const handleClose4 = () => {
    setOpen4(false);
  };

  console.log(transs);
  return (
    <TableContainer component={Box} mt={2}>
      <DirectionSnackbar
        open={open4}
        handleClose={handleClose4}
        message={error}
      />
      <Table
        sx={{ minWidth: { xs: "max-content", lg: 650 } }}
        aria-label="table"
      >
        <TableHead>
          <TableRow
            sx={{ ...styles.tableHeader, "th, td": { border: "none", py: 1 } }}
          >
            {/*  <TableCell sx={styles.headerText}>
              DATE <SortIcon style={{ verticalAlign: "middle" }} />
            </TableCell> */}
            {dataHead?.map((item) => {
              return (
                <TableCell sx={styles.headerText}>
                  {item}
                  {/* <Info /> */}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData?.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                "&:nth-of-type(even)": {
                  bgcolor: "rgba(247, 249, 252, 0.8)",
                },
                "&:last-child td, &:last-child th": { border: 0 },
                "th, td": { border: "none" },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  ...styles.contentText,
                  ...styles.textColor("green3.main"),
                  textTransform: "capitalize",
                }}
              >
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : (
                  //new Date(row.beneficiary_name).toDateString().slice(4)
                  row?.transfer_option || "--"
                )}
              </TableCell>

              <TableCell sx={styles.contentText}>
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : (
                  formatCurrency(Number(row?.transfers?.[0]?.amount)) || "---"
                )}
              </TableCell>
              {/* <TableCell sx={styles.contentText}>{row.account}</TableCell> */}
              <TableCell sx={styles.contentText}>
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : (
                  moment(row?.created_on).format("DD-MMM-YYYY")
                )}
              </TableCell>

              <TableCell sx={styles.contentText}>
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : (
                  row?.schedule_type
                )}
              </TableCell>
              <TableCell sx={styles.contentText}>
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : (
                  row?.day_of_the_week || "Day " + row?.day_of_the_month
                )}
              </TableCell>
              <TableCell sx={styles.contentText}>
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : row?.completed ? (
                  <span style={{ color: "green" }}>True</span>
                ) : (
                  <span style={{ color: "red" }}>False</span>
                )}
              </TableCell>
              <TableCell
                sx={{
                  ...styles.contentText,
                  ...styles.textColor(
                    row.type === "Credit" ? "green3.main" : "red.main"
                  ),
                }}
                style={{
                  opacity: loading2 && "0.5",
                  pointerEvents: loading2 && "none",
                }}
              >
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      width: { xs: "fit-content" },
                      bgcolor:
                        row?.status === "active"
                          ? "rgba(45, 202, 114, 0.2)"
                          : row?.status === "inactive"
                          ? "rgba(202, 134, 45, 0.2)"
                          : "",
                      color:
                        row?.status === "active"
                          ? "rgb(45, 202, 113)"
                          : row?.status === "inactive"
                          ? "rgb(202, 155, 45)"
                          : "",
                      fontSize: { xs: 6, lg: 10 },
                      fontWeight: 600,
                      boxShadow: "none",
                      alignSelf: { xs: "flex-end", sm: "auto" },
                      "&:hover": {
                        bgcolor:
                          row?.status === "active"
                            ? "rgba(45, 202, 114, 0.2)"
                            : row?.status === "inactive"
                            ? "rgba(202, 134, 45, 0.2)"
                            : "",
                      },
                    }}
                  >
                    {row?.status === "active"
                      ? "ACTIVE"
                      : row?.status === "inactive"
                      ? "INACTIVE"
                      : ""}
                  </Button>
                )}
              </TableCell>
              <TableCell
                sx={{
                  ...styles.contentText,
                  ...styles.textColor(
                    row.type === "Credit" ? "green3.main" : "red.main"
                  ),
                }}
                style={{
                  opacity: loading2 && "0.5",
                  pointerEvents: loading2 && "none",
                }}
              >
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setSelected(row?.id);
                      setActionModal(true);

                      if (row?.status === "inactive") {
                        setState("deactivate");
                      } else {
                        setState("activate");
                      }
                    }}
                    sx={{
                      width: { xs: "fit-content" },
                      bgcolor:
                        row?.status === "inactive"
                          ? "rgba(45, 202, 114, 0.2)"
                          : row?.status === "active"
                          ? "rgba(202, 134, 45, 0.2)"
                          : "",
                      color:
                        row?.status === "inactive"
                          ? "rgb(45, 202, 113)"
                          : row?.status === "active"
                          ? "rgb(202, 155, 45)"
                          : "",
                      fontSize: { xs: 6, lg: 10 },
                      fontWeight: 600,
                      boxShadow: "none",
                      alignSelf: { xs: "flex-end", sm: "auto" },
                      "&:hover": {
                        bgcolor:
                          row?.status === "inactive"
                            ? "rgba(45, 202, 114, 0.2)"
                            : row?.status === "active"
                            ? "rgba(202, 134, 45, 0.2)"
                            : "",
                      },
                    }}
                  >
                    {row?.status === "active"
                      ? "DEACTIVATE"
                      : row?.status === "inactive"
                      ? "ACTIVATE"
                      : ""}
                  </Button>
                )}
              </TableCell>
              <TableCell sx={styles.contentText}>
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : (
                  <svg
                    onClick={() => {
                      setSelected(row?.id);
                      setDeleteModal(true);
                    }}
                    style={{
                      cursor: loading2 ? "" : "pointer",
                      opacity: loading2 && "0.5",
                    }}
                    width="22"
                    height="24"
                    viewBox="0 0 22 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.125 24C3.36875 24 2.72112 23.7387 2.18212 23.216C1.64312 22.6933 1.37408 22.0658 1.375 21.3333V4H0V1.33333H6.875V0H15.125V1.33333H22V4H20.625V21.3333C20.625 22.0667 20.3555 22.6947 19.8165 23.2173C19.2775 23.74 18.6303 24.0009 17.875 24H4.125ZM17.875 4H4.125V21.3333H17.875V4ZM6.875 18.6667H9.625V6.66667H6.875V18.6667ZM12.375 18.6667H15.125V6.66667H12.375V18.6667Z"
                      fill="#F31830"
                    />
                  </svg>
                )}
              </TableCell>
              <TableCell
                sx={{
                  ...styles.contentText,
                  ...styles.textColor("green3.main"),
                  textTransform: "capitalize",
                  cursor: "pointer",
                }}
              >
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : (
                  <span
                    onClick={() => {
                      setViewModal(true);
                      setSelected(row);
                    }}
                  >
                    View
                  </span>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>

        <SlideInModal open={deleteModal} handleClose={closeDeleteModal}>
          <Typography onClick={closeDeleteModal} sx={styles2.closeX}>
            <Close sx={styles2.closeIcon} />
          </Typography>
          <Box my="4px">
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="4" y="4" width="48" height="48" rx="24" fill="#C8FFE0" />
              <path
                d="M23 19V22.4C23 22.9601 23 23.2401 23.109 23.454C23.2049 23.6422 23.3578 23.7951 23.546 23.891C23.7599 24 24.0399 24 24.6 24H31.4C31.9601 24 32.2401 24 32.454 23.891C32.6422 23.7951 32.7951 23.6422 32.891 23.454C33 23.2401 33 22.9601 33 22.4V20M33 37V30.6C33 30.0399 33 29.7599 32.891 29.546C32.7951 29.3578 32.6422 29.2049 32.454 29.109C32.2401 29 31.9601 29 31.4 29H24.6C24.0399 29 23.7599 29 23.546 29.109C23.3578 29.2049 23.2049 29.3578 23.109 29.546C23 29.7599 23 30.0399 23 30.6V37M37 25.3255V32.2C37 33.8802 37 34.7202 36.673 35.362C36.3854 35.9265 35.9265 36.3854 35.362 36.673C34.7202 37 33.8802 37 32.2 37H23.8C22.1198 37 21.2798 37 20.638 36.673C20.0735 36.3854 19.6146 35.9265 19.327 35.362C19 34.7202 19 33.8802 19 32.2V23.8C19 22.1198 19 21.2798 19.327 20.638C19.6146 20.0735 20.0735 19.6146 20.638 19.327C21.2798 19 22.1198 19 23.8 19H30.6745C31.1637 19 31.4083 19 31.6385 19.0553C31.8425 19.1043 32.0376 19.1851 32.2166 19.2947C32.4184 19.4184 32.5914 19.5914 32.9373 19.9373L36.0627 23.0627C36.4086 23.4086 36.5816 23.5816 36.7053 23.7834C36.8149 23.9624 36.8957 24.1575 36.9447 24.3615C37 24.5917 37 24.8363 37 25.3255Z"
                stroke="#27490F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect
                x="4"
                y="4"
                width="48"
                height="48"
                rx="24"
                stroke="#EDFFF5"
                stroke-width="8"
              />
            </svg>
          </Box>

          <Typography style={{ margin: "30px 0" }} sx={styles2.desc}>
            Are you sure you want to delete this scheduled transaction?
          </Typography>

          <Box
            sx={{
              display: "flex",
              mt: "10px",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="outlined"
              fullWidth
              sx={{
                color: "#000",
                border: "1px solid #dadada",
                ...styles2.closeBtn,
              }}
              onClick={() => {
                closeDeleteModal();
              }}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              fullWidth
              sx={{
                color: "white",
                backgroundColor: "green3.main",
                ...styles2.closeBtn,
              }}
              onClick={() => {
                setLoading2(true);
                deletedSchedules(selected)
                  .then((res) => {
                    action();
                    setLoading2(false);
                    closeDeleteModal();
                  })
                  .catch((err) => {
                    setLoading2(false);
                    setError(err?.response?.data?.detail || err.message);
                    setOpen4(true);
                  });
              }}
              disabled={loading2}
            >
              Confirm
            </Button>
          </Box>
        </SlideInModal>
        {/* CLOSE MODAL */}
        <SlideInModal open={actionModal} handleClose={closeActionModal}>
          <Typography onClick={closeActionModal} sx={styles2.closeX}>
            <Close sx={styles2.closeIcon} />
          </Typography>
          <Box my="4px">
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="4" y="4" width="48" height="48" rx="24" fill="#C8FFE0" />
              <path
                d="M23 19V22.4C23 22.9601 23 23.2401 23.109 23.454C23.2049 23.6422 23.3578 23.7951 23.546 23.891C23.7599 24 24.0399 24 24.6 24H31.4C31.9601 24 32.2401 24 32.454 23.891C32.6422 23.7951 32.7951 23.6422 32.891 23.454C33 23.2401 33 22.9601 33 22.4V20M33 37V30.6C33 30.0399 33 29.7599 32.891 29.546C32.7951 29.3578 32.6422 29.2049 32.454 29.109C32.2401 29 31.9601 29 31.4 29H24.6C24.0399 29 23.7599 29 23.546 29.109C23.3578 29.2049 23.2049 29.3578 23.109 29.546C23 29.7599 23 30.0399 23 30.6V37M37 25.3255V32.2C37 33.8802 37 34.7202 36.673 35.362C36.3854 35.9265 35.9265 36.3854 35.362 36.673C34.7202 37 33.8802 37 32.2 37H23.8C22.1198 37 21.2798 37 20.638 36.673C20.0735 36.3854 19.6146 35.9265 19.327 35.362C19 34.7202 19 33.8802 19 32.2V23.8C19 22.1198 19 21.2798 19.327 20.638C19.6146 20.0735 20.0735 19.6146 20.638 19.327C21.2798 19 22.1198 19 23.8 19H30.6745C31.1637 19 31.4083 19 31.6385 19.0553C31.8425 19.1043 32.0376 19.1851 32.2166 19.2947C32.4184 19.4184 32.5914 19.5914 32.9373 19.9373L36.0627 23.0627C36.4086 23.4086 36.5816 23.5816 36.7053 23.7834C36.8149 23.9624 36.8957 24.1575 36.9447 24.3615C37 24.5917 37 24.8363 37 25.3255Z"
                stroke="#27490F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect
                x="4"
                y="4"
                width="48"
                height="48"
                rx="24"
                stroke="#EDFFF5"
                stroke-width="8"
              />
            </svg>
          </Box>

          <Typography style={{ margin: "30px 0" }} sx={styles2.desc}>
            Are you sure you want to{" "}
            {state === "deactivate" ? "activate" : "deactivate"} this scheduled
            transaction?
          </Typography>

          <Box
            sx={{
              display: "flex",
              mt: "10px",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="outlined"
              fullWidth
              sx={{
                color: "#000",
                border: "1px solid #dadada",
                ...styles2.closeBtn,
              }}
              onClick={() => {
                closeDeleteModal();
              }}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              fullWidth
              sx={{
                color: "white",
                backgroundColor: "green3.main",
                ...styles2.closeBtn,
              }}
              onClick={() => {
                if (state === "deactivate") {
                  setLoading2(true);
                  updateSchedule(selected, {
                    status: "active",
                  })
                    .then((res) => {
                      action();
                      closeActionModal();
                      setLoading2(false);
                    })
                    .catch((err) => {
                      setLoading2(false);
                      setError(err?.response?.data?.detail || err.message);
                      closeActionModal();
                      setOpen4(true);
                    });
                } else {
                  setLoading2(true);

                  updateSchedule(selected, {
                    status: "inactive",
                  })
                    .then((res) => {
                      action();
                      closeActionModal();
                      setLoading2(false);
                    })
                    .catch((err) => {
                      setLoading2(false);
                      closeActionModal();
                      setError(err?.response?.data?.detail || err.message);
                      setOpen4(true);
                    });
                }
              }}
              disabled={loading2}
            >
              Confirm
            </Button>
          </Box>
        </SlideInModal>
        {/* VIEW MODAL */}
        <SlideInModal
          sx={{
            width: "700px",
            padding: "100px",
          }}
          open={viewModal}
          handleClose={closeViewModal}
        >
          <Typography onClick={closeViewModal} sx={styles2.closeX}>
            <Close sx={styles2.closeIcon} />
          </Typography>
          <h2 style={{ margin: "0px 0" }} sx={styles2.desc}>
            Schedule Details
          </h2>
          <div style={{ margin: "30px 0" }}>
            <h3
              style={{ margin: "0px 0", marginBottom: "10px", opacity: "0.6" }}
              sx={styles2.desc}
            >
              Transfer Details
            </h3>
            {selected?.transfers?.map((item) => {
              return (
                <div
                  style={{
                    padding: "0px",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    style={{
                      marginRight: "20px",
                    }}
                  >
                    <h4 style={{ margin: 0 }}>Beneficiary Name</h4>
                    <span style={{}}>{item?.beneficiary_name}</span>
                  </div>
                  <div
                    style={{
                      marginRight: "20px",
                    }}
                  >
                    <h4 style={{ margin: 0 }}>Beneficiary Bank</h4>
                    <span style={{}}>{item?.bank_name || "KC Bank"}</span>
                  </div>
                  <div
                    style={{
                      marginRight: "20px",
                    }}
                  >
                    <h4 style={{ margin: 0 }}>Description</h4>
                    <span style={{}}>{item?.description}</span>
                  </div>
                  <div
                    style={{
                      marginRight: "20px",
                    }}
                  >
                    <h4 style={{ margin: 0 }}>Transaction Day</h4>
                    <span style={{}}>
                      {selected?.day_of_the_week || selected?.day_of_the_month}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          <h3
            style={{ margin: "0px 0", marginBottom: "10px", opacity: "0.6" }}
            sx={styles2.desc}
          >
            Other Details
          </h3>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                marginRight: "20px",
              }}
            >
              <h3 style={{ margin: 0 }}>Start Data</h3>
              <span style={{}}>
                {moment(selected?.start_date).format("DD-MMM-YYYY")}
              </span>
            </div>
            <div style={{}}>
              <h3 style={{ margin: 0 }}>End Data</h3>
              <span style={{}}>
                {moment(selected?.end_date).format("DD-MMM-YYYY")}
              </span>
            </div>
          </div>

          <Button
            variant="contained"
            onClick={() => {
              setSelected(selected?.id);
              setActionModal(true);
              setViewModal(false);

              if (selected?.status === "inactive") {
                setState("deactivate");
              } else {
                setState("activate");
              }
            }}
            sx={{
              width: { xs: "fit-content" },
              marginTop: "20px",
              bgcolor:
                selected?.status === "inactive"
                  ? "rgba(45, 202, 114, 0.2)"
                  : selected?.status === "active"
                  ? "rgba(202, 134, 45, 0.2)"
                  : "",
              color:
                selected?.status === "inactive"
                  ? "rgb(45, 202, 113)"
                  : selected?.status === "active"
                  ? "rgb(202, 155, 45)"
                  : "",
              fontSize: { xs: 6, lg: 10 },
              fontWeight: 600,
              boxShadow: "none",
              alignSelf: { xs: "flex-end", sm: "auto" },
              "&:hover": {
                bgcolor:
                  selected?.status === "inactive"
                    ? "rgba(45, 202, 114, 0.2)"
                    : selected?.status === "active"
                    ? "rgba(202, 134, 45, 0.2)"
                    : "",
              },
            }}
          >
            {selected?.status === "active"
              ? "DEACTIVATE"
              : selected?.status === "inactive"
              ? "ACTIVATE"
              : ""}
          </Button>
        </SlideInModal>
      </Table>
    </TableContainer>
  );
};

export default TransactionsTable;
