export const styles = {
  container: {
    background: 'white.main',
    boxShadow: '0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)',
    mt: 2,
    py: 1,
  },
  header: {
    fontSize: { xs: 16, sm: 18 },
    fontWeight: 700,
    color: 'green1.main',
    bgcolor: '#E1FCEF',
    py: '5px',
    px: { xs: '10px', sm: 2 },
  },
  box: { flex: '1' },
  name: { fontSize: { xs: 16, md: 20 }, fontWeight: 700, color: 'green1.main' },
  address: {
    fontSize: { xs: 12, md: 14 },
    fontWeight: 600,
    color: 'green1.main',
    mt: 1,
  },
  label: {
    fontSize: { xs: 14, md: 16 },
    fontWeight: 600,
    color: '#666464',
  },
  value: {
    fontSize: { xs: 16, md: 18 },
    fontWeight: 600,
  },
  summary: {
    fontSize: { xs: 16, md: 20 },
    fontWeight: 800,
    color: 'green1.main',
    px: { xs: '10px', sm: 2 },
  },
  moneyInOutBox: {
    border: '1px solid #DBDCE0',
    p: 2,
    mx: { xs: '12px', sm: 2 },
    mt: 2,
  },
  btn: {
    color: 'white.main',
    boxShadow: 'none',
    '&:hover': { boxShadow: 7 },
  },
  downloadFileText: {
    fontSize: { xs: 14, sm: 16 },
    fontWeight: 600,
  },
};
