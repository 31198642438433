import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import Select from "components/base/Select/Select";
import { getServices, getPackages } from "services/corporateDashboard";
import { useQuery } from "@tanstack/react-query";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import { formatCurrency } from "utils/formatCurrency";
import Switch from "components/base/Switch/Switch";
import { validateNumbers } from "utils/validateNumbers";

const CableTvForm = ({
  formik,
  styles,
  validateSmartCard,
  cardInfo,
  accounts,
}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [durations, setDurations] = useState([]);

  //   Get cable tv services
  const { data: getBiller } = useQuery({
    queryKey: ["getServices"],
    queryFn: getServices,
    onError: (err) => {
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
    },
  });

  const biller = getBiller?.detail || [];
  //   Get package by name
  const service_type = formik.values.service_name;
  const { data: packages } = useQuery({
    queryKey: ["getPackages", service_type],
    queryFn: () => getPackages(service_type),
    onError: (err) => {
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
    },
    enabled: Boolean(formik.values.service_name),
  });

  const packageNames = packages?.detail?.map(({ name }) => name) || [];

  const handleBillerOnchange = () => {
    formik.setFieldValue("product_name", "");
    formik.setFieldValue("duration", "");
    formik.setFieldValue("amount", "");
    formik.setFieldValue("smart_card_no", "");
  };

  const [newPackage, setNewPackage] = useState();

  const handlePackagesOnchange = (value) => {
    const selectedPackage = packages?.detail?.find((c) => c?.name === value);
    setNewPackage(selectedPackage);

    console.log(selectedPackage, "mee", value);

    const durations = selectedPackage?.availablePricingOptions?.map(
      ({ monthsPaidFor, price }) =>
        `${monthsPaidFor} month${
          monthsPaidFor > 1 ? "s" : ""
        } - ${formatCurrency(price)}`
    );

    setDurations(durations);

    formik.setFieldValue("product_codes", selectedPackage?.code);
  };

  const handleDurationOnchange = (value) => {
    /* const findSelectedPackage = packages?.detail?.find(
      ({ name }) => (name = formik.values.product_name)
    ); */

    const findSelectedDuration = newPackage?.availablePricingOptions?.find(
      ({ monthsPaidFor, price }) =>
        `${monthsPaidFor} month${
          monthsPaidFor > 1 ? "s" : ""
        } - ${formatCurrency(price)}` === value
    );

    formik.setFieldValue("amount", findSelectedDuration?.price);
    console.log(findSelectedDuration);
  };

  // reset form fields on mount
  useEffect(() => {
    formik.resetForm();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <DirectionSnackbar
          open={open}
          message={message}
          handleClose={() => {
            setOpen(false);
          }}
        />

        <InputWithLabel label="Select Account">
          <Select
            options={accounts}
            placeholder="Select Account"
            disableClearable={true}
            name="account"
            value={formik.values.account}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>

        <InputWithLabel label="Select Biller">
          <Select
            options={biller}
            placeholder="(e.g DSTV, GOTV)"
            name="service_name"
            value={formik.values.service_name}
            formik={formik}
            sx={styles.input}
            onChange={handleBillerOnchange}
          />
        </InputWithLabel>
      </Stack>

      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Select Package">
          <Select
            options={packageNames}
            placeholder="Select package"
            name="product_name"
            value={formik.values.product_name}
            formik={formik}
            sx={styles.input}
            onChange={handlePackagesOnchange}
          />
        </InputWithLabel>

        <InputWithLabel label="Select Period">
          <Select
            options={durations}
            placeholder="Select package"
            name="duration"
            value={formik.values.duration}
            formik={formik}
            sx={styles.input}
            onChange={handleDurationOnchange}
          />
        </InputWithLabel>
      </Stack>

      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Amount">
          <Input
            type="number"
            id="amount"
            name="amount"
            value={formik.values.amount}
            placeholder="Enter amount"
            formik={formik}
            disabled={true}
            sx={styles.input}
          />
        </InputWithLabel>

        <InputWithLabel label="Smart Card Number">
          <Input
            type="tel"
            id="smart_card_no"
            name="smart_card_no"
            value={formik.values.smart_card_no}
            onChange={(event) => {
              validateNumbers(event, 50, formik.handleChange);
            }}
            placeholder="Enter smart card number"
            formik={formik}
            sx={styles.input}
            onBlur={validateSmartCard}
          />
          <Typography sx={{ textTransform: "capitalize", fontSize: 12 }}>
            {cardInfo?.detail?.firstName?.toLowerCase()}{" "}
            {cardInfo?.detail?.lastName?.toLowerCase()}
          </Typography>
        </InputWithLabel>
      </Stack>
      <InputWithLabel label="Phone Number">
        <Input
          type="text"
          id="phone_number"
          name="phone_number"
          value={formik.values.phone_number}
          placeholder="Enter Phone Number"
          formik={formik}
          sx={styles.input}
          onChange={formik.handleChange}
        />
      </InputWithLabel>

      <Stack
        sx={{
          transform: "translateY(-1rem)",
          pb: 5,
        }}
      >
        <Switch
          name="saveBeneficiary"
          label="Save as beneficiary"
          value={formik.values.saveBeneficiary}
          onChange={formik.handleChange}
        />
      </Stack>
    </>
  );
};

export default CableTvForm;
