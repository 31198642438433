export const styles = {
    container: {
        width: { xs: "95%", sm: "90%", lg: "70%" },
        py: { xs: 4, sm: 4, lg: 6 },
        mx: "auto",
    },
    header: {
        fontSize: { xs: 18, sm: 20, md: 22 },
        fontWeight: 700,
    },
    input: {
        backgroundColor: "#FFFFFF",
        borderColor: "#EFF0F6",
    },
    submitBtn: {
        width: { xs: "80%", sm: 300 },
        height: 55,
        alignSelf: "center",
        color: "white.main",
    },
};