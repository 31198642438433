import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as VisibilityOnIcon } from "assets/svg/visibility-on.svg";
import { ReactComponent as VisibilityOffIcon } from "assets/svg/visibility-off.svg";
import { styles } from "./HideAccountBalance.styles";

const HideAccountBalance = ({ toggle, state, showLabel }) => {
  return (
    <Box
      onClick={toggle}
      sx={{
        cursor: "pointer",
        userSelect: "none",
        width: 35,
        height: 30,
        display: "inline-block",
      }}
    >
      <Box sx={styles.toggleVisibility}>
        {state ? (
          <VisibilityOffIcon />
        ) : (
          <VisibilityOnIcon className="white-stroke" />
        )}
      </Box>

      {showLabel && (
        <Typography color="green1.main" sx={styles.hideText}>
          {state ? "Show Balance" : "Hide Balance"}
        </Typography>
      )}
    </Box>
  );
};

export default HideAccountBalance;
