import Box from "@mui/material/Box";
import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import { validateNumbers } from "utils/validateNumbers";

const EnterPhoneNumberForm = ({ formik }) => {
  return (
    <Box mb={2} mt={3}>
                  <InputWithLabel label="User Name">
        <Input
          id="username"
          type="text"
          placeholder="Enter username"
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
          formik={formik}
        />
      </InputWithLabel>
      <InputWithLabel label="Phone Number">
        <Input
          id="phone_number"
          type="tel"
          placeholder="(e.g 07064378577))"
          name="phone_number"
          value={formik.values.phone_number}
          onChange={(event) => {
            validateNumbers(event, 11, formik.handleChange);
          }}
          formik={formik}
        />
      </InputWithLabel>
    </Box>
  );
};

export default EnterPhoneNumberForm;
