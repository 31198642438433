import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styles } from './StatementSummary.styles';

const StatementSummary = ({ showModal, downloadStatementAsPdf, data }) => {
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.header}>Account statement</Typography>

      <Box
        sx={{ overfowX: 'auto', mt: 3, px: 3 }}
        dangerouslySetInnerHTML={{ __html: data?.detail }}
      ></Box>

      {/* <Stack
        direction='row'
        justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
        alignItems='center'
        spacing={{ xs: 2, sm: 3 }}
        mt={5}
        sx={{ flexWrap: 'wrap', px: { xs: 1, sm: 3 } }}
      >
        <Typography sx={styles.downloadFileText}>
          Download this File in:
        </Typography>
        <Stack direction='row' spacing={{ xs: 2, sm: 3 }}>
          <Button
            variant='contained'
            color='green3'
            sx={styles.btn}
            onClick={downloadStatementAsPdf}
          >
            PDF Format
          </Button>
          {/* <Button
            variant='contained'
            color='gray10'
            sx={styles.btn}
            onClick={showModal}
          >
            Excel Format
          </Button> 
        </Stack>
      </Stack> */}
    </Box>
  );
};

export default StatementSummary;
