export const styles = {
  background: {
    bgcolor: "white.main",
    boxShadow: "0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)",
    borderRadius: "10.2375px",
    py: { xs: 3, md: 4 },
  },
  container: {
    width: { xs: "95%", sm: 500, xl: 631 },
  },
  header: {
    fontSize: { xs: 18, sm: 20, lg: 22 },
    fontWeight: 700,
  },
  submitBtn: {
    width: "100%",
    height: { xs: 45, sm: 50 },
    color: "white.main",
    fontSize: { xs: 16, sm: 18 },
    fontWeight: 500,
    mt: 7,
  },
  noCardIssued: {
    height: "30vh",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #D9D9D9",
    borderRadius: "8px",
    mt: { xs: 3, sm: 4 },
  },
};
