import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Input from "components/base/Input/Input";
import { Typography } from "@mui/material";
import SlideInModal from "components/base/SlideInModal/SlideInModal";
import { ReactComponent as Close } from "assets/svg/close.svg";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
//import Select from "components/base/Select/Select";
//import Switch from "components/base/Switch/Switch";
import TransferModal from "components/base/TransferModalCorporate/TransferModal";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import {
  uploadBulkFile,
  bulkTransferReq,
  //userProfile,
  createBeneficiary,
} from "services/corporateDashboard";
import { useMutation } from "@tanstack/react-query";
//import { validateNumbers } from "utils/validateNumbers";
import { styles, styles2 } from "./TransferToCitForm.styles";
import FileUpload from "assets/svg/file.svg";
import { Box } from "@mui/system";
//import CorporateDashboardContext from "context/CorporateDashboardContext";
import LoadingAnimation from "components/base/LoadingAnimation/LoadingAnimation";
import Select from "components/base/Select/Select";
import { useNavigate } from "react-router-dom";

const TransferToCitForm = ({ formik, action }) => {
  //const { accountNumbers } = React.useContext(CorporateDashboardContext);

  // get user profile
  /*   const { data } = useQuery({
    queryKey: ["userProfile"],
    queryFn: userProfile,
  }); */

  // const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [error, setError] = React.useState("");
  const [step, setStep] = React.useState(2);
  const [file, setFile] = React.useState();

  // const handleOpenModal = () => setOpen(true);

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleClose = () => {
    setOpen2(false);
  };
  //const handleOpen = () => setOpen2(true);

  const handleCloseModal = () => {
    setStep(0);
    setOpen(false);
    formik.setFieldValue("");
  };

  const handleCloseError = (key) => {
    setOpenError(false);
  };

  const handleOpenError = (key) => {
    setOpenError(true);
  };

  const setBackendError = (key, err) => {
    setError(err);
  };

  const handleTransfer = () => {
    if (formik.values.saveBeneficiary) {
      CreateBeneficiary({
        beneficiary_type: "local_transfer",
        beneficiary_name: formik.values.beneficiary_account_name,
        beneficiary_bank: "",
        beneficiary_acct_no: formik.values.beneficiary_account_no,
      });
    }

    mutate({
      transfer_type: "same_bank",
      transaction_pin: formik.values.transaction_pin,
      account_no: formik.values.account,
      beneficiary_name: formik.values.beneficiary_account_name,
      beneficiary_acct_no: formik.values.beneficiary_account_no,
      amount: formik.values.amount,
      narration: formik.values.narration,
      beneficiary_acct_type: "savings",
    });
  };

  const formdata = new FormData();
  const router = useNavigate();

  const { mutate, isLoading } = useMutation({
    mutationFn: bulkTransferReq,
    onSuccess: (data) => {
      setOpen(true);
      setStep(1);
      setOpen2(false);

      router("/corporate-scheduled-payments?tab=table");
      action();
      handleCloseModal();
      formik.resetForm();

      setFile();

      console.log(data);
    },
    onError: (err) => {
      setBackendError(
        "transaction",
        err?.response?.data?.detail || err.message
      );
      handleOpenError("transaction");
      console.log(err?.response?.data?.detail, err.message);
      setFile();
    },
  });

  const [uploadedFiles, setUploadedFiles] = React.useState();
  const { mutate: mutate2, isLoading: isLoading2 } = useMutation({
    mutationFn: uploadBulkFile,
    onSuccess: (data) => {
      setOpen2(true);
      console.log(data);
      setUploadedFiles(data);
      setFile();
    },
    onError: (err) => {
      setBackendError(
        "transaction",
        err?.response?.data?.detail || err.message
      );
      handleOpenError("transaction");
      setFile();

      console.log(err?.response?.data?.detail, err.message, err);
    },
  });

  // save beneficiary mutation
  const { mutate: CreateBeneficiary } = useMutation({
    mutationFn: createBeneficiary,
  });
  return (
    <Stack
      as="form"
      spacing={{ xs: 3, sm: 4 }}
      onSubmit={formik.handleSubmit}
      sx={styles.container}
    >
      <TransferModal
        open={open}
        handleClose={handleCloseModal}
        step={step}
        setStep={setStep}
        handleTransfer={handleTransfer}
        isLoading={isLoading}
        openError={openError}
        handleCloseError={() => handleCloseError("")}
        errorMessage={error}
        formik={formik}
      />

      <DirectionSnackbar
        open={openError}
        handleClose={() => handleCloseError("")}
        message={error}
      />
      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Frequency Type">
          <Select
            options={["Monthly", "Weekly"]}
            placeholder="Select Frequency Type"
            name="frequencyType"
            value={formik.values.frequencyType}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>
        <InputWithLabel label="Frequency">
          <Select
            options={
              formik.values.frequencyType === "Monthly"
                ? [
                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                    18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                  ]
                : formik.values.frequencyType === "Weekly"
                ? [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ]
                : []
            }
            placeholder="Select Frequency"
            name="frequency"
            value={formik.values.frequency}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>
      </Stack>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Start Date">
          <Box sx={styles.box}>
            <Typography sx={styles.placeholder}>Start Date</Typography>

            <Input
              type="date"
              name="fromDate"
              value={formik.values.fromDate}
              onChange={formik.handleChange}
              formik={formik}
              sx={styles.input2}
            />
            <Typography sx={styles.dateValue}>
              {formik.values.fromDate
                ? new Date(formik.values.fromDate)
                    .toDateString()
                    .slice(4)
                    .replaceAll(" ", "-")
                : ""}
            </Typography>
          </Box>
        </InputWithLabel>
        <InputWithLabel label="End Date">
          <Box sx={styles.box}>
            <Typography sx={styles.placeholder}>End Date</Typography>

            <Input
              type="date"
              name="toDate"
              value={formik.values.toDate}
              onChange={formik.handleChange}
              formik={formik}
              sx={styles.input2}
            />
            <Typography sx={styles.dateValue}>
              {formik.values.toDate
                ? new Date(formik.values.toDate)
                    .toDateString()
                    .slice(4)
                    .replaceAll(" ", "-")
                : ""}
            </Typography>
          </Box>
        </InputWithLabel>
      </Stack>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Description">
          <Input
            name="description"
            placeholder="Description"
            value={formik.values.description}
            onChange={formik.handleChange}
            formik={formik}
          />
          <span style={{ color: "red" }}>{error}</span>
        </InputWithLabel>
      </Stack>
      {formik.values.description ? (
        file ? (
          ""
        ) : (
          <label
            onClick={() => setError("")}
            style={{ display: "block" }}
            name="file"
            id="file"
          >
            <Box sx={{ width: "100%" }}>
              <img style={{ width: "100%" }} src={FileUpload} alt="" />
            </Box>
            <input
              style={{ display: "none" }}
              name="file"
              id="file"
              type="file"
              accept=".csv"
              onChange={(e) => {
                formdata.append("file", e.target.files[0]);
                mutate2(formdata);
                setFile(e.target.files[0]);
              }}
            />
          </label>
        )
      ) : (
        <img
          style={{ width: "100%" }}
          onClick={() => setError("Descriptions must be filled")}
          src={FileUpload}
          alt=""
        />
      )}
      {isLoading2 && <LoadingAnimation />}

      <div>
        Click{" "}
        <a
          href={`${process.env.REACT_APP_API}/media/bulk-transfer-upload.csv`}
          target="_blank"
          rel="noreferrer"
          download
          style={{ color: "#D22F31", textDecoration: "none" }}
        >
          Here
        </a>{" "}
        to download template
      </div>

      {/* <Button
        type="submit"
        variant="contained"
        color="green3"
        sx={styles.submitBtn}
      >
        Proceed
      </Button> */}
      <SlideInModal
        sx={{ width: "80%" }}
        open={open2}
        handleClose={handleClose}
      >
        <Typography onClick={handleClose} sx={styles2.closeX}>
          <Close sx={styles2.closeIcon} />
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(6, 1fr)",
            gap: "8px",
            width: "100%",
            color: "grey",
          }}
        >
          <Box>S/N</Box>
          <Box>Account</Box>
          <Box>Amount</Box>
          <Box>Beneficiary Acc</Box>
          <Box>Beneficiary Name</Box>
          <Box>Account Type</Box>
        </Box>

        {uploadedFiles?.data?.success?.length === 0
          ? "Re check csv uploaded to confirm correct details"
          : uploadedFiles?.data?.success?.map((item, index) => {
              return (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(6, 1fr)",
                    gap: "8px",
                    width: "100%",
                    weight: "bold",
                  }}
                >
                  <Box>{index + 1}.</Box>
                  <Box>{item?.account_no}</Box>
                  <Box>{item?.amount}</Box>
                  <Box>{item?.beneficiary_acct_no}</Box>
                  <Box>{item?.beneficiary_name}</Box>
                  <Box>{item?.beneficiary_acct_type}</Box>
                </Box>
              );
            })}

        <Box
          sx={{
            display: "flex",
            mt: "10px",
            justifyContent: "space-between",
            width: "90%",
          }}
        >
          <Button
            variant="outlined"
            fullWidth
            sx={{
              color: "#000",
              border: "1px solid #dadada",
              ...styles2.closeBtn,
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            fullWidth
            sx={{
              color: "white",
              backgroundColor: "green3.main",
              ...styles2.closeBtn,
            }}
            onClick={() => {
              if (formik.values.frequencyType === "Monthly") {
                mutate({
                  start_date: formik.values.fromDate,
                  end_date: formik.values.toDate,
                  transfer_type: "bulk",
                  transfer_option: "bulk",
                  schedule_type: formik.values.frequencyType,
                  schedule: true,
                  day_of_the_month: formik.values.frequency,
                  data: uploadedFiles?.data?.success,
                  description: formik.values.description,
                });
              } else {
                mutate({
                  start_date: formik.values.fromDate,
                  end_date: formik.values.toDate,
                  transfer_type: "bulk",
                  transfer_option: "bulk",
                  schedule_type: formik.values.frequencyType,
                  schedule: true,
                  day_of_the_week: formik.values.frequency,
                  data: uploadedFiles?.data?.success,
                  description: formik.values.description,
                });
              }
            }}
            disabled={isLoading || uploadedFiles?.data?.success?.length === 0}
          >
            {isLoading ? <LoadingAnimation /> : "Continue to Submit"}
          </Button>
        </Box>
      </SlideInModal>
    </Stack>
  );
};

export default TransferToCitForm;
