import * as React from "react";
import Box from "@mui/material/Box";
import CustomModal from "components/base/Modal/Modal";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import { useSearchParams } from "react-router-dom";
import ConfirmTransactionCableTV from "../ConfirmTransactionCableTv/ConfirmTransactionCableTV";
import EnterCableTvPin from "../ConfirmTransactionCableTv/EnterCableTvPin";
import TransactionBillSuccessful from "../TransactionBillSuccessful/TransactionBillSuccessful";

const CableTVPaymentModal = ({
  open,
  handleClose,
  step,
  handleConfirmTransactionBtn,
  handleTransfer,
  isLoading,
  openError,
  handleCloseError,
  errorMessage,
  formik,
  error,
}) => {
  const [param] = useSearchParams();

  const currentTab = param.get("tab");

  return (
    <CustomModal open={open} handleClose={handleClose}>
      <DirectionSnackbar
        open={openError}
        handleClose={handleCloseError}
        message={errorMessage}
      />

      <Box>
        {step === 0 && (
          <ConfirmTransactionCableTV
            formik={formik}
            handleClick={handleConfirmTransactionBtn}
          />
        )}

        {step === 1 && (
          <EnterCableTvPin
            handleConfirmTransactionBtn={handleConfirmTransactionBtn}
            handleClick={handleTransfer}
            isLoading={isLoading}
            formik={formik}
            error={error}
          />
        )}

        {step === 2 && <TransactionBillSuccessful handleClick={handleClose} />}
      </Box>
    </CustomModal>
  );
};

export default CableTVPaymentModal;
