export const styles = {
    container: {
      width: { xs: '100%', sm: '60%', md: '70%', lg: 399, xl: '50%' },
      mx: { xs: 0, lg: 8 },
      mt: { xs: 4, sm: 6 },
    },
    header: {
      fontSize: { xs: 20, sm: 25, lg: 30 },
      fontWeight: 500,
      color: 'black.main',
    },
    subText: {
      fotnSize: { xs: 14, sm: 14 },
      color: 'gray5.main',
      mt: 2,
    },
    progressIndicator: (active, number) => ({
      width: 41,
      height: 4,
      bgcolor: active === number ? 'green7.main' : 'gray6.main',
      borderRadius: '10px',
      cursor: 'pointer',
    }),
    submitBtn: {
      width: '100%',
      height: { xs: 50, lg: 70 },
      fontSize: { xs: 16, sm: 20 },
      color: 'white.main',
      boxShadow: '0px 4px 30px rgba(38, 58, 67, 0.15)',
    },
  };
  