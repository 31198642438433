import * as React from 'react';
import { servicesAndTransactions } from 'services/dashboard';
import { useQuery } from '@tanstack/react-query';

const options = [
  { param: 'last_7_days=true', value: 'Last 7 Days' },
  { param: 'this_month=true', value: 'This Month ' },
  { param: 'this_year=true', value: 'This Year ' },
  // { param: '', value: 'Custom' },
];

const UserDashboardContext = React.createContext(null);

export const UserDashboardProvider = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(2);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const param = options[selectedIndex].param;

  const { data, isLoading } = useQuery({
    queryKey: ['services', param],
    queryFn: () => servicesAndTransactions(param),
  });

  return (
    <UserDashboardContext.Provider
      value={{
        options,
        open,
        anchorEl,
        selectedIndex,
        handleClickListItem,
        handleClose,
        handleMenuItemClick,
        data,
        isLoading,
      }}
    >
      {children}
    </UserDashboardContext.Provider>
  );
};

export default UserDashboardContext;
