import * as Yup from "yup";

export const FirstFormSchema = Yup.object().shape({
  account_no: Yup.string()
    .min(10, "Enter correct 10 digits")
    .max(10, "Enter correct 10 digits")
    .required("Account Number is required"),
  phone_number: Yup.string()
    .min(11, "Invalid phone number")
    .max(15, "Invalid phone number")
    .required("Phone number is required"),
  email: Yup.string().email().required("Email is required"),
});

export const SecondFormSchema = Yup.object().shape({
  
  token: Yup.string()
    .min(6, "Enter valid OTP")
    .max(6, "Enter valid OTP")
    .required("OTP is required"),
  
});

export const ThirdFormSchema = Yup.object().shape({
  new_password: Yup.string().required("Enter password"),
  confirm_password: Yup.string()
    .required("Please retype your password")
    .oneOf([Yup.ref("new_password")], "Password does not match"),
});
