import BillsPaymentComponent from "components/BillsPayment/BillsPayment";

const BillsPayment = () => {
  return (
    <>
      <BillsPaymentComponent />
    </>
  );
};

export default BillsPayment;
