import * as React from "react";
import { useContext } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import Select from "components/base/Select/Select";
import Switch from "components/base/Switch/Switch";
import TransferModal from "components/base/TransferModalCorporate/TransferModal";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import {
  getAccountName,
  //userProfile,
  createBeneficiary,
  transferReq,
} from "services/corporateDashboard";
import { useQuery, useMutation } from "@tanstack/react-query";
import { validateNumbers } from "utils/validateNumbers";
import { styles } from "./TransferToCitForm.styles";
import CorporateDashboardContext from "context/CorporateDashboardContext";

const TransferToCitForm = ({ formik, open, setOpen }) => {
  const { accountNumbers } = useContext(CorporateDashboardContext);

  console.log(accountNumbers);
  // get user profile
  /*  const { data } = useQuery({
    queryKey: ["userProfile"],
    queryFn: userProfile,
  }); */

  // const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState({
    nameEnquiry: false,
    transaction: false,
  });
  const [error, setError] = React.useState({
    nameEnquiry: "",
    transaction: "",
  });
  const [step, setStep] = React.useState(0);
  const [otpError, setOtpError] = React.useState(0);

  // const handleOpenModal = () => setOpen(true);

  const handleCloseModal = () => {
    setStep(0);
    setOpen(false);
    formik.setFieldValue("transaction_pin", "");
  };

  const handleCloseError = (key) => {
    setOpenError((prevS) => ({ ...prevS, [key]: false }));
  };

  const handleOpenError = (key) => {
    setOpenError((prevS) => ({ ...prevS, [key]: true }));
  };

  const setBackendError = (key, err) => {
    setError((prevS) => ({ ...prevS, [key]: err }));
  };

  React.useEffect(() => {
    if (formik.values.transaction_pin.length === 4) {
      setOtpError("");
    }
  }, [formik.values.transaction_pin.length]);

  const handleConfirmTransactionBtn = () => {
    setStep((prevS) => prevS + 1);
  };

  const handleTransfer = () => {
    if (formik.values.saveBeneficiary) {
      CreateBeneficiary({
        beneficiary_type: "local_transfer",
        beneficiary_name: formik.values.beneficiary_account_name,
        beneficiary_bank: "",
        beneficiary_acct_no: formik.values.beneficiary_account_no,
        account_type: "corporate",
      });
    }

    mutate({
      transfer_type: "same_bank",
      account_no: formik.values.account?.slice(0, 10),
      beneficiary_name: formik.values.beneficiary_account_name,
      beneficiary_acct_no: formik.values.beneficiary_account_no,
      amount: formik.values.amount,
      narration: formik.values.narration,
      beneficiary_acct_type: "savings",
    });
  };

  // get beneficiary account name query
  const queryType = "same_bank";
  const account_no = `${formik.values.beneficiary_account_no}`;

  const { isError } = useQuery({
    queryKey: ["name_enquiry", queryType, account_no],
    queryFn: () => getAccountName(queryType, account_no),
    onSuccess: (data) => {
      handleCloseError("nameEnquiry");

      formik.setFieldValue(
        "beneficiary_account_name",
        data.name.replace(",", "")
      );
    },
    onError: (err) => {
      setBackendError(
        "nameEnquiry",
        err?.response?.data?.detail || err.message
      );
      handleOpenError("nameEnquiry");
      formik.setFieldValue("beneficiary_account_name", "");
    },
    enabled: account_no.length === 10,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: transferReq,
    onSuccess: (data) => {
      setStep((prevS) => prevS + 1);
    },
    onError: (err) => {
      setBackendError(
        "transaction",
        err?.response?.data?.detail || err.message
      );
      handleOpenError("transaction");
      console.log(err?.response?.data?.detail, err.message);
    },
  });

  // save beneficiary mutation
  const { mutate: CreateBeneficiary } = useMutation({
    mutationFn: createBeneficiary,
  });

  //const accounts =
  //data?.customer?.accounts?.map(({ account_no }) => account_no) || [];

  return (
    <Stack
      as="form"
      spacing={{ xs: 3, sm: 4 }}
      onSubmit={formik.handleSubmit}
      sx={styles.container}
    >
      <TransferModal
        open={open}
        handleClose={handleCloseModal}
        step={step}
        handleConfirmTransactionBtn={handleConfirmTransactionBtn}
        setStep={setStep}
        handleTransfer={handleTransfer}
        isLoading={isLoading}
        openError={openError.transaction}
        handleCloseError={() => handleCloseError("transaction")}
        errorMessage={error.transaction}
        formik={formik}
        error={otpError}
      />

      <DirectionSnackbar
        open={openError.nameEnquiry}
        handleClose={() => handleCloseError("nameEnquiry")}
        message={error.nameEnquiry}
      />

      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Account">
          <Select
            options={accountNumbers || []}
            placeholder="Select Account"
            name="account"
            value={formik.values.account}
            disableClearable={true}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>

        <InputWithLabel label="Amount">
          <Input
            name="amount"
            type="tel"
            placeholder="Enter Amount"
            value={formik.values.amount}
            onChange={(event) => {
              validateNumbers(event, 1000, formik.handleChange);
            }}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>
      </Stack>

      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Beneficiary Account Number">
          <Input
            type="tel"
            name="beneficiary_account_no"
            placeholder="Enter Beneficiary Account Number"
            value={formik.values.beneficiary_account_no}
            onChange={(event) => {
              validateNumbers(event, 1000, formik.handleChange);
            }}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>

        <InputWithLabel label="Beneficiary Account Name">
          <Input
            name="beneficiary_account_name"
            placeholder="Enter Beneficiary Account Name"
            value={formik.values.beneficiary_account_name}
            disabled={true}
            onChange={formik.handleChange}
            formik={formik}
            sx={{
              ...styles.input,
              borderColor: isError ? "red.main" : "gray3.main",
            }}
          />
        </InputWithLabel>
      </Stack>

      <InputWithLabel label="Narration">
        <Input
          name="narration"
          placeholder="Enter Description (Optional)"
          value={formik.values.narration}
          onChange={formik.handleChange}
          formik={formik}
          sx={styles.input}
        />
      </InputWithLabel>

      <Stack sx={{ transform: "translateY(-1rem)", pb: 5 }}>
        <Switch
          name="saveBeneficiary"
          label="Save as beneficiary"
          value={formik.values.saveBeneficiary}
          onChange={formik.handleChange}
        />
      </Stack>

      <Button
        type="submit"
        variant="contained"
        color="green3"
        sx={styles.submitBtn}
      >
        Proceed
      </Button>
    </Stack>
  );
};

export default TransferToCitForm;
