export const styles = {
    container: {
        backgroundColor: "white.main",
        boxShadow: "0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)",
        borderRadius: "10.2375px",
        py: { xs: 3, sm: 4 },
    },
    header: {
        width: { lg: "85%" },
        fontSize: { xs: 16, sm: 18, lg: 20 },
        fontWeight: 700,
        color: "#000000",
        px: { xs: 2, lg: 0 },
        mb: 3,
        mx: "auto",
    },
    tabContainer: {
        width: "100%",
        borderBottom: "1px solid #ABABAB",
        mb: 3,
        overflow: "hidden",
    },
    tab: {
        flexWrap: "nowrap",
        width: { lg: "85%" },
        px: { xs: 2, lg: 0 },
        mx: "auto",
        overflowX: "auto",
    },
    category: (active) => ({
        fontSize: { xs: 13, lg: 14 },
        fontWeight: 600,
        color: active ? "green3.main" : "#888686",
        borderBottom: active ? "1px solid #D22F31" : "1px solid transparent",
        px: { xs: 2, sm: 3, lg: 4 },
        pb: "5px",
        cursor: "pointer",
        minWidth: "max-content",
    }),
    input: {
        backgroundColor: "#FFFFFF",
        borderColor: "#EFF0F6",
        borderRadius: "6px",
    },
    submitBtn: {
        width: { xs: "80%", sm: 300 },
        height: 55,
        alignSelf: "center",
        color: "white.main",
    },
};