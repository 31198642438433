import CardHotListComponent from 'components/CardHotList/CardHotList';

const CardHotList = () => {
  return (
    <>
      <CardHotListComponent />
    </>
  );
};

export default CardHotList;
