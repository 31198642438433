export const styles = {
    container: {
        maxWidth: { xs: '95%', sm: '80%', lg: '75%' },
        mx: 'auto',
        mt: { xs: 5, md: 6 },
    },
    input: {
        backgroundColor: '#FFFFFF',
        borderColor: '#EFF0F6',
    },
    submitBtn: {
        width: { xs: "80%", sm: 300 },
        height: 55,
        alignSelf: 'center',
        color: 'white.main',
    },
};