export const styles = {
  closeBtn: {
    position: 'absolute',
    right: '5%',
    top: '5%',
    width: { xs: 20, sm: 25 },
    height: { xs: 20, sm: 25 },
    cursor: 'pointer',
  },
  downloadFormatHeader: {
    fontSize: { xs: 22, sm: 30 },
    fontWeight: 700,
    color: '#263A43',
  },
  selectionBox: {
    display: 'flex',
    justifyContent: 'center',
    gap: { xs: 3, sm: 4 },
    my: 3,
  },
  selectionOption: {
    cursor: 'pointer',
  },
  option: (bool) => ({
    width: { xs: 40, sm: 40 },
    height: { xs: 40, sm: 40 },
    borderRadius: '50%',
    bgcolor: bool ? 'green3.main' : 'gray6.main',
    mx: 'auto',
  }),
  optionText: (bool) => ({
    fontSize: { xs: 12, sm: 14 },
    fontWeight: 500,
    color: bool ? '#434040' : 'As Excel',
    mt: 1,
  }),
  downloadFormatBtn: {
    width: { xs: 'auto', sm: 300 },
    height: { xs: 40, sm: 50 },
    fontSize: { xs: 16, sm: 18 },
    fontWeight: 500,
    color: 'white.main',
    px: { xs: 3, sm: 0 },
  },
  sendToEmail: {
    fontSize: { xs: 13, sm: 15 },
    color: '#263A43',
    mt: 2,
    cursor: 'pointer',
    '&:hover': { color: 'green3.main' },
  },
  img: {
    display: 'block',
    width: 200,
    mx: 'auto',
    mt: "-3rem",
  },
  downloadingHeader: {
    fontSize: { xs: 25, sm: 35 },
    fontWeight: 700,
    mt: "-3rem",
  },
  subText: {
    width: '70%',
    fontSize: { xs: 16, sm: 20 },
    fontWeight: 500,
    textAlign: 'center',
    mx: 'auto',
    mt: 3,
    mb: 6,
  },
  STEHeader: {
    fontSize: { xs: 22, sm: 30 },
    fontWeight: 700,
    color: '#263A43',
    textAlign: 'left',
  },
  STESubtext: {
    fontSize: { xs: 12, sm: 15 },
    color: '#AFAEAE',
    textAlign: 'left',
    my: 2,
  },
};
