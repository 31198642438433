import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import Select from "components/base/Select/Select";
import TransferModal from "components/base/TransferModal/TransferModal";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import { useFormik } from "formik";
import { getAccountName, bankTransfer, userProfile } from "services/dashboard";
import { useQuery, useMutation } from "@tanstack/react-query";
import { SelfAccountTransferScehma } from "services/Yup/SelfAccountTransferScehma";
import { validateNumbers } from "utils/validateNumbers";
import { styles } from "./SelfAccountTransferForm.styles";

const SelfAccountTransferForm = () => {
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState({
    nameEnquiry: false,
    transaction: false,
  });
  const [error, setError] = React.useState({
    nameEnquiry: "",
    transaction: "",
  });
  const [step, setStep] = React.useState(0);
  const [otpError, setOtpError] = React.useState(0);

  const handleOpenModal = () => setOpen(true);

  const handleCloseModal = () => {
    setStep(0);
    setOpen(false);
    setOtpError("")
    formik.setFieldValue("transaction_pin", "");
  };

  const handleCloseError = (key) => {
    setOpenError((prevS) => ({ ...prevS, [key]: false }));
  };

  const handleOpenError = (key) => {
    setOpenError((prevS) => ({ ...prevS, [key]: true }));
  };

  const setBackendError = (key, err) => {
    setError((prevS) => ({ ...prevS, [key]: err }));
  };

  const formik = useFormik({
    initialValues: {
      account_source: "",
      beneficiary_account_no: "",
      beneficiary_account_name: "",
      amount: "",
      narration: "",
      transaction_pin: "",
    },
    validationSchema: SelfAccountTransferScehma,
    onSubmit: () => {
      handleOpenModal();
    },
  });

  React.useEffect(() => {
    if (formik.values.transaction_pin.length === 4) {
      setOtpError("");
    }
  }, [formik.values.transaction_pin.length]);

  const handleConfirmTransactionBtn = () => {
    setStep((prevS) => prevS + 1);
  };

  const handleTransfer = () => {
    if (formik.values.transaction_pin.length === 4) {
      setOtpError("");

      mutate({
        transfer_type: "same_bank",
        transaction_pin: formik.values.transaction_pin,
        account_no: formik.values.account_source,
        beneficiary_name: formik.values.beneficiary_account_name,
        beneficiary_acct_no: formik.values.beneficiary_account_no,
        amount: formik.values.amount,
        narration: formik.values.narration,
        beneficiary_acct_type: "savings",
      });
    } else {
      setOtpError("Enter valid transaction pin");
    }
  };

  // get beneficiary account name query
  const queryType = "same_bank";
  const account_no = `${formik.values.beneficiary_account_no}`;

  useQuery({
    queryKey: ["name_enquiry", queryType, account_no],
    queryFn: () => getAccountName(queryType, account_no),
    onSuccess: (data) => {
      handleCloseError("nameEnquiry");

      formik.setFieldValue(
        "beneficiary_account_name",
        data.name.replace(",", "")
      );
    },
    onError: (err) => {
      setBackendError(
        "nameEnquiry",
        err?.response?.data?.detail || err.message
      );
      handleOpenError("nameEnquiry");
      formik.setFieldValue("beneficiary_account_name", "");
    },
    enabled: account_no.length === 10,
  });

  // get user profile
  const { data } = useQuery({
    queryKey: ["userProfile"],
    queryFn: userProfile,
  });

  const customerAccountNumber =
    data?.customer?.accounts?.map(({ account_no }) => account_no) || [];

  const { mutate, isLoading } = useMutation({
    mutationFn: bankTransfer,
    onSuccess: () => {
      setStep((prevS) => prevS + 1);
    },
    onError: (err) => {
      setBackendError(
        "transaction",
        err?.response?.data?.detail || err.message
      );
      handleOpenError("transaction");
    },
  });

  return (
    <Stack
      as="form"
      onSubmit={formik.handleSubmit}
      sx={styles.container}
      spacing={{ xs: 3, sm: 4 }}
    >
      <TransferModal
        open={open}
        handleClose={handleCloseModal}
        step={step}
        handleConfirmTransactionBtn={handleConfirmTransactionBtn}
        setStep={setStep}
        handleTransfer={handleTransfer}
        isLoading={isLoading}
        openError={openError.transaction}
        handleCloseError={() => handleCloseError("transaction")}
        errorMessage={error.transaction}
        formik={formik}
        error={otpError}
      />

      <DirectionSnackbar
        open={openError.nameEnquiry}
        handleClose={() => handleCloseError("nameEnquiry")}
        message={error.nameEnquiry}
      />

      <Typography sx={styles.header}>Self-Account Transfer</Typography>

      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Account Source">
          <Select
            options={customerAccountNumber}
            placeholder="Select Account Source"
            name="account_source"
            value={formik.values.account_source}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>

        <InputWithLabel label="Destination Account">
          <Select
            options={customerAccountNumber}
            placeholder="Select Destination Account"
            name="beneficiary_account_no"
            value={formik.values.beneficiary_account_no}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>
      </Stack>

      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Amount">
          <Input
            name="amount"
            type="tel"
            placeholder="Enter Amount"
            value={formik.values.amount}
            onChange={(event) => {
              validateNumbers(event, 1000, formik.handleChange);
            }}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>

        <InputWithLabel label="Narration">
          <Input
            name="narration"
            placeholder="Enter Description (Optional)"
            value={formik.values.narration}
            onChange={formik.handleChange}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>
      </Stack>

      <Button
        type="submit"
        variant="contained"
        color="green3"
        sx={styles.submitBtn}
      >
        Proceed
      </Button>
    </Stack>
  );
};

export default SelfAccountTransferForm;
