import TransactionHistoryTable from "components/CorporateTransactionLimits/TransactionHistoryTable/TransactionHistoryTable";

const TransactionHistory = () => {
  return (
    <>
      <TransactionHistoryTable />
    </>
  );
};

export default TransactionHistory;
