import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import BillsPaymentModal from "./BillsPaymentModal/BillsPaymentModal";
import { useFormik } from "formik";
import { billPaymentsTab } from "assets/data";
import { Link, useSearchParams } from "react-router-dom";
import {
  validateUniqueNumber,
  billPayments,
  createBeneficiary,
} from "services/corporateDashboard";
import { useMutation } from "@tanstack/react-query";
import {
  ElectricityPaymentSchema,
  CableTvSchema,
} from "services/Yup/BillsPaymentSchema";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import ElectricityPaymentForm from "./ElectricityPaymentForm";
//import AirtimePayment from "./AirtimePayment";
import CableTvForm from "./CableTvForm";
import CorporateDashboardContext from "context/CorporateDashboardContext";
import { styles } from "./BillsPayment.styles";
import AirtimeAndDataComponent from "./AirtimeAndData/AirtimeAndData";
import AirtimeAndData from "./Data/AirtimeAndData";

const BillsPaymentComponent = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [openModal, setOpenModal] = useState(false);
  //eslint-disable-next-line
  const [error, setError] = useState("");
  const [step, setStep] = useState(0);
  const [param] = useSearchParams();
  const currentTab = param.get("tab");
  const { accountNumbers } = useContext(CorporateDashboardContext);

  // get user profile

  const formik = useFormik({
    initialValues: {
      account: "",
      disco_type: "",
      disco_name: "",
      service_name: "",
      smart_card_no: "",
      product_name: "",
      product_codes: "",
      amount: "",
      meter_no: "",
      phone_number: "",
      transaction_pin: "",
      duration: "",
      saveBeneficiary: false,
    },
    validationSchema:
      currentTab === "electricity" ? ElectricityPaymentSchema : CableTvSchema,
    onSubmit: () => {
      setOpenModal(true);
    },
  });

  //   Buy electricity mutation
  const { mutate: BuyElectricity, isLoading } = useMutation({
    mutationFn: billPayments,
    onSuccess: (data) => {
      setSeverity("success");
      setMessage("Transaction successful");
      setOpen(true);
      setStep(1);
      console.log("BuyElectricity", data);
    },
    onError: (err) => {
      setSeverity("error");
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
    },
  });

  // save beneficiary mutation
  const { mutate: CreateBeneficiary } = useMutation({
    mutationFn: createBeneficiary,
  });

  //   validate Unique Number mutation
  const { mutate: ValidateUniqueNumber, data: cardInfo } = useMutation({
    mutationFn: validateUniqueNumber,
    onSuccess: () => {
      setSeverity("success");
      setMessage("Unique number is valid");
      setOpen(true);

      setTimeout(() => {
        setOpen(false);
      }, 5000);
    },
    onError: (err) => {
      setSeverity("error");
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
    },
  });

  //   cable Tv Subscription mutation
  const { mutate: CableTvSubscription, isLoading: loadingTV } = useMutation({
    mutationFn: billPayments,
    onSuccess: (data) => {
      setSeverity("success");
      setMessage("Transaction successful");
      setOpen(true);
      setStep(1);
      console.log("cableTvSubscription", data);
    },
    onError: (err) => {
      setSeverity("error");
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
    },
  });

  const validateSmartCard = () => {
    ValidateUniqueNumber({
      validate_type: "smart_card",
      smart_card_no: formik.values.smart_card_no,
      service_name: formik.values.service_name,
      account_type: "corporate",
    });
  };

  const validateMeterNumber = () => {
    ValidateUniqueNumber({
      validate_type: "meter",
      disco_type: formik.values.disco_type,
      meter_no: formik.values.meter_no,
      account_type: "corporate",
    });
  };

  const handleClose = () => {
    setOpenModal(false);
    setStep(0);
    formik.setFieldValue("transaction_pin", "");
  };

  const nextStep = () => {
    setStep((prevS) => prevS + 1);
  };

  const Subscribe = () => {
    if (formik.values.saveBeneficiary) {
      CreateBeneficiary({
        beneficiary_type: "utility",
        beneficiary_number: formik.values.meter_no
          ? formik.values.meter_no
          : formik.values.smart_card_no,
        biller_name: formik.values.disco_type
          ? formik.values.disco_type
          : formik.values.service_name,
        account_type: "corporate",
      });
    }

    if (currentTab === "electricity") {
      return BuyElectricity({
        account_no: formik.values.account?.slice(0, 10),
        disco_type: formik.values.disco_type,
        amount: formik.values.amount,
        meter_no: formik.values.meter_no,
        phone_number: formik?.values?.phone_number,
        payment_type: "electricity",
      });
    } else if (currentTab === "cable_tv") {
      return CableTvSubscription({
        account_no: formik.values.account?.slice(0, 10),
        amount: formik.values.amount,
        phone_number: formik?.values?.phone_number,
        service_name: formik.values.service_name,
        smart_card_no: formik.values.smart_card_no,
        product_codes: [formik.values.product_codes],
        duration: formik.values.duration.charAt(),
        payment_type: "cable_tv",
      });
    }
    return;
  };

  useEffect(() => {
    formik.resetForm();
    //eslint-disable-next-line
  }, [currentTab]);

  return (
    <Box sx={styles.container}>
      <DirectionSnackbar
        open={open}
        severity={severity}
        message={message}
        handleClose={() => {
          setOpen(false);
        }}
      />

      <BillsPaymentModal
        open={openModal}
        handleClose={handleClose}
        step={step}
        nextStep={nextStep}
        formik={formik}
        Subscribe={Subscribe}
        error={error}
        isLoading={isLoading || loadingTV}
      />

      <Typography sx={styles.header}>Bills Payment</Typography>

      <Box sx={styles.tabContainer}>
        <Stack direction="row" alignItems="center" sx={styles.tab}>
          {billPaymentsTab.map(({ label, href }, index) => (
            <Link key={index} to={href}>
              <Typography
                sx={styles.category(
                  label
                    .toLowerCase()
                    .includes(currentTab?.toLowerCase().split("_")[0])
                )}
              >
                {label}
              </Typography>
            </Link>
          ))}
        </Stack>
      </Box>

      <Stack
        as="form"
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        spacing={{ xs: 3, sm: 4 }}
        sx={{ ...styles.tab, width: { sm: "85%", lg: "70%" } }}
      >
        {currentTab === "electricity" && (
          <ElectricityPaymentForm
            formik={formik}
            styles={styles}
            validateMeterNumber={validateMeterNumber}
            cardInfo={cardInfo}
            accounts={accountNumbers || []}
          />
        )}

        {currentTab === "cable_tv" && (
          <CableTvForm
            formik={formik}
            styles={styles}
            validateSmartCard={validateSmartCard}
            cardInfo={cardInfo}
            accounts={accountNumbers || []}
          />
        )}

        {currentTab === "airtime" || currentTab === "data" ? (
          ""
        ) : (
          <Button
            type="submit"
            variant="contained"
            color="green3"
            sx={styles.submitBtn}
          >
            Proceed
          </Button>
        )}
      </Stack>
      {currentTab === "airtime" && <AirtimeAndDataComponent />}
      {currentTab === "data" && <AirtimeAndData />}
    </Box>
  );
};

export default BillsPaymentComponent;
