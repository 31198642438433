import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import UserAccountCard from "../UserAccountCard/UserAccountCard";
import Skeleton from "@mui/material/Skeleton";
import { userAccounts } from "assets/data";
import { styles } from "./AccountSummary.styles";

const AccountSummary = ({ data, isLoading }) => {
  const getAccountBalance = (account_no) => {
    return data?.account_balances?.find(
      (item) => item.account_no === account_no
    );
  };

  return (
    <Box sx={styles.container}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography sx={styles.header}>My Account Summary</Typography>
          <Typography sx={styles.subText}>Manage Your Bank Account</Typography>
        </Box>

        {/* <Button sx={styles.addNewBtn}>+ Add new</Button> */}
      </Stack>

      {isLoading ? (
        <Box sx={styles.loadingAnimation}>
          <Skeleton
            sx={{
              width: { xs: "100%", sm: 450, md: 500 },
              height: { xs: 190, sm: 250 },
              borderRadius: { xs: "15px", sm: "20px" },
              transform: "none",
            }}
          />
        </Box>
      ) : (
        <Box mt={2} sx={styles.cardContainer}>
          {data?.customer?.accounts?.map(
            ({ account_type, account_no, active }, index) => (
              <UserAccountCard
                key={account_no}
                firstName={data?.customer?.customer_detail?.first_name}
                lastName={data?.customer?.customer_detail?.last_name}
                type={account_type}
                AcNumber={account_no}
                bal={getAccountBalance(account_no)?.withdrawable_balance || 0}
                ledger_balance={
                  getAccountBalance(account_no)?.ledger_balance || 0
                }
                status={active}
                color={userAccounts[index].color}
                bgImg={userAccounts[index].bgImg}
              />
            )
          )}
        </Box>
      )}
    </Box>
  );
};

export default AccountSummary;
