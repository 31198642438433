import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { ReactComponent as InfoIcon } from "assets/svg/info.svg";
import { ReactComponent as SortIcon } from "assets/svg/sort.svg";
import { styled } from "@mui/material";
import { formatCurrency } from "utils/formatCurrency";
import { styles } from "./TransactionsTable.styles";

const createData = (date, transactionType, amount, desc, type) => {
  return { date, transactionType, amount, desc, type };
};

const Info = styled(InfoIcon)(() => ({
  marginLeft: "5px",
  verticalAlign: "middle",
}));

const TransactionsTable = ({ data, loading }) => {
  const rowData = (data || [1, 2, 3, 4, 5])?.map(
    ({ date, direction, amount, description, reference_no }) =>
      createData(date, reference_no, amount, description, direction)
  );

  return (
    <TableContainer component={Box} mt={2}>
      <Table
        sx={{ minWidth: { xs: "max-content", lg: 650 } }}
        aria-label="table"
      >
        <TableHead>
          <TableRow
            sx={{ ...styles.tableHeader, "th, td": { border: "none", py: 1 } }}
          >
            <TableCell sx={styles.headerText}>
              DATE <SortIcon style={{ verticalAlign: "middle" }} />
            </TableCell>
            <TableCell sx={styles.headerText}>
              REFERENCE NO <Info />
            </TableCell>
            {/* <TableCell sx={styles.headerText}>
              ACCOUNT <Info />
            </TableCell> */}
            <TableCell sx={styles.headerText}>AMOUNT</TableCell>
            {/* <TableCell sx={styles.headerText}>
              STATUS <Info />
            </TableCell> */}
            <TableCell sx={styles.headerText}>DESCRIPTION</TableCell>
            <TableCell sx={styles.headerText}>TYPE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData?.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                "&:nth-of-type(even)": { bgcolor: "rgba(247, 249, 252, 0.8)" },
                "&:last-child td, &:last-child th": { border: 0 },
                "th, td": { border: "none" },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  ...styles.contentText,
                  ...styles.textColor("green3.main"),
                }}
              >
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : (
                  new Date(row.date).toDateString().slice(4)
                )}
              </TableCell>
              <TableCell sx={styles.contentText}>
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : (
                  row.transactionType
                )}
              </TableCell>
              {/* <TableCell sx={styles.contentText}>{row.account}</TableCell> */}
              <TableCell sx={styles.contentText}>
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : (
                  formatCurrency(Number(row.amount))
                )}
              </TableCell>
              {/* <TableCell>
                <Typography
                  as='li'
                  sx={{
                    ...styles.contentText,
                    ...{
                      ...(row.status === 'SUCCESSFUL' &&
                        styles.status('green3.main', '#E1FCEF')),
                    },
                    ...{
                      ...(row.status === 'PENDING' &&
                        styles.status('orange1.main', '#FCF2E6')),
                    },
                    ...{
                      ...(row.status === 'FAILED' &&
                        styles.status('red.main', '#FCE6E6')),
                    },
                  }}
                >
                  {row.status}
                </Typography>
              </TableCell> */}
              <TableCell sx={styles.contentText}>
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : (
                  row.desc
                )}
              </TableCell>
              <TableCell
                sx={{
                  ...styles.contentText,
                  ...styles.textColor(
                    row.type === "Credit" ? "green" : "red.main"
                  ),
                }}
              >
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : (
                  row.type
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransactionsTable;
