export const styles = {
  container: {
    maxWidth: { xs: "95%", sm: "80%", lg: "75%" },
    mx: "auto",
  },
  header: {
    fontSize: { xs: 22, sm: 25, md: 28 },
    fontWeight: 700,
  },
  text: {
    fontSize: { xs: 16, md: 18 },
    fontWeight: 500,
  },
  viewAll: {
    fontSize: { xs: 14, md: 16 },
    fontWeight: 500,
    "&:hover": {
      color: "green3.main",
    },
  },
  beneficiaryContainer: {
    display: { xs: "flex", sm: "grid" },
    gridTemplateColumns: "repeat(auto-fit, minmax(20px, 120px))",
    gap: 1,
    flexWrap: "no-wrap",
    overflowX: "auto",
    minWidth: "max-content",
  },
  beneficiary: {
    width: { xs: 82, sm: "auto" },
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    overflow: "hidden",
  },
  beneficiaryInitials: (bgcolor) => ({
    width: { xs: 45, lg: 60 },
    height: { xs: 45, lg: 60 },
    justifyContent: "center",
    alignItems: "center",
    fontSize: 18,
    fontWeight: 600,
    borderRadius: "50%",
    bgcolor,
    color: "white.main",
    mx: "auto",
  }),
  beneficiaryName: {
    fontSize: { xs: 12, lg: 14 },
    fontWeight: 500,
    textAlign: "center",
    lineHeight: { xs: "16px", lg: "18px" },
    mt: "4px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textTransform: "capitalize",
  },
};
