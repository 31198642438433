export const styles = {
  toggleVisibility: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "green8.main",
    borderRadius: "5px",
    width: 30,
    height: 30,
    zIndex: 2,
  },
  hideText: { fontSize: { xs: 5, sm: 5, lg: 5 } },
};
