import * as React from "react";
import AuthLayout from "layout/Auth/Auth.layout";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SetUpPinForm from "components/Register/SetUpPinForm";
import SetUpPasswordForm from "components/Register/SetUpPasswordForm";
import ConfirmationModal from "components/base/ConfirmationModal/ConfirmationModal";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import { registerUser, requestSignUpOtp } from "services/Auth";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import {
  SetUpPinSchema,
  SetUpPasswordSchema,
} from "services/Yup/RegisterSchema";
import { useNavigate } from "react-router-dom";
import { styles } from "./Register.styles";

const Register = () => {
  const [active, setActive] = React.useState(1);
  const [open, setOpen] = React.useState({
    otp: false,
    successfullyRegistered: false,
  });
  const [openError, setOpenError] = React.useState(false);
  const [otpError, setOtpError] = React.useState("");
  const navigate = useNavigate();

  const closeError = () => {
    setOpenError(false);
  };

  const handleError = (err) => {
    const error =
      err?.response?.status === 500
        ? "Service unavailable. Try later"
        : err?.response?.data?.detail || err.message;
    setOtpError(error);
    setOpenError(true);
  };

  const handleOpen = (type) => setOpen((prevS) => ({ ...prevS, [type]: true }));
  const handleClose = (type) =>
    setOpen((prevS) => ({ ...prevS, [type]: false }));

  const navigateActiveForm = (form) => {
    setActive(form);
  };

  const activeValidationSchema =
    active === 1 ? SetUpPinSchema : SetUpPasswordSchema;

  const { mutate, isLoading } = useMutation({
    mutationFn: registerUser,
    onSuccess: (data) => {
      handleOpen("successfullyRegistered");
    },
    onError: (err) => {
      handleError(err);
    },
  });

  const { mutate: requestOtp, isLoading: requestingOtp } = useMutation({
    mutationFn: requestSignUpOtp,
    onSuccess: (data) => {
      handleOpen("otp");
    },
    onError: (err) => {
      handleError(err);
    },
  });

  const formik = useFormik({
    initialValues: {
      accountNumber: "",
      username: "",
      transactionPin: "",
      confirmTransactionPin: "",
      otp: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: activeValidationSchema,
    onSubmit: (values) => {
      if (active <= 1) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });

        return setActive((prevS) => prevS + 1);
      }
      mutate({
        account_no: values.accountNumber,
        username: values.username,
        transaction_pin: `${values.transactionPin}`,
        transaction_pin_confirm: `${values.confirmTransactionPin}`,
        password: values.password,
        password_confirm: values.confirmPassword,
        otp: `${values.otp}`,
        bank_id: process.env.REACT_APP_BANK_ID,
      });
    },
  });

  const getOtp = () => {
    requestOtp({
      account_no: formik.values.accountNumber,
      bank_id: process.env.REACT_APP_BANK_ID,
    });
  };

  const navigateToLogin = () => {
    navigate("/auth/sign-in");
  };

  return (
    <AuthLayout image="/img/auth-3.png">
      <DirectionSnackbar
        open={openError}
        handleClose={closeError}
        message={otpError}
      />

      <ConfirmationModal
        open={open.otp}
        handleClose={() => handleClose("otp")}
        mainText="OTP Sent"
        subText="An OTP has been sent to your Phone Number and Email Address."
        btnText="Continue"
        handleClick={() => handleClose("otp")}
      />

      <ConfirmationModal
        open={open.successfullyRegistered}
        handleClose={() => handleClose("successfullyRegistered")}
        mainText="Success"
        subText="Your Device has been Successfully Registered
        Proceed to Login for the First Time."
        btnText="Continue"
        handleClick={navigateToLogin}
      />

      <Box sx={styles.container}>
        <Typography sx={styles.header}>
          {active === 1 ? "Setup Pin" : "Set Up your Password"}
        </Typography>
        <Typography sx={styles.subText}>
          Please create a secure password including the following criteria
          below:
        </Typography>

        <Stack direction="row" spacing={1} mt={2} mb={5}>
          {[1, 2].map((tab) => (
            <Box
              key={tab}
              sx={styles.progressIndicator(active, tab)}
              onClick={() => navigateActiveForm(tab)}
            />
          ))}
        </Stack>

        <Stack spacing={3} as="form" onSubmit={formik.handleSubmit}>
          {active === 1 && (
            <SetUpPinForm
              requestingOtp={requestingOtp}
              formik={formik}
              getOtp={getOtp}
            />
          )}
          {active === 2 && <SetUpPasswordForm formik={formik} />}
          <Box pb={2} />
          <Button
            variant="contained"
            type="submit"
            color="green3"
            sx={styles.submitBtn}
          >
            {isLoading ? "Submitting..." : active === 2 ? "Submit" : "Proceed"}
          </Button>
        </Stack>
      </Box>
    </AuthLayout>
  );
};

export default Register;
