export const styles = {
  navItem: (selected) => ({
    width: { xs: 35, sm: 48 },
    height: { xs: 35, sm: 48 },
    borderRadius: '16px',
    bgcolor: '#F4F5F7',
    fontSize: { xs: 14, sm: 16 },
    fontWeight: 500,
    color: selected ? 'green3.main' : '#8992A9',
    border: '1px solid',
    borderColor: selected ? 'green3.main' : '#F4F5F7',
    userSelect: 'none',
    cursor: 'pointer',
  }),
  nextBtn: (disabled) => ({
    width: { xs: 60, sm: 72 },
    height: { xs: 35, sm: 48 },
    borderRadius: '16px',
    bgcolor: disabled ? 'gray6.main' : 'green3.main',
    fontSize: { xs: 14, sm: 16 },
    fontWeight: 500,
    color: 'white.main',
    border: 'none',
    userSelect: 'none',
    cursor: disabled ? 'auto' : 'pointer',
  }),
  pageInfo: {
    fontSize: 14,
    fontWeight: 500,
  },
};
