import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
//import TablePagination from "components/base/TablePagination/TablePagination";
import TransactionsTable from "../TransactionsTable/TransactionsTable";
import { styles } from "./TransactionHistoryTable.styles";
//import { useQuery } from "@tanstack/react-query";
//import { getTransferReq } from "services/corporateDashboard";
//import { useState } from "react";

const TransactionHistoryTable = () => {
  //
  /* const [currentPage, setCurrentPage] = useState();
  const setPage = (page) => {
    setCurrentPage(page);
  }; */

  console.log(JSON.parse(sessionStorage.getItem("signatories")));
  const data = JSON.parse(sessionStorage.getItem("signatories"));
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.header}>Signatories</Typography>

      <TransactionsTable
        data={data}
        dataHead={["NAME", "EMAIL", "LEVEL", "PHONE NUMBER", "STATUS", ""]}
        //loading={loading}
      />
    </Box>
  );
};

export default TransactionHistoryTable;
