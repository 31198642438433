import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const InputWithLabel = ({ label, children }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography
        sx={{
          fontSize: { xs: 14, sm: 16 },
          fontWeight: { xs: 500, sm: 600 },
          color: 'gray2.main',
          mb: '0.35rem',
        }}
      >
        {label}
      </Typography>
      {children}
    </Box>
  );
};

export default InputWithLabel;
