import { useState } from "react";

const TablePagination = ({ transactions, action }) => {
  const [start, setStart] = useState(1);
  //eslint-disable-next-line
  const [end, setEnd] = useState(10);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 30px",
      }}
    >
      <div>
        Showing {start || 0} -{" "}
        {transactions?.results?.length >= 10
          ? end || 0
          : transactions?.results?.length + start - 1 || 0}{" "}
        entries of {transactions?.count || 0}
      </div>

      <div
        className="buttons"
        style={{
          display: "flex",
        }}
      >
        <button
          onClick={() => {
            if (!transactions?.previous) {
              console.log("end");
            } else {
              const prev = async () => {
                try {
                  const data = await action(transactions?.previous);
                  setStart((current) => current - 10);
                  setEnd((current) => current - 10);
                  console.log(data);
                } catch (e) {
                  console.log(e);
                }
              };

              prev();
            }
          }}
          style={{
            backgroundColor: !transactions?.previous ? "#fff" : "#D22F31",
            border: "1px solid #E0E0E0",
            borderRadius: "14px",
            color: !transactions?.previous ? "#CCCCCC" : "#fff",
            cursor: !transactions?.previous ? "default" : "pointer",
            padding: "18px 20px ",
            display: "flex",
            alignItems: "center",
            fontSize: "16px",
          }}
        >
          Prev
        </button>
        &nbsp; &nbsp; &nbsp;
        <button
          onClick={() => {
            if (!transactions?.next) {
              console.log("end");
            } else {
              const next = async () => {
                try {
                  const data = await action(transactions?.next);
                  setStart((current) => current + 10);
                  setEnd((current) => current + 10);
                  console.log(data);
                } catch (e) {
                  console.log(e);
                }
              };

              next();
            }
          }}
          style={{
            backgroundColor: !transactions?.next ? "#fff" : "#D22F31",
            border: "1px solid #E0E0E0",
            borderRadius: "18px",
            color: !transactions?.next ? "#CCCCCC" : "#fff",
            cursor: !transactions?.next ? "default" : "pointer",
            padding: "18px 20px ",
            display: "flex",
            alignItems: "center",
            fontSize: "16px",
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TablePagination;
