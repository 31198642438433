import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import { styles } from "./TransactionHistoryTable.styles";
import { useQuery } from "@tanstack/react-query";
import { getTransferReq } from "services/corporateDashboard";
import { formatCurrency } from "utils/formatCurrency";
import { Button } from "@mui/material";
import { useState } from "react";
import ApproveTransactionLimit from "components/CorporateProfile/ProfileForm/ApproveTransactionLimit";

const TransactionHistoryTable = () => {
  //

  const { data /* loading */ } = useQuery({
    queryKey: ["transfer_req"],
    queryFn: () => getTransferReq(),
  });

  /*   const [datum, setData] = useState();

  const sendReq = async (page) => {
    Axios.get(page)
      .then((res) => {
        console.log(res);
        setData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }; */
  console.log(data?.results);

  const addBeneficiartBtn = {
    width: { xs: "fit-content", sm: 288 },
    bgcolor: "rgba(45, 202, 114, 0.2)",
    color: "rgb(45, 202, 113)",
    fontSize: { xs: 14, lg: 16 },
    fontWeight: 600,
    boxShadow: "none",
    alignSelf: { xs: "flex-end", sm: "auto" },
    "&:hover": { bgcolor: "rgba(45, 202, 114, 0.2)" },
    marginTop: "20px",
  };

  const addBeneficiartBtn2 = {
    width: { xs: "fit-content", sm: 288 },
    bgcolor: "rgba(202, 45, 45, 0.2)",
    color: "rgb(255, 1, 1)",
    fontSize: { xs: 14, lg: 16 },
    fontWeight: 600,
    boxShadow: "none",
    alignSelf: { xs: "flex-end", sm: "auto" },
    "&:hover": { bgcolor: "rgba(202, 45, 45, 0.2)" },
    marginTop: "20px",
  };
  //TRANSACTION LIMIT

  const [step2, setStep2] = useState(0);

  const [open2, setOpen2] = useState(false);

  const [error2, setError2] = useState("");
  const [action, setAction] = useState("");

  const nextStep2 = () => {
    setStep2((current) => current + 1);
  };

  const handleClose2 = () => {
    setOpen2(false);
    setStep2(0);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleError2 = (err) => {
    setError2(err?.response?.data?.detail || err?.message || err);
  };
  const limits = JSON.parse(localStorage.getItem("limits"));
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.header}>Approval : Transaction Limits</Typography>

      <ApproveTransactionLimit
        open={open2}
        handleClose={handleClose2}
        step={step2}
        error={error2}
        handleError={handleError2}
        handleOpen={handleOpen2}
        nextStep={nextStep2}
        action={action}
        dailyLimit={limits?.daily_transfer_limit}
        transactionLimit={limits?.total_transfer_limit}
      />
      {/* <TransactionsTable
        data={datum?.results || data?.results}
        dataHead={[
          "BATCH NAME",
          "NAME",
          "BANK NAME",
          "BANK ACCOUNT",
          "AMOUNT",
          "ACTIONS",
          "",
        ]}
        loading={loading}
      />

      <TablePagination transactions={datum || data} action={sendReq} /> */}
      {/* 
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 3, sm: 4, lg: 5 }}
      >
        <InputWithLabel label="Transaction Limit">
          <Input value="100,100" disabled sx={styles.input} />
        </InputWithLabel>
      </Stack> */}
      <Box sx={{ padding: "20px" }}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 3, sm: 4, lg: 5 }}
        >
          <InputWithLabel label="Transaction Limit">
            <Input
              value={formatCurrency(limits?.total_transfer_limit)}
              disabled
              sx={styles.input}
            />
          </InputWithLabel>

          <InputWithLabel label="Daily Limit">
            <Input
              value={formatCurrency(limits?.daily_transfer_limit)}
              disabled
              sx={styles.input}
            />
          </InputWithLabel>
        </Stack>
        <Box sx={{ dispay: "flex" }} spacing={{ xs: 3, sm: 4, lg: 5 }}>
          <Button
            variant="contained"
            onClick={() => {
              handleOpen2();
              setAction("approve");
            }}
            sx={addBeneficiartBtn}
          >
            Approve
          </Button>
          &nbsp; &nbsp;
          <Button
            onClick={() => {
              handleOpen2();
              setAction("decline");
            }}
            variant="contained"
            sx={addBeneficiartBtn2}
          >
            Decline
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TransactionHistoryTable;
