import * as Yup from 'yup';

export const SetUpPinSchema = Yup.object().shape({
  accountNumber: Yup.string()
    .min(10, 'Enter correct 10 digits')
    .max(10, 'Enter correct 10 digits')
    .required('Enter account number'),
  username: Yup.string().required('Please set your username'),
  transactionPin: Yup.string().max(4, 'Enter 4 digits').required('Enter transaction pin'),
  confirmTransactionPin: Yup.string()
    .required('Please retype your transaction pin')
    .oneOf([Yup.ref('transactionPin')], 'Transaction pin does not match'),
  otp: Yup.string().required('Enter OTP'),
});

export const SetUpPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(/(^[0-9]*$)/, 'Only numbers are allowed')
    .min(6, 'Password must be 6 digits')
    .max(6, 'Password must be 6 digits')
    .required('Enter password'),
  confirmPassword: Yup.string()
    .required('Please retype your password')
    .oneOf([Yup.ref('password')], 'Password does not match'),
});
