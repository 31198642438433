export const styles = {
  body: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", sm: "70%", md: 550, xl: 674 },
    bgcolor: "white.main",
    boxShadow: 24,
    px: { xs: 2, sm: 4 },
    py: { xs: 3, sm: 4 },
    borderRadius: { xs: "20px", sm: "30px", xl: "40px" },
    boxSizing: "border-box",
    outline: "none",
  },
  closeBtn: {
    position: "absolute",
    right: "0%",
    transform: "translate(-100%, 0%)",
    width: { xs: 20, sm: 25 },
    height: { xs: 20, sm: 25 },
    cursor: "pointer",
  },
};
