export const styles = {
  barStyle: {
    position: 'absolute',
    width: 25,
    height: 2,
    bgcolor: 'green1.main',
    display: 'inline-block',
    transition: '0.3s ease',
    left: 0,
  },

  top: {
    transform: 'rotate(45deg)',
    transformOrigin: 'top left',
    width: 30,
    top: 0,
    left: '2px',
  },

  middle: {
    transform: 'translateX(-20px)',
    opacity: 0,
  },

  bottom: {
    transform: 'rotate(-45deg)',
    transformOrigin: 'top left',
    width: 30,
    bottom: '-8px',
    boxShadow: '0 0 5px #495057',
  },
};
