export const formatCurrency = (currency, minDigit = 0) => {
  return currency?.toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: minDigit,
  });
};


export const kFormatter = (num) => {
  return `${num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
};
