import * as React from "react";
import Box from "@mui/material/Box";
import BulkTransfer from "components/CorporateScheduledTransfer/BulkTransfer/TransferToCitForm";
import SingleTransfer from "components/CorporateScheduledTransfer/SingleTransfer/TransferToCitForm";
import TransactionTable from "components/CorporateScheduledTransfer/TransactionsTable/TransactionsTable";
import { useFormik } from "formik";
import {
  TransferToCitScheduleSchema,
  TransferToCitScheduleBulkSchema,
} from "services/Yup/TransferToCitSchema";
import { Button, Typography } from "@mui/material";
import MiniTab from "../components/MiniTab/MiniTab";
import { corporateTransferTab } from "assets/data";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSchedules } from "services/corporateDashboard";
import TablePagination from "components/base/TablePagination2/TablePagination";

const TransferToCit = () => {
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [param] = useSearchParams();
  const activeTab = param.get("tab");
  const [loading, setLoading] = React.useState(false);

  //eslint-disable-next-line
  const [data, setData] = React.useState();
  const getTheSchedules = async () => {
    setLoading(true);
    try {
      const data = await getSchedules("");
      setData(data);
      setLoading(false);

      console.log(data);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const action = async (page) => {
    setLoading(true);

    try {
      const data = await getSchedules(page);
      setData(data);
      console.log(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getTheSchedules();
  }, []);

  const handleOpenModal = () => setOpen(true);

  const formik = useFormik({
    initialValues: {
      account: "",
      amount: "",
      beneficiary_account_no: "",
      beneficiary_account_name: "",
      frequency: "",
      frequencyType: "",
      fromDate: "",
      toDate: "",
      narration: "",
      saveBeneficiary: false,
      transaction_pin: "",
    },
    validationSchema:
      activeTab === "single"
        ? TransferToCitScheduleSchema
        : TransferToCitScheduleBulkSchema,
    onSubmit: () => {
      handleOpenModal();
    },
  });
  const router = useNavigate();

  React.useEffect(() => {
    console.log(query);
  }, [query]);

  const header = {
    width: { xs: "95%", md: "90%", lg: "80%" },
    fontSize: { xs: 22, sm: 25, md: 28 },
    fontWeight: 700,
    mx: "auto",
  };

  const addBeneficiartBtn = {
    width: { xs: "fit-content", sm: 288 },
    bgcolor: "#d22f3244",
    color: "#D22F31",
    fontSize: { xs: 14, lg: 16 },
    fontWeight: 600,
    boxShadow: "none",
    alignSelf: { xs: "flex-end", sm: "auto" },
    "&:hover": { bgcolor: "#d22f3234" },
  };

  return (
    <Box
      sx={{
        bgcolor: "white.main",
        boxShadow: "0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)",
        borderRadius: "10.2375px",
        py: { xs: 3, sm: 5 },
      }}
    >
      {activeTab === "table" ? (
        <div style={{ display: "flex", padding: "0px 14px" }}>
          <Typography sx={header}>Scheduled Payments</Typography>

          <Button
            variant="contained"
            sx={addBeneficiartBtn}
            onClick={() => {
              router("/corporate-scheduled-payments?tab=single");
            }}
          >
            + Add New Schedule
          </Button>
        </div>
      ) : (
        <div>
          <div
            style={{
              cursor: "pointer",
              width: "90%",
              margin: "0 auto",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
            onClick={() => {
              router("/corporate-scheduled-payments?tab=table");
            }}
          >
            <div
              style={{
                cursor: "pointer",
                width: "fit-content",
                fontWeight: "medium",
                marginBottom: "20px",
                background: "#d22f3233",
                color: "#D22F31",
                padding: "10px",
              }}
            >
              Back to Table
            </div>
          </div>
          <MiniTab
            title="Scheduled Payments"
            tabOnChange={() => {
              setQuery("");
              formik.resetForm();
            }}
            data={corporateTransferTab}
          />
        </div>
      )}
      {activeTab === "table" && (
        <>
          <TransactionTable
            data={data?.results}
            action={getTheSchedules}
            loading={loading}
            dataHead={[
              "TRANSACTION OPTION",
              "AMOUNT",
              "DATE CREATED",
              "FREQUENCY",
              "TRANSACTION DAY",
              "COMPLETED",
              "STATUS",
              "ACTIONS",
              "",
              "",
              "",
            ]}
          />

          <TablePagination transactions={data} action={action} />
        </>
      )}

      {activeTab === "single" && (
        <SingleTransfer
          action={getTheSchedules}
          formik={formik}
          open={open}
          setOpen={setOpen}
        />
      )}
      {activeTab === "bulk" && (
        <BulkTransfer
          formik={formik}
          open={open}
          setOpen={setOpen}
          action={getTheSchedules}
        />
      )}
    </Box>
  );
};

export default TransferToCit;
