import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import CustomModal from "components/base/Modal/Modal";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import { useMutation } from "@tanstack/react-query";
import OTP from "components/base/OTP/OTP";
import { changePassword } from "services/Auth";
import { useFormik } from "formik";
import { styles } from "components/SavedBeneficiaries/AddNewCitmfbBeneficiaryModal/AddNewCitmfbBeneficiaryModal.styles";

const ChangePasswordModal = ({
  open,
  handleClose,
  step,
  error,
  handleOpen,
  handleError,
  nextStep,
}) => {
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const { mutate: ChangePassword, isLoading: changingPassword } = useMutation({
    mutationFn: changePassword,
    onSuccess: () => {
      nextStep("changePassword");
      formik.resetForm();
    },
    onError: (err) => {
      handleError("changePassword", err);
      handleOpen("error");
    },
  });

  const handleChangePassword = () => {
    const { oldPassword, newPassword, confirmPassword } = formik.values;

    if (!oldPassword || !newPassword || !confirmPassword) {
      handleError("changePassword", "Fill all fields to proceed");
      return handleOpen("error");
    }

    if (newPassword === confirmPassword) {
      ChangePassword({
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      });
    } else {
      handleError("changePassword", "Old and new pin must be the same");
      handleOpen("error");
    }
  };

  return (
    <CustomModal
      open={open.changePassword}
      handleClose={() => {
        handleClose("changePassword");
        formik.resetForm();
      }}
    >
      <DirectionSnackbar
        open={open.error}
        handleClose={() => handleClose("error")}
        message={error}
        direction={{ vertical: "top", horizontal: "center" }}
      />

      <Box sx={styles.container} as="form">
        {step === 0 && (
          <>
            <Stack spacing={3} sx={styles.content} alignItems="center">
              <Typography sx={styles.header}>Change Password</Typography>

              <InputWithLabel label="Old Password">
                <OTP
                  formik={formik}
                  formikKey="oldPassword"
                  variant={2}
                  length={6}
                />
              </InputWithLabel>

              <InputWithLabel label="New Password">
                <OTP
                  formik={formik}
                  formikKey="newPassword"
                  variant={2}
                  length={6}
                />
              </InputWithLabel>

              <InputWithLabel label="Confirm Password">
                <OTP
                  formik={formik}
                  formikKey="confirmPassword"
                  variant={2}
                  length={6}
                />
              </InputWithLabel>

              <Box sx={{ height: 10 }} />

              <Box mx="auto" width="fit-content" mt={{ xs: 3, sm: 5 }}>
                <Button
                  variant="contained"
                  color="green3"
                  sx={styles.btn}
                  disabled={changingPassword}
                  onClick={handleChangePassword}
                >
                  {changingPassword ? "Please wait" : "Proceed"}
                </Button>
              </Box>
            </Stack>
          </>
        )}

        {step === 1 && (
          <>
            <Box
              as="img"
              src="/img/successful_gif.gif"
              alt="successful"
              sx={styles.gif}
            />
            <Stack spacing={3} sx={styles.content} alignItems="center">
              <Typography sx={styles.header}>
                Password Change Successful
              </Typography>
              <Typography sx={styles.subText}>
                Your Transaction Password has been succesfully changed.
              </Typography>
            </Stack>

            <Box mx="auto" width="fit-content" mt={{ xs: 3, sm: 5 }}>
              <Button
                variant="contained"
                color="green3"
                sx={styles.btn}
                onClick={() => {
                  handleClose("changePassword");
                  formik.resetForm();
                }}
              >
                Done
              </Button>
            </Box>
          </>
        )}
      </Box>
    </CustomModal>
  );
};

export default ChangePasswordModal;
