import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: { main: '#4083D4', light: '#399AF4' },
        secondary: { light: '#F58220', main: '#0044ff', contrastText: '#ffcc00' },
        white: { main: '#FFFFFF' },
        black: { main: '#000000' },
        green1: { main: '#D22F31' },
        green2: { main: '#D22F31' },
        green3: { main: '#D22F31' },
        green4: { main: '#D22F31' },
        green5: { main: '#D22F31' },
        green6: { main: '#D22F31' },
        green7: { main: '#D22F31' },
        green8: { main: '#D22F31' },
        green9: { main: '#D22F31' },
        green10: { main: '#D22F31' },
        yellow: { main: '#FFDE00' },
        blue: { main: '#202047' },
        blue1: { main: '#0AE8CD' },
        blue2: { main: '#D22F3133' },
        gray1: { main: '#A7A9AB' },
        gray2: { main: '#263A43' },
        gray3: { main: '#D22F31' },
        gray4: { main: '#D22F31' },
        gray5: { main: '#B9B8B8' },
        gray6: { main: '#D9D9D9' },
        gray7: { main: '#454545' },
        gray8: { main: '#727272' },
        gray9: { main: '#E9EDF5' },
        gray10: { main: '#A1A9B8' },
        gray11: { main: '#FAFAFA' },
        red: { main: '#FF0505' },
        orange: { main: '#FF6701' },
        orange1: { main: '#AA5B00' },
        pink: { main: '#EF5DA8' },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        fontSize: 14,
        htmlFontSize: 16,
        fontFamily: 'Manrope, DM Sans, sans-serif',
    },
});