export const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    height: { xs: 150, md: 200 },
    background: 'url(/img/dashboard4.webp) 50% 50% no-repeat',
    backgroundColor: "#C0C0C080",
    backgroundSize: 'cover',
    borderRadius: '12px',
    px: { xs: 3, md: 6 },
  },
  managerImg: {
    width: { xs: 60, md: 80 },
    height: { xs: 60, md: 80 },
    borderRadius: '50%',
  },
  text: { fontSize: { xs: 20, sm: 25 }, fontWeight: 700, ml: 3 },
};
