import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import UserDashboardContext from 'context/UserDashboardContext';

const ActivitiesTimeFrame = () => {
  const {
    options,
    open,
    anchorEl,
    selectedIndex,
    handleClickListItem,
    handleClose,
    handleMenuItemClick,
  } = React.useContext(UserDashboardContext);

  return (
    <div>
      <Button
        sx={{ fontSize: 12, fontWeight: 600, color: '#0F2552', p: '3px' }}
        onClick={handleClickListItem}
      >
        {options[selectedIndex].value}
      </Button>
      <Menu
        id='lock-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
            sx={{ fontSize: 14 }}
          >
            {option.value}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ActivitiesTimeFrame;
