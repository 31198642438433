import * as React from "react";
import { servicesAndTransactions, activity } from "services/corporateDashboard";
import { useQuery } from "@tanstack/react-query";
import { formatCurrency } from "utils/formatCurrency";

const options = [
  { param: "last_7_days=true", value: "Last 7 Days" },
  { param: "this_month=true", value: "This Month " },
  { param: "this_year=true", value: "This Year " },
  // { param: '', value: 'Custom' },
];

const CorporateDashboardContext = React.createContext(null);

export const CorporateDashboardProvider = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(2);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //const param = options[selectedIndex].param;

  const { data, isLoading } = useQuery({
    queryKey: ["services", ""],
    queryFn: () => servicesAndTransactions(""),
  });
  const param = options[selectedIndex].param;

  const { data: data2, isLoading: loading2 } = useQuery({
    queryKey: ["activity", param],
    queryFn: () => activity(param),
  });

  const accountNumbers = data?.data?.account_balances?.map(
    (x) => x?.account_no + " - " + formatCurrency(x?.withdrawable_balance)
  );
  console.log(accountNumbers, "acct");
  const passwordChanged = data?.data?.password_changed;

  console.log(data2?.recent_transactions, "me");
  console.log(passwordChanged, "me");

  return (
    <CorporateDashboardContext.Provider
      value={{
        options,
        open,
        anchorEl,
        selectedIndex,
        handleClickListItem,
        handleClose,
        handleMenuItemClick,
        data: data?.data,
        activity: data2,
        activityLoading: loading2,
        accountNumbers: accountNumbers,
        recentTransactions: data2?.recent_transactions,
        passwordChanged,
        isLoading,
      }}
    >
      {children}
    </CorporateDashboardContext.Provider>
  );
};

export default CorporateDashboardContext;
