import { useState } from "react";
import Box from "@mui/material/Box";
import CameraIcon from "assets/svg/camera.svg";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Input from "components/base/Input/Input";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import { updateProfile } from "services/Auth";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { styles } from "./ProfileBanner.styles";

const ProfileBanner = ({ data, isLoading }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("Image upload in progress");
  const [severity, setSeverity] = useState("warning");

  const handleClose = () => {
    setOpen(false);
  };

  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: updateProfile,
    onSuccess: () => {
      setSeverity("success");
      setMessage("Profile Picture updated");
      setOpen(true);

      queryClient.invalidateQueries("profile");

      setTimeout(() => {
        setOpen(false);
      }, 5000);
    },
    onError: (err) => {
      setSeverity("error");
      setMessage(err?.response?.data?.detail || err.message);
      setOpen(true);

      setTimeout(() => {
        setOpen(false);
      }, 5000);
    },
  });

  const uploadProfilePhoto = (event) => {
    if (
      !["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(
        event.target.files[0].type
      )
    ) {
      setSeverity("error");
      setMessage("File is not supported");
      setOpen(true);
      return;
    }

    let form = new FormData();
    const file = event.target.files[0];
    // const file = URL.createObjectURL(event.target.files[0]);

    form.append("profile_picture", file);
    mutate(form);
  };

  const imgSrc = data?.customer?.image
    ? data?.customer?.image
    : data?.customer?.gender === "Male"
    ? "/img/male.webp"
    : "/img/female.webp";

  return (
    <>
      <DirectionSnackbar
        open={open}
        handleClose={handleClose}
        message={message}
        severity={severity}
      />

      <Typography sx={styles.text}>Profile</Typography>
      <Box sx={styles.container}>
        {isLoading ? (
          <Skeleton sx={{ ...styles.userImg, transform: "none" }} />
        ) : (
          <Box
            sx={{ position: "relative", display: "flex", alignItems: "center" }}
          >
            <Box
              as="img"
              src={imgSrc}
              alt="account manager"
              sx={styles.userImg}
            />
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Box
                as="img"
                src={CameraIcon}
                alt="account manager"
                sx={styles.camera}
              />
              <Input
                type="file"
                sx={styles.file}
                onChange={uploadProfilePhoto}
                accept="image/*, image/png, image/jpeg, image/jpg"
              />
            </Box>
          </Box>
        )}

        {data ? (
          <Typography sx={styles.profileName}>{data?.first_name} </Typography>
        ) : (
          <Skeleton width="8rem" sx={styles.profileName} />
        )}
      </Box>
    </>
  );
};

export default ProfileBanner;
