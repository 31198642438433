import {
  Axios
} from "utils/Axios";

export const servicesAndTransactions = async (param) => {
  const {
    data
  } = await Axios.get(
    `${process.env.REACT_APP_API}corporate/dashboard/`
  );
  return data;
};

export const activity = async (param) => {
  const {
    data
  } = await Axios.get(
    `${process.env.REACT_APP_API}account/dashboard/${process.env.REACT_APP_BANK_ID}?account_type=corporate&${param}`
  );
  return data;
};

export const getAllAccountOfficers = async (param) => {
  const {
    data
  } = await Axios.get(
    `${process.env.REACT_APP_API}corporate/account-officer/`
  );
  return data;
};

export const sendOtpReq = async () => {
  const {
    data
  } = await Axios.get(
    `${process.env.REACT_APP_API}corporate/send-token/`
  );
  return data;
};

export const transferReq = async (body) => {
  const {
    data
  } = await Axios.post(
    `${process.env.REACT_APP_API}corporate/transfer-request/`,
    body
  );
  console.log(data, 'kkklll')
  localStorage.setItem('transfer_data', JSON.stringify(data))

  return data;
};
export const completeRequest = async (body) => {
  console.log(body);
  const {
    data
  } = await Axios.put(
    `${process.env.REACT_APP_API}corporate/transfer-request/${body?.id}/`,
    body
  );
  return data;
};

export const completeRequestBills = async (body) => {
  console.log(body);
  const {
    data
  } = await Axios.put(
    `${process.env.REACT_APP_API}corporate/bill-payment/${body?.id}/`,
    body
  );
  return data;
};

export const bulkTransferReq = async (body) => {
  const {
    data
  } = await Axios.post(
    `${process.env.REACT_APP_API}corporate/bulk-transfer/`,
    body
  );
  return data;
};

export const approveBulkTransferReq = async (body) => {
  const {
    data
  } = await Axios.put(
    `${process.env.REACT_APP_API}corporate/transfer-request/${body?.id}/`,
    body
  );
  return data;
};
export const getBulkTransferReq = async (body) => {
  const {
    data
  } = await Axios.get(
    `${process.env.REACT_APP_API}corporate/bulk-transfer${body||''}`
  );
  return data;
};

export const bulkAirtime = async (body) => {
  const {
    data
  } = await Axios.post(
    `${process.env.REACT_APP_API}corporate/bulk-payment/`,
    body
  );
  return data;
};
export const getBulkAirtime = async (body) => {
  const {
    data
  } = await Axios.get(
    `${process.env.REACT_APP_API}corporate/bulk-payment${body||''}`
  );
  return data;
};
export const billPayments = async (body) => {
  const {
    data
  } = await Axios.post(
    `${process.env.REACT_APP_API}corporate/bill-payment/`,
    body
  );
  return data;
};

export const billPaymentsList = async (body) => {
  const {
    data
  } = await Axios.get(
    `${process.env.REACT_APP_API}corporate/bill-payment?payment_type=${body}`,
    body
  );
  return data;
};

export const uploadBulkFile = async (body) => {
  const {
    data
  } = await Axios.post(
    `${process.env.REACT_APP_API}corporate/upload/`,
    body
  );
  return data;
};

export const updateTransReq = async (body) => {
  const {
    data
  } = await Axios.put(
    `${process.env.REACT_APP_API}corporate/transfer-request/`,
    body
  );
  return data;
};
export const setNewTransactionLimit = async (body) => {
  const {
    data
  } = await Axios.put(
    `${process.env.REACT_APP_API}corporate/limit/`,
    body
  );
  return data;
};
export const getTransferReq = async (param) => {
  const {
    data
  } = await Axios.get(
    `${process.env.REACT_APP_API}corporate/transfer-request${param}`
  );
  return data;
};



export const transaction_history = async(account_no, page) => {
  const { data } = await Axios.get(
      `${process.env.REACT_APP_API}account/history/${process.env.REACT_APP_BANK_ID}?account_type=corporate&account_no=${account_no}&page_no=${page}`
  );
  return data;
};

export const getStatement = async (body) => {
  const {
    data
  } = await Axios.post(
    `${process.env.REACT_APP_API}account/statement/${process.env.REACT_APP_BANK_ID}`,
    body
  );
  return data;
};

export const getBeneficiaries = async (type, searchParam) => {
  const {
    data
  } = await Axios.get(
    `${
      process.env.REACT_APP_API
    }account/beneficiary/?beneficiary_type=${type}&account_type=corporate${
      searchParam && `&search=${searchParam}`
    }`
  );
  return data;
};

export const getAccountManager = async (account_no) => {
  const {
    data
  } = await Axios.get(
    `${process.env.REACT_APP_API}account/manager?account_no=${account_no}`
  );
  return data;
};

export const getAccountName = async (query_type, account_no, bank_code) => {
  const {
    data
  } = await Axios.get(
    `${process.env.REACT_APP_API}account/name-enquiry/${
      process.env.REACT_APP_BANK_ID
    }/?query_type=${query_type}&account_no=${account_no}${
      bank_code ? `&bank_code=${bank_code}` : ""
    }`
  );
  return data;
};

export const getAllBanks = async () => {
  const {
    data
  } = await Axios.get(
    `${process.env.REACT_APP_API}account/banks/`
  );
  return data;
};

export const getBankFlexBalance = async () => {
  const {
    data
  } = await Axios.get(
    `${process.env.REACT_APP_API}account/bank-flex/`
  );
  return data;
};

export const bankTransfer = async (body) => {
  const {
    data
  } = await Axios.post(
    `${process.env.REACT_APP_API}account/transfer/${process.env.REACT_APP_BANK_ID}/`,
    body
  );
  return data;
};

export const createBeneficiary = async (body) => {
  const {
    data
  } = await Axios.post(
    `${process.env.REACT_APP_API}account/beneficiary/`,
    body
  );
  return data;
};

export const getCustomersCard = async (account_no) => {
  const {
    data
  } = await Axios.get(
    `${process.env.REACT_APP_API}account/card/?account_no=${account_no}`
  );
  return data;
};

export const blockCard = async (body) => {
  const {
    data
  } = await Axios.put(
    `${process.env.REACT_APP_API}account/card/`,
    body
  );
  return data;
};

export const buyAirtimeAndData = async (body) => {
  const {
    data
  } = await Axios.post(
    `${process.env.REACT_APP_API}bills/recharge/`,
    body
  );
  return data;
};

export const getdataPlans = async (provider) => {
  const {
    data
  } = await Axios.get(
    `${process.env.REACT_APP_API}bills/network/?network=${provider}&account_type=corporate`
  );
  return data;
};

export const getDiscos = async () => {
  const {
    data
  } = await Axios.get(
    `${process.env.REACT_APP_API}bills/electricity/?account_type=corporate`
  );
  return data;
};

export const buyElectricity = async (body) => {
  const {
    data
  } = await Axios.post(
    `${process.env.REACT_APP_API}bills/electricity/`,
    body
  );
  return data;
};

export const getServices = async () => {
  const {
    data
  } = await Axios.get(
    `${process.env.REACT_APP_API}bills/cable/?service_type=cable&account_type=corporate`
  );
  return data;
};

export const validateUniqueNumber = async (body) => {
  const {
    data
  } = await Axios.post(
    `${process.env.REACT_APP_API}bills/validate/`,
    body
  );
  return data;
};

export const getPackages = async (service_type) => {
  const {
    data
  } = await Axios.get(
    `${
      process.env.REACT_APP_API
    }bills/cable/${service_type.toLowerCase()}?account_type=corporate`
  );
  return data;
};

export const cableTvSubscription = async (body) => {
  const {
    data
  } = await Axios.post(
    `${process.env.REACT_APP_API}bills/cable/`,
    body
  );
  return data;
};

export const getFixedDeposit = async (phoneNumber) => {
  const {
    data
  } = await Axios.get(
    `${process.env.REACT_APP_API}account/fixed-deposit/1?phone_no=${phoneNumber}`
  );
  return data;
};

//SCHEDULED TRANSFER

export const getSchedules = async (id) => {
  const {
    data
  } = await Axios.get(
    `${process.env.REACT_APP_API}corporate/scheduler${id || ""}`
  );
  return data;
};
export const deletedSchedules = async (id) => {
  const {
    data
  } = await Axios.delete(
    `${process.env.REACT_APP_API}corporate/scheduler/${id}`
  );
  return data;
};
export const updateSchedule = async (id, body) => {
  const {
    data
  } = await Axios.put(
    `${process.env.REACT_APP_API}corporate/scheduler/${id || ""}/`,
    body
  );
  return data;
};