import { useState, useEffect, createRef } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Sidebar from "components/Dashboard/Sidebar/Sidebar";
import { Outlet, Navigate } from "react-router-dom";
import { useSessionStorage } from "hooks/useSessionStorage";
import Navbar from "../../components/Dashboard/Navbar/Navbar.Dashboard";
import { useLocation } from "react-router-dom";
import PageTransition from "components/base/PageTransition/PageTransition";
import { styles } from "./Dashboard.layout.styles";

const Dashboard = () => {
  const [open, setOpen] = useState(false);
  const { getSessionStorage } = useSessionStorage("__appUser");
  const { pathname } = useLocation();
  const nodeRef = createRef();

  const toggleHamburger = () => {
    setOpen((prevS) => !prevS);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return getSessionStorage ? (
    <Grid container sx={styles.container}>
      <Grid
        item
        xs={12}
        sm={3}
        lg={2.5}
        sx={{
          ...styles.left,
          display: { xs: open ? "block" : "none", sm: "block" },
        }}
      >
        <Sidebar open={open} toggleHamburger={toggleHamburger} />
      </Grid>
      <Grid item xs={12} sm={9} lg={9.5} sx={styles.right}>
        <Navbar open={open} toggleHamburger={toggleHamburger} />

        <PageTransition nodeRef={nodeRef}>
          <Box sx={styles.content}>
            <Outlet />
          </Box>
        </PageTransition>
      </Grid>
    </Grid>
  ) : (
    <Navigate to="/auth/sign-in" />
  );
};

export default Dashboard;
