import CardReplacementComponent from 'components/CardReplacement/CardReplacement';

const CardReplacement = () => {
  return (
    <>
      <CardReplacementComponent />
    </>
  );
};

export default CardReplacement;
