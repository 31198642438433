import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import DragAndDropToUpload from "./DragAndDropToUpload";

const ThirdForm = ({ formik, styles }) => {
  return (
    <Box>
      <Stack direction="row" gap={{ sx: 0, sm: 2 }} mt={3}>
        <DragAndDropToUpload
          styles={styles}
          formik={formik}
          formikKey="image"
          label="passport photo"
        />

        <DragAndDropToUpload
          styles={styles}
          formik={formik}
          formikKey="signature_image"
          label="signature"
        />
      </Stack>

      <Stack direction="row" gap={{ sx: 0, sm: 2 }} mt={3}>
        <DragAndDropToUpload
          styles={styles}
          formik={formik}
          formikKey="utility"
          label="utility bill"
        />

        <DragAndDropToUpload
          styles={styles}
          formik={formik}
          formikKey="valid_id"
          label="ID card"
        />
      </Stack>
    </Box>
  );
};

export default ThirdForm;
