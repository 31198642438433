import * as Yup from "yup";

export const ElectricityPaymentSchema = Yup.object().shape({
  account: Yup.string().required("Select billing account"),
  disco_type: Yup.string().required("Select distribution company"),
  amount: Yup.number().min(1, "Can't be less than 1").required("Enter Amount"),
  meter_no: Yup.string().required("Enter valid meter number"),
  // phone_no: Yup.string()
  //   .min(10, "Minimum of 11 digits")
  //   .required("Enter phone number"),
});

export const CableTvSchema = Yup.object().shape({
  account: Yup.string().required("Select billing account"),
  service_name: Yup.string().required("Select service name"),
  smart_card_no: Yup.string().required("Enter valid smart card number"),
  amount: Yup.number().min(1, "Can't be less than 1").required("Enter Amount"),
  // phone_no: Yup.string()
  //   .min(10, "Minimum of 11 digits")
  //   .required("Enter phone number"),
});

export const BettingPaymentSchema = Yup.object().shape({
  account: Yup.string().required("Select billing account"),
  amount: Yup.number().min(1, "Can't be less than 1").required("Enter Amount"),
  customer_id: Yup.string().required("Enter valid customer id"),
  
    service_name: Yup.object().required("Select service name"),

});
