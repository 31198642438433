import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ReactComponent as InfoIcon } from "assets/svg/info.svg";
import { ReactComponent as SortIcon } from "assets/svg/sort.svg";
import { styled } from "@mui/material";
import { formatCurrency } from "utils/formatCurrency";
import LoadingAnimation from "components/base/LoadingAnimation/LoadingAnimation";
import { styles } from "./AccountSummaryTable.styles";

const Info = styled(InfoIcon)(() => ({
  marginLeft: "5px",
  verticalAlign: "middle",
}));

const AccountSummaryTable = ({ data, isLoading }) => {
  const getAccountBalance = (account_no) => {
    return data?.account_balances?.find(
      (item) => item.account_no === account_no
    );
  };

  const tableData = data?.customer?.accounts?.map((account) => ({
    account_name: account?.customer_name,
    account_type: account.account_type?.replaceAll("Or", "/"),
    account_no: account.account_no,
    currency: "NGN",
    account_balance:
      getAccountBalance(account.account_no)?.withdrawable_balance || 0,
    available_balance:
      getAccountBalance(account.account_no)?.withdrawable_balance || 0,
    ledger_balance: getAccountBalance(account.account_no)?.ledger_balance || 0,
    status: account.active,
  }));

  console.log(tableData);

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.header}>Account summary</Typography>
      {isLoading ? (
        <Box sx={styles.loadingAnimation}>
          <LoadingAnimation />
        </Box>
      ) : (
        <TableContainer component={Box} mt={2}>
          <Table
            sx={{ minWidth: { xs: "max-content", lg: "max-content" } }}
            aria-label="table"
          >
            <TableHead>
              <TableRow
                sx={{
                  ...styles.tableHeader,
                  "th, td": { border: "none", py: 1 },
                }}
              >
                <TableCell sx={styles.headerText}>
                  ACCOUNT NAME <SortIcon style={{ verticalAlign: "middle" }} />
                </TableCell>
                <TableCell sx={styles.headerText}>
                  ACCOUNT TYPE <Info />
                </TableCell>
                <TableCell sx={styles.headerText}>
                  ACCOUNT NUMBER <Info />
                </TableCell>
                <TableCell sx={styles.headerText}>CURRENCY</TableCell>
                <TableCell sx={styles.headerText}>ACCOUNT BALACE</TableCell>
                <TableCell sx={styles.headerText}>AVAILABLE BALANCE</TableCell>
                <TableCell sx={styles.headerText}>
                  STATUS <Info />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(even)": {
                      bgcolor: "rgba(247, 249, 252, 0.8)",
                    },
                    "&:last-child td, &:last-child th": { border: 0 },
                    "th, td": { border: "none" },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      ...styles.contentText,
                      ...styles.textColor("green3.main"),
                    }}
                  >
                    {row.account_name}
                  </TableCell>
                  <TableCell sx={styles.contentText}>
                    {row.account_type}
                  </TableCell>
                  <TableCell sx={styles.contentText}>
                    {row.account_no}
                  </TableCell>
                  <TableCell sx={styles.contentText}>{row.currency}</TableCell>
                  <TableCell>{formatCurrency(row.account_balance)}</TableCell>
                  <TableCell sx={styles.contentText}>
                    {formatCurrency(row.withdrawable_balance)}
                  </TableCell>
                  <TableCell sx={styles.contentText}>
                    {formatCurrency(row.ledger_balance)}
                  </TableCell>
                  <TableCell>
                    <Typography
                      as="li"
                      sx={{
                        ...styles.contentText,
                        ...{
                          ...(row.status &&
                            styles.status("green3.main", "#E1FCEF")),
                        },
                        ...{
                          ...(!row.status &&
                            styles.status("red.main", "#FCE6E6")),
                        },
                      }}
                    >
                      {row.status ? "AVAILABLE" : "UNAVAILABLE"}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default AccountSummaryTable;
