import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CustomModal from 'components/base/Modal/Modal';
import { styles } from './DeleteCitmfbBeneficiaryModal.styles';

const DeleteCitmfbBeneficiaryModal = ({
  open,
  handleClose,
  step,
  deleteBeneficiary,
}) => {
  return (
    <CustomModal open={open} handleClose={() => handleClose('delete')}>
      <Box sx={styles.container}>
        {step === 0 && (
          <Box spacing={3} sx={styles.content} alignItems='center'>
            <Typography sx={styles.header}>
              Are you sure you want to Delete this Beneficiary?
            </Typography>

            <Stack mt={4} mb={3} spacing={4}>
              <Button
                variant='contained'
                color='green3'
                sx={styles.actionBtn}
                onClick={deleteBeneficiary}
              >
                Yes
              </Button>

              <Button
                variant='contained'
                color='red'
                sx={styles.actionBtn}
                onClick={() => handleClose('delete')}
              >
                No
              </Button>
            </Stack>
          </Box>
        )}

        {step === 1 && (
          <Stack spacing={3} sx={styles.content} alignItems='center'>
            <Typography sx={styles.header}>Done!</Typography>

            <Typography sx={styles.subText}>
              Beneficiary has been deleted successfully
            </Typography>

            <Box mx='auto' width='fit-content' mt={{ xs: 3, sm: 5 }}>
              <Button
                type='submit'
                variant='contained'
                color='green3'
                sx={styles.btn}
                onClick={() => handleClose('delete')}
              >
                Done
              </Button>
            </Box>
          </Stack>
        )}
      </Box>
    </CustomModal>
  );
};

export default DeleteCitmfbBeneficiaryModal;
