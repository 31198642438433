import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TablePagination from "components/base/TablePagination/TablePagination";
import { ReactComponent as InfoIcon } from "assets/svg/info.svg";
import { ReactComponent as SortIcon } from "assets/svg/sort.svg";
import { styled } from "@mui/material";
import { styles } from "components/SavedBeneficiaries/CitmfbBeneficiariesTable/CitmfbBeneficiariesTable.styles";

const createData = (name, network, phoneNumber, action) => {
  return { name, network, phoneNumber, action };
};

const Info = styled(InfoIcon)(() => ({
  marginLeft: "5px",
  verticalAlign: "middle",
}));

const AirtimeAndDataBeneficiariesTable = ({
  tableData,
  handleOpen,
  page,
  setPage,
  item_count,
}) => {
  const rowData = tableData.map(
    ({ beneficiary_name, biller_name, beneficiary_number }) =>
      createData(beneficiary_name, biller_name, beneficiary_number)
  );

  return (
    <>
      <TableContainer
        component={Box}
        mt={2}
        sx={{ width: { xs: "95%", md: "90%", lg: "80%" }, mx: "auto" }}
      >
        <Table
          sx={{ minWidth: { xs: "max-content", lg: 650 } }}
          aria-label="table"
        >
          <TableHead>
            <TableRow
              sx={{
                ...styles.tableHeader,
                "th, td": { border: "none", py: 1 },
              }}
            >
              <TableCell sx={styles.headerText}>
                NAME <SortIcon style={{ verticalAlign: "middle" }} />
              </TableCell>
              <TableCell sx={styles.headerText}>
                NETWORK <Info />
              </TableCell>
              <TableCell sx={styles.headerText}>
                PHONE NUMBER <Info />
              </TableCell>
              <TableCell sx={styles.headerText}>ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:nth-of-type(even)": {
                    bgcolor: "rgba(247, 249, 252, 0.8)",
                  },
                  "&:last-child td, &:last-child th": { border: 0 },
                  "th, td": { border: "none" },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    ...styles.contentText,
                    ...styles.textColor("green3.main"),
                  }}
                >
                  {row.name}
                </TableCell>
                <TableCell sx={styles.contentText}>{row.network}</TableCell>
                <TableCell sx={styles.contentText}>{row.phoneNumber}</TableCell>
                <TableCell sx={styles.contentText}>
                  <Stack direction="row">
                    <Typography
                      mr={3}
                      sx={{ ...styles.actionText, ...styles.sendMoney }}
                    >
                      SEND MONEY
                    </Typography>
                    <Typography
                      sx={{ ...styles.actionText, ...styles.delete }}
                      onClick={() => handleOpen("delete")}
                    >
                      DELETE
                    </Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {tableData.length > 0 && (
        <Box sx={{ width: { xs: "95%", md: "90%", lg: "80%" }, mx: "auto" }}>
          <TablePagination
            pagination={{
              page_count: Math.ceil(item_count / 10),
              item_count,
              size: 10,
            }}
            page={page}
            setPage={setPage}
          />
        </Box>
      )}
    </>
  );
};

export default AirtimeAndDataBeneficiariesTable;
