export const styles = {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 3,
  width: { xs: '90%', sm: '70%', md: '45%', xl: 674 },
  bgcolor: 'white.main',
  boxShadow: 24,
  p: { xs: 6, md: 8 },
  borderRadius: { xs: '20px', md: '47px', xl: '47px' },
  boxSizing: 'border-box',
};
