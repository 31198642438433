import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import Button from "@mui/material/Button";
import Select from "components/base/Select/Select";
import AirtimeAndDataModal from "./AirtimeAndDataModal/AirtimeAndDataModal";
import Switch from "components/base/Switch/Switch";
import { useFormik } from "formik";
import { DataSchema } from "services/Yup/AirtimeAndDataSchema";
import { useSearchParams } from "react-router-dom";
import { networkProviders } from "assets/data";
import {
  billPayments,
  getdataPlans,
  //userProfile,
  createBeneficiary,
} from "services/corporateDashboard";
import { validateNumbers } from "utils/validateNumbers";
import { useQuery, useMutation } from "@tanstack/react-query";
import { styles } from "./AirtimeAndData.styles";
import CorporateDashboardContext from "context/CorporateDashboardContext";

const AirtimeAndDataComponent = () => {
  const [param] = useSearchParams();
  const currentTab = param.get("tab");
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      account_source: "",
      networkProvider: "",
      dataPlan: "",
      plan_id: "",
      phone: "",
      amount: "",
      beneficiaryName: "",
      saveBeneficiary: false,
    },
    validationSchema: DataSchema,
    onSubmit: () => {
      setOpenModal(true);
    },
  });
  const { accountNumbers } = useContext(CorporateDashboardContext);

  // Reset error
  useEffect(() => {
    if (formik.values.transaction_pin.length === 4) {
      setError("");
    }
  }, [formik.values.transaction_pin.length]);

  const provider = formik.values.networkProvider;
  const { data: dataPlans } = useQuery({
    queryKey: ["dataPlans", provider],
    queryFn: () => getdataPlans(provider),
    enabled: Boolean(formik.values.networkProvider) && currentTab === "data",
  });
  console.log(provider, currentTab);
  // get user profile
  // const { data } = useQuery({
  //   queryKey: ["userProfile"],
  //   queryFn: userProfile,
  // });

  const plans = dataPlans?.data_plans?.map(({ plan_name }) => plan_name) || [];
  const accounts = accountNumbers || [];

  // Buy airtime mutation
  const { mutate: BuyAirtimeAndData, isLoading } = useMutation({
    mutationFn: billPayments,
    onSuccess: () => {
      setSeverity("success");
      setMessage("Transaction successful");
      setOpen(true);
      setStep(1);
    },
    onError: (err) => {
      setSeverity("error");
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
    },
  });

  // save beneficiary mutation
  const { mutate: CreateBeneficiary } = useMutation({
    mutationFn: createBeneficiary,
  });

  const setNetworkProvider = (value) => {
    formik.setFieldValue("networkProvider", value);
  };

  const handlePlanSelect = (value) => {
    const findSelectedPlan = dataPlans?.data_plans?.find(
      ({ plan_name }) => plan_name === value
    );

    formik.setFieldValue("amount", findSelectedPlan.plan_price);
    formik.setFieldValue("plan_id", findSelectedPlan.plan_id);
  };

  // reset form fields on mount
  useEffect(() => {
    if (currentTab === "data") {
      formik.resetForm();
    }
    // eslint-disable-next-line
  }, [currentTab]);

  const handleClose = () => {
    setOpenModal(false);
    setStep(0);
    formik.resetForm();
  };

  const nextStep = () => {
    setStep((prevS) => prevS + 1);
  };
  //const [typeTf, setTypeTf] = useState(true);

  const Subscribe = () => {
    if (formik.values.saveBeneficiary) {
      CreateBeneficiary({
        beneficiary_type: "data",
        beneficiary_name: formik.values.beneficiaryName,
        beneficiary_number: formik.values.phone,
        biller_name: formik.values.networkProvider.toUpperCase(),
      });
    }

    return BuyAirtimeAndData({
      account_no: formik.values.account_source?.slice(0, 10),
      phone_number: formik.values.phone,
      network: formik.values.networkProvider,
      amount: formik.values.amount,
      plan_id: formik.values.plan_id,
      payment_type: "data",
    });
  };

  return (
    <Box sx={styles.container}>
      <AirtimeAndDataModal
        open={openModal}
        handleClose={handleClose}
        step={step}
        nextStep={nextStep}
        formik={formik}
        Subscribe={Subscribe}
        isLoading={isLoading}
        openError={open}
        error={error}
        severity={severity}
        message={message}
        closeError={() => {
          setOpen(false);
        }}
      />

      {/*    <Box sx={styles2.container}>
        <Stack direction="row" spacing={{ xs: 2, sm: 0 }} sx={styles2.tab}>
          {[
            { label: "Airtime", state: true },
            { label: "Data", state: false },
          ].map(({ label, state }) => (
            <Typography
              key={label}
              sx={styles2.tabItem(state === typeTf)}
              onClick={() => {
                setTypeTf(state);
              }}
            >
              {label}
            </Typography>
          ))}
        </Stack>
      </Box> */}

      <Stack
        spacing={{ xs: 3, sm: 4 }}
        as="form"
        sx={styles.tab}
        onSubmit={formik.handleSubmit}
      >
        <InputWithLabel label="Account">
          <Select
            options={accounts}
            placeholder="Select Account"
            name="account_source"
            value={formik.values.account_source}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>

        <InputWithLabel label="Select Network Provider">
          <Stack direction="row" spacing={{ xs: 3, sm: 4 }}>
            {networkProviders.map(({ label, src }) => (
              <Box
                key={src}
                sx={styles.provider(formik.values.networkProvider === label)}
                onClick={() => setNetworkProvider(label)}
              >
                <Box as="img" src={src} alt={label} sx={styles.providerImg} />
              </Box>
            ))}
          </Stack>
          {formik.touched.networkProvider && formik.errors.networkProvider && (
            <Typography sx={styles.networkProviderErr}>
              {formik.errors.networkProvider}
            </Typography>
          )}
        </InputWithLabel>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 3, sm: 4 }}
        >
          <InputWithLabel label="Data Plan">
            <Select
              options={plans}
              placeholder="Select Data Plan"
              name="dataPlan"
              value={formik.values.dataPlan}
              formik={formik}
              sx={styles.input}
              onChange={handlePlanSelect}
            />
          </InputWithLabel>
        </Stack>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 3, sm: 4 }}
        >
          <InputWithLabel label="Phone Number">
            <Input
              type="tel"
              id="phone"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              placeholder="Enter Phone number (e.g 07054635423)"
              formik={formik}
              sx={styles.input}
            />
          </InputWithLabel>

          <InputWithLabel label="Amount">
            <Input
              type="tel"
              id="amount"
              name="amount"
              value={formik.values.amount}
              onChange={(event) => {
                validateNumbers(event, 1000, formik.handleChange);
              }}
              placeholder="Enter Amount"
              formik={formik}
              disabled={true}
              sx={styles.input}
            />
          </InputWithLabel>
        </Stack>

        {formik.values.saveBeneficiary && (
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 3, sm: 4 }}
          >
            <InputWithLabel label="Beneficiary name">
              <Input
                id="beneficiaryName"
                name="beneficiaryName"
                value={formik.values.beneficiaryName}
                onChange={formik.handleChange}
                placeholder="Enter beneficiary name"
                formik={formik}
                sx={styles.input}
              />
            </InputWithLabel>
          </Stack>
        )}

        <Stack
          sx={{
            transform: "translateY(-1rem)",
            pb: 5,
          }}
        >
          <Switch
            name="saveBeneficiary"
            label="Save as beneficiary"
            value={formik.values.saveBeneficiary}
            onChange={formik.handleChange}
          />
        </Stack>

        <Button
          type="submit"
          variant="contained"
          color="green3"
          sx={styles.submitBtn}
        >
          {isLoading ? "Please wait..." : "Proceed"}
        </Button>
      </Stack>
    </Box>
  );
};

export default AirtimeAndDataComponent;
