import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import TransactionsTable from "../TransactionsTable/TransactionsTable";
import CorporateDashboardContext from "context/CorporateDashboardContext";
import { styles } from "./RecentTransaction.styles";

const RecentTransaction = () => {
  const { recentTransactions, isLoading } = React.useContext(
    CorporateDashboardContext
  );

  console.log(recentTransactions);

  return (
    <Box sx={styles.container}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={{ xs: 2, lg: "26px" }}
      >
        <Typography sx={styles.header}>Recent Transactions</Typography>
        <Typography sx={styles.viewMore}>
          <Link to="/corporate-transactions">View More... &gt;</Link>
        </Typography>
      </Stack>

      <TransactionsTable data={recentTransactions} loading={isLoading} />
    </Box>
  );
};

export default RecentTransaction;
