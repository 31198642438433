export const styles = {
  container: {
    width: { xs: "auto", lg: "75%" },
    mx: "auto",
    mt: 4,
  },
  input: {
    fontSize: 14,
    borderColor: "#D22F31",
    borderRadius: "8px",
  },
};
