import * as Yup from "yup";

export const ValidatePhoneNumberSchema = Yup.object().shape({
  phone_number: Yup.string().min(11, "Enter valid phone number").required("Phone number is required"),
});

export const ValidateOtpSchema = Yup.object().shape({
  otp: Yup.string().required("OTP is required"),
});

export const ValidatePasswordSchema = Yup.object().shape({
  password: Yup.string().required("Enter password"),
  confirmPassword: Yup.string()
    .required("Please retype your password")
    .oneOf([Yup.ref("password")], "Password does not match"),
});
