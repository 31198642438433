export const styles = {
    container: {
        width: { xs: "100%", sm: "90%", lg: "80%" },
        mx: "auto",
    },
    input: {
        borderColor: "#EFF0F6",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
    },
};