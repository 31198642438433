import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import SlideInModal from "components/base/SlideInModal2/SlideInModal";
import Typography from "@mui/material/Typography";
//import { styled } from "@mui/material/";
import { ReactComponent as Close } from "assets/svg/close.svg";
import { styles2 } from "./TransactionsTable.styles";
//import { ReactComponent as InfoIcon } from "assets/svg/info.svg";
//import { ReactComponent as SortIcon } from "assets/svg/sort.svg";
import { Button /* styled */ /* TextField */, Stack } from "@mui/material";
import { formatCurrency } from "utils/formatCurrency";
import { styles } from "./TransactionsTable.styles";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
//import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
//import { useMutation } from "@tanstack/react-query";

/* const Info = styled(InfoIcon)(() => ({
  marginLeft: "5px",
  verticalAlign: "middle",
})); */

const TransactionsTable = ({ data, dataHead, loading }) => {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState({});
  const [param] = useSearchParams();
  const activeTab = param.get("tab");
  // const [openError, setOpenError] = useState(false);
  //const [error, setError] = useState("");

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
  };

  /*  const handleError = (err) => {
    setError(err?.response?.data?.detail || err.message);
    setOpenError(true);
  }; */

  /*  const closeError = () => {
    setOpenError(false);
  }; */

  /*  const navigateActiveForm = (form) => {
    if (form < active) {
      setActive(form);
    }
  }; */

  /*   const { mutate, isLoading } = useMutation({
    mutationFn: createAccount,
    onSuccess: () => {
      handleOpen();
    },
    onError: (err) => {
      handleError(err);
    },
  }); */

  const rowData = data;

  return (
    <TableContainer component={Box} mt={2}>
      <Table
        sx={{ minWidth: { xs: "max-content", lg: 650 } }}
        aria-label="table"
      >
        <TableHead>
          <TableRow
            sx={{ ...styles.tableHeader, "th, td": { border: "none", py: 1 } }}
          >
            {/*  <TableCell sx={styles.headerText}>
              DATE <SortIcon style={{ verticalAlign: "middle" }} />
            </TableCell> */}

            {dataHead?.map((item) => {
              return (
                <TableCell sx={styles.headerText}>
                  {item}
                  {/* <Info /> */}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData?.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                "&:nth-of-type(even)": { bgcolor: "rgba(247, 249, 252, 0.8)" },
                "&:last-child td, &:last-child th": { border: 0 },
                "th, td": { border: "none" },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  ...styles.contentText,
                  ...styles.textColor("green3.main"),
                }}
              >
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : (
                  //new Date(row.beneficiary_name).toDateString().slice(4)
                  row?.beneficiary_name ||
                  (activeTab === "bulk" && row?.description) ||
                  (activeTab === "electricity" && row?.transaction_id) ||
                  (activeTab === "cable_tv" && "+" + row?.phone_number) ||
                  (activeTab === "airtime" && row?.beneficiary) ||
                  row?.description ||
                  (activeTab === "data" && row?.beneficiary)
                )}
              </TableCell>
              <TableCell sx={styles.contentText}>
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : (
                  (activeTab === "single" && row?.bank_name) ||
                  (activeTab === "single" && "KC Microfinance Bank	") ||
                  (activeTab === "bulk" && row?.institution) ||
                  (activeTab === "electricity" && row?.smart_card_no) ||
                  (activeTab === "cable_tv" && row?.smart_card_no) ||
                  (activeTab === "airtime" && row?.network) ||
                  (activeTab === "airtime" &&
                    moment(row?.created_on).format("DD-MMM-YYYY")) ||
                  (activeTab === "data" && row?.network)
                )}
              </TableCell>
              {/* <TableCell sx={styles.contentText}>{row.account}</TableCell> */}
              <TableCell sx={styles.contentText}>
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : (
                  row?.beneficiary_acct ||
                  (activeTab === "bulk" && row?.id) ||
                  (activeTab === "electricity" && row?.service_name) ||
                  (activeTab === "cable_tv" && row?.service_name) ||
                  (activeTab === "airtime" && row?.account_no) ||
                  row?.status ||
                  (activeTab === "data" && row?.account_no)
                )}
              </TableCell>
              {/* <TableCell>
                <Typography
                  as='li'
                  sx={{
                    ...styles.contentText,
                    ...{
                      ...(row.status === 'SUCCESSFUL' &&
                        styles.status('green3.main', '#E1FCEF')),
                    },
                    ...{
                      ...(row.status === 'PENDING' &&
                        styles.status('orange1.main', '#FCF2E6')),
                    },
                    ...{
                      ...(row.status === 'FAILED' &&
                        styles.status('red.main', '#FCE6E6')),
                    },
                  }}
                >
                  {row.status}
                </Typography>
              </TableCell> */}
              <TableCell sx={styles.contentText}>
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : (
                  (activeTab === "single" &&
                    formatCurrency(Number(row?.amount))) ||
                  (activeTab === "bulk" &&
                    formatCurrency(Number(row?.amount))) ||
                  (activeTab === "electricity" &&
                    formatCurrency(Number(row?.amount))) ||
                  (activeTab === "cable_tv" &&
                    formatCurrency(Number(row?.amount))) ||
                  (activeTab === "airtime" &&
                    formatCurrency(Number(row?.amount))) ||
                  (activeTab === "data" && formatCurrency(Number(row?.amount)))
                )}
              </TableCell>
              <TableCell
                sx={{
                  ...styles.contentText,
                  ...styles.textColor(
                    row.type === "Credit" ? "green3.main" : "red.main"
                  ),
                }}
              >
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                    }}
                  />
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      width: { xs: "fit-content" },
                      bgcolor:
                        row?.status === "approved"
                          ? "rgba(45, 202, 114, 0.2)"
                          : row?.status === "pending"
                          ? "rgba(202, 134, 45, 0.2)"
                          : "rgba(243, 42, 42, 0.2)",
                      color:
                        row?.status === "approved"
                          ? "green3.main"
                          : row?.status === "pending"
                          ? "rgb(202, 155, 45)"
                          : "rgb(243, 42, 42)",
                      fontSize: { xs: 6, lg: 10 },
                      fontWeight: 600,
                      boxShadow: "none",
                      alignSelf: { xs: "flex-end", sm: "auto" },
                      "&:hover": {
                        bgcolor:
                          row?.status === "approved"
                            ? "rgba(45, 202, 114, 0.2)"
                            : row?.status === "pending"
                            ? "rgba(202, 134, 45, 0.2)"
                            : "rgba(243, 42, 42, 0.2)",
                      },
                    }}
                  >
                    {row?.status === "approved"
                      ? "APPROVED"
                      : row?.status === "pending"
                      ? "PENDING"
                      : "DECLINED"}
                  </Button>
                )}
              </TableCell>
              <TableCell
                sx={{
                  ...styles.contentText,
                  ...styles.textColor("green3.main"),
                }}
              >
                {loading ? (
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 100, lg: "auto" },
                      height: 20,
                      transform: "none",
                      background: "green3.main",
                    }}
                  />
                ) : (
                  <div
                    onClick={() => {
                      handleOpen();
                      setItem(row);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    View
                  </div>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>

        {activeTab === "single" && (
          <SlideInModal
            open={open}
            handleClose={handleClose}
            sx={{ alignItems: "center" }}
          >
            <Typography onClick={handleClose} sx={styles2.closeX}>
              <Close sx={styles2.closeIcon} />
            </Typography>

            <Typography
              sx={{
                ...styles2.header,
                my: "20px",
                fontWeight: "700",
                fontSize: "24px",
                textAlign: "left",
              }}
            >
              Status Details
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "40px",
              }}
            >
              <div style={{ position: "relative" }}>
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="25"
                    cy="25"
                    r="25"
                    fill={item?.checked ? "#fff" : "#868686"}
                  />
                  {item?.checked && (
                    <path
                      d="M21.5 29.5L16.0625 24.0625C15.6042 23.6042 15.0417 23.375 14.375 23.375C13.7083 23.375 13.125 23.625 12.625 24.125C12.1667 24.5833 11.9375 25.1667 11.9375 25.875C11.9375 26.5833 12.1667 27.1667 12.625 27.625L19.75 34.75C20.2083 35.2083 20.7917 35.4375 21.5 35.4375C22.2083 35.4375 22.7917 35.2083 23.25 34.75L37.4375 20.5625C37.8958 20.1042 38.125 19.5417 38.125 18.875C38.125 18.2083 37.875 17.625 37.375 17.125C36.9167 16.6667 36.3333 16.4375 35.625 16.4375C34.9167 16.4375 34.3333 16.6667 33.875 17.125L21.5 29.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50Z"
                      fill="#D22F31"
                    />
                  )}
                  {item?.declined_by?.length === 1 && (
                    <path
                      d="M33.6782 13.6864L24.6274 22.7372L15.5764 13.6862C14.952 13.0618 13.9382 13.0618 13.3136 13.6864C12.6892 14.3108 12.6892 15.3246 13.3136 15.949L22.3646 25L13.3136 34.051C12.6892 34.6754 12.6892 35.6892 13.3136 36.3136C13.9382 36.9382 14.952 36.9382 15.5764 36.3138L24.6274 27.2628L33.6782 36.3136C34.3028 36.9382 35.3166 36.9382 35.941 36.3138C36.5656 35.6892 36.5656 34.6754 35.941 34.0508L26.8902 25L35.941 15.9492C36.5656 15.3246 36.5656 14.3108 35.941 13.6862C35.3166 13.0618 34.3028 13.0618 33.6782 13.6864V13.6864Z"
                      fill="white"
                    />
                  )}
                </svg>
                <div style={{ position: "absolute", textAlign: "center" }}>
                  {item?.approved_by?.[0] || item?.declined_by?.[0]}
                </div>
              </div>
              <svg
                width="164"
                height="5"
                viewBox="0 0 164 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  y1="2.5"
                  x2="164"
                  y2="2.5"
                  stroke={item?.checked ? "#D22F31" : "#D9D9D9"}
                  stroke-width="5"
                />
              </svg>
              <div style={{ position: "relative" }}>
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="25"
                    cy="25"
                    r="25"
                    fill={item?.verified ? "#fff" : "#868686"}
                  />
                  {item?.verified && (
                    <path
                      d="M21.5 29.5L16.0625 24.0625C15.6042 23.6042 15.0417 23.375 14.375 23.375C13.7083 23.375 13.125 23.625 12.625 24.125C12.1667 24.5833 11.9375 25.1667 11.9375 25.875C11.9375 26.5833 12.1667 27.1667 12.625 27.625L19.75 34.75C20.2083 35.2083 20.7917 35.4375 21.5 35.4375C22.2083 35.4375 22.7917 35.2083 23.25 34.75L37.4375 20.5625C37.8958 20.1042 38.125 19.5417 38.125 18.875C38.125 18.2083 37.875 17.625 37.375 17.125C36.9167 16.6667 36.3333 16.4375 35.625 16.4375C34.9167 16.4375 34.3333 16.6667 33.875 17.125L21.5 29.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50Z"
                      fill="#D22F31"
                    />
                  )}
                  {item?.declined_by?.length === 2 && (
                    <path
                      d="M33.6782 13.6864L24.6274 22.7372L15.5764 13.6862C14.952 13.0618 13.9382 13.0618 13.3136 13.6864C12.6892 14.3108 12.6892 15.3246 13.3136 15.949L22.3646 25L13.3136 34.051C12.6892 34.6754 12.6892 35.6892 13.3136 36.3136C13.9382 36.9382 14.952 36.9382 15.5764 36.3138L24.6274 27.2628L33.6782 36.3136C34.3028 36.9382 35.3166 36.9382 35.941 36.3138C36.5656 35.6892 36.5656 34.6754 35.941 34.0508L26.8902 25L35.941 15.9492C36.5656 15.3246 36.5656 14.3108 35.941 13.6862C35.3166 13.0618 34.3028 13.0618 33.6782 13.6864V13.6864Z"
                      fill="white"
                    />
                  )}
                </svg>

                <div style={{ position: "absolute", textAlign: "center" }}>
                  {item?.approved_by?.[1] || item?.declined_by?.[1]}
                </div>
              </div>
              <svg
                width="164"
                height="5"
                viewBox="0 0 164 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  y1="2.5"
                  x2="164"
                  y2="2.5"
                  stroke={item?.verified ? "#D22F31" : "#D9D9D9"}
                  stroke-width="5"
                />
              </svg>
              <div style={{ position: "relative" }}>
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="25"
                    cy="25"
                    r="25"
                    fill={item?.approved ? "#fff" : "#868686"}
                  />
                  {item?.approved && (
                    <path
                      d="M21.5 29.5L16.0625 24.0625C15.6042 23.6042 15.0417 23.375 14.375 23.375C13.7083 23.375 13.125 23.625 12.625 24.125C12.1667 24.5833 11.9375 25.1667 11.9375 25.875C11.9375 26.5833 12.1667 27.1667 12.625 27.625L19.75 34.75C20.2083 35.2083 20.7917 35.4375 21.5 35.4375C22.2083 35.4375 22.7917 35.2083 23.25 34.75L37.4375 20.5625C37.8958 20.1042 38.125 19.5417 38.125 18.875C38.125 18.2083 37.875 17.625 37.375 17.125C36.9167 16.6667 36.3333 16.4375 35.625 16.4375C34.9167 16.4375 34.3333 16.6667 33.875 17.125L21.5 29.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50Z"
                      fill="#D22F31"
                    />
                  )}
                  {item?.declined_by?.length === 3 && (
                    <path
                      d="M33.6782 13.6864L24.6274 22.7372L15.5764 13.6862C14.952 13.0618 13.9382 13.0618 13.3136 13.6864C12.6892 14.3108 12.6892 15.3246 13.3136 15.949L22.3646 25L13.3136 34.051C12.6892 34.6754 12.6892 35.6892 13.3136 36.3136C13.9382 36.9382 14.952 36.9382 15.5764 36.3138L24.6274 27.2628L33.6782 36.3136C34.3028 36.9382 35.3166 36.9382 35.941 36.3138C36.5656 35.6892 36.5656 34.6754 35.941 34.0508L26.8902 25L35.941 15.9492C36.5656 15.3246 36.5656 14.3108 35.941 13.6862C35.3166 13.0618 34.3028 13.0618 33.6782 13.6864V13.6864Z"
                      fill="white"
                    />
                  )}
                </svg>
                <div style={{ position: "absolute", textAlign: "center" }}>
                  {item?.approved_by?.[2] || item?.declined_by?.[2]}
                </div>
              </div>
            </Box>
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
              }}
            ></Stack>

            <Typography
              sx={{
                ...styles2.header,
                my: "20px",
                fontWeight: "700",
                fontSize: "24px",
                textAlign: "left",
              }}
            >
              Beneficiary Details
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
              }}
            >
              <Box>
                <Typography
                  sx={{ ...styles2.desc, fontSize: "16px", fontWeight: "700" }}
                >
                  Name
                </Typography>
                <Typography sx={{ ...styles2.desc, fontSize: "14px" }}>
                  {item?.beneficiary_name}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ ...styles2.desc, fontSize: "16px", fontWeight: "700" }}
                >
                  Bank Name
                </Typography>
                <Typography sx={{ ...styles2.desc, fontSize: "14px" }}>
                  {item?.bank_name || "KC Microfinance Bank"}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ ...styles2.desc, fontSize: "16px", fontWeight: "700" }}
                >
                  Bank Account
                </Typography>
                <Typography sx={{ ...styles2.desc, fontSize: "14px" }}>
                  {item?.beneficiary_acct}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ ...styles2.desc, fontSize: "16px", fontWeight: "700" }}
                >
                  Amount
                </Typography>
                <Typography sx={{ ...styles2.desc, fontSize: "14px" }}>
                  {formatCurrency(Number(item?.amount))}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ ...styles2.desc, fontSize: "16px", fontWeight: "700" }}
                >
                  Status
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    width: { xs: "fit-content" },

                    bgcolor:
                      item?.status === "approved"
                        ? "rgba(45, 202, 114, 0.2)"
                        : item?.status === "pending"
                        ? "rgba(202, 134, 45, 0.2)"
                        : "rgba(243, 42, 42, 0.2)",
                    color:
                      item?.status === "approved"
                        ? "green3.main"
                        : item?.status === "pending"
                        ? "rgb(202, 155, 45)"
                        : "rgb(243, 42, 42)",

                    fontSize: { xs: 6, lg: 10 },
                    fontWeight: 600,
                    boxShadow: "none",
                    alignSelf: { xs: "flex-end", sm: "auto" },
                    "&:hover": {
                      bgcolor: item?.approved
                        ? "rgba(45, 202, 114, 0.2)"
                        : "rgba(202, 134, 45, 0.2)",
                    },
                  }}
                >
                  {item?.status === "approved"
                    ? "APPROVED"
                    : item?.status === "pending"
                    ? "PENDING"
                    : "DECLINED"}
                </Button>
              </Box>
            </Box>
          </SlideInModal>
        )}
        {(activeTab === "bulk" && (
          <SlideInModal
            open={open}
            handleClose={handleClose}
            sx={{ alignItems: "center" }}
          >
            <Typography onClick={handleClose} sx={styles2.closeX}>
              <Close sx={styles2.closeIcon} />
            </Typography>

            <Typography
              sx={{
                ...styles2.header,
                my: "20px",
                fontWeight: "700",
                fontSize: "24px",
                textAlign: "left",
              }}
            >
              Status Details
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "40px",
              }}
            >
              <div style={{ position: "relative" }}>
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="25"
                    cy="25"
                    r="25"
                    fill={item?.checked ? "#fff" : "#868686"}
                  />
                  {item?.checked && (
                    <path
                      d="M21.5 29.5L16.0625 24.0625C15.6042 23.6042 15.0417 23.375 14.375 23.375C13.7083 23.375 13.125 23.625 12.625 24.125C12.1667 24.5833 11.9375 25.1667 11.9375 25.875C11.9375 26.5833 12.1667 27.1667 12.625 27.625L19.75 34.75C20.2083 35.2083 20.7917 35.4375 21.5 35.4375C22.2083 35.4375 22.7917 35.2083 23.25 34.75L37.4375 20.5625C37.8958 20.1042 38.125 19.5417 38.125 18.875C38.125 18.2083 37.875 17.625 37.375 17.125C36.9167 16.6667 36.3333 16.4375 35.625 16.4375C34.9167 16.4375 34.3333 16.6667 33.875 17.125L21.5 29.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50Z"
                      fill="#D22F31"
                    />
                  )}
                  {item?.declined_by?.length === 1 && (
                    <path
                      d="M33.6782 13.6864L24.6274 22.7372L15.5764 13.6862C14.952 13.0618 13.9382 13.0618 13.3136 13.6864C12.6892 14.3108 12.6892 15.3246 13.3136 15.949L22.3646 25L13.3136 34.051C12.6892 34.6754 12.6892 35.6892 13.3136 36.3136C13.9382 36.9382 14.952 36.9382 15.5764 36.3138L24.6274 27.2628L33.6782 36.3136C34.3028 36.9382 35.3166 36.9382 35.941 36.3138C36.5656 35.6892 36.5656 34.6754 35.941 34.0508L26.8902 25L35.941 15.9492C36.5656 15.3246 36.5656 14.3108 35.941 13.6862C35.3166 13.0618 34.3028 13.0618 33.6782 13.6864V13.6864Z"
                      fill="white"
                    />
                  )}
                </svg>
                <div style={{ position: "absolute", textAlign: "center" }}>
                  {item?.approved_by?.[0] || item?.declined_by?.[0]}
                </div>
              </div>
              <svg
                width="164"
                height="5"
                viewBox="0 0 164 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  y1="2.5"
                  x2="164"
                  y2="2.5"
                  stroke={item?.checked ? "#D22F31" : "#D9D9D9"}
                  stroke-width="5"
                />
              </svg>
              <div style={{ position: "relative" }}>
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="25"
                    cy="25"
                    r="25"
                    fill={item?.verified ? "#fff" : "#868686"}
                  />
                  {item?.verified && (
                    <path
                      d="M21.5 29.5L16.0625 24.0625C15.6042 23.6042 15.0417 23.375 14.375 23.375C13.7083 23.375 13.125 23.625 12.625 24.125C12.1667 24.5833 11.9375 25.1667 11.9375 25.875C11.9375 26.5833 12.1667 27.1667 12.625 27.625L19.75 34.75C20.2083 35.2083 20.7917 35.4375 21.5 35.4375C22.2083 35.4375 22.7917 35.2083 23.25 34.75L37.4375 20.5625C37.8958 20.1042 38.125 19.5417 38.125 18.875C38.125 18.2083 37.875 17.625 37.375 17.125C36.9167 16.6667 36.3333 16.4375 35.625 16.4375C34.9167 16.4375 34.3333 16.6667 33.875 17.125L21.5 29.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50Z"
                      fill="#D22F31"
                    />
                  )}
                  {item?.declined_by?.length === 2 && (
                    <path
                      d="M33.6782 13.6864L24.6274 22.7372L15.5764 13.6862C14.952 13.0618 13.9382 13.0618 13.3136 13.6864C12.6892 14.3108 12.6892 15.3246 13.3136 15.949L22.3646 25L13.3136 34.051C12.6892 34.6754 12.6892 35.6892 13.3136 36.3136C13.9382 36.9382 14.952 36.9382 15.5764 36.3138L24.6274 27.2628L33.6782 36.3136C34.3028 36.9382 35.3166 36.9382 35.941 36.3138C36.5656 35.6892 36.5656 34.6754 35.941 34.0508L26.8902 25L35.941 15.9492C36.5656 15.3246 36.5656 14.3108 35.941 13.6862C35.3166 13.0618 34.3028 13.0618 33.6782 13.6864V13.6864Z"
                      fill="white"
                    />
                  )}
                </svg>

                <div style={{ position: "absolute", textAlign: "center" }}>
                  {item?.approved_by?.[1] || item?.declined_by?.[1]}
                </div>
              </div>
              <svg
                width="164"
                height="5"
                viewBox="0 0 164 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  y1="2.5"
                  x2="164"
                  y2="2.5"
                  stroke={item?.verified ? "#D22F31" : "#D9D9D9"}
                  stroke-width="5"
                />
              </svg>
              <div style={{ position: "relative" }}>
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="25"
                    cy="25"
                    r="25"
                    fill={item?.approved ? "#fff" : "#868686"}
                  />
                  {item?.approved && (
                    <path
                      d="M21.5 29.5L16.0625 24.0625C15.6042 23.6042 15.0417 23.375 14.375 23.375C13.7083 23.375 13.125 23.625 12.625 24.125C12.1667 24.5833 11.9375 25.1667 11.9375 25.875C11.9375 26.5833 12.1667 27.1667 12.625 27.625L19.75 34.75C20.2083 35.2083 20.7917 35.4375 21.5 35.4375C22.2083 35.4375 22.7917 35.2083 23.25 34.75L37.4375 20.5625C37.8958 20.1042 38.125 19.5417 38.125 18.875C38.125 18.2083 37.875 17.625 37.375 17.125C36.9167 16.6667 36.3333 16.4375 35.625 16.4375C34.9167 16.4375 34.3333 16.6667 33.875 17.125L21.5 29.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50Z"
                      fill="#D22F31"
                    />
                  )}
                  {item?.declined_by?.length === 3 && (
                    <path
                      d="M33.6782 13.6864L24.6274 22.7372L15.5764 13.6862C14.952 13.0618 13.9382 13.0618 13.3136 13.6864C12.6892 14.3108 12.6892 15.3246 13.3136 15.949L22.3646 25L13.3136 34.051C12.6892 34.6754 12.6892 35.6892 13.3136 36.3136C13.9382 36.9382 14.952 36.9382 15.5764 36.3138L24.6274 27.2628L33.6782 36.3136C34.3028 36.9382 35.3166 36.9382 35.941 36.3138C36.5656 35.6892 36.5656 34.6754 35.941 34.0508L26.8902 25L35.941 15.9492C36.5656 15.3246 36.5656 14.3108 35.941 13.6862C35.3166 13.0618 34.3028 13.0618 33.6782 13.6864V13.6864Z"
                      fill="white"
                    />
                  )}
                </svg>
                <div style={{ position: "absolute", textAlign: "center" }}>
                  {item?.approved_by?.[2] || item?.declined_by?.[2]}
                </div>
              </div>
            </Box>
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
              }}
            ></Stack>

            <Typography
              sx={{
                ...styles2.header,
                my: "20px",
                fontWeight: "700",
                fontSize: "24px",
                textAlign: "left",
              }}
            >
              Beneficiary Details
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
              }}
            >
              <Box>
                <Typography
                  sx={{ ...styles2.desc, fontSize: "16px", fontWeight: "700" }}
                >
                  Amount
                </Typography>
                <Typography sx={{ ...styles2.desc, fontSize: "14px" }}>
                  {formatCurrency(Number(item?.amount))}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ ...styles2.desc, fontSize: "16px", fontWeight: "700" }}
                >
                  Status
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    width: { xs: "fit-content" },
                    bgcolor: item?.approved
                      ? "rgba(45, 202, 114, 0.2)"
                      : "rgba(202, 134, 45, 0.2)",
                    color: item?.approved ? "green3.main" : "rgb(202, 155, 45)",
                    fontSize: { xs: 6, lg: 10 },
                    fontWeight: 600,
                    boxShadow: "none",
                    alignSelf: { xs: "flex-end", sm: "auto" },
                    "&:hover": {
                      bgcolor: item?.approved
                        ? "rgba(45, 202, 114, 0.2)"
                        : "rgba(202, 134, 45, 0.2)",
                    },
                  }}
                >
                  {item?.approved ? "APPROVED" : "PENDING"}
                </Button>
              </Box>
              <Box>
                <Typography
                  sx={{ ...styles2.desc, fontSize: "16px", fontWeight: "700" }}
                >
                  Description
                </Typography>
                <Typography sx={{ ...styles2.desc, fontSize: "14px" }}>
                  {item?.description}
                </Typography>
              </Box>
            </Box>
          </SlideInModal>
        )) ||
          (activeTab === "single" ? (
            ""
          ) : (
            <SlideInModal
              open={open}
              handleClose={handleClose}
              sx={{ alignItems: "center" }}
            >
              <Typography onClick={handleClose} sx={styles2.closeX}>
                <Close sx={styles2.closeIcon} />
              </Typography>

              <Typography
                sx={{
                  ...styles2.header,
                  my: "20px",
                  fontWeight: "700",
                  fontSize: "24px",
                  textAlign: "left",
                }}
              >
                Status Details
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "40px",
                }}
              >
                <div style={{ position: "relative" }}>
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="25"
                      cy="25"
                      r="25"
                      fill={item?.checked ? "#fff" : "#868686"}
                    />
                    {item?.checked && (
                      <path
                        d="M21.5 29.5L16.0625 24.0625C15.6042 23.6042 15.0417 23.375 14.375 23.375C13.7083 23.375 13.125 23.625 12.625 24.125C12.1667 24.5833 11.9375 25.1667 11.9375 25.875C11.9375 26.5833 12.1667 27.1667 12.625 27.625L19.75 34.75C20.2083 35.2083 20.7917 35.4375 21.5 35.4375C22.2083 35.4375 22.7917 35.2083 23.25 34.75L37.4375 20.5625C37.8958 20.1042 38.125 19.5417 38.125 18.875C38.125 18.2083 37.875 17.625 37.375 17.125C36.9167 16.6667 36.3333 16.4375 35.625 16.4375C34.9167 16.4375 34.3333 16.6667 33.875 17.125L21.5 29.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50Z"
                        fill="#D22F31"
                      />
                    )}
                    {item?.declined_by?.length === 1 && (
                      <path
                        d="M33.6782 13.6864L24.6274 22.7372L15.5764 13.6862C14.952 13.0618 13.9382 13.0618 13.3136 13.6864C12.6892 14.3108 12.6892 15.3246 13.3136 15.949L22.3646 25L13.3136 34.051C12.6892 34.6754 12.6892 35.6892 13.3136 36.3136C13.9382 36.9382 14.952 36.9382 15.5764 36.3138L24.6274 27.2628L33.6782 36.3136C34.3028 36.9382 35.3166 36.9382 35.941 36.3138C36.5656 35.6892 36.5656 34.6754 35.941 34.0508L26.8902 25L35.941 15.9492C36.5656 15.3246 36.5656 14.3108 35.941 13.6862C35.3166 13.0618 34.3028 13.0618 33.6782 13.6864V13.6864Z"
                        fill="white"
                      />
                    )}
                  </svg>
                  <div style={{ position: "absolute", textAlign: "center" }}>
                    {item?.approved_by?.[0] || item?.declined_by?.[0]}
                  </div>
                </div>
                <svg
                  width="164"
                  height="5"
                  viewBox="0 0 164 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    y1="2.5"
                    x2="164"
                    y2="2.5"
                    stroke={item?.checked ? "#D22F31" : "#D9D9D9"}
                    stroke-width="5"
                  />
                </svg>
                <div style={{ position: "relative" }}>
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="25"
                      cy="25"
                      r="25"
                      fill={item?.verified ? "#fff" : "#868686"}
                    />
                    {item?.verified && (
                      <path
                        d="M21.5 29.5L16.0625 24.0625C15.6042 23.6042 15.0417 23.375 14.375 23.375C13.7083 23.375 13.125 23.625 12.625 24.125C12.1667 24.5833 11.9375 25.1667 11.9375 25.875C11.9375 26.5833 12.1667 27.1667 12.625 27.625L19.75 34.75C20.2083 35.2083 20.7917 35.4375 21.5 35.4375C22.2083 35.4375 22.7917 35.2083 23.25 34.75L37.4375 20.5625C37.8958 20.1042 38.125 19.5417 38.125 18.875C38.125 18.2083 37.875 17.625 37.375 17.125C36.9167 16.6667 36.3333 16.4375 35.625 16.4375C34.9167 16.4375 34.3333 16.6667 33.875 17.125L21.5 29.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50Z"
                        fill="#D22F31"
                      />
                    )}
                    {item?.declined_by?.length === 2 && (
                      <path
                        d="M33.6782 13.6864L24.6274 22.7372L15.5764 13.6862C14.952 13.0618 13.9382 13.0618 13.3136 13.6864C12.6892 14.3108 12.6892 15.3246 13.3136 15.949L22.3646 25L13.3136 34.051C12.6892 34.6754 12.6892 35.6892 13.3136 36.3136C13.9382 36.9382 14.952 36.9382 15.5764 36.3138L24.6274 27.2628L33.6782 36.3136C34.3028 36.9382 35.3166 36.9382 35.941 36.3138C36.5656 35.6892 36.5656 34.6754 35.941 34.0508L26.8902 25L35.941 15.9492C36.5656 15.3246 36.5656 14.3108 35.941 13.6862C35.3166 13.0618 34.3028 13.0618 33.6782 13.6864V13.6864Z"
                        fill="white"
                      />
                    )}
                  </svg>

                  <div style={{ position: "absolute", textAlign: "center" }}>
                    {item?.approved_by?.[1] || item?.declined_by?.[1]}
                  </div>
                </div>
                <svg
                  width="164"
                  height="5"
                  viewBox="0 0 164 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    y1="2.5"
                    x2="164"
                    y2="2.5"
                    stroke={item?.verified ? "#D22F31" : "#D9D9D9"}
                    stroke-width="5"
                  />
                </svg>
                <div style={{ position: "relative" }}>
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="25"
                      cy="25"
                      r="25"
                      fill={item?.approved ? "#fff" : "#868686"}
                    />
                    {item?.approved && (
                      <path
                        d="M21.5 29.5L16.0625 24.0625C15.6042 23.6042 15.0417 23.375 14.375 23.375C13.7083 23.375 13.125 23.625 12.625 24.125C12.1667 24.5833 11.9375 25.1667 11.9375 25.875C11.9375 26.5833 12.1667 27.1667 12.625 27.625L19.75 34.75C20.2083 35.2083 20.7917 35.4375 21.5 35.4375C22.2083 35.4375 22.7917 35.2083 23.25 34.75L37.4375 20.5625C37.8958 20.1042 38.125 19.5417 38.125 18.875C38.125 18.2083 37.875 17.625 37.375 17.125C36.9167 16.6667 36.3333 16.4375 35.625 16.4375C34.9167 16.4375 34.3333 16.6667 33.875 17.125L21.5 29.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50Z"
                        fill="#D22F31"
                      />
                    )}
                    {item?.declined_by?.length === 3 && (
                      <path
                        d="M33.6782 13.6864L24.6274 22.7372L15.5764 13.6862C14.952 13.0618 13.9382 13.0618 13.3136 13.6864C12.6892 14.3108 12.6892 15.3246 13.3136 15.949L22.3646 25L13.3136 34.051C12.6892 34.6754 12.6892 35.6892 13.3136 36.3136C13.9382 36.9382 14.952 36.9382 15.5764 36.3138L24.6274 27.2628L33.6782 36.3136C34.3028 36.9382 35.3166 36.9382 35.941 36.3138C36.5656 35.6892 36.5656 34.6754 35.941 34.0508L26.8902 25L35.941 15.9492C36.5656 15.3246 36.5656 14.3108 35.941 13.6862C35.3166 13.0618 34.3028 13.0618 33.6782 13.6864V13.6864Z"
                        fill="white"
                      />
                    )}
                  </svg>
                  <div style={{ position: "absolute", textAlign: "center" }}>
                    {item?.approved_by?.[2] || item?.declined_by?.[2]}
                  </div>
                </div>
              </Box>
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              ></Stack>

              <Typography
                sx={{
                  ...styles2.header,
                  my: "20px",
                  fontWeight: "700",
                  fontSize: "24px",
                  textAlign: "left",
                }}
              >
                Beneficiary Details
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      ...styles2.desc,
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Amount
                  </Typography>
                  <Typography sx={{ ...styles2.desc, fontSize: "14px" }}>
                    {formatCurrency(Number(item?.amount))}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      ...styles2.desc,
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Status
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      width: { xs: "fit-content" },
                      bgcolor: item?.approved
                        ? "rgba(45, 202, 114, 0.2)"
                        : "rgba(202, 134, 45, 0.2)",
                      color: item?.approved
                        ? "green3.main"
                        : "rgb(202, 155, 45)",
                      fontSize: { xs: 6, lg: 10 },
                      fontWeight: 600,
                      boxShadow: "none",
                      alignSelf: { xs: "flex-end", sm: "auto" },
                      "&:hover": {
                        bgcolor: item?.approved
                          ? "rgba(45, 202, 114, 0.2)"
                          : "rgba(202, 134, 45, 0.2)",
                      },
                    }}
                  >
                    {item?.approved ? "APPROVED" : "PENDING"}
                  </Button>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      ...styles2.desc,
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Description
                  </Typography>
                  <Typography sx={{ ...styles2.desc, fontSize: "14px" }}>
                    {item?.description}
                  </Typography>
                </Box>
              </Box>
            </SlideInModal>
          ))}
      </Table>
    </TableContainer>
  );
};

export default TransactionsTable;
