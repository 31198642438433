export const styles = {
  container: {
    maxWidth: { xs: '95%', sm: '80%', lg: '75%' },
    mx: 'auto',
    mt: { xs: 5, md: 6 },
  },
  input: {
    backgroundColor: 'rgba(180, 246, 215, 0.05)',
    borderColor: '#D22F31',
  },
  submitBtn: {
    width: { xs: "80%", sm: 300 },
    height: 55,
    alignSelf: 'center',
    color: 'white.main',
  },
};

export const styles2 = {
  closeX: {
    position: 'absolute',
    top: { xs: '5%', sm: '8%' },
    right: { xs: '5%', sm: '8%' },
    cursor: 'pointer',
  },
  closeIcon: {
    width: { xs: 18, sm: 32 },
    height: { xs: 18, sm: 32 },
    '&:hover': { transform: 'scale(1.05)' },
  },
  header: {
    fontSize: { xs: 30, md: 40 },
    fontWeight: 700,
    color: 'black.main',
    textAlign: 'center',
    mt: "-50px"
  },
  desc: {
    fontSize: { xs: 14, md: 16, lg: 18 },
    fontWeight: 500,
    color: 'black.main',
    textAlign: '',
    mt: '10px',
    mb: '10px'
  },
  closeBtn: {
    borderRadius: '10px',
    width: '100%',
    fontSize: { xs: 16, md: 20, lg: 22 },
    fontWeight: 600,
    px: 3,
    "&:first-child": {
      mr: '10px'
    },
    "&:first-child:hover":{
      background: 'none',
      border: '1px solid'
    },
    "&:last-child:hover":{
      backgroundColor: 'green3.main'
    }
  },
  
  img: {
    display: 'block',
    width: 200,
    mx: 'auto',
  },
};