import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import FirstForm from "components/CreateAccount/FirstForm";
import SecondForm from "components/CreateAccount/SecondForm";
import ThirdForm from "components/CreateAccount/ThirdForm";
import AuthLayout from "layout/Auth/Auth.layout";
import ConfirmationModal from "components/base/ConfirmationModal/ConfirmationModal";
import {
  FirstFormSchema,
  SecondFormSchema,
  ThirdFormLivenessSchema,
  ThirdFormSchema,
} from "services/Yup/CreateAccountSchema";
import { createAccount } from "services/Auth";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createAccountBody } from "utils/createAccountBody";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import { useNavigate } from "react-router-dom";
import { styles } from "./CreateAccount.styles";
import { getAllBanks } from "services/dashboard";
import ThirdFormLiveness from "components/CreateAccount/ThirdFormLiveness";

const CreateAccount = () => {
  const navigate = useNavigate();
  const [active, setActive] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleOpen = () => setOpen(true);

  const { data: allBanks } = useQuery({
    queryKey: ["allBankss"],
    queryFn: getAllBanks,
    initialData: [],
  });

  console.log(allBanks?.[0]?.customer_onboarding_type);

  const liveness = allBanks?.[0]?.customer_onboarding_type;

  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  const handleError = (err) => {
    setError(err?.response?.data?.detail || err.message);
    setOpenError(true);
  };

  const closeError = () => {
    setOpenError(false);
  };

  const navigateActiveForm = (form) => {
    if (form < active) {
      setActive(form);
    }
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: createAccount,
    onSuccess: () => {
      handleOpen();
    },
    onError: (err) => {
      handleError(err);
    },
  });

  const [image, setImage] = React.useState();

  const activeValidationSchema =
    active === 1
      ? FirstFormSchema
      : active === 2
      ? SecondFormSchema
      : active === 3
      ? liveness === "liveness_verify"
        ? ThirdFormLivenessSchema
        : ThirdFormSchema
      : "";

  const formik = useFormik({
    initialValues: {
      bvn: "",
      phone: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      gender: "",
      dob: "",
      nin: "",
      email: "",
      residentialAddress: "",
      image: "",
      signature_image: "",
      utility: "",
      valid_id: "",
    },
    validationSchema: activeValidationSchema,
    onSubmit: (values) => {
      if (active <= 2) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });

        return setActive((prevS) => prevS + 1);
      }

      function DataURIToBlob(dataURI) {
        const splitDataURI = dataURI.split(",");
        const byteString =
          splitDataURI[0].indexOf("base64") >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++)
          ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
      }

      const file = DataURIToBlob(image);

      const body =
        liveness === "liveness_verify"
          ? createAccountBody({
              ...values,
              email: values.email,
              image: file,
            })
          : createAccountBody({
              ...values,
              email: values.email,
            });

      mutate(body);
    },
  });

  return (
    <AuthLayout image="/img/auth-3.webp">
      <DirectionSnackbar
        open={openError}
        handleClose={closeError}
        message={error}
      />

      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        mainText="Success"
        subText="
        Your account opening is under review, please wait for some few minutes."
        btnText="Continue"
        handleClick={handleClose}
      />

      <Box sx={styles.container}>
        <Typography sx={styles.header}>Open an Instant Account</Typography>
        <Typography sx={styles.subText}>
          Please fill in the following information
        </Typography>
        <Stack direction="row" spacing={1} mt={2} mb={5}>
          {[1, 2, 3].map((tab) => (
            <Box
              key={tab}
              sx={styles.progressIndicator(active, tab)}
              onClick={() => navigateActiveForm(tab)}
            />
          ))}
        </Stack>
        <Stack spacing={3} as="form" onSubmit={formik.handleSubmit}>
          {active === 1 && <FirstForm formik={formik} />}
          {active === 2 && <SecondForm formik={formik} />}
          {active === 3 &&
            (liveness === "liveness_verify" ? (
              <ThirdFormLiveness
                image={image}
                setImage={setImage}
                formik={formik}
                styles={styles}
              />
            ) : (
              <ThirdForm formik={formik} styles={styles} />
            ))}

          <Box pb={2} />

          {liveness === "liveness_verify" ? (
            (active === 3 && (
              <Button
                variant="contained"
                color="green3"
                type="submit"
                sx={styles.submitBtn}
                disabled={!image}
              >
                {isLoading
                  ? "Submitting..."
                  : active === 3
                  ? "Submit"
                  : "Proceed"}
              </Button>
            )) || (
              <Button
                variant="contained"
                color="green3"
                type="submit"
                sx={styles.submitBtn}
              >
                {isLoading
                  ? "Submitting..."
                  : active === 3
                  ? "Submit"
                  : "Proceed"}
              </Button>
            )
          ) : (
            <Button
              variant="contained"
              color="green3"
              type="submit"
              sx={styles.submitBtn}
            >
              {isLoading
                ? "Submitting..."
                : active === 3
                ? "Submit"
                : "Proceed"}
            </Button>
          )}
        </Stack>
      </Box>
    </AuthLayout>
  );
};

export default CreateAccount;
