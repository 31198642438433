export const style = {
    width: "100%",
    height: { xs: 55, xl: 90 },
    color: "black.main",
    fontSize: 16,
    px: 2,
    border: "1px solid",
    borderColor: "#EFF0F6",
    bgColor: "#FFFFFF",
    borderRadius: "6px",
};