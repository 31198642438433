import { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import SidebarNavItem from "components/Dashboard/SidebarNavItem/SidebarNavItem";
import Hamburger from "components/Hamburger/Hamburger";
import { Link } from "react-router-dom";
import { sideBar, sideBarLiveness } from "assets/data";
import { styles } from "./Sidebar.styles";
import { useQuery } from "@tanstack/react-query";
import { getAllBanks } from "services/dashboard";

const Sidebar = ({ open, toggleHamburger }) => {
  const [activeTab, setActiveTab] = useState("Dashboard");
  const { data: allBanks } = useQuery({
    queryKey: ["getAllBanks"],
    queryFn: getAllBanks,
    initialData: [],
  });

  const liveness = allBanks?.[0]?.customer_onboarding_type;

  const updateActiveTab = (tab) => {
    if (tab === activeTab) {
      setActiveTab("");
    } else setActiveTab(tab);
  };

  return (
    <Box as="aside" sx={styles.container}>
      <Stack
        direction="row"
        justifyContent={{ xs: "flex-start", sm: "center" }}
        sx={{
          position: { xs: "sticky", sm: "relative" },
          top: 0,
          bgcolor: "white.main",
          px: 2,
          py: 3,
          zIndex: 2,
        }}
      >
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <Hamburger open={open} toggleHamburger={toggleHamburger} />
        </Box>

        <Stack justifyContent="center" sx={{ flex: "1" }}>
          <Link to="/dashboard">
            <Stack
              as="img"
              src="/img/brand-logo.png"
              alt="brand"
              sx={styles.brandLogo}
            />
          </Link>
        </Stack>
      </Stack>

      {liveness === "liveness_verify" ? (
        <Stack spacing={3} mt={{ xs: 2, sm: 8 }}>
          {sideBarLiveness.map(({ categoryName, subMenu, paths }) => (
            <SidebarNavItem
              key={categoryName}
              toggleHamburger={toggleHamburger}
              categoryName={categoryName}
              subMenu={subMenu}
              paths={paths}
              activeTab={activeTab}
              updateActiveTab={updateActiveTab}
            />
          ))}
        </Stack>
      ) : (
        <Stack spacing={3} mt={{ xs: 2, sm: 8 }}>
          {sideBar.map(({ categoryName, subMenu, paths }) => (
            <SidebarNavItem
              key={categoryName}
              toggleHamburger={toggleHamburger}
              categoryName={categoryName}
              subMenu={subMenu}
              paths={paths}
              activeTab={activeTab}
              updateActiveTab={updateActiveTab}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default Sidebar;
