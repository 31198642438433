export const styles = {
  container: {
    bgcolor: 'white.main',
    boxShadow: '0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)',
    borderRadius: '10.2375px',
    py: { xs: 2, lg: 3 },
  },
  header: {
    fontSize: { xs: 16, sm: 18 },
    fontWeight: 700,
    color: 'green3.main',
  },
  viewMore: {
    fontSize: 14,
    fontWeight: 500,
    color: 'green3.main',
    '&:hover': { color: 'green1.main' },
  },
};
