import SignIn from "pages/Auth/SignIn/SignIn";
import CreateAccount from "pages/Auth/CreateAccount/CreateAccount";
import LandingPage from "pages/LandingPage";
import Register from "pages/Auth/Register/Register";
import { Routes, Route } from "react-router-dom";
import ForgotPassword from "pages/Auth/ForgotPassword/ForgotPassword";
import UserDashboard from "pages/UserDashboard";
import Dashboard from "layout/Dashboard/Dashboard.layout";
import BalanceEnquiry from "pages/BalanceEnquiry";
//import BankFlex from "pages/BankFlex";
import TransactionHistory from "pages/TransactionHistory";
import AccountStatement from "pages/AccountStatement";
import AccountManager from "pages/AccountManager";
import TransferToCit from "pages/TransferToCit";
import TransferToOtherBanks from "pages/TransferToOtherBanks";
import SelfAccountTransfer from "pages/SelfAccountTransfer";
import { UserDashboardProvider } from "context/UserDashboardContext";
import SavedBeneficiaries from "pages/SavedBeneficiaries";
import CardEnquiry from "pages/CardEnquiry";
import CardHotList from "pages/CardHotList";
import CardReplacement from "pages/CardReplacement";
import Profile from "pages/Auth/Profile/Profile";
import AirtimeAndData from "pages/AirtimeAndData";
import BillsPayment from "pages/BillsPayment";
import InActivityTimeOut from "components/InActivityTimeOut/InActivityTimeOut";
//import FixedDeposit from "pages/FixedDeposit";
import "./App.scss";

/* CORPORATE */
import { CorporateDashboardProvider } from "context/CorporateDashboardContext";
import SignInCop from "pages/Auth/SignInCorporate/SignIn";
import CorporateDashboard from "pages/CorporateDashboard";
import CorporateDashboardLay from "layout/Dashboard/CorporateDashboard.layout";
import CorporateAccountStatement from "pages/CorporateAccountStatement";
import CorporateAccountManager from "pages/CorporateAccountManager";
import CorporateSelfAccountTransfer from "pages/CorporateSelfAccountTransfer";
import CorporateTransferToCit from "pages/CorporateTransferToCit";
import CorporateTransferToOtherBanks from "pages/CorporateTransferToOtherBanks";
import CorporateScheduledTransfer from "pages/CorporateScheduledTransfer";
import CorporateSavedBeneficiaries from "pages/CorporateSavedBeneficiaries";
import CorporateTransactionHistory from "pages/CorporateTransactionHistory";
import CorporatePendingApprovals from "pages/CorporatePendingApprovals";
import CorporateAwaitingApproval from "pages/CorporateAwaitingApproval";
import CorporateTransactionLimits from "pages/CorporateTransactionLimits";
import CorporateApprovalStatus from "pages/CorporateApprovalStatus";
import CorporateProfile from "pages/Auth/CorporateProfile/Profile";
import CorporateSignatories from "pages/CorporateSignatories";
import CorporateBillsPayment from "pages/CorporateBillsPayment";
import { useQuery } from "@tanstack/react-query";
import { getAllBanks } from "services/dashboard";
import AccountTierPage from "pages/AccountTierPage";
import UpgradeTierPage from "pages/UpgradeTierPage";
import CorporateForgotPassword from "pages/Auth/CorporateForgotPassword/CorporateForgotPassword";

function App() {

  const { data: allBanks } = useQuery({
    queryKey: ["getAllBanks"],
    queryFn: getAllBanks,
    initialData: [],
  });

  const liveness = allBanks?.[0]?.customer_onboarding_type;

  return (
    <main className="App">
      <Routes>
        <Route element={<InActivityTimeOut />}>
          <Route path="/" element={<LandingPage />} />
          <Route path="auth/sign-in" element={<SignIn />} />
          <Route path="auth/corporate-sign-in" element={<SignInCop />} />
          <Route path="auth/create-account" element={<CreateAccount />} />
          <Route path="auth/register" element={<Register />} />
          <Route path="auth/forgot-password" element={<ForgotPassword />} />
          <Route path="auth/forgot-password-corporate" element={<CorporateForgotPassword />} />

          {/* dashboard / user routes */}
          <Route element={<Dashboard />}>
            <Route
              path="dashboard"
              element={
                <UserDashboardProvider>
                  <UserDashboard />
                </UserDashboardProvider>
              }
            />
            <Route path="balance-enquiry" element={<BalanceEnquiry />} />
            {/* <Route path="bank-flex" element={<BankFlex />} />
            <Route path="fixed-deposit" element={<FixedDeposit />} /> */}
            <Route
              path="transaction-history"
              element={<TransactionHistory />}
            />
            <Route path="account-statement" element={<AccountStatement />} />
            <Route path="account-manager" element={<AccountManager />} />
            <Route path="transfer-internal" element={<TransferToCit />} />
          {liveness === "liveness_verify"&&<Route path="account-tier" element={<AccountTierPage />} />}
          {liveness === "liveness_verify"&&<Route path="upgrade-tier" element={<UpgradeTierPage />} />}

            <Route
              path="transfer-other-banks"
              element={<TransferToOtherBanks />}
            />
            <Route
              path="self-account-transfer"
              element={<SelfAccountTransfer />}
            />
            <Route
              path="saved-beneficiaries"
              element={<SavedBeneficiaries />}
            />
            <Route path="card-enquiry" element={<CardEnquiry />} />
            <Route path="card-hotlist" element={<CardHotList />} />
            <Route path="card-replacement" element={<CardReplacement />} />
            <Route path="auth/profile" element={<Profile />} />
            <Route path="airtime-data" element={<AirtimeAndData />} />
            <Route path="/bills-payment" element={<BillsPayment />} />
          </Route>
          {/* CORPORATE DASHBOARD */}
          <Route
            element={
              <CorporateDashboardProvider>
                <CorporateDashboardLay />
              </CorporateDashboardProvider>
            }
          >
            <Route
              path="corporate-dashboard"
              element={
                <CorporateDashboardProvider>
                  <CorporateDashboard />
                </CorporateDashboardProvider>
              }
            />
            <Route
              path="corporate-account-statement"
              element={
                <CorporateDashboardProvider>
                  <CorporateAccountStatement />
                </CorporateDashboardProvider>
              }
            />
            <Route
              path="corporate-account-manager"
              element={<CorporateAccountManager />}
            />
            <Route
              path="/corporate-self-account-transfer"
              element={
                <CorporateDashboardProvider>
                  <CorporateSelfAccountTransfer />
                </CorporateDashboardProvider>
              }
            />
            <Route
              path="/corporate-transfer-internal"
              element={
                <CorporateDashboardProvider>
                  <CorporateTransferToCit />
                </CorporateDashboardProvider>
              }
            />

            <Route
              path="/corporate-transfer-other-banks"
              element={
                <CorporateDashboardProvider>
                  <CorporateTransferToOtherBanks />
                </CorporateDashboardProvider>
              }
            />
            <Route
              path="/corporate-scheduled-payments"
              element={
                <CorporateDashboardProvider>
                  <CorporateScheduledTransfer />
                </CorporateDashboardProvider>
              }
            />
            <Route
              path="/corporate-saved-beneficiaries"
              element={<CorporateSavedBeneficiaries />}
            />
            <Route
              path="/corporate-transactions"
              element={
                <CorporateDashboardProvider>
                  <CorporateTransactionHistory />
                </CorporateDashboardProvider>
              }
            />

            <Route
              path="/corporate-bills"
              element={<CorporatePendingApprovals />}
            />
            <Route
              path="/corporate-awaiting"
              element={<CorporateAwaitingApproval />}
            />
            <Route
              path="/corporate-transaction-limits"
              element={<CorporateTransactionLimits />}
            />
            <Route
              path="/corporate-approval-status"
              element={<CorporateApprovalStatus />}
            />
            <Route
              path="/auth/corporate-profile"
              element={
                <CorporateDashboardProvider>
                  <CorporateProfile />
                </CorporateDashboardProvider>
              }
            />
            <Route
              path="/corporate-signatories"
              element={<CorporateSignatories />}
            />

            <Route
              path="/corporate-bills-payment"
              element={
                <CorporateDashboardProvider>
                  <CorporateBillsPayment />
                </CorporateDashboardProvider>
              }
            />
            {/*             <Route path="balance-enquiry" element={<BalanceEnquiry />} />
            <Route path="bank-flex" element={<BankFlex />} />
            <Route path="fixed-deposit" element={<FixedDeposit />} />
            <Route
              path="transaction-history"
              element={<TransactionHistory />}
            />
            <Route path="account-statement" element={<AccountStatement />} />
            <Route path="account-manager" element={<AccountManager />} />
            <Route path="transfer-internal" element={<TransferToCit />} />
            <Route
              path="transfer-other-banks"
              element={<TransferToOtherBanks />}
            />
            <Route
              path="self-account-transfer"
              element={<SelfAccountTransfer />}
            />
            <Route
              path="saved-beneficiaries"
              element={<SavedBeneficiaries />}
            />
            <Route path="card-enquiry" element={<CardEnquiry />} />
            <Route path="card-hotlist" element={<CardHotList />} />
            <Route path="card-replacement" element={<CardReplacement />} />
            <Route path="auth/profile" element={<Profile />} />
            <Route path="airtime-data" element={<AirtimeAndData />} />
            <Route path="/bills-payment" element={<BillsPayment />} /> */}
          </Route>
        </Route>
      </Routes>
    </main>
  );
}

export default App;
