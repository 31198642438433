/** @format */

import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import BillsPaymentModal from "./BillsPaymentModal/BillsPaymentModal";
import { useFormik } from "formik";
import { billsPaymentTab } from "assets/data";
import { Link, useSearchParams } from "react-router-dom";
import {
  userProfile,
  buyElectricity,
  validateUniqueNumber,
  cableTvSubscription,
  createBeneficiary,
  sportsBetting,
} from "services/dashboard";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  ElectricityPaymentSchema,
  CableTvSchema,
  BettingPaymentSchema,
} from "services/Yup/BillsPaymentSchema";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import ElectricityPaymentForm from "./ElectricityPaymentForm";
import CableTvForm from "./CableTvForm";
import { styles } from "./BillsPayment.styles";
import SportBettingForm from "./SportBettingForm";

const BillsPaymentComponent = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState("");
  const [step, setStep] = useState(0);
  const [param] = useSearchParams();
  const currentTab = param.get("tab");

  // get user profile
  const { data } = useQuery({
    queryKey: ["userProfile"],
    queryFn: userProfile,
    onError: (err) => {
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
    },
  });

  const accounts =
    data?.customer?.accounts?.map(({ account_no }) => account_no) || [];

  const formik = useFormik({
    initialValues: {
      account: "",
      disco_type: "",
      disco_name: "",
      service_name: "",
      smart_card_no: "",
      product_name: "",
      product_codes: "",
      amount: "",
      meter_no: "",
      phone_no: "",
      transaction_pin: "",
      duration: "",
      saveBeneficiary: false,
      customer_id: "",
    },
    validationSchema:
      currentTab === "electricity-payment"
        ? ElectricityPaymentSchema
        : currentTab === "cable-tv"
        ? CableTvSchema
        : BettingPaymentSchema,
    onSubmit: () => {
      setOpenModal(true);
      console.log("jdddd");
    },
  });

  // Reset error
  useEffect(() => {
    if (formik.values.transaction_pin.length === 4) {
      setError("");
    }
  }, [formik.values.transaction_pin.length]);

  //   Buy electricity mutation
  const { mutate: BuyElectricity, isLoading } = useMutation({
    mutationFn: buyElectricity,
    onSuccess: (data) => {
      setSeverity("success");
      setMessage("Transaction successful");
      setOpen(true);
      console.log("BuyElectricity", data);
    },
    onError: (err) => {
      setSeverity("error");
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
    },
  });

  // save beneficiary mutation
  const { mutate: CreateBeneficiary } = useMutation({
    mutationFn: createBeneficiary,
  });

  //   validate Unique Number mutation
  const { mutate: ValidateUniqueNumber, data: cardInfo } = useMutation({
    mutationFn: validateUniqueNumber,
    onSuccess: () => {
      setSeverity("success");
      setMessage("Unique number is valid");
      setOpen(true);
    },
    onError: (err) => {
      setSeverity("error");
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
    },
  });

  //   cable Tv Subscription mutation
  const { mutate: CableTvSubscription, isLoading: loadingTV } = useMutation({
    mutationFn: cableTvSubscription,
    onSuccess: (data) => {
      setSeverity("success");
      setMessage("Transaction successful");
      setOpen(true);
      console.log("cableTvSubscription", data);
    },
    onError: (err) => {
      setSeverity("error");
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
    },
  });

  //   sports betting mutation
  const { mutate: SportsBetting, isLoading: bettingLoading } = useMutation({
    mutationFn: sportsBetting,
    onSuccess: (data) => {
      setSeverity("success");
      setMessage("Transaction successful");
      setOpen(true);
      console.log("Betting", data);
    },
    onError: (err) => {
      setSeverity("error");
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
    },
  });

  const validateSmartCard = () => {
    ValidateUniqueNumber({
      validate_type: "smart_card",
      smart_card_no: formik.values.smart_card_no,
      service_name: formik.values.service_name,
    });
  };

  const validateBetting = () => {
    ValidateUniqueNumber({
      validate_type: "betting",
      customer_id: formik.values.customer_id,
      service_name: formik.values.service_name.label,
    });
  };

  const validateMeterNumber = () => {
    ValidateUniqueNumber({
      validate_type: "meter",
      disco_type: formik.values.disco_type,
      meter_no: formik.values.meter_no,
    });
  };

  const handleClose = () => {
    setOpenModal(false);
    setStep(0);
    formik.setFieldValue("transaction_pin", "");
  };

  const nextStep = () => {
    setStep((prevS) => prevS + 1);
  };

  const Subscribe = () => {
    if (formik.values.transaction_pin.length === 4) {
      setError("");

      if (formik.values.saveBeneficiary) {
        CreateBeneficiary({
          beneficiary_type: "utility",
          beneficiary_number: formik.values.meter_no
            ? formik.values.meter_no
            : formik.values.smart_card_no,
          biller_name: formik.values.disco_type
            ? formik.values.disco_type
            : formik.values.service_name,
        });
      }

      if (currentTab === "electricity-payment") {
        return BuyElectricity({
          account_no: formik.values.account,
          disco_type: formik.values.disco_type,
          amount: formik.values.amount,
          meter_no: formik.values.meter_no,
          // phone_no: data?.customer?.phone_number,
           phone_no: formik.values.phone_no,

          transaction_pin: formik.values.transaction_pin,
        });
      } else if (currentTab === "cable-tv") {
        return CableTvSubscription({
          account_no: formik.values.account,
          amount: formik.values.amount,
          phone_no: data?.customer?.phone_number,
          transaction_pin: formik.values.transaction_pin,
          service_name: formik.values.service_name,
          smart_card_no: formik.values.smart_card_no,
          product_codes: [formik.values.product_codes],
          duration: formik.values.duration.charAt(),
        });
      } else if (currentTab === "betting") {
        return SportsBetting({
          account_no: formik.values.account,
          amount: formik.values.amount,
          phone_number: data?.customer?.phone_number,
          transaction_pin: formik.values.transaction_pin,
          name: cardInfo.detail.result.name,

        });
      }
      return;
    } else {
      setError("Enter valid transaction pin");
    }
  };

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.header}>Bills Payment</Typography>

      <Box sx={styles.tabContainer}>
        <Stack direction="row" alignItems="center" sx={styles.tab}>
          {billsPaymentTab.map(({ label, href }) => (
            <Link key={label} to={href}>
              <Typography
                sx={styles.category(
                  label
                    .toLowerCase()
                    .includes(currentTab.toLowerCase().split("-")[0])
                )}
              >
                {label}
              </Typography>
            </Link>
          ))}
        </Stack>
      </Box>

      <Stack
        spacing={{ xs: 3, sm: 4 }}
        sx={{ ...styles.tab, width: { sm: "85%", lg: "70%" } }}
      >
        {currentTab === "electricity-payment" && (
          <ElectricityPaymentForm
            formik={formik}
            styles={styles}
            validateMeterNumber={validateMeterNumber}
            cardInfo={cardInfo}
            accounts={accounts}
            open={openModal}
            setOpen={setOpenModal}
            // phone_no={phone_no}

          />
        )}

        {currentTab === "cable-tv" && (
          <CableTvForm
            formik={formik}
            styles={styles}
            validateSmartCard={validateSmartCard}
            cardInfo={cardInfo}
            accounts={accounts}
            open={openModal}
            setOpen={setOpenModal}

          />
        )}
        {currentTab === "betting" && (
          <SportBettingForm
            formik={formik}
            styles={styles}
            validateSmartCard={validateBetting}
            cardInfo={cardInfo}
            accounts={accounts}
            open={openModal}
            setOpen={setOpenModal}
          />
        )}
      </Stack>
    </Box>
  );
};

export default BillsPaymentComponent;
