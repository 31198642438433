// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-animation {
  text-align: center;
}

.loading-animation span {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: #9c1214;
  border-radius: 50px;
  margin-right: 8px;
  animation: up-down6 0.5s ease-in infinite alternate;
}

.loading-animation span:nth-child(2) {
  background: #e77b7d;
  animation-delay: 0.16s;
}

.loading-animation span:nth-child(3) {
  background: #e04a4c;
  animation-delay: 0.32s;
}

.loading-animation span:nth-child(4) {
  background: #D22F31;
  animation-delay: 0.48s;
}

@keyframes up-down6 {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/base/LoadingAnimation/LoadingAnimation.styles.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,qBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mDAAA;AACJ;;AAEA;EACI,mBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,sBAAA;AACJ;;AAEA;EACI;IACI,4BAAA;EACN;EACE;IACI,2BAAA;EACN;AACF","sourcesContent":[".loading-animation {\n    text-align: center;\n}\n\n.loading-animation span {\n    display: inline-block;\n    width: 25px;\n    height: 25px;\n    background: #9c1214;\n    border-radius: 50px;\n    margin-right: 8px;\n    animation: up-down6 0.5s ease-in infinite alternate;\n}\n\n.loading-animation span:nth-child(2) {\n    background: #e77b7d;\n    animation-delay: 0.16s;\n}\n\n.loading-animation span:nth-child(3) {\n    background: #e04a4c;\n    animation-delay: 0.32s;\n}\n\n.loading-animation span:nth-child(4) {\n    background: #D22F31;\n    animation-delay: 0.48s;\n}\n\n@keyframes up-down6 {\n    0% {\n        transform: translateY(-10px);\n    }\n    100% {\n        transform: translateY(10px);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
