import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import Select from "components/base/Select/Select";
import Switch from "components/base/Switch/Switch";
import TransferModal from "components/base/TransferModal/TransferModal";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import {
  getAccountName,
  bankTransfer,
  userProfile,
  createBeneficiary,
  getAllBanks,
} from "services/dashboard";
import { useQuery, useMutation } from "@tanstack/react-query";
import { validateNumbers } from "utils/validateNumbers";
import { styles } from "./TransferToOtherBanksForm.styles";

const TransferToOtherBanksForm = ({ formik, open, setOpen }) => {
  // get user profile
  const { data } = useQuery({
    queryKey: ["userProfile"],
    queryFn: userProfile,
  });

  const [openError, setOpenError] = React.useState({
    nameEnquiry: false,
    transaction: false,
  });
  const [error, setError] = React.useState({
    nameEnquiry: "",
    transaction: "",
  });
  const [step, setStep] = React.useState(0);
  const [otpError, setOtpError] = React.useState(0);

  const getBankCode = (bankName) => {
    const bankObject = fullBanks?.find(
      ({ bank_name }) => bank_name === bankName
    );

    return bankObject?.bank_code || "";
  };

  const handleCloseModal = () => {
    setStep(0);
    setOpen(false);
    setOtpError("");
    formik.setFieldValue("transaction_pin", "");
  };

  const handleCloseError = (key) => {
    setOpenError((prevS) => ({ ...prevS, [key]: false }));
  };

  const handleOpenError = (key) => {
    setOpenError((prevS) => ({ ...prevS, [key]: true }));
  };

  const setBackendError = (key, err) => {
    setError((prevS) => ({ ...prevS, [key]: err }));
  };

  React.useEffect(() => {
    if (formik.values.transaction_pin.length === 4) {
      setOtpError("");
    }
  }, [formik.values.transaction_pin.length]);

  const handleConfirmTransactionBtn = () => {
    setStep((prevS) => prevS + 1);
  };

  const handleTransfer = () => {
    if (formik.values.transaction_pin.length === 4) {
      setOtpError("");

      if (formik.values.saveBeneficiary) {
        CreateBeneficiary({
          beneficiary_type: "external_transfer",
          beneficiary_name: formik.values.beneficiary_account_name,
          beneficiary_bank: `${
            bankCode || getBankCode(formik.values.beneficiary_bank_name)
          },${formik.values.beneficiary_bank_name
            ?.replace(/[^a-z]+/gi, " ")
            ?.split("")
            ?.join("")}`,
          beneficiary_acct_no: formik.values.beneficiary_account_no,
        });
      }

      mutate({
        transfer_type: "other_bank",
        transaction_pin: formik.values.transaction_pin,
        account_no: formik.values.account,
        beneficiary_name: formik.values.beneficiary_account_name,
        beneficiary_acct_no: formik.values.beneficiary_account_no,
        amount: formik.values.amount,
        beneficiary_acct_type: "savings",
        beneficiary_bank_code:
          bankCode || getBankCode(formik.values.beneficiary_bank_name),
        beneficiary_bank_name: formik.values.beneficiary_bank_name
          ?.replace(/[^a-z]+/gi, " ")
          ?.split("")
          ?.join(""),
        nip_session_id: formik.values.nip_session_id,
        ...(formik.values.narration && { narration: formik.values.narration }),
      });
    } else {
      setOtpError("Enter valid transaction pin");
    }
  };

  // get all banks query
  const { data: allBanks } = useQuery({
    queryKey: ["allBanks"],
    queryFn: getAllBanks,
    initialData: [],
  });
  const fullBanks = allBanks[0]?.nip_banks || [];

  //const banks = allBanks[0]?.nip_banks?.map(({ bank_name }) => bank_name) || [];

  // get beneficiary account name query
  const queryType = "other_bank";
  const account_no = `${formik.values.beneficiary_account_no}`;
  const bankCode =
    getBankCode(formik.values.beneficiary_bank_name) ||
    formik.values.beneficiary_bank_name?.match(/\d/g)?.join("");
  console.log(bankCode);

  const { isError } = useQuery({
    queryKey: ["name_enquiry", queryType, account_no, bankCode],
    queryFn: () => getAccountName(queryType, account_no, bankCode),
    onSuccess: (data) => {
      handleCloseError("nameEnquiry");

      formik.setFieldValue(
        "beneficiary_account_name",
        data.name.replace(",", "")
      );

      formik.setFieldValue("nip_session_id", data.nip_session_id);
    },
    onError: (err) => {
      setBackendError(
        "nameEnquiry",
        err?.response?.data?.detail || err.message
      );
      handleOpenError("nameEnquiry");
      formik.setFieldValue("beneficiary_account_name", "oi");
      console.log(err.response);
    },
    enabled: account_no.length === 10,
  });
  const { mutate, isLoading } = useMutation({
    mutationFn: bankTransfer,
    onSuccess: () => {
      setStep((prevS) => prevS + 1);
    },
    onError: (err) => {
      setBackendError(
        "transaction",
        err?.response?.data?.detail || err.message
      );
      handleOpenError("transaction");
    },
  });

  // save beneficiary mutation
  const { mutate: CreateBeneficiary } = useMutation({
    mutationFn: createBeneficiary,
  });

  const accounts =
    data?.customer?.accounts?.map(({ account_no }) => account_no) || [];

  return (
    <Stack
      as="form"
      spacing={{ xs: 3, sm: 4 }}
      onSubmit={formik.handleSubmit}
      sx={styles.container}
    >
      <TransferModal
        open={open}
        handleClose={handleCloseModal}
        step={step}
        handleConfirmTransactionBtn={handleConfirmTransactionBtn}
        setStep={setStep}
        handleTransfer={handleTransfer}
        isLoading={isLoading}
        openError={openError.transaction}
        handleCloseError={() => handleCloseError("transaction")}
        errorMessage={error.transaction}
        formik={formik}
        error={otpError}
      />

      <DirectionSnackbar
        open={openError.nameEnquiry}
        handleClose={() => handleCloseError("nameEnquiry")}
        message={error.nameEnquiry}
      />

      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Account">
          <Select
            options={accounts}
            placeholder="Select Account"
            name="account"
            value={formik.values.account}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>

        <InputWithLabel label="Beneficiary Bank Name">
          <Select
            options={fullBanks.map(({ bank_name }) => bank_name)}
            placeholder="Enter Beneficiary Bank Name"
            name="beneficiary_bank_name"
            value={formik.values.beneficiary_bank_name
              ?.replace(/[^a-z]+/gi, " ")
              ?.split("")
              ?.join("")}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>
      </Stack>

      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Beneficiary Account Number">
          <Input
            type="tel"
            name="beneficiary_account_no"
            placeholder="Enter Beneficiary Account Number"
            value={formik.values.beneficiary_account_no}
            onChange={(event) => {
              validateNumbers(event, 10, formik.handleChange);
              formik.setFieldValue("beneficiary_account_name", "");
            }}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>

        <InputWithLabel label="Beneficiary Account Name">
          <Input
            name="beneficiary_account_name"
            placeholder="Enter Beneficiary Account Name"
            value={formik.values.beneficiary_account_name}
            disabled={true}
            onChange={formik.handleChange}
            formik={formik}
            sx={{
              ...styles.input,
              borderColor: isError ? "red.main" : "#EFF0F6",
            }}
          />
        </InputWithLabel>
      </Stack>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 3, sm: 4 }}>
        <InputWithLabel label="Amount">
          <Input
            name="amount"
            type="tel"
            placeholder="Enter Amount"
            value={formik.values.amount}
            onChange={(event) => {
              validateNumbers(event, 1000, formik.handleChange);
            }}
            formik={formik}
            sx={styles.input}
          />
          <div
            style={{
              fontSize: "13px",
              marginTop: "4px",
              fontWeight: "600",
            }}
          >
            Transfer Fee: <span>₦ {allBanks[0]?.transfer_fee}</span>{" "}
          </div>
        </InputWithLabel>

        <InputWithLabel label="Narration">
          <Input
            name="narration"
            placeholder="Enter Description (Optional)"
            value={formik.values.narration}
            onChange={formik.handleChange}
            formik={formik}
            sx={styles.input}
          />
        </InputWithLabel>
      </Stack>

      <Stack sx={{ transform: "translateY(-1.125rem)" }}>
        <Switch
          name="saveBeneficiary"
          label="Save as beneficiary"
          value={formik.values.saveBeneficiary}
          onChange={formik.handleChange}
        />
      </Stack>

      <Button
        type="submit"
        variant="contained"
        color="green3"
        sx={styles.submitBtn}
      >
        Proceed
      </Button>
    </Stack>
  );
};

export default TransferToOtherBanksForm;
