import { useState, useEffect, createRef, useContext } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Sidebar from "components/Dashboard/Sidebar2/Sidebar";
import { Outlet, Navigate } from "react-router-dom";
import { useSessionStorage } from "hooks/useSessionStorage";
import Navbar from "../../components/Dashboard/Navbar/Navbar.CorporateDashboard";
import { useLocation } from "react-router-dom";
import PageTransition from "components/base/PageTransition/PageTransition";
import { styles } from "./Dashboard.layout.styles";
import CorporateDashboardContext from "context/CorporateDashboardContext";
import ChangePinModal from "components/CorporateDashboard/ChangePasswordModal";

const CorperateDashboardLay = () => {
  const { passwordChanged } = useContext(CorporateDashboardContext);

  const password_changed = passwordChanged === false ? false : true;

  const [open, setOpen] = useState(false);
  const { getSessionStorage } = useSessionStorage("__appUser");
  const { pathname } = useLocation();
  const nodeRef = createRef();

  const toggleHamburger = () => {
    setOpen((prevS) => !prevS);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [step, setStep] = useState(0);

  const [open2, setOpen2] = useState(true);

  const [error, setError] = useState("");
  const [errorOther, setErrorOther] = useState();

  const nextStep = () => {
    setStep((current) => current + 1);
  };

  const handleClose = () => {
    setOpen2(true);
  };

  const handleOpen = () => {
    setOpen2(true);
  };

  useEffect(() => {
    //eslint-disable-next-line
  }, [passwordChanged]);

  const handleError = (err) => {
    setError(err?.response?.data?.detail || err?.message || err);
    setErrorOther(
      "Password must be 8 characters long, on upper and lower case letter, must contain a special character and a number"
    );
  };
  console.log(!passwordChanged, "nice");

  return getSessionStorage ? (
    <Grid container sx={styles.container}>
      <Grid
        item
        xs={12}
        sm={3}
        lg={2.5}
        sx={{
          ...styles.left,
          display: { xs: open ? "block" : "none", sm: "block" },
        }}
      >
        <Sidebar open={open} toggleHamburger={toggleHamburger} />
      </Grid>
      <Grid item xs={12} sm={9} lg={9.5} sx={styles.right}>
        <Navbar open={open} toggleHamburger={toggleHamburger} />

        <PageTransition nodeRef={nodeRef}>
          <Box sx={styles.content}>
            <Outlet />
          </Box>
        </PageTransition>
      </Grid>
      {password_changed === true ? (
        ""
      ) : (
        <ChangePinModal
          open={open2}
          handleClose={handleClose}
          step={step}
          error={error}
          errorOther={errorOther}
          handleError={handleError}
          handleOpen={handleOpen}
          nextStep={nextStep}
          setOtherError={setErrorOther}
        />
      )}
    </Grid>
  ) : (
    <Navigate to="/auth/corporate-sign-in" />
  );
};

export default CorperateDashboardLay;
