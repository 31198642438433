/** @format */

import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Input from "components/base/Input/Input";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import { buyElectricity, getDiscos, vendBetting } from "services/dashboard";
import { useMutation, useQuery } from "@tanstack/react-query";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import Select from "components/base/Select/Select";
import Switch from "components/base/Switch/Switch";
import { validateNumbers } from "utils/validateNumbers";
import Button from "@mui/material/Button";
import CableTVPaymentModal from "./BillsPaymentModal/CableTvPaymentModal";
import { useNavigate } from "react-router-dom";
import ElectricityPaymentModal from "./BillsPaymentModal/ElectricticyPaymentModal";

const ElectricityPaymentForm = ({
  formik,
  styles,
  validateMeterNumber,
  cardInfo,
  accounts,
  action,
  open,
  setOpen,
  phone_no,
}) => {
  // const [open, setOpen] = useState(false);
  const [otpError, setOtpError] = useState(0);
  const [open2, setOpen2] = useState(false);
  const [step, setStep] = useState(0);


  const [error, setError] = React.useState({
    nameEnquiry: "",
    transaction: "",
  });

  const [openError, setOpenError] = React.useState({
    nameEnquiry: false,
    transaction: false,
  });
  const router = useNavigate();

  const purchaseElectricity = () => {
    if (formik.values.transaction_pin.length === 4) {
      mutate({
        account_no: formik.values.account,
        amount: formik.values.amount,
        meter_no: formik.values.meter_no,
        transaction_pin:formik.values.transaction_pin,
        phone_no: formik.values.phone_no,
        disco_type: formik.values.disco_type,
      });
    }
else{
  setOtpError("Enter valid OTP");

}


  };
  const handleCloseModal = () => {
    setStep(0);
    setOpen(false);
    formik.setFieldValue("transaction_pin", "");
  };

  const handleCloseError = (key) => {
    setOpenError((prevS) => ({ ...prevS, [key]: false }));
  };

  const handleOpenError = (key) => {
    setOpenError((prevS) => ({ ...prevS, [key]: true }));
  };

  const setBackendError = (key, err) => {
    setError((prevS) => ({ ...prevS, [key]: err }));
  };

  React.useEffect(() => {
    if (formik.values.transaction_pin.length === 4) {
      setOtpError("");
    }
  }, [formik.values.transaction_pin.length]);

  const handleConfirmTransactionBtn = () => {
    setStep((prevS) => prevS + 1);
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: buyElectricity,
    onSuccess: (data) => {
      setOpen(true);
      setStep(1);
      // setOpen2(false);
    
      handleCloseModal();
      formik.resetForm();

      console.log(data);
    },
    onError: (err) => {
      setBackendError(
        "transaction",
        err?.response?.data?.detail || err.message
      );
      handleOpenError("transaction");
      console.log(err?.response?.data?.detail, err.message);
    },
  });

  const [message, setMessage] = useState("");

  // get Biller
  const { data: discos } = useQuery({
    queryKey: ["getDiscos"],
    queryFn: getDiscos,
    onError: (err) => {
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
    },
  });

  const handleDiscoType = (value) => {
    const findSelectedDisco = discos?.detail?.find(
      ({ disco }) => disco === value
    );

    formik.setFieldValue("disco_type", findSelectedDisco.code);
  };

  const electricyBiller = discos?.detail?.map(({ disco }) => disco) || [];

  // reset form fields on mount
  useEffect(() => {
    formik.resetForm();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Stack
        as="form"
        spacing={{ xs: 3, sm: 4 }}
        onSubmit={formik.handleSubmit}
        sx={styles.container}
      >
        <ElectricityPaymentModal
          open={open}
          handleClose={handleCloseModal}
          step={step}
          handleConfirmTransactionBtn={handleConfirmTransactionBtn}
          setStep={setStep}
          handleTransfer={purchaseElectricity}
          isLoading={isLoading}
          openError={openError.transaction}
          handleCloseError={() => handleCloseError("transaction")}
          errorMessage={error.transaction}
          formik={formik}
          error={otpError}
        />

        <DirectionSnackbar
          open={open}
          message={message}
          handleClose={() => {
            setMessage(false);
          }}
        />

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 3, sm: 4 }}
        >
          <InputWithLabel label="Select Account">
            <Select
              options={accounts}
              placeholder="Select Account"
              name="account"
              value={formik.values.account}
              formik={formik}
              sx={styles.input}
            />
          </InputWithLabel>

          <InputWithLabel label="Select Biller">
            <Select
              options={electricyBiller}
              placeholder="Select Disco (e.g IKEDC)"
              name="disco_name"
              value={formik.values.disco_name}
              formik={formik}
              sx={styles.input}
              onChange={handleDiscoType}
            />
          </InputWithLabel>
        </Stack>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 3, sm: 4 }}
        >
          <InputWithLabel label="Amount">
            <Input
              id="amount"
              name="amount"
              type="tel"
              value={formik.values.amount}
              onChange={(event) => {
                validateNumbers(event, 1000, formik.handleChange);
              }}
              placeholder="Enter amount"
              formik={formik}
              sx={styles.input}
            />
          </InputWithLabel>

          <InputWithLabel label="Meter Number">
            <Input
              type="tel"
              id="meter_no"
              name="meter_no"
              value={formik.values.meter_no}
              onChange={(event) => {
                validateNumbers(event, 50, formik.handleChange);
              }}
              placeholder="Enter meter number"
              formik={formik}
              sx={styles.input}
              onBlur={validateMeterNumber}
            />
            <Typography sx={{ textTransform: "capitalize", fontSize: 12 }}>
              {cardInfo?.detail?.name?.toLowerCase()}
            </Typography>
          </InputWithLabel>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 3, sm: 4 }}
        >
          <InputWithLabel label=" Enter Phone number">
          <Input
              type="tel"
              id="phone_no"
              name="phone_no"
              value={formik.values.phone_no}
              placeholder="Enter Phone number"
              formik={formik}
              sx={styles.input}
              onChange={(event) => {
                validateNumbers(event, 11, formik.handleChange);
              }}
            />
          </InputWithLabel>

</Stack>
        <Stack
          sx={{
            transform: "translateY(-1rem)",
            pb: 5,
          }}
        >
          <Switch
            name="saveBeneficiary"
            label="Save as beneficiary"
            value={formik.values.saveBeneficiary}
            onChange={formik.handleChange}
          />
        </Stack>
        <Button
          type="submit"
          variant="contained"
          color="green3"
          sx={styles.submitBtn}
        >
          Proceed
        </Button>
      </Stack>
    </>
  );
};

export default ElectricityPaymentForm;
