import * as React from "react";
import AuthLayout from "layout/Auth/Auth.layout";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import Input from "components/base/Input/Input";
// import CheckBox from "components/base/Checkbox/Checkbox";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import { ReactComponent as VisibilityOn } from "assets/svg/visibility-on.svg";
import { ReactComponent as VisibilityOff } from "assets/svg/visibility-off.svg";
import { styles } from "./signIn.styles";
import { Link, useNavigate } from "react-router-dom";
import { userLogin } from "services/Auth";
import { useMutation } from "@tanstack/react-query";
import { SignInSchema } from "services/Yup/SignInSchema";
import { useSessionStorage } from "hooks/useSessionStorage";
import { useFormik } from "formik";
import { validateNumbers } from "utils/validateNumbers";
import LoadingAnimation from "components/base/LoadingAnimation/LoadingAnimation";

const SignIn = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState("");
  const { setSessionStorage } = useSessionStorage("__appUser");
  const navigate = useNavigate();

  // scroll to top
  window.scrollTo(0, 0);

  const toggleShowPassword = () => {
    setShowPassword((prevS) => !prevS);
  };

  const handleClick = (err) => {
    setError(err?.response?.data?.detail || err.message);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: userLogin,
    onSuccess: (data) => {
      const accountNumber = data.data.customer.accounts.map(
        (account) => account.account_no
      );

      setSessionStorage({
        image: data.data.customer.image,
        access_token: data.access_token,
        account: accountNumber,
      });
      navigate("/dashboard");
    },
    onError: (err) => {
      handleClick(err);
    },
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      build: 1000000,
      rememberMe: false,
    },
    validationSchema: SignInSchema,
    onSubmit: (values) => {
      setError("");
      const { username, password, build } = values;
      mutate({ username, password, build });
    },
  });

  return (
    <AuthLayout image="/img/auth-1.webp">
      <DirectionSnackbar
        open={open}
        handleClose={handleClose}
        message={error}
      />

      <Box as="form" sx={styles.form} onSubmit={formik.handleSubmit}>
        <Typography sx={styles.header}>Login</Typography>
        <Typography sx={styles.openAccountTxt}>
          If you don’t have an account yet?{" "}
          <Typography as="span" sx={styles.openAccount}>
            <Link to="/auth/create-account">Open an Instant Account!</Link>
          </Typography>
        </Typography>

        <Stack spacing={3}>
          <InputWithLabel label="Username">
            <Input
              name="username"
              placeholder="Enter your username"
              value={formik.values.username}
              onChange={formik.handleChange}
              formik={formik}
            />
          </InputWithLabel>

          <InputWithLabel label="Enter your Password">
            <Input
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter your 6 digit pin"
              name="password"
              maxLength={6}
              value={formik.values.password}
              onChange={(event) => {
                validateNumbers(event, 6, formik.handleChange);
              }}
              formik={formik}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOn />
                    ) : (
                      <VisibilityOff className="dark-bg-visibility-off" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </InputWithLabel>

          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ pb: 2, transform: "translateY(-1rem)" }}
          >
            {/* <Stack
              as="label"
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ cursor: "pointer", mb: 0 }}
            >
              <CheckBox
                name="rememberMe"
                checked={formik.values.rememberMe}
                onChange={formik.handleChange}
              />
              <Typography sx={styles.text}>Remember me?</Typography>
            </Stack> */}

            <Link to="/auth/forgot-password">
              <Typography sx={styles.text}>Forgot Password ?</Typography>
            </Link>
          </Stack>

          <Button
            variant="contained"
            color="green3"
            type="submit"
            disabled={isLoading}
            sx={styles.btn}
          >
            {isLoading ? <LoadingAnimation /> : "Login"}
          </Button>

          <Box>
            <Typography sx={styles.bottomText}>
              You already have an account number?
            </Typography>
            <Typography sx={styles.createAccount}>
              <Link to="/auth/register">
                Click here to continue Registration!
              </Link>
            </Typography>
          </Box>
        </Stack>
      </Box>
    </AuthLayout>
  );
};

export default SignIn;
