import * as React from "react";
import Box from "@mui/material/Box";
import CustomModal from "components/base/Modal/Modal";
import ConfirmTransaction from "../ConfirmTransaction/ConfirmTransaction";
import EnterTransactionPin from "../EnterTransactionPin/EnterTransactionPin";
import TransactionSuccessful from "../TransactionSuccessful/TransactionSuccessful";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";

const TransferModal = ({
  open,
  handleClose,
  step,
  handleConfirmTransactionBtn,
  handleTransfer,
  isLoading,
  openError,
  handleCloseError,
  errorMessage,
  formik,
  error,
}) => {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <DirectionSnackbar
        open={openError}
        handleClose={handleCloseError}
        message={errorMessage}
      />

      <Box>
        {step === 0 && (
          <ConfirmTransaction
            formik={formik}
            handleClick={handleConfirmTransactionBtn}
          />
        )}

        {step === 1 && (
          <EnterTransactionPin
            handleConfirmTransactionBtn={handleConfirmTransactionBtn}
            handleClick={handleTransfer}
            isLoading={isLoading}
            formik={formik}
            error={error}
          />
        )}

        {step === 2 && (
          <TransactionSuccessful handleClick={handleClose} formik={formik} />
        )}
      </Box>
    </CustomModal>
  );
};

export default TransferModal;
