/** @format */

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FlexDetails from "components/base/FlexDetails/FlexDetails";
import { formatCurrency } from "utils/formatCurrency";
import { styles } from "components/base/TransferModal/TransferModal.styles";

const ConfirmTransactionCableTV = ({ formik, handleClick }) => {
  return (
    <Box>
      <Typography sx={styles.header}>Confirm Transaction</Typography>
      <Stack spacing={2}>
        <FlexDetails left="Account " right={formik.values.account} />

        <FlexDetails
          left="Amount"
          right={`${formatCurrency(formik.values.amount)}`}
        />

        <FlexDetails left="Biller" right={formik.values.service_name} />
        <FlexDetails
          left="Smart Card Number"
          right={formik.values.smart_card_no}
        />
        <FlexDetails left="Plan Category" right={formik.values.product_name} />
        <FlexDetails left="Duration" right={formik.values.duration} />

        {/* <FlexDetails left='Reference No.' right='2103428464717174731' /> */}
        <FlexDetails left="Sent on" right={new Date().toLocaleString()} />
      </Stack>

      <Box mx="auto" width="fit-content" mt={{ xs: 3, sm: 5 }}>
        <Button
          variant="contained"
          color="green3"
          sx={styles.btn}
          onClick={(e) => {
            e.stopPropagation();
            handleClick();
          }}

        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmTransactionCableTV ;
