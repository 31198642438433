import { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "assets/svg/arrow-right.svg";
import { rowColors } from "assets/data";
import { getBeneficiaries } from "services/dashboard";
import { useQuery } from "@tanstack/react-query";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import { styles } from "./Beneficiaries.styles";

const Beneficiaries = ({ formik }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const type = "local_transfer";
  const searchParam = "";

  const { data: beneficiaries, isLoading } = useQuery({
    queryKey: ["getBeneficiaries", type, searchParam],
    queryFn: () => getBeneficiaries(type, searchParam, 1),
    onError: (err) => {
      setMessage(err.response.data.detail || err.message);
      setOpen(true);
    },
  });

  const handleSetbeneficiaryAccountNumber = (value) => {
    formik.setFieldValue("beneficiary_account_no", value);
  };

  return (
    <Box sx={styles.container}>
      <DirectionSnackbar
        open={open}
        message={message}
        handleClose={() => {
          setOpen(false);
        }}
      />

      <Typography sx={styles.header}>Transfer to KCMFB A/C</Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        mt={{ xs: 2, md: 3 }}
      >
        <Typography sx={styles.text}>Select Beneficiaries</Typography>
        <Typography sx={styles.viewAll}>
          <Link to="/saved-beneficiaries?tab=local_transfer">
            View all Beneficiaries <ArrowIcon />
          </Link>
        </Typography>
      </Stack>
      {isLoading ? (
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ height: "auto" }}
        >
          {[1, 2, 3, 4, 5, 6, 7].map((skel) => (
            <Box key={skel}>
              <Skeleton
                variant="circular"
                sx={{
                  width: { xs: 45, lg: 60 },
                  height: { xs: 45, lg: 60 },
                  transform: "none",
                  mt: 1,
                }}
              />
              <Skeleton
                variant="text"
                sx={{
                  width: "100%",
                  fontSize: { xs: 12, lg: 14 },
                  transform: "none",
                  mt: 1,
                }}
              />
            </Box>
          ))}
        </Stack>
      ) : (
        <Box sx={{ overflow: "auto" }} mt={{ xs: 2, md: 3 }}>
          <Stack
            direction="row"
            alignItems="center"
            sx={styles.beneficiaryContainer}
          >
            {beneficiaries?.detail?.results?.map(
              ({ beneficiary_name, beneficiary_acct_no }, index) => (
                <Box
                  key={beneficiary_name + index}
                  sx={styles.beneficiary}
                  onClick={() =>
                    handleSetbeneficiaryAccountNumber(beneficiary_acct_no)
                  }
                >
                  <Stack
                    sx={styles.beneficiaryInitials(rowColors[(index + 1) % 4])}
                  >
                    {beneficiary_name.split(" ")[0].charAt().toUpperCase()}{" "}
                    {beneficiary_name?.split(" ")[1]
                      ? beneficiary_name?.split(" ")[1].charAt().toUpperCase()
                      : beneficiary_name
                          ?.split(" ")[0]
                          .charAt()
                          .toUpperCase()}{" "}
                  </Stack>
                  <Typography sx={styles.beneficiaryName}>
                    {beneficiary_name}
                  </Typography>
                </Box>
              )
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default Beneficiaries;
