import Box from "@mui/material/Box";
import AccountCard from "../AccountCard/AccountCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import { userProfile } from "services/dashboard";
import { useQuery } from "@tanstack/react-query";
import { styles } from "./AccountsCarousel.styles";

const AccountsCarousel = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["profile"],
    queryFn: userProfile,
  });

  const getAccountBalance = (account_no) => {
    return data?.account_balances?.find(
      (item) => item.account_no === account_no
    );
  };

  return (
    <Box sx={styles.container}>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        loop={data?.customer?.accounts?.length > 1 ? true : false}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {isLoading ? (
          <SwiperSlide>
            <Box
              as="img"
              src="/img/dashboard3.webp"
              sx={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
            ></Box>
          </SwiperSlide>
        ) : (
          data?.customer?.accounts?.map(
            ({ account_no, account_type, active }, index) => (
              <SwiperSlide key={index}>
                <AccountCard
                  firstName={data?.customer?.customer_detail?.first_name}
                  lastName={data?.customer?.customer_detail?.last_name}
                  accountNo={account_no}
                  accountType={account_type}
                  bal={getAccountBalance(account_no)?.withdrawable_balance || 0}
                  ledger_balance={
                    getAccountBalance(account_no)?.ledger_balance || 0
                  }
                  active={active}
                />
              </SwiperSlide>
            )
          )
        )}
      </Swiper>
    </Box>
  );
};

export default AccountsCarousel;
