import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";
import { ReactComponent as DownIcon } from "assets/svg/arrow-down.svg";
import { style } from "components/base/Input/Input.styles";

const Input = styled("input")({});
const Icon = styled(DownIcon)({});

const Select = ({ options, placeholder, value, name, sx, formik, onChange }) => {
  const handleChange = (value) => {
    formik.setFieldValue(name, value);
    onChange(value)
  };
  return (
    <>
      <Autocomplete
        value={value}
        onChange={(e, value) => handleChange(value)}
        name={name}
        id={name}
        options={options}
        PaperComponent={Box}
        isOptionEqualToValue={() => true}
        renderInput={(params) => (
          <Box ref={params.InputProps.ref} sx={{ position: "relative" }}>
            <Input
              sx={{
                ...style,
                boxSizing: "border-box",
                outline: "none",
                fontFamily: "Manrope",
                "&::placeholder": {
                  color: "gray5.main",
                  fontWeight: 400,
                  fontSize: 14,
                },
                ...sx,
              }}
              placeholder={placeholder ? placeholder : ""}
              type="text"
              {...params.inputProps}
            />
            <Icon
              className="arrow-down"
              sx={{
                position: "absolute",
                right: 14,
                top: "40%",
              }}
            />
          </Box>
        )}
        sx={{ width: "100%" }}
      />
      {formik.touched[name] && (
        <Typography sx={{ fontSize: 12, color: "red.main" }}>
          {formik.errors[name]}
        </Typography>
      )}
    </>
  );
};

Select.defaultProps = {
  onChange: () => {}
}

export default Select;
