export const styles = {
    container: {
        background: "url(/img/dashboard3.webp)",
        backgroundSize: 'cover',
        borderRadius: "10px",
        zIndex: "301px",
        p: 2,
    },
    accountType: {
        fontSize: { xs: 16, sm: 10, lg: 18 },
        fontWeight: { xs: 500, lg: 600 },
    },
    accountNumber: {
        fontSize: { xs: 18, sm: 12, lg: 20 },
        fontWeight: { xs: 700, lg: 800 },
    },
    accountName: {
        fontSize: { xs: 20, sm: 14, lg: 22 },
        fontWeight: { xs: 700, lg: 800 },
        textTransform: "capitalize",
    },
    status: { fontSize: { xs: 12, sm: 8, lg: 12 }, textAlign: "center" },
    accountStatus: { fontSize: { xs: 20, sm: 14, lg: 22 }, fontWeight: 700 },
    availableBalance: {
        fontSize: { xs: 16, sm: 10, lg: 16 },
        fontWeight: { xs: 500, lg: 600 },
    },
    accountBalance: { fontSize: { xs: 26, sm: 16, lg: 30 }, fontWeight: 700 },
    hideText: { fontSize: { xs: 5, sm: 5, lg: 5 } },
    viewAccounts: {
        fontSize: { xs: 14, sm: 10, lg: 14 },
        fontWeight: 500,
        width: "fit-content",
    },
};