import Box from "@mui/material/Box";
//import AccountCard from "../AccountCard/AccountCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
//import { userProfile } from "services/dashboard";
//import { useQuery } from "@tanstack/react-query";
import { styles } from "./AccountsCarousel.styles";
import UserAccountCard from "../AccountsAndAdsSlider/UserAccountCard/UserAccountCard";
import { userAccounts } from "assets/data";
import { useContext } from "react";
import CorperateDashboardContext from "context/CorporateDashboardContext";

const AccountsCarousel = () => {
  /* const { data, isLoading } = useQuery({
    queryKey: ["profile"],
    queryFn: userProfile,
  }); */
  /* 
  const getAccountBalance = (account_no) => {
    return data?.account_balances?.find(
      (item) => item.account_no === account_no
    );
  }; */
  const { data } = useContext(CorperateDashboardContext);

  return (
    <Box sx={styles.container}>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        loop={data?.customer?.accounts?.length > 1 ? true : false}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {!data ? (
          <SwiperSlide>
            <Box
              as="img"
              src="/img/dashboard3.webp"
              sx={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
            ></Box>
          </SwiperSlide>
        ) : (
          data?.account_balances?.map(
            (
              {
                account_no,
                account_type,
                active,
                ledger_balance,
                withdrawable_balance,
              },
              index
            ) => (
              <SwiperSlide key={index}>
                <UserAccountCard
                  key={"1024434802"}
                  firstName={data?.first_name}
                  //lastName={data?.customer?.customer_detail?.last_name}
                  type={account_type || ""}
                  AcNumber={account_no}
                  bal={withdrawable_balance || 0}
                  ledger_balance={ledger_balance || 0}
                  // status={active}
                  color={"#D22F31"}
                  bgImg={userAccounts[1].bgImg}
                />
              </SwiperSlide>
            )
          )
        )}
      </Swiper>
    </Box>
  );
};

export default AccountsCarousel;
