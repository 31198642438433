import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import AccountsCarousel from '../AccountsCarousel/AccountsCarousel';
import { styles } from './AccountsAndAdsSlider.styles';
import AdvertCarousel from '../AdvertCarousel/AdvertCarousel';

const AccountsAndAdsSlider = () => {
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{xs: 2, sm: 1, lg: 2}}
      sx={styles.container}
    >
      <Grid item xs={12} sm={6} lg={5.5}>
        <AccountsCarousel />
      </Grid>
      <Grid item xs={12} sm={6} lg={6.3}>
        <AdvertCarousel />
      </Grid>
    </Stack>
  );
};

export default AccountsAndAdsSlider;
