import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TablePagination from "components/base/TablePagination2/TablePagination";
import TransactionsTable from "../TransactionsTable/TransactionsTable";
//import TransactionsTableElectricity from "../TransactionsTableElectricity/TransactionsTable";
//import TransactionsTableCable from "../TransactionsTableCable/TransactionsTable";
//import TransactionsTableAirtime from "../TransactionsTableAirtime/TransactionsTable";
//import TransactionsTableData from "../TransactionsTableData/TransactionsTable";
import { styles } from "./TransactionHistoryTable.styles";
//import { useSearchParams } from "react-router-dom";

const TransactionHistoryTable = ({
  loading,
  data,
  setPage,
  dataHead,
  action,
  dataLoading,
}) => {
  //

  //const [param] = useSearchParams();
  //const activeTab = param.get("tab");

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.header}>Status</Typography>

      <TransactionsTable
        data={data?.results}
        dataHead={dataHead}
        loading={loading}
      />
      {/* 
      {activeTab === "electricity" && (
        <TransactionsTable
          data={data?.results}
          dataHead={dataHead}
          loading={dataLoading}
        />
      )}

      {activeTab === "cable_tv" && (
        <TransactionsTable
          data={data?.results}
          dataHead={dataHead}
          loading={dataLoading}
        />
      )}

      {activeTab === "airtime" && (
        <TransactionsTable
          data={data?.results}
          dataHead={dataHead}
          loading={dataLoading}
        />
      )}

      {activeTab === "data" && (
        <TransactionsTable
          data={data?.results}
          dataHead={dataHead}
          loading={dataLoading}
        />
      )} */}

      <TablePagination transactions={data} action={action} />
    </Box>
  );
};

export default TransactionHistoryTable;
