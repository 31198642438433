import Box from "@mui/material/Box";
import ProfileBanner from "components/CorporateProfile/ProfileBanner/ProfileBanner";
import ProfileForm from "components/CorporateProfile/ProfileForm/ProfileForm";
import { styles } from "./Profile.styles";
import CorporateDashboardContext from "context/CorporateDashboardContext";
import { useContext } from "react";

const Profile = () => {
  const { data, isLoading } = useContext(CorporateDashboardContext);

  return (
    <Box sx={styles.container}>
      <ProfileBanner data={data} isLoading={isLoading} />
      <ProfileForm />
    </Box>
  );
};

export default Profile;
