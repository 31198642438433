import { SwitchTransition, CSSTransition } from "react-transition-group";
import { useLocation } from "react-router-dom";
import "./styles.css";

const PageTransition = ({ children, nodeRef,  }) => {
  const { pathname } = useLocation();

  return (
    <SwitchTransition>
      <CSSTransition
        key={pathname}
        nodeRef={nodeRef}
        addEndListener={(done) => {
          nodeRef.current.addEventListener("transitionend", done, false);
        }}
        classNames="fade"
      >
        <div ref={nodeRef} className="page">
          {children}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default PageTransition;
