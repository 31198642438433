export const styles = {
    tableHeader: { bgcolor: 'rgba(247, 249, 252, 0.8)' },
    headerText: { fontSize: 12, fontWeight: 600 },
    contentText: { fontSize: { xs: 12, sm: 14 }, fontWeight: 500 },
    actionText: {
        fontSize: { xs: 10, sm: 12 },
        fontWeight: 500,
        px: 1,
        borderRadius: '4px',
        cursor: 'pointer',
    },
    sendMoney: { color: 'green3.main', bgcolor: 'rgba(210, 47, 49, 0.2)' },
    delete: { color: 'red.main', bgcolor: 'rgba(240, 80, 0, 0.2)' },
    status: (color, bg) => ({
        width: 'fit-content',
        fontSize: 12,
        bgcolor: bg,
        color: color,
        py: '2px',
        px: '4px',
        borderRadius: '4px',
        listStyle: 'none',
        paddingLeft: '1.3rem',
        '&::before': {
            content: "''",
            display: 'inline-block',
            ml: '-1.3em',
            mr: 2,
            width: 6,
            height: 6,
            borderRadius: '50%',
            bgcolor: color,
        },
    }),
    textColor: (color) => ({ color: color, textTransform: 'uppercase' }),
};