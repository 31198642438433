export const styles = {
  tableHeader: { bgcolor: 'rgba(247, 249, 252, 0.8)' },
  headerText: { fontSize: 12, fontWeight: 600 },
  contentText: { fontSize: { xs: 12, sm: 14 }, fontWeight: 500 },
  status: (color, bg) => ({
    width: 'fit-content',
    fontSize: 12,
    bgcolor: bg,
    color: color,
    py: '2px',
    px: '4px',
    borderRadius: '4px',
    listStyle: 'none',
    paddingLeft: '1.3rem',
    '&::before': {
      content: "''",
      display: 'inline-block',
      ml: '-1.3em',
      mr: 2,
      width: 6,
      height: 6,
      borderRadius: '50%',
      bgcolor: color,
    },
  }),
  textColor: (color) => ({ color: color }),
};

export const styles2 = {
  closeX: {
    position: 'absolute',
    top: { xs: '5%', sm: '8%' },
    right: { xs: '5%', sm: '8%' },
    cursor: 'pointer',
  },
  closeIcon: {
    width: { xs: 18, sm: 32 },
    height: { xs: 18, sm: 32 },
    '&:hover': { transform: 'scale(1.05)' },
  },
  header: {
    fontSize: { xs: 30, md: 40 },
    fontWeight: 700,
    color: 'black.main',
    textAlign: 'center',
    mt: "-50px"
  },
  desc: {
    fontSize: { xs: 14, md: 16, lg: 18 },
    fontWeight: 500,
    color: 'black.main',
    textAlign: '',
    mt: '10px',
    mb: '10px'
  },
  closeBtn: {
    borderRadius: '10px',
    width: '100%',
    fontSize: { xs: 16, md: 20, lg: 22 },
    fontWeight: 600,
    px: 3,
    "&:first-child": {
      mr: '10px'
    },
    "&:first-child:hover":{
      background: 'none',
      border: '1px solid'
    },
    "&:last-child:hover":{
      backgroundColor: 'green3.main'
    }
  },
  
  img: {
    display: 'block',
    width: 200,
    mx: 'auto',
  },
};
