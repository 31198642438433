import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FlexDetails from "components/base/FlexDetails/FlexDetails";
import CustomModal from "components/base/Modal/Modal";
import Typography from "@mui/material/Typography";
import OTP from "components/base/OTP/OTP";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import { formatCurrency } from "utils/formatCurrency";
import { styles } from "./AirtimeAndDataModal.styles";

const AirtimeAndDataModal = ({
  open,
  handleClose,
  step,
  nextStep,
  formik,
  Subscribe,
  error,
  isLoading,
  openError,
  severity,
  message,
  closeError,
}) => {
  const closeAndresetModal = () => {
    handleClose()
    formik.resetForm()
  }

  return (
    <CustomModal open={open} handleClose={handleClose}>
      <DirectionSnackbar
        open={openError}
        severity={severity}
        message={message}
        handleClose={closeError}
      />

      <Box sx={{ mx: "auto" }}>
        {step === 0 && (
          <>
            <Typography sx={styles.header}>Confirm Transaction</Typography>

            <Stack spacing={1}>
              <FlexDetails
                left="Account"
                right={formik.values.account_source}
              />
              <FlexDetails
                left="Network"
                right={formik.values.networkProvider}
              />
              {formik.values.dataPlan && (
                <FlexDetails
                  left="Data Plan"
                  right={formik.values.dataPlan.replaceAll("|", " | ")}
                />
              )}
              <FlexDetails
                left="Amount"
                right={formatCurrency(formik.values.amount)}
              />
              <FlexDetails left="Phone Number" right={formik.values.phone} />
            </Stack>

            <Box mx="auto" width="fit-content" mt={{ xs: 3, sm: 5 }}>
              <Button
                variant="contained"
                color="green3"
                sx={styles.btn}
                onClick={nextStep}
              >
                Continue
              </Button>
            </Box>
          </>
        )}

        {step === 1 && (
          <>
            <Typography sx={styles.header}>Enter Transaction Pin</Typography>

            <Box sx={{ mx: "auto", width: "fit-content", mt: 5 }}>
              <OTP formik={formik} formikKey="transaction_pin" error={error} />

              <Box mx="auto" width="fit-content" mt={{ xs: 3, sm: 5 }}>
                <Button
                  variant="contained"
                  color="green3"
                  sx={styles.btn}
                  disabled={isLoading}
                  onClick={Subscribe}
                >
                  {isLoading ? "Please wait..." : "Pay"}
                </Button>
              </Box>
            </Box>
          </>
        )}
        {step === 2 && (
          <Box>
            <Box
              as="img"
              src="/img/successful_gif.gif"
              alt="successful"
              sx={styles.gif}
            />
            <Typography sx={{ ...styles.header, mb: 2, mt: "-20px" }}>
              Transaction Successful
            </Typography>

            <Typography sx={styles.subText}>
              Transfer receipt has been generated to your Bank Statement Page
            </Typography>

            <Stack justifyContent="center">
              <Button
                variant="contained"
                color="green3"
                sx={styles.btn}
                onClick={closeAndresetModal}
              >
                Continue
              </Button>
            </Stack>
          </Box>
        )}
      </Box>
    </CustomModal>
  );
};

export default AirtimeAndDataModal;
