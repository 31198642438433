import Typography from '@mui/material/Typography';
import './Switch.styles.scss';

const Switch = ({ name, label, value, onChange, disabled }) => {
  return (
    <label className='switch'>
      {label && (
        <Typography
          as='span'
          className='label'
          sx={{
            fontSize: { xs: 14, sm: 16 },
            fontWeight: { xs: 500, sm: 600 },
          }}
        >
          {label}
        </Typography>
      )}
      <input
        name={name}
        checked={value}
        disabled={disabled}
        onChange={onChange}
        type='checkbox'
      />
      <span className='slider'></span>
    </label>
  );
};

Switch.defaultProps = {
  disabled: false,
};

export default Switch;
