import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styles } from "components/base/TransferModal/TransferModal.styles";

const TransactionBillSuccessful = ({
  handleClick,
  title = "Transfer receipt has been generated to your Bank Statement Page",
}) => {
  return (
    <Box>
      <Box
        as="img"
        src="/img/successful_gif.gif"
        alt="successful"
        sx={styles.gif}
      />
      <Typography sx={{ ...styles.header, mt: 0, textAlign: "center" }}>
        Transaction Successful
      </Typography>

      <Typography sx={styles.subText}>{title}</Typography>

      <Box mx="auto" width="fit-content" mt={{ xs: 3, sm: 5 }}>
        <Button
          variant="contained"
          color="green3"
          sx={styles.btn}
          onClick={handleClick}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default TransactionBillSuccessful;
