export const styles = {
  inputStylesOne: {
    width: "45px",
    height: "45px",
    backgroundColor: "rgba(180, 246, 215, 0.4)",
    border: "none",
    borderRadius: "50%",
    fontSize: "20px",
    boxSizing: "border-box",
    marginRight: "10px",
  },
  inputStylesTwo: {
    width: "42px",
    height: "46px",
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",
    border: "none",
    bgcolor: "#EFF0F6",
    borderBottom: "1px solid #aaaaaa",
    borderRadius: "6px",
    boxSizing: "border-box",
    outline: "none",
  },
  inputStylesThree: {
    width: "45px",
    height: "45px",
    fontSize: "16px",
    borderRadius: "6px",
    marginRight: "8px",
  },
  error: {
    fontSize: 12,
    color: "red.main",
    mt: 1,
  },
};
