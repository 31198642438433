// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datePicker {
  padding: 4px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  margin-left: 10px;
  box-sizing: border-box;
  background-color: "#FBFBFB";
}

.datePicker:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AACA;EACI,YAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;EACA,iBAAA;EACA,sBAAA;EACA,2BAAA;AAAJ;;AAIE;EACE,aAAA;AADJ","sourcesContent":["\n.datePicker {\n    padding: 4px;\n    border: none;\n    border-radius: 4px;\n    font-size: 16px;\n    width: 100%;\n    margin-left: 10px;\n    box-sizing: border-box;\n    background-color: '#FBFBFB';\n\n  }\n  \n  .datePicker:focus {\n    outline: none;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
