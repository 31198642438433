import Stack from "@mui/material/Stack";
import { userProfile } from "services/dashboard";
import { useQuery } from "@tanstack/react-query";
import AccountTier from "components/BalanceEnquiry/AccountTier/AccountTier";

const AccountTierPage = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["profile"],
    queryFn: userProfile,
  });

  return (
    <Stack spacing={3}>
      <AccountTier data={data} isLoading={isLoading} />
    </Stack>
  );
};

export default AccountTierPage;
