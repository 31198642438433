import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dots from "components/base/Dots/Dots";
//import { Link } from "react-router-dom";
import { styles } from "./Auth.layout.styles";

const AuthLayout = ({ image, children }) => {
  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid item xs={12} sm={7} sx={styles.leftGrid}>
        <a href="https://kcmfb.com/">
          <Box
            as="img"
            src="/img/brand-logo.png"
            alt="KC brand logo"
            sx={styles.brandLogo}
          />
        </a>
        <>{children}</>
      </Grid>
      <Grid item xs={12} sm={5} sx={styles.rightGrid}>
        <Box sx={styles.fixedBackground}>
          <Box sx={styles.rightGridImgContainer}>
            <Box as="img" src={image} alt="sign in" sx={styles.rightGridImg} />
            <Box sx={styles.dotsPosition}>
              <Dots />
            </Box>
          </Box>
          <Box sx={styles.bubble1} />
          <Box sx={styles.bubble2} />
          <Box sx={styles.bubble3} />
          <Box sx={styles.bubble4} />
          <Box sx={styles.bubble5} />
          <Box sx={styles.bubble6} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
