export const styles = {
    background: {
        bgcolor: "white.main",
        boxShadow: "0px 14.625px 80.4375px -21.9375px rgba(43, 37, 37, 0.12)",
        borderRadius: "10.2375px",
        py: { xs: 3, md: 4 },
    },
    container: {
        width: { xs: "95%", sm: 500, xl: 631 },
    },
    header: {
        fontSize: { xs: 18, sm: 20, lg: 22 },
        fontWeight: 700,
        mb: 4,
    },
    hashed: {
        fontSize: { xs: 14, lg: 15 },
        fontWeight: 600,
    },
    cardName: {
        fontSize: { xs: 17, lg: 18 },
        fontWeight: 600,
    },
    count: {
        fontSize: { xs: 14, lg: 15 },
        fontWeight: 500,
        textAlign: "center",
        py: 5,
    },
    input: {
        backgroundColor: "#FFFFFF",
        borderColor: "#EFF0F6",
        borderRadius: "6px",
    },
    actionBox: {
        bgcolor: "rgba(180, 246, 215, 0.1)",
        border: "1px solid #D22F31",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
        borderRadius: "10px",
        p: 2,
    },
    noCardIssued: {
        height: "30vh",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #D22F31",
        borderRadius: "8px",
        mt: { xs: 3, sm: 4 },
    },
};