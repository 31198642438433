import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Hamburger from "components/Hamburger/Hamburger";
import Skeleton from "@mui/material/Skeleton";
// import { ReactComponent as NotificationIcon } from "assets/svg/notification.svg";
import { ReactComponent as LogoutIcon } from "assets/svg/logout.svg";
import { styled } from "@mui/material";
import { styles } from "./Navbar.Dashboard.styles";
// import { useFormik } from "formik";
import { useSessionStorage } from "hooks/useSessionStorage";
import { Link, useNavigate } from "react-router-dom";
import { userProfile } from "services/dashboard";
import { useQuery } from "@tanstack/react-query";
// import SearchBar from "components/Dashboard/SearchBar/SearchBar";

// const Notification = styled(NotificationIcon)(() => ({}));
const Logout = styled(LogoutIcon)(() => ({}));

const Navbar = ({ open, toggleHamburger }) => {
  const { removeSessionStorage } = useSessionStorage("__appUser");
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryKey: ["profile"],
    queryFn: userProfile,
  });

  const imgSrc = data?.customer?.image
    ? data?.customer?.image
    : data?.customer?.gender === "Male"
    ? "/img/male.webp"
    : "/img/female.webp";

  // const formik = useFormik({
  //   initialValues: {
  //     searchInput: "",
  //   },
  //   onSubmit: (values) => {
  //     alert(JSON.stringify(values));
  //   },
  // });

  const logoutUser = () => {
    removeSessionStorage();
    navigate("/auth/sign-in");
  };

  return (
    <Box as="header" sx={styles.container}>
      <Grid alignItems="center" container>
        <Grid item xs="auto" sx={styles.hamburger}>
          <Box sx={{ mt: { xs: "-6px", sm: 0 } }}>
            <Hamburger open={open} toggleHamburger={toggleHamburger} />
          </Box>
        </Grid>

        <Grid item xs="auto" sx={styles.brandLogo}>
          <Link to="/dashboard">
            <Box
              as="img"
              src="/img/brand-logo.png"
              alt="brand"
              sx={styles.brand}
            />
          </Link>
        </Grid>
        <Grid as="nav" item xs sx={styles.navItems}>
          <Stack
            direction="row"
            spacing={{ xs: 1, lg: 2 }}
            alignItems="center"
            justifyContent="flex-end"
          >
            {/* <SearchBar formik={formik} /> */}
            {/* <Box sx={styles.iconContainer(true)}>
              <Notification sx={styles.notificationIcon} />
            </Box> */}

            {isLoading ? (
              <Skeleton sx={{ ...styles.user, transform: "none" }} />
            ) : (
              <Link to="/auth/profile">
                <Box as="img" src={imgSrc} alt="brand" sx={styles.user} />
              </Link>
            )}

            <Button
              color="white"
              endIcon={<Logout sx={styles.logoutIcon} />}
              onClick={logoutUser}
            >
              Logout
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Navbar;
