import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import InputWithLabel from "components/base/InputWithLabel/InputWithLabel";
import CustomModal from "components/base/Modal/Modal";
import DirectionSnackbar from "components/base/SnackBar/SnackBar";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as VisibilityOff } from "assets/svg/visibility-off.svg";
import { ReactComponent as VisibilityOn } from "assets/svg/visibility-on.svg";
import { sendOtpReq } from "services/corporateDashboard";
import { changePasswordCorporate } from "services/Auth";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { styles } from "components/SavedBeneficiaries/AddNewCitmfbBeneficiaryModal/AddNewCitmfbBeneficiaryModal.styles";
import Input from "components/base/Input/Input";
//import { validateLetters } from "utils/validateNumbers";
import OTP from "components/base/OTP/OTP";
import { useState } from "react";

const ChangePinModal = ({
  open,
  handleClose,
  step,
  error,
  handleError,
  handleOpen,
  nextStep,
  errorOther,
}) => {
  const formik = useFormik({
    initialValues: {
      otp: "",
      confirmPassword: "",
      oldPassword: "",
      newPassword: "",
    },
  });

  const { mutate: ChangePassword, isLoading: changingPin } = useMutation({
    mutationFn: changePasswordCorporate,
    onSuccess: () => {
      nextStep();
      formik.resetForm();
      window.location = "/corporate-dashboard";
    },
    onError: (err) => {
      handleError(err);
      console.log(err);
      handleOpen();
    },
  });

  const {
    mutate: SendOtpReq,
    isLoading: reqOtp,
    data,
  } = useMutation({
    mutationFn: sendOtpReq,
    onSuccess: () => {
      nextStep();
      formik.resetForm();
    },
    onError: (err) => {
      handleError(err);
      console.log(err);
      handleOpen();
    },
  });
  //console.log(data);

  const handleChangePin = () => {
    const { newPassword, confirmPassword, otp, oldPassword } = formik.values;
    if (!newPassword || !confirmPassword || !otp || !oldPassword) {
      handleError("Fill all fields to proceed");
      return handleOpen();
    }

    if (newPassword === confirmPassword) {
      ChangePassword({
        old_password: oldPassword,
        new_password: newPassword,
        confirm_new_password: confirmPassword,
        otp: otp,
      });
    } else {
      handleError("New passwords does not match");
      handleOpen("error");
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword((prevS) => !prevS);
  };

  const toggleShowPassword2 = () => {
    setShowPassword2((prevS) => !prevS);
  };

  const toggleShowPassword3 = () => {
    setShowPassword3((prevS) => !prevS);
  };

  const handleSendOtpReq = () => {
    SendOtpReq();
  };

  return (
    <CustomModal
      open={open}
      handleClose={() => {
        handleClose();
        formik.resetForm();
      }}
    >
      <DirectionSnackbar
        open={error}
        handleClose={() => handleError("")}
        message={error}
        direction={{ vertical: "top", horizontal: "center" }}
      />

      <Box sx={styles.container} as="form">
        {step === 0 && (
          <Stack spacing={3} sx={styles.content} alignItems="center">
            <Typography sx={styles.header}>
              Request for OTP to change your password
            </Typography>
            <Button
              variant="contained"
              color="green3"
              sx={styles.btn}
              disabled={reqOtp}
              onClick={handleSendOtpReq}
            >
              {reqOtp ? "Please wait" : "Send Request"}
            </Button>
          </Stack>
        )}
        {step === 1 && (
          <>
            <Stack spacing={3} sx={styles.content} alignItems="center">
              <Typography sx={styles.header}>Change Password</Typography>
              {data?.detail}
              <InputWithLabel label="OTP Pin">
                <OTP formik={formik} formikKey="otp" variant={2} length={6} />
              </InputWithLabel>

              <Input
                type={showPassword ? "text" : "password"}
                name="oldPassword"
                placeholder="Old password"
                value={formik.values.oldPassword}
                onChange={formik.handleChange}
                formik={formik}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOn />
                      ) : (
                        <VisibilityOff className="dark-bg-visibility-off" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />

              <Input
                type={showPassword2 ? "text" : "password"}
                name="newPassword"
                placeholder="New password"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                formik={formik}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword2}
                      edge="end"
                    >
                      {showPassword2 ? (
                        <VisibilityOn />
                      ) : (
                        <VisibilityOff className="dark-bg-visibility-off" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />

              <Input
                type={showPassword3 ? "text" : "password"}
                name="confirmPassword"
                placeholder="Confirm password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                formik={formik}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword3}
                      edge="end"
                    >
                      {showPassword3 ? (
                        <VisibilityOn />
                      ) : (
                        <VisibilityOff className="dark-bg-visibility-off" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />

              <Box sx={{ height: 10 }} />
              <div style={{ width: "100%" }}>{errorOther}</div>

              <Box mx="auto" width="fit-content" mt={{ xs: 3, sm: 5 }}>
                <Button
                  variant="contained"
                  color="green3"
                  sx={styles.btn}
                  disabled={changingPin}
                  onClick={handleChangePin}
                >
                  {changingPin ? "Please wait" : "Change Password"}
                </Button>
              </Box>
            </Stack>
          </>
        )}

        {step === 2 && (
          <>
            <Box
              as="img"
              src="/img/successful_gif.gif"
              alt="successful"
              sx={styles.gif}
            />
            <Stack spacing={3} sx={styles.content} alignItems="center">
              <Typography sx={styles.header}>
                Password Changed Successfully
              </Typography>
              <Typography sx={styles.subText}>
                Your Password has been successfully changed.
              </Typography>
            </Stack>

            <Box mx="auto" width="fit-content" mt={{ xs: 3, sm: 5 }}>
              <Button
                variant="contained"
                color="green3"
                sx={styles.btn}
                onClick={() => {
                  handleClose("changePin");
                  formik.resetForm();
                }}
              >
                Done
              </Button>
            </Box>
          </>
        )}
      </Box>
    </CustomModal>
  );
};

export default ChangePinModal;
