import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Input from "components/base/Input/Input";
import { ReactComponent as DownloadIcon } from "assets/svg/download.svg";
import { ReactComponent as TrashIcon } from "assets/svg/trash.svg";
import imageCompression from "browser-image-compression";

const options = {
  maxSizeMB: 0.2,
  maxWidthOrHeight: 1080,
  useWebWorker: false,
};

const compress = async (file, name, formik) => {
  imageCompression(file, options)
    .then((compressedFile) => {
      formik.setFieldValue(name, compressedFile);
    })
    .catch((error) => {
      console.log(error.message);
    });
};

const DragAndDropToUpload = ({ styles, formik, formikKey, label, type }) => {
  const handleChange = (event, name) => {
    compress(event.currentTarget.files[0], name, formik);
  };

  const clearFile = (name) => {
    formik.setFieldValue(name, "");
  };

  const dragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const fileDrop = (event, name) => {
    event.preventDefault();
    event.stopPropagation();

    compress(event.currentTarget.files[0], name, formik);
  };

  return (
    <Box sx={{ flex: "1" }}>
      <Typography sx={styles.thirdFormHeader}>
        {type === "take" ? "" : "Upload your"} {label}
      </Typography>
      <div
        onDragEnter={dragEnter}
        onDragOver={dragEnter}
        onDrop={(e) => fileDrop(e, formikKey)}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing="5px"
          sx={styles.thirdForm}
        >
          {type === "take" ? (
            ""
          ) : (
            <DownloadIcon style={{ width: 25, height: 25 }} />
          )}

          {type === "take" ? (
            ""
          ) : (
            <Typography variant="caption">Drag and Drop here</Typography>
          )}

          {type === "take" ? "" : <Typography variant="caption">or</Typography>}

          {type === "take" ? (
            <Button variant="contained" color="blue2" sx={styles.selectFileBtn}>
              <Input
                type="file"
                name={formikKey}
                onChange={(e) => handleChange(e, formikKey)}
                sx={styles.file}
              />
              Take Photo
            </Button>
          ) : (
            <Button variant="contained" color="blue2" sx={styles.selectFileBtn}>
              <Input
                type="file"
                name={formikKey}
                onChange={(e) => handleChange(e, formikKey)}
                sx={styles.file}
              />
              Select file
            </Button>
          )}

          {Boolean(
            formik?.values[formikKey]?.name || formik.errors[formikKey]
          ) && (
            <Typography
              variant="caption"
              mt={1}
              sx={{
                color: formik.errors[formikKey] ? "red.main" : "black",
                textAlign: "center",
                px: 1,
              }}
            >
              {formik?.values?.[formikKey]?.name || formik.errors[formikKey]}

              {formik?.values?.[formikKey]?.size && (
                <Typography variant="caption" as="span" sx={{ ml: 1 }}>
                  {(formik?.values[formikKey]?.size / 1024).toFixed(0)}
                  KB
                </Typography>
              )}

              {!formik.errors[formikKey] && (
                <TrashIcon
                  style={{
                    width: "20px",
                    height: "20px",
                    display: "inline-block",
                    verticalAlign: "bottom",
                    marginLeft: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => clearFile(formikKey)}
                />
              )}
            </Typography>
          )}
        </Stack>
      </div>
    </Box>
  );
};

export default DragAndDropToUpload;
