import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const DirectionSnackbar = ({
  open,
  handleClose,
  message,
  direction,
  severity,
}) => {
  return (
    <Snackbar
      anchorOrigin={direction}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <MuiAlert
        variant="filled"
        onClose={handleClose}
        severity={severity}
        sx={{ width: "100%" }}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

DirectionSnackbar.defaultProps = {
  direction: { vertical: "top", horizontal: "right" },
  severity: "error",
};

export default DirectionSnackbar;
