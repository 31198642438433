export const createAccountBody = (values) => {
  const formData = new FormData();
  formData.append('bank_id', process.env.REACT_APP_BANK_ID);
  formData.append('bvn', values.bvn);
  formData.append('phone_number', values.phone);
  formData.append('first_name', values.first_name);
  formData.append('last_name', values.last_name);
  formData.append('other_name', values?.middle_name  || '');
  formData.append('gender', values.gender.toLowerCase());
  formData.append('dob', values.dob);
  formData.append('nin', values.nin);
  formData.append('email', values.email);
  formData.append('address', values.residentialAddress);
  formData.append('signature_image', values.signature_image);
  formData.append('image', values.image);
  formData.append('utility', values.utility);
  formData.append('valid_id', values.valid_id);

  return formData;
};

export const upgradeTierBody = (values) => {
  const formData = new FormData();
  formData.append('tier_id', values.tier_id);
  formData.append('utility_image', values.utility_image);
  formData.append('valid_id_image', values.valid_id_image);

  return formData;
};
